import { Pagination } from "@mui/material";
import BreadcrumbsComponent from "../../components/Breadcrumbs/BreadcrumbsComponent";
import Boton from "../../components/Boton";
import { useEffect, useState } from "react";
import MyAssetsCreate from "./MyAssetsCreate/MyAssetsCreate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, NavDropdown } from "react-bootstrap";
import { DropdownSubmenu, NavDropdownMenu } from "react-bootstrap-submenu";
import { IsUnauthorized, httpService } from "../../helpers/ApiService";
import { useNavigate } from "react-router-dom";
import BadgesProjects from "../../components/BadgesProjects/BadgesProjects/BadgesProjects";
import { classNameProyect } from "../../helpers/functions";

export default function PageMyAssets() {
  const navigate = useNavigate();
  const [componenteActivo, setComponenteActivo] = useState("index");
  //const [idActivo, setIdActivo] = useState(0);
  const [activo, setActivo] = useState(null);
  const [misActivos, setMisActivos] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [countResult, setCountResult] = useState(0);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [next, setNext] = useState(0);
  const [previous, setPrevious] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    let _offset = (page - 1) * limit;
    setOffset(_offset);
    getMisActivos(limit, _offset);
  }, [page]);
  const getMisActivos = (_limit = limit, _offset = offset) => {
    const url = `/asset/list/myassets/?limit=${_limit}&offset=${_offset}`;

    return httpService(`${url}`)
      .get()
      .then((res) => {
        if (res instanceof Object) {
          if (res.results && res.count) {
            setMisActivos(res.results);
            setCountResult(res.results.length);
            if (res.next) {
              let paramsUrl = res.next.split("?");
              if (paramsUrl.length > 1) {
                const urlParams = new URLSearchParams(paramsUrl[1]);
                const _limit = urlParams.get("limit");
                const _offset = urlParams.get("offset");
                setLimit(_limit);
                setNext(_offset);
                let _pageCount = Math.ceil(res.count / _limit);
                setPageCount(_pageCount);
              }
            }
            if (res.previous) {
              let paramsUrl = res.previous.split("?");
              if (paramsUrl.length > 1) {
                const urlParams = new URLSearchParams(paramsUrl[1]);
                console.log("urlParams:", urlParams);
                const param1 = urlParams.get("limit");
                const param2 = urlParams.get("offset");
                console.log("limit:", param1);
                console.log("offset:", param2);
              }
            }
          } else {
            setMisActivos([]);
            setPageCount(0);
          }
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response:", err);
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        } else {
        }
        return;
      });
  };
  const crearAvtivo = () => {
    //setIdActivo(0);
    setActivo(null);
    setComponenteActivo("create");
  };
  const setIndex = (result = null) => {
    setActivo(null);
    if (result) {
      getMisActivos();
    }
    setComponenteActivo("index");
  };
  
  const handlerClickReloadData = async(result = null) => {
    const id = activo.id;
    await getMisActivos();
    console.log("gerDetailProject------->handlerClickReloadData id:",id);
    console.log("gerDetailProject------->handlerClickReloadData",misActivos);
    const refreshActivo = misActivos.find(_activo=>_activo.id = id);
    console.log("gerDetailProject------->handlerClickReloadData refreshActivo",refreshActivo);
    if(refreshActivo)
    setActivo(refreshActivo)
    
  }



  useEffect(() => {
    if (activo) {
      setComponenteActivo("update");
    }
  }, [activo]);
  const viewActivo = (_activo) => {
    console.log("_activo:", _activo);
    setActivo(_activo);
  };
  const handleChangePagination = (e, value) => {
    setPage(value);
  };
  return (
    <div className="page-my-assets container-animated">
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <BreadcrumbsComponent />
        </div>
      </div>
      {componenteActivo && componenteActivo === "index" && (
        <div className="row mt-4 index container-animated">
          <div style={{ display: "flex" }} className="col-md-12">
            <Boton
              className={"btn _btn-primary font-14"}
              label="Crear activo"
              borderRadius="10px"
              handlerClick={crearAvtivo}
            />
          </div>
          <div className="col-md-12">
            <div className={"table-container-responsive"}>
              <div className="table-responsive">
                <table className="table table-borderless">
                  <thead>
                    <tr className="head border-top-0">
                      <th
                        scope="col"
                        style={{ minWidth: "100px" }}
                        className="first-col"
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        style={{ minWidth: "100px" }}
                        className="middle-col"
                      >
                        Proyecto
                      </th>
                      <th
                        scope="col"
                        style={{ minWidth: "150px" }}
                        className="middle-col"
                      >
                        Tipo
                      </th>
                      <th
                        scope="col"
                        style={{ minWidth: "100px" }}
                        className="middle-col"
                      >
                        Categoría
                      </th>
                      <th
                        scope="col"
                        style={{ minWidth: "100px" }}
                        className="middle-col"
                      >
                        Estado
                      </th>
                      <th
                        scope="col"
                        style={{ minWidth: "100px" }}
                        className="last-col"
                      >
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {misActivos.map((data, i) => (
                      <tr
                        key={"row-component-investments-" + i}
                        className="row-component-investments"
                      >
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                          className="col-component-investments"
                        >
                          <div
                            style={{ textAlign: "center" }}
                            className="center"
                          >
                            {data.id}
                          </div>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                          className="col-component-investments"
                        >
                          <div
                            style={{ textAlign: "center" }}
                            className="center"
                          >
                            {data.nombre}
                          </div>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                          className="col-component-investments"
                        >
                          <div className="badge">
                            <BadgesProjects
                              labelStyle={{
                                background: data.tipo_proyecto_nombre.color,
                              }}
                              className={`${
                                classNameProyect[
                                  data.tipo_proyecto_nombre.nombre
                                ]
                                  ? classNameProyect[
                                      data.tipo_proyecto_nombre.nombre
                                    ]
                                  : "bg-blue"
                              }`}
                              text={data.tipo_proyecto_nombre.nombre}
                            />
                          </div>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                          className="col-component-investments"
                        >
                          <div className="center col-fecha-inversion">
                            {data.categoria_nombre}
                          </div>
                        </td>

                        {/* <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                          className="col-component-investments"
                        >
                          <div
                            style={{ fontSize: "12px" }}
                            className="center col-fecha-inversion"
                          >{`${data.pais_name}, ${data.region_name}, ${data.ciudad_name}`}</div>
                        </td> */}
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                          className="col-component-investments"
                        >
                          <div className="center col-fecha-inversion">
                            {data.estado_aprobacion}
                          </div>
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                          className="col-component-investments"
                        >
                          <div className="center col-fecha-inversion">
                            <div className="center col-propiedad">
                              <NavDropdownMenu
                                className="hide-arrow"
                                title={
                                  <FontAwesomeIcon
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      color: "#787878",
                                      cursor: "pointer",
                                    }}
                                    /* onClick={props.handlerClick.hideMenu} */
                                    icon={faEllipsisV}
                                  />
                                }
                                id={"collasible-nav-dropdown" + data.id}
                              >
                                <NavDropdown.Item
                                  onClick={() => viewActivo(data)}
                                >
                                  Editar
                                </NavDropdown.Item>
                              </NavDropdownMenu>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="pagination-right">
                  <div className="box-right">
                    <Pagination
                      count={pageCount}
                      page={page}
                      onChange={handleChangePagination}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {componenteActivo &&
        (componenteActivo === "create" || componenteActivo === "update") && (
          <div className="row mt-4 create container-animated">
            <MyAssetsCreate
              scenario={componenteActivo}
              activo={activo}
              handlerClick={setIndex}
              handlerClickReloadData={handlerClickReloadData}
            />
          </div>
        )}
    </div>
  );
}
