import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadcrumbsComponent from "../components/Breadcrumbs/BreadcrumbsComponent";

export default function Page404({isdelay=true}) {
  const [delay, setDelay] = useState(false);
  useEffect(() => {
    if(isdelay){
      setTimeout(() => {
        setDelay(true);
      }, 600);
    }else{
      setDelay(true);
    }
    
  }, [delay]);
  return (
    <div className="content-wrapper ">
      {delay && (
        <section className="content-header container-animated">
          <div className="container-fluid">
            <div className="row mb-2">
              
              <div className="col-sm-6">
              <BreadcrumbsComponent  />
              </div>
            </div>
          </div>
          {/* <!-- /.container-fluid --> */}
        </section>
      )}

      {delay && (
        <section className="container-animated">
          <div className="error-page">
            <h2 className="headline text-warning"> 404</h2>

            <div className="error-content">
              <h3>
                <i className="fas fa-exclamation-triangle text-warning"></i>{" "}
                ¡Ups! Página no encontrada.
              </h3>

              <p>
                No pudimos encontrar la página que estabas buscando. Mientras
                tanto, puedes <Link style={{color:"#112e6d"}} to="/dashboard">regresar a Mi tablero</Link>
              </p>

              {/* <form className="search-form">
              <div className="input-group">
                <input
                  type="text"
                  name="search"
                  className="form-control"
                  placeholder="Search"
                />

                <div className="input-group-append">
                  <button
                    type="submit"
                    name="submit"
                    className="btn btn-warning"
                  >
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>
             
            </form> */}
            </div>
            {/* <!-- /.error-content --> */}
          </div>
          {/* <!-- /.error-page --> */}
        </section>
      )}
      {!delay && (
        <section >
          <div className="loader-container">
            <div className="spinner"></div>
          </div>
        </section>
      )}
    </div>
  );
}
