// Overlay use className props to pass style properties to child component.
// To make this component work add className props to your child component manually.
// Here an example: https://gist.github.com/Miniplop/8f87608f8100e758fa5a4eb46f9d151f

import React, { useState } from "react";
/* import { Brand, TextField, Button } from "components"; */
import styles from "./FormForgetPassword.module.css";
import Logo from "../../assets/Logo/Logo";
import {
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import Boton from "../../components/Boton";
import { Link } from "react-router-dom";
import { httpService } from "../../helpers/ApiService";

export const FormForgetPassword = () => {
  const [authFormData, setAuthFormData] = useState({
    email: "",
  });
  const [errFormData, setErrFormData] = useState({
    email: "",
  });
  const onChange = (event) => {
    let data = { ...authFormData };

    data[event.name] = event.value;
    let attr = event.name;
    let errData = { ...errFormData };

    if (!data[event.name]) {
      errData[attr] = "Campo requerido";
      setErrFormData(errData);
    } else {
      errData[attr] = "";
      setErrFormData(errData);
    }
    setAuthFormData(data);
  };
  const handlerClick = async () => {
    let err = false;
    if (!authFormData.email) {
      let _errFormData = { ...errFormData };

      if (!authFormData.email) {
        _errFormData.email = "Campo requerido";
        setErrFormData(_errFormData);
      }

      err = true;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!err && !emailRegex.test(authFormData.email)) {
      let _errFormData = { ...errFormData };
      _errFormData.email = "Correo inválido";
      setErrFormData(_errFormData);
      err = true;
    }
    if (err) {
      return;
    }
    console.log("enviando...");
    sendData()
  };
  const sendData = () => {
    //password/reset/done/<slug:slug>/
    const id = toast.loading("Solicitando...", { className: "foo-bar" });
    const url = "/user/password/reset/";
    const formData = new FormData();
    formData.append("email",authFormData.email)
    return httpService(url, formData)
      .post()
      .then(async (res) => {
        console.log("saveData:", res);
        toast.update(id, {
          render: "Se a enviado un correo con las instrucciones para cambiar la contraseña",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
          className: "foo-bar",
        });
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.data.errors);
        let errors = [];
        err.data.errors.forEach(detail=>{
          console.error("XXXX Response status:", detail);
          errors.push(detail.detail)
        })
        toast.update(id, {
          render: errors.join(", "),
          type: "error",
          isLoading: false,
          autoClose: 10000,
          closeOnClick: true,
          className: "foo-bar",
        });
        //toast.dismiss()
        return err;
      });
  };
  return (
    <div className="for">
      <div className="logo-img">
        <Logo />
        <div className="form-title three text-field-box form-box">
          <h1 className="form-title-sup">
            <span className="color-main">Recuperar </span>
            <span className="color-tertiary">Contraseña</span>
          </h1>
          <div className="form-title-sub">
            Por favor igresa la dirección de correo electrónico asociada a tu
            cuenta y te enviaremos un enlace para que restablescas tu
            contraseña.
          </div>
        </div>
        <div className="two">
          <div className="text-field-box">
            <TextField
              onChange={(e) => onChange(e.target)}
              value={authFormData.email}
              placeholder="Correo electrónico"
              className="textField form-box"
              name="email"
            />
            {errFormData.email && (
              <div>
                <label className="form-error">{errFormData.email}</label>
              </div>
            )}
          </div>
          <div className="three text-field-box form-box">
            <Boton
              className="btn button-secondary form-box"
              label="Enviar"
              redirect="/login"
              variant="contained"
              handlerClick={handlerClick}
            >
              Enviar
            </Boton>
          </div>
          <div className="col-md-12 mb-2 mt-4">
            <Link
              to={"/login"}
              replace={true}
              className="link"
            >
              <span style={{ color: "var(--tertiary-main)" }}>
                Volver al Login
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
{
  /* <div className={styles.formForgetPassword}>
      
      <div className={styles.authFormResetPassword}>
        <div className={styles.sharp}>
          <p className={styles.forgotYourPassword}>
            ¿Olvidaste tu contraseña?
          </p>
          <p className={styles.pleaseEnterTheEma}>
            Por favor igresa la dirección de correo
            electrónico asociada a tu cuenta y te enviaremos
            un enlace para que restablescas tu contraseña.
            <br />
          </p>
        </div>
        <div className={styles.sharp}>
          <TextField className={styles.textField} />
          <div className={styles.two}>
            <Button title="11" className={styles.button} />
            <Button title="22" className={styles.button} />
          </div>
        </div>
      </div>
    </div> */
}
