import React, { useEffect, useRef, useState } from "react";
import styles from "./Chat.module.css";
import "./Chat.css";
import user from "../../assets/user-d.png";
import { IsUnauthorized, httpService } from "../../helpers/ApiService";
import { getEnviroments } from "../../helpers/enviroments";
import { useNavigate } from "react-router-dom";
import { GetAuth } from "../../helpers/Auth";
export default function Chat(props) {
  const divRef = useRef(null);
  const [userImage, setUserImage] = useState(user);
  const [messages, setMessages] = useState([]);
  const [auth, setAuth] = useState(null);
  const [title, setTitle] = useState('Observaciones Activo');

  const navigate = useNavigate();
  const getUserMe = async () => {
    return httpService("/user/me/")
      .get()
      .then((res) => {
        if (res && res.id && res.perfil_image) {
          let perfil_image = res.perfil_image;
          setUserImage(getEnviroments().DOMAIN + perfil_image);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getMessages = async () => {
    setMessages(props.messages);
    console.log("getMessages Chat", props.messages);
    setTimeout(() => {
      divRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "start",
      });
    }, 50);
    /* return httpService(`/asset/list/feedback/messages/${props.id}/?limit=50&offset=0"`)
      .get()
      .then((res) => {
        console.log("getMessages:", res);
        if (res && res.results) {
          let messages = res.results
          setMessages(messages.reverse());
        } else {
          setMessages([]);
        }
        setTimeout(() => {
          divRef.current.scrollIntoView({ behavior: 'smooth', block: "start", inline: "start" });
         
        }, 50);
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          
        }
        return;
      }); */
  };
  useEffect(() => {
    let AUTH = GetAuth();
    if (AUTH) {
      setAuth(AUTH);
      getUserMe();
    }
    if(props.title){
      setTitle(props.title)
    }
    /* console.log("AUTH:", AUTH); */
  }, []);
  useEffect(() => {
    getMessages();
  }, [props.messages]);

  const onSubmit = (event) => {
    if (event.keyCode === 13) {
      sendMessage(event.target.value);
      event.target.value = "";
    }
  };
  const sendMessage = (message) => {
    console.log("sendMessage", message);
    let payload = new FormData();
    payload.append("activo", `${props.id}`);
    payload.append("mensaje", `${message}`);
    return httpService(`/asset/send/feedback/`, payload)
      .post()
      .then((res) => {
        console.log("sendMessage:", res);
        if (res && res.activo) {
          //setMessages(res.results);
          getMessages();
          divRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        } else {
          //setMessages([]);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };

  return (
    <>
      <div className="container">
        <div className="row clearfix">
          <div className="col-lg-12">
            <div className="card chat-app">
              <div className="chat">
                <div className="chat-header clearfix">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="chat-about">
                        <h6 className="m-b-0">{title}</h6>
                        {/* <small>Last seen: 2 hours ago</small> */}
                      </div>
                    </div>
                    {/* <div className="col-lg-6 hidden-sm text-right">
                      <a
                        href="javascript:void(0);"
                        className="btn btn-outline-secondary"
                      >
                        <i className="fa fa-camera"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        className="btn btn-outline-primary"
                      >
                        <i className="fa fa-image"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        className="btn btn-outline-info"
                      >
                        <i className="fa fa-cogs"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        className="btn btn-outline-warning"
                      >
                        <i className="fa fa-question"></i>
                      </a>
                    </div> */}
                  </div>
                </div>
                <div className="chat-history">
                  <ul className="m-b-0 messages-list">
                    {messages.map((data) => {
                      return (
                        <li
                          ref={divRef}
                          key={`message-${data.id}`}
                          className={"clearfix " + `message-${data.id}`}
                        >
                          {data.remitente.id === auth.user.id && (
                            <>
                              <div
                                style={{ width: "calc(100% - 35px)" }}
                                className="message-data text-right"
                              >
                                <div className="row">
                                  <div className="col-md-10">
                                    <span className="message-data-time">
                                      {auth && auth.user.email}
                                    </span>
                                  </div>
                                  <div className="col-md-2">
                                    <img src={userImage} alt="@" />
                                  </div>
                                </div>
                              </div>
                              <div className="message other-message float-right">
                                {data.mensaje}
                                {data.archivo_adjunto && (
                                  <div className="adjunto">
                                    Adjunto:{" "}
                                    <a
                                      href={data.archivo_adjunto}
                                      target="_blank"
                                    >
                                      abrir
                                    </a>
                                  </div>
                                )}
                              </div>
                              <br />
                              <span
                                style={{ display: "inline-block" }}
                                className="message-data-time"
                              >
                                <small>{data.fecha}</small>
                              </span>
                            </>
                          )}
                          {data.remitente.id !== auth.user.id && (
                            <>
                              <div className="message-data">
                                {data.remitente.nombre}
                              </div>
                              <div className="message my-message">
                                {data.mensaje}
                                {data.archivo_adjunto && (
                                  <div className="adjunto">
                                    Adjunto:{" "}
                                    <a
                                      href={data.archivo_adjunto}
                                      target="_blank"
                                    >
                                      abrir
                                    </a>
                                  </div>
                                )}
                              </div>
                              <br />
                              <span
                                style={{ display: "inline-block" }}
                                className="message-data-time"
                              >
                                <small>{data.fecha}</small>
                              </span>
                            </>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="chat-message clearfix">
                  {props.send && (
                    <div className="input-group mb-0">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fa fa-send"></i>
                        </span>
                      </div>

                      <input
                        onKeyDown={(e) => onSubmit(e)}
                        type="text"
                        className="form-control"
                        placeholder="Escriba su mensaje aquí..."
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
