import { useEffect, useState } from "react";
import "./LoaderComponentStyle.css";
export default function LoaderComponent({ loader = false }) {
    const [hide, setHide] = useState(true);
    useEffect(()=>{
        if(loader){
            setHide(false);
        }else{
            setTimeout(() => {
                setHide(true);
            }, 1000);
        }
    },[loader])

  return (
    <div style={{display:(hide?"none":"initial")}} >
      <div className={loader?"wrapper-loaader fade-in":"wrapper-loaader fade-out"}>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </div>
    </div>
  );
}
