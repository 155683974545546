import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Boton from "../../../components/Boton";
import FormFloatingLabel from "../../../components/FormFloatingLabel";
import { useState } from "react";
import { AddAuth, GetAuth } from "../../../helpers/Auth";
import { IsUnauthorized, httpService } from "../../../helpers/ApiService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Correspondence(props) {
  const navigate = useNavigate();
  const [initialData, setInitialData] = useState(null);
  const [userData, setUserData] = useState();
  const [loadUserData, setLoadUserData] = useState(null);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState(null);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState(null);

  const ATTR = "has_resident_info";
  const [disableForm, setDisableForm] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const parseUserData = {
    doc_country_expedition: {
      parse: "resident_country"
    },
    /* doc_region_expedition: {
      parse: "resident_region"
    },
    doc_city_expedition:{
      parse:"resident_city"
    } */
  };

  useEffect(() => {
    console.log("Parent data:", props.data);
    let userData = GetAuth();
    console.log("userData:", userData);
    let profile = userData.profileCompleted.data;
    setDisableForm(profile[ATTR]);
    const loadData = async () => {
      
      await getCountries();
      await loadInitialData();
      
    };
    loadData();
  }, []);
  useEffect(() => {
    //console.log("get departments");
    setDepartments([]);
    setDepartment(null);
    setCities([]);
    setCity(null);
    if (country) {
      console.log("get departments country:", country);
      (async () => {
        await getDeparments();
      })();
    }
  }, [country]);
  useEffect(() => {
    //console.log("get departments");
    setCities([]);
    setCity(null);
    (async () => {
      if (department) {
        console.log("get departments country:", country);
        await getCities();
      }
    })();
  }, [department]);
  useEffect(() => {
    if (userData) {
      let data = {};
      for (let key in userData) {
        if (parseUserData[key]) {
          console.log(
            "userData[" + key + "] =======->",
            parseUserData[key].parse,
            userData[key]
          );
          let index = parseUserData[key].parse;
          data[index] = userData[key];
          let _data = { ...loadUserData, ...data };
          data = _data;
        }
      }
      setLoadUserData(data);
    }
  }, [userData]);
  useEffect(() => {
    if (userData) {
      setTimeout(() => {
        for (let key in loadUserData) {
          setValue(key, loadUserData[key]);
        }
      }, 100);
    }
  }, [loadUserData]);

  /************************** */
  const getBasicdata = async () => {    
    return httpService("/user/basicdata/update_read/")
      .get()
      .then(async (res) => {
        console.log("getBasicdata:", res);
        if (res instanceof Object) {
          setUserData(res);
          let _country = "";
          let _department = "";
          if (res) {
            _country = res.doc_country_expedition;
            setCountry(_country)
            if(parseUserData["doc_country_expedition"] && res.doc_country_expedition){
              await getDeparments(_country)
            }
            if(parseUserData["doc_region_expedition"] && res.doc_region_expedition){
              _department = res.doc_region_expedition;
              /* setDepartment(_department) */
              await getCities(_department,_country)
            }
            setUserData(res);
          }
        }

        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getCountries = async () => {
    return httpService("/cities/countries/")
      .get()
      .then((res) => {
        if (res instanceof Array) {
          setCountries(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getDeparments = async (__country = null) => {
    let _country = country;
    if(__country){
      _country = __country;
    }
    return httpService(`cities/countries/${_country}/regions/`)
      .get()
      .then((res) => {
        if (res instanceof Array) {
          setDepartments(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getCities = async (__department = null, __country = null) => {
    console.log("XXX getCities",__country);
    let _country = country;
    if(__country){
      _country = __country;
    }
    let _department = department;
    if(__department){
      _department = __department;
    }
    return httpService(
      `cities/countries/${_country}/regions/${_department}/cities/`
    )
      .get()
      .then(async (res) => {
        if (res instanceof Array) {
          await setCities(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };

  const loadInitialData = async () => {
    return httpService("user/residential/update_read/")
      .get()
      .then(async (res) => {
        console.log("getBasicdata:", res);
        if (res instanceof Object) {
          console.log("getBasicdata Object:", res);
          if (res && res.resident_country && res.resident_address && res.resident_zip && res.resident_phone) {
            setInitialData(res);

            let _country = res.resident_country;
            setCountry(_country)
            await getDeparments(_country)
            let _department = res.resident_region;
            /* setDepartment(_department) */
            await getCities(_department,_country)
            setTimeout(() => {
              for (let key in res) {
                setValue(key, res[key]);
              }
            }, 100);
          }else{
            await getBasicdata();
          }
        }

        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const onChangeCountry = async (event) => {
    console.log("onChangeCountry:", event.target.value);
    /* setDepartments([]);
    setCities([]); */
    /* let _userBasicData = { ...userBasicData };
    _userBasicData["birth_country"] = event.target.value;
    setUserBasicData(_userBasicData); */
    await setCountry(event.target.value);
    //getDeparments(event.target.value);
  };
  const onChangeDepartment = async (event) => {
    console.log("onChangeDepartment:", event.target.value);
    await setDepartment(event.target.value);
  };
  const onChangeCity = async (event) => {
    console.log("onChangeCity:", event.target.value);
    await setCity(event.target.value);
  };
  /************************* */

  const onSubmit = async(data) => {
    console.log("onSubmit:", data);
    const formData = new FormData();
    for(let key in data){
      formData.append(key,`${data[key]}`)
    }
    console.log("onSubmit formdata:", formData);
    const id = toast.loading("Enviando...");
    const result = await sendData(formData);
    if(result){
      toast.update(id, {
        render: "Se registraron los datos",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
      props.actionButton(data);
    }else{
      toast.update(id, {
        render: "Se ha producido un error inténtelo más tarde",
        type: "error",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
    }
    console.log("result:",result);
    
  };
  const sendData = async(formData) => {
    return httpService(
      `user/residential/create/`,formData
    )
      .post()
      .then(async (res) => {
        let userData = GetAuth();
        let newData = { ...userData };
        newData.profileCompleted.data[ATTR] = true;
        AddAuth(newData)
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        
        return;
      });
  }
  const onNext = () => {
    props.actionButton(props.data);
  };
  return (
    <div className="component-datos-basicos container-animated">
      <form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <fieldset className="row" disabled={disableForm}>
          <div
            className="page-security-head"
            style={{ padding: "5px 0px 15px" }}
          >
            <div className="page-security-subtitle">
              Datos de correspondencia
            </div>
            <div className="page-security-body">
              Utilizar mis datos como datos de correspondencia
              <div className="checkbox-text">
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    {...register("correspondence_data", { required: false })}
                    className="custom-control-input"
                    id="customSwitches2"
                    /* defaultChecked={verifyEmail} */
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customSwitches2"
                  ></label>
                </div>
              </div>
            </div>
          </div>

          <div className="row w-100">
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="País" required={true}/>
                <div className="custom-input-box">
                  <select
                    {...register("resident_country", {
                      required: true,
                      onChange: (e) => {
                        onChangeCountry(e);
                      },
                    })}
                  >
                    <option value="">Selecione</option>
                    {countries.map((item) => (
                      <option key={`country-${item.id}`} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                {errors.resident_country && (
                  <div>
                    {errors.resident_country.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Departamento" required={true}/>
                <div className="custom-input-box">
                  <select
                    {...register("resident_region", {
                      required: true,
                      onChange: (e) => {
                        onChangeDepartment(e);
                      },
                    })}
                  >
                    <option value="">Selecione</option>
                    {departments.map((item) => (
                      <option key={`region-${item.id}`} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                {errors.resident_region && (
                  <div>
                    {errors.resident_region.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Ciudad" required={true}/>
                <div className="custom-input-box">
                  <select
                    {...register("resident_city", {
                      required: true,
                      onChange: (e) => {
                        onChangeCity(e);
                      },
                    })}
                  >
                    <option value="">Selecione</option>
                    {cities.map((item) => (
                      <option key={`city-${item.id}`} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                {errors.resident_city && (
                  <div>
                    {errors.resident_city.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Zip de residencia" required={true}/>
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register("resident_zip", {
                      required: true,
                      maxLength: 1000,
                    })}
                    placeholder="zip de residencia"
                    autoComplete="off"
                  />
                </div>
                {errors["resident_zip"] && (
                  <div>
                    {errors["resident_zip"].type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                    {errors["resident_zip"].type === "maxLength" && (
                      <label className="form-error">
                        Máximo de caracteres 1000
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Dirección de residencia"required={true} />
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register("resident_address", {
                      required: true,
                      maxLength: 1000,
                    })}
                    placeholder="Dirección de residencia"
                    autoComplete="off"
                  />
                </div>
                {errors.resident_address && (
                  <div>
                    {errors["resident_address"].type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                    {errors["resident_address"].type === "maxLength" && (
                      <label className="form-error">
                        Máximo de caracteres 1000
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Teléfono de residencia" required={true}/>
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register("resident_phone", {
                      required: true,
                      maxLength: 10,
                      pattern: {
                        value: /^([0-9]\d*)(\.\d+)?$/,
                        message: "Sólo valores numérico",
                      },
                    })}
                    placeholder="teléfono de residencia"
                    autoComplete="off"
                  />
                </div>
                {errors.resident_phone && (
                  <div>
                    {errors["resident_phone"].type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                    {errors["resident_phone"].type === "maxLength" && (
                      <label className="form-error">
                        Máximo de caracteres 10
                      </label>
                    )}
                    {errors["resident_phone"].type === "pattern" && (
                      <label className="form-error">
                        {errors["resident_phone"].message}
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            {!disableForm && (
              <div
                className="col-md-12 mb-2 mt-4"
                style={props.btnstyle ? props.btnstyle : { display: "flex" }}
              >
                <Boton
                  className={"btn _btn-primary font-14"}
                  label={
                    props && props.btntext ? props.btntext : "Actualizar datos"
                  }
                />
              </div>
            )}
          </div>
        </fieldset>
      </form>
      {disableForm && (
        <div className="row">
          <div
            className="row col-md-12 mb-2 mt-4"
            style={props.btnstyle ? props.btnstyle : { display: "flex" }}
          >
            <Boton
              className={"btn _btn-primary font-14"}
              label={"Siguiente"}
              handlerClick={onNext}
            />
          </div>
        </div>
      )}
    </div>
  );
}
