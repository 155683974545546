export const getEnviroments = () =>{
    let DOMAIN = process.env.REACT_APP_PRODDOMAIN;
    let HOST = process.env.REACT_APP_PROD_HOST;
    if (process.env.NODE_ENV !== "production") {
        DOMAIN = process.env.REACT_APP_DEVDOMAIN;
        HOST = process.env.REACT_APP_DEV_HOST;
      }else{
        DOMAIN = process.env.REACT_APP_PRODDOMAIN;
        HOST = process.env.REACT_APP_PROD_HOST;
      }
    const env = {
        DOMAIN,
        HOST
    }
   /*  console.log("getEnviroments:",env);
    console.log("getEnviroments: process.env.NODE_ENV",process.env.NODE_ENV); */
    return env;
}