import { useForm } from "react-hook-form";
import Boton from "../../../components/Boton";
import { useEffect, useState } from "react";
import FormFloatingLabel from "../../../components/FormFloatingLabel";
import {
  createAssetForm,
  createAssetFormP2,
  extractDataForm,
} from "../../../components/modelForms/createAssetForm";
import { IsUnauthorized, httpService } from "../../../helpers/ApiService";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@mui/material";
import { GetAuth } from "../../../helpers/Auth";
import { toast } from "react-toastify";
import { Tab, Tabs } from "react-bootstrap";
import {
  IconBoxEye,
  IconBoxReloadOutLine,
} from "../../../components/Icons/Icons";
import { unlinkJosnObject } from "../../../helpers/functions";
import LoaderComponent from "../../../components/LoaderComponent/LoaderComponent";
import "./MyAssetsCreate.css";
import Chat from "../../../components/chat/Chat";

import Modal from "react-modal";
import { StatusData } from "../../../constants/status";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function MyAssetsCreate(props) {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalEscrituraMinutaIsOpen, setModalEscrituraMinutaIsOpen] =
    useState(false);
  const [sendMinutaEscritura, setSendMinutaEscritura] = useState(false);
  const [closeModalEscrituraMinuta, setCloseModalEscrituraMinuta] =
    useState(false);

  const [paso, setPaso] = useState(0);
  const [messagesMinuta, setMessagesMinuta] = useState([]);
  const [viewChat, setViewChat] = useState(0);
  const textEstados = StatusData;
  const [DATA, setDATA] = useState(null);
  let subtitle;
  function openModal() {
    setIsOpen(true);
  }
  const [openResponseMinuta, setOpenResponseMinuta] = useState(false);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const navigate = useNavigate();
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };
    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);
  const [form, setForm] = useState(unlinkJosnObject(createAssetForm));
  const [form2, setForm2] = useState(unlinkJosnObject(createAssetFormP2));
  const [formData, setFormData] = useState(null);
  const [formData2, setFormData2] = useState(null);
  const [defaultFormData, setDefaultFormData] = useState(null);
  const [defaultFormData2, setDefaultFormData2] = useState(null);
  const [loader, setLoader] = useState(false);

  const [categoriesList, setCategoriesList] = useState([]);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState("");
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");
  const [sponsorLis, setSponsorLis] = useState([]);
  const [fiduciaLis, setFiduciaLis] = useState([]);
  const [tipoproyecto, setTipoproyecto] = useState([]);
  const [btnText, setBtnText] = useState("Guardar");
  const [title, setTitle] = useState("Crear activo");
  const [user, setUser] = useState(null);
  const [activo, setActivo] = useState(props.activo);
  const [disableForm, setDisableForm] = useState(false);

  const [detailProject, setDetailProject] = useState(null);
  //const scenario = props.scenario;
  const [scenario, setScenario] = useState(props.scenario);

  const [certificadoTradicionLibertad, setCertificadoTradicionLibertad] =
    useState(null);
  const [contratoArriendo, setContratoArriendo] = useState(null);

  const [imagen1, setImagen1] = useState(null);
  const [imagen2, setImagen2] = useState(null);
  const [imagen3, setImagen3] = useState(null);
  const [imagen4, setImagen4] = useState(null);
  const [imagen5, setImagen5] = useState(null);

  const [sendToSponsor, seTsendToSponsor] = useState(false);

  const [rawData, setRawData] = useState(null);
  const [messages, setMessages] = useState([]);

  const [tempFormData, setTempFormData] = useState(null);
  const [tempForm2Data, setTempForm2Data] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,

    formState: { errors },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    reset: reset2,
    getValues: getValues2,
    formState: { errors: errors2 },
  } = useForm();
  /* useEffect(() => {
    setForm2({...createAssetFormP2});
  }, []); */
  /* useEffect(() => {
    console.log("useEffect-> createAssetFormP2:", createAssetFormP2);
  }, [createAssetFormP2]); */
  useEffect(() => {
    console.log("gerDetailProject---------->props.activo", props.activo);
    setActivo(props.activo);
  }, [props.activo]);

  useEffect(() => {
    setDepartments([]);
    setCities([]);
    getDeparments();
  }, [country]);
  useEffect(() => {
    setCities([]);
    getCities();
  }, [department]);

  const loadData = async (_user) => {
    let data = {};
    for (let key in createAssetForm) {
      data[key] = "";
      //setValue(key, "");
    }
    data.propietario = _user.user.id;
    setFormData(data);
    setDefaultFormData(data);
    /******Parte 2***** */
    let data2 = {};
    for (let key in createAssetFormP2) {
      data2[key] = "";
      //setValue(key, "");
    }
    data.propietario = _user.user.id;
    setFormData2(data2);
    setDefaultFormData2(data2);

    /******Parte 2***** */

    if (scenario === "update") {
      const [_tipoProyectoList] = await Promise.all([getTipoProyecto()]);
      setTitle("Estado");

      setBtnText("Actualizar");
      getData();
    } else if (scenario === "create") {
      setDisableForm(false);
      const [_countries, _categoriesList, _tipoProyectoList, _sponsorsList] =
        await Promise.all([
          getCountries(),
          getCategories(),
          getTipoProyecto(),
          getSponsors(),
          getFiducias(),
        ]);
    }
  };
  useEffect(() => {
    let _user = GetAuth();
    setUser(_user);

    console.log("gerDetailProject Activo-------->:", activo);
    loadData(_user);
  }, []);
  const newMessages = async () => {
    setDepartments([]);
    setCities([]);
    setCountry(null);
    setDepartment(null);
    setCity(null);
    await getData();
    getMessages();
    props.handlerClickReloadData();
    setTimeout(async () => {
      let response = await gerDetailProject();
      console.log("gerDetailProject----->", response.estado_aprobacion);
      if (
        response &&
        response.estado_aprobacion === "PENDIENTE_RESPUESTA_PROPIETARIO"
      ) {
        setDisableForm(false);
      } else {
        setDisableForm(true);
      }
    }, 500);
  };
  const getMessages = async () => {
    return httpService(
      `/asset/list/feedback/messages/${rawData.id}/?limit=50&offset=0"`
    )
      .get()
      .then((res) => {
        if (res && res.results) {
          let messages = res.results;
          setMessages(messages.reverse());
        } else {
          setMessages([]);
        }

        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };

  const newMessagesMinuta = async () => {
    getMessagesMinuta();
    loadData(user);
    //getInitialData();
  };
  const getMessagesMinuta = () => {
    //DATA.minuta_escrituracion

    const url = `asset/minutaescrituracion/list/messages/asset/${activo.id}/?limit=50&offset=0`;
    return httpService(url)
      .get()
      .then((res) => {
        if (res && res.results) {
          let messages = res.results;
          setMessagesMinuta(messages);
        } else {
          setMessagesMinuta([]);
        }

        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const handleOpenResponseMinuta = () => {
    setOpenResponseMinuta(true);
  };
  const sendMinuta = (send) => {
    console.log("sendMinuta activo---->", send);

    if (send) {
      const texarea = document.getElementById("m-motivo-minuta");
      const documentFile = document.getElementById("m-minuta-document");
      console.log("sendMinuta: texarea", texarea.value);
      const selectedFile = documentFile?.files[0];
      console.log("sendMinuta: selectedFile", selectedFile);

      if (texarea.value) {
        if (selectedFile) {
          const replacedName = selectedFile.name.replace(/[^a-z0-9.]/gi, "_");
          console.log("documentFile name:", replacedName);
          //handleMinuta()
          sendMessageMinuta(texarea.value, selectedFile);

          setOpenResponseMinuta(false);
        } else {
          //handleMinuta()
          sendMessageMinuta(texarea.value);
          setOpenResponseMinuta(false);
        }
      }
    }
  };
  const sendMessageMinuta = (message, file = null) => {
    console.log("sendMessage", message);
    let payload = new FormData();
    payload.append("activo", `${activo.id}`);
    payload.append("mensaje", `${message}`);
    if (file) {
      const replacedName = file.name.replace(/[^a-z0-9.]/gi, "_");
      payload.append("archivo_adjunto", file, `${replacedName}`);
    }
    const url = `/asset/minutaescrituracion/api/message/`;

    return httpService(`${url}`, payload)
      .post()
      .then((res) => {
        if (res && res.activo) {
          //setMessages(res.results);

          getMessagesMinuta();
        } else {
          //setMessages([]);
        }
        setOpenResponseMinuta(false);
        getMessagesMinuta();
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };

  useEffect(() => {
    for (let key in formData) {
      setValue(key, formData[key]);
    }
  }, [formData]);

  useEffect(() => {
    console.log("rawData load", rawData);
    if (
      rawData &&
      (rawData.estado_aprobacion === "" ||
        rawData.estado_aprobacion === "PENDIENTE_RESPUESTA_PROPIETARIO")
    ) {
      setDisableForm(false);
    } else {
      if (scenario === "update") {
        setDisableForm(true);
      } else {
        setDisableForm(false);
      }
    }
    if (rawData) {
      getMessages();
      getMessagesMinuta();
    }
  }, [rawData]);

  /*  useEffect(() => {
    setForm2(createAssetFormP2);
  },[createAssetFormP2]); */
  //const [form2, setForm2] = useState(createAssetFormP2);

  useEffect(() => {
    for (let key in formData2) {
      setValue2(key, formData2[key]);
    }
  }, [formData2]);
  /*    useEffect(() => {
    getData()
  }, [register]); */
  useEffect(() => {
    if (scenario === "update") {
      setTitle("Editar activo");
      setBtnText("Actualizar");
    } else {
      setTitle("Crear activo");
      setBtnText("Guardar");
    }
  }, [scenario]);

  useEffect(() => {
    if (scenario === "update" && tempFormData) {
      //onSubmit(tempFormData)
    }
  }, [tempFormData]);
  useEffect(() => {
    if (scenario === "update" && tempForm2Data) {
      //onSubmit2(tempForm2Data)
    }
  }, [tempForm2Data]);
  const verifySubmit = async (data) => {
    if (
      rawData &&
      rawData.estado_aprobacion === "PENDIENTE_RESPUESTA_PROPIETARIO"
    ) {
      openModal();
      /* setTempFormData(data); */
      let form1Update = false;
      let form2Update = false;
      const form1 = document.getElementById("form1");
      console.log("tempFormData", data);
      console.log("form1", form1);

      var formData = new FormData(form1);
      let _data = Object.fromEntries(formData);
      let keys = Object.keys(_data);
      console.log("form1 data", _data);
      for await (let k of keys) {
        if (_data[k] != defaultFormData[k]) {
          console.log("Datos modificados " + k);
          form1Update = true;
          if (_data[k] instanceof File) {
            if (_data[k].name) {
              console.log("Datos modificados " + k);
              form1Update = true;
            }
          } else {
            form1Update = true;
            console.log("Datos 2 modificados " + k);
          }
        }
      }
      if (form1Update) {
        setTempFormData(data);
      } else {
        setTempFormData(null);
      }

      console.log("form1 defaultFormData", defaultFormData);
      const form2 = document.getElementById("form2");
      var formData2 = new FormData(form2);
      let _data2 = Object.fromEntries(formData2);
      let keys2 = Object.keys(_data2);
      for await (let k of keys2) {
        if (_data2[k] != defaultFormData2[k]) {
          if (_data2[k] instanceof File) {
            if (_data2[k].name) {
              console.log("Datos 2 modificados " + k);
              form2Update = true;
            }
          } else {
            form2Update = true;
            console.log("Datos 2 modificados " + k);
          }
        }
      }
      if (form2Update) {
        setTempForm2Data(_data2);
      } else {
        setTempForm2Data(null);
      }
      console.log("form2 data2", _data2);
      console.log("form2 defaultFormData2", defaultFormData2);
    } else {
      setTempFormData(null);
      onSubmit(data);
    }
  };
  const verifySubmit2 = async (data) => {
    if (
      rawData &&
      rawData.estado_aprobacion === "PENDIENTE_RESPUESTA_PROPIETARIO"
    ) {
      openModal();
      /* setTempFormData(data); */
      let form1Update = false;
      let form2Update = false;

      console.log("form2 defaultFormData2", defaultFormData2);
      const form2 = document.getElementById("form2");
      var formData2 = new FormData(form2);
      let _data2 = Object.fromEntries(formData2);
      let keys2 = Object.keys(_data2);
      for await (let k of keys2) {
        if (_data2[k] != defaultFormData2[k]) {
          if (_data2[k] instanceof File) {
            if (_data2[k].name) {
              console.log("Datos 2 modificados " + k);
              form2Update = true;
            }
          } else {
            form2Update = true;
            console.log("Datos 2 modificados " + k);
          }
        }
      }
      if (form2Update) {
        setTempForm2Data(data);
      } else {
        setTempForm2Data(null);
      }
      console.log("form2 data2", _data2);
      console.log("form2 defaultFormData2", defaultFormData2);

      const form1 = document.getElementById("form1");
      console.log("tempFormData", data);
      console.log("form1", form1);

      var formData = new FormData(form1);
      let _data = Object.fromEntries(formData);
      let keys = Object.keys(_data);
      console.log("form1 data------->", _data);

      for await (let k of keys) {
        if (_data[k] != defaultFormData[k]) {
          console.log("Datos modificados " + k);
          form1Update = true;
          if (_data[k] instanceof File) {
            if (_data[k].name) {
              console.log("Datos modificados " + k);
              form1Update = true;
            }
          } else {
            form1Update = true;
            console.log("Datos 2 modificados " + k);
          }
        }
      }
      if (form1Update) {
        setTempFormData(_data);
      } else {
        setTempFormData(null);
      }
    } else {
      setTempForm2Data(null);
      onSubmit2(data);
    }
  };

  const sendForm = async () => {
    console.log("tempFormData", tempFormData);
    console.log("tempForm2Data", tempForm2Data);

    if (tempFormData) {
      await onSubmit(tempFormData);
    }

    if (tempForm2Data) {
      await onSubmit2(tempForm2Data);
    }
    if (!tempFormData && !tempForm2Data) {
      getData();
    }

    sendResponse();
  };
  const sendResponse = async () => {
    const texarea = document.getElementById("motivo");
    /*  const documentFile = document.getElementById("document"); */
    console.log("texarea:", texarea);
    console.log("texarea:", texarea.value);
    /* const selectedFile = documentFile.files[0];
    console.log("documentFile:", selectedFile); */
    if (texarea.value) {
      await sendMessage(texarea.value);
      closeModal();
      /* if (selectedFile) {
        const replacedName = selectedFile.name.replace(/[^a-z0-9.]/gi, "_");
        console.log("documentFile name:", replacedName);
        sendMessage(texarea.value, selectedFile);
      } else {
        sendMessage(texarea.value);
      } */
    }
  };

  const [open, setOpen] = useState(false);
  const [textDialog, setTextDialog] = useState("salir");

  const [open2, setOpen2] = useState(false);
  useEffect(() => {
    if (open) {
      checkFormChanged();
    }
  }, [open]);

  const gerDetailProject = (_setDetailProject = true) => {
    const data = activo;
    if (!data) {
      return [];
    }
    const url = `/asset/retrieve/${data.id}/`;
    return httpService(url)
      .get()
      .then((res) => {
        try {
          let data = extractDataForm(res);
          if (res instanceof Object) {
            setRawData(res);
            console.log("gerDetailProject-----> rawData", res);
            let _sendToSponsor = true;
            try {
              let _createAssetForm = unlinkJosnObject(createAssetForm);
              for (const key in _createAssetForm) {
                if (
                  key !== "propietario" &&
                  _createAssetForm[key].validators.required.value
                ) {
                  if (!res[key]) {
                    _sendToSponsor = false;
                  }
                }
              }

              let _createAssetFormP2 = unlinkJosnObject(createAssetFormP2);
              console.log("_createAssetFormP2----->", _createAssetFormP2);
              for (const key in _createAssetFormP2) {
                console.log("catch key----->", key);
                if (_createAssetFormP2[key].validators.required.value) {
                  if (key === "estado_aprobacion") {
                    if (res[key] && res[key] !== "NA") {
                      console.log("catch key res[key]----->", res[key]);
                      _sendToSponsor = false;
                    }
                  } else if (!res[key]) {
                    _sendToSponsor = false;
                  }
                }
              }
            } catch (error) {
              console.log("catch error", error);
              _sendToSponsor = false;
            }
            seTsendToSponsor(_sendToSponsor);
            if (_setDetailProject) setDetailProject(data);
          }
          return data;
        } catch (error) {
          console.error("gerDetailProject -> catch:", error);
          return [];
        }
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
        }
        return;
      });
  };

  const getDetailProjectCreate = (id) => {
    const url = `/asset/retrieve/${id}/`;
    return httpService(url)
      .get()
      .then((res) => {
        try {
          if (res instanceof Object) {
          }
          return res;
        } catch (error) {
          console.error("gerDetailProject -> catch:", error);
          return [];
        }
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
        }
        return;
      });
  };

  const getData = async (_activo = null) => {
    setLoader(true);
    let _countries = await getCountries();
    let _categories = await getCategories();
    let _sponsors = await getSponsors();
    let _fiducias = await getFiducias();
    let __data = null;
    if (_activo) {
      __data = _activo;
      setActivo(__data);
    } else {
      __data = props.activo;
    }

    const data = await gerDetailProject();
    let _paso = 0;
    if (textEstados[data.estado_aprobacion]) {
      _paso = textEstados[data.estado_aprobacion].paso;
      setPaso(_paso);
    }
    console.log("data-------------->:_paso", _paso);
    console.log("data-------------->:data", data);
    setDATA(data);
    if (_paso >= 1 && _paso <= 2) {
      setViewChat(1);
    }
    if (_paso >= 7 && _paso <= 7) {
      setViewChat(2);
    }

    let _data = {};
    let _dataDefault = {};
    for (let key in createAssetForm) {
      if (data[key]) {
        _data[key] = data[key];
        _dataDefault[key] = data[key];
      } else {
        _dataDefault[key] = "";
      }
    }
    let _departments = await getDeparments(data.pais);
    let _cities = await getCities(data.pais, data.region);
    setFormData(_data);
    setDefaultFormData(_dataDefault);

    let _data2 = {};
    let _dataDefault2 = {};
    let _createAssetFormP2 = JSON.parse(JSON.stringify(createAssetFormP2));
    for (let key in _createAssetFormP2) {
      if (data[key]) {
        if (key === "certificado_tradicion_libertad" && data[key]) {
          _createAssetFormP2[key].validators.required.value = false;
          setForm2(_createAssetFormP2);
        } else if (key === "certificado_tradicion_libertad") {
          _createAssetFormP2[key].validators.required.value = true;
          setForm2(_createAssetFormP2);
        }
        if (key === "contrato_arriendo" && data[key]) {
          _createAssetFormP2[key].validators.required.value = false;
          setForm2(_createAssetFormP2);
        } else if (key === "contrato_arriendo") {
          _createAssetFormP2[key].validators.required.value = true;
          setForm2(_createAssetFormP2);
        }
        if (key === "imagen_1" && data[key]) {
          _createAssetFormP2[key].validators.required.value = false;
          setForm2(_createAssetFormP2);
        } else if (key === "imagen_1") {
          _createAssetFormP2[key].validators.required.value = true;
          setForm2(_createAssetFormP2);
        }
        if (key === "imagen_2" && data[key]) {
          _createAssetFormP2[key].validators.required.value = false;
          setForm2(_createAssetFormP2);
        } else if (key === "imagen_2") {
          _createAssetFormP2[key].validators.required.value =
            createAssetFormP2[key].validators.required.value;
          setForm2(_createAssetFormP2);
        }
        if (key === "imagen_3" && data[key]) {
          _createAssetFormP2[key].validators.required.value = false;
          setForm2(_createAssetFormP2);
        } else if (key === "imagen_3") {
          _createAssetFormP2[key].validators.required.value =
            createAssetFormP2[key].validators.required.value;
          setForm2(_createAssetFormP2);
        }
        if (key === "imagen_4" && data[key]) {
          _createAssetFormP2[key].validators.required.value = false;
          setForm2(_createAssetFormP2);
        } else if (key === "imagen_4") {
          _createAssetFormP2[key].validators.required.value =
            createAssetFormP2[key].validators.required.value;
          setForm2(_createAssetFormP2);
        }
        if (key === "imagen_5" && data[key]) {
          _createAssetFormP2[key].validators.required.value = false;
          setForm2(_createAssetFormP2);
        } else if (key === "imagen_5") {
          _createAssetFormP2[key].validators.required.value =
            createAssetFormP2[key].validators.required.value;
          setForm2(_createAssetFormP2);
        }
        _data2[key] = data[key];
        _dataDefault2[key] = data[key];
      } else {
        _createAssetFormP2[key].validators.required.value =
          createAssetFormP2[key].validators.required.value;
        setForm2(_createAssetFormP2);
        _dataDefault2[key] = "";
      }
    }
    setFormData2(_data2);
    setDefaultFormData2(_dataDefault2);
    setLoader(false);
  };
  const getCategories = async () => {
    const url = "/asset/list/categorias/";
    return httpService(url)
      .get()
      .then(async (res) => {
        if (res instanceof Array) {
          setCategoriesList(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getCountries = async () => {
    return httpService("/cities/countries/")
      .get()
      .then((res) => {
        if (res instanceof Array) {
          setCountries(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getDeparments = async (__country = "") => {
    const attr = "pais";
    const _country = getValues(attr);
    let country;
    if (__country) {
      country = __country;
    } else {
      if (!_country) {
        return;
      } else {
        country = _country;
      }
    }

    return httpService(`cities/countries/${country}/regions/`)
      .get()
      .then((res) => {
        if (res instanceof Array) {
          setDepartments(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getCities = async (_country = null, _department = null) => {
    const attr = "pais";
    const attr2 = "region";
    let country;
    let department;
    const __country = getValues(attr);
    const __department = getValues(attr2);
    if (_country && _department) {
      country = _country;
      department = _department;
    } else {
      if (!__country || !__department) {
        return;
      } else {
        country = __country;
        department = __department;
      }
    }

    return httpService(
      `cities/countries/${country}/regions/${department}/cities/`
    )
      .get()
      .then((res) => {
        if (res instanceof Array) {
          setCities(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getTipoProyecto = async () => {
    const url = "/asset/list/tipoproyecto/";
    return httpService(url)
      .get()
      .then(async (res) => {
        if (res instanceof Array) {
          setTipoproyecto(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  ///asset/list/sponsor/assets/
  const getSponsors = async () => {
    const url = "/sponsor/select/list/";
    return httpService(url)
      .get()
      .then(async (res) => {
        if (res instanceof Array) {
          if (res) {
            let _sponsor = extractDataForm(res);

            setSponsorLis(res);
          }
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  ///asset/list/fiducia/assets/
  const getFiducias = async () => {
    const url = "/fiducia/select/list/";
    return httpService(url)
      .get()
      .then(async (res) => {
        if (res instanceof Array) {
          if (res) {
            let _fiducia = extractDataForm(res);

            setFiduciaLis(res);
          }
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };

  const onChangeCountry = (e) => {
    const attr = "pais";
    setDepartments([]);
    setCities([]);
    setCountry(e.target.value);
    //getDeparments();
  };
  const onChangeDepartment = (e) => {
    const attr = "region";
    setCities([]);
    //getCities();
    setDepartment(e.target.value);
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    for (let key in data) {
      if (data[key] instanceof FileList) {
        const _file = data[key][0];
        let name = _file.name.replace(/[^a-zA-Z0-9._ ]/g, "");
        name = name.replace(/\s{2,}/g, " ");
        name = name.replace(/ /g, "_");
        name = name.trim();
        formData.append(key, _file, name);
      } else {
        formData.append(key, data[key]);
      }
    }
    setLoader(true);
    if (scenario === "update") {
      let __data = null;
      if (props.activo) {
        __data = props.activo;
      } else {
        __data = activo;
      }

      const url = `/asset/update/${__data.id}/`;
      const id = toast.loading("Enviando...", {
        closeOnClick: true,
        className: "foo-bar",
      });

      return httpService(`${url}`, formData)
        .patch()
        .then((res) => {
          if (res instanceof Object) {
            if (res) {
              setTempFormData(null);
              toast.update(id, {
                render: "Los datos se registraron satisfactoriamente",
                type: "success",
                isLoading: false,
                autoClose: 5000,
                closeOnClick: true,
              });
              /* props.handlerClick(true); */

              getData();
              setOpen2(true);
            } else {
              toast.update(id, {
                render: "Ocurrio un error, inténtelo más tarde",
                type: "error",
                isLoading: false,
                autoClose: 5000,
                closeOnClick: true,
              });
            }
          }
          return res;
        })
        .catch((err) => {
          console.error("ERROR Response status:", err.status);
          if (IsUnauthorized(err, navigate)) {
            /* notify("Sesión expirada",{type:"error"}) */
          } else {
            toast.update(id, {
              render: "Ocurrio un error, inténtelo más tarde",
              type: "error",
              isLoading: false,
              autoClose: 5000,
              closeOnClick: true,
            });
          }

          return;
        })
        .finally((_) => {
          setLoader(false);
        });
    } else if (scenario === "create") {
      const url = "/asset/create/";
      const id = toast.loading("Enviando...", {
        closeOnClick: true,
        className: "foo-bar",
      });
      return httpService(`${url}`, formData)
        .post()
        .then(async (res) => {
          if (res instanceof Object) {
            if (res) {
              toast.update(id, {
                render: "Los datos se registraron satisfactoriamente",
                type: "success",
                isLoading: false,
                autoClose: 5000,
                closeOnClick: true,
              });
              const ID = res.id;
              setActivo({ id: ID });
              let _gerDetailProject = await getDetailProjectCreate(ID);
              const _activo = {
                categoria_nombre: _gerDetailProject.categoria.nombre,
                ciudad_name: _gerDetailProject.ciudad.name,
                descripcion_proyecto: _gerDetailProject.descripcion_proyecto,
                descripcion_ubicacion: _gerDetailProject.descripcion_ubicacion,
                dimension_m2: _gerDetailProject.dimension_m2,
                direccion: _gerDetailProject.direccion,
                id: ID,
                latitud: _gerDetailProject.latitud,
                longitud: _gerDetailProject.longitud,
                nombre: _gerDetailProject.nombre,
                pais_name: _gerDetailProject.pais.name,
                region_name: _gerDetailProject.region.name,
                tipo_proyecto_nombre: _gerDetailProject.tipo_proyecto.nombre,
                tir_ea: null,
              };
              setActivo(_activo);
              setTimeout(() => {
                getData(_activo);
              }, 10);

              //props.handlerClick(true);
              setScenario("update");
            } else {
              toast.update(id, {
                render: "Ocurrio un error desconocido, inténtelo más tarde",
                type: "error",
                isLoading: false,
                autoClose: 5000,
                closeOnClick: true,
              });
            }
          }
          return res;
        })
        .catch((err) => {
          console.error("ERR erro:", err);
          console.error("ERR Response status:", err.status);
          if (IsUnauthorized(err, navigate)) {
            /* notify("Sesión expirada",{type:"error"}) */
          } else {
            if (err.status === 400) {
              console.log("error->" + err.status);
              if (err.data && err.data.type === "validation_error") {
                if (err.data.errors instanceof Array) {
                  let message = "";
                  let errorsList = [];
                  errorsList = err.data.errors;
                  let attr = "";
                  errorsList.forEach((data, i, arr) => {
                    if (!message) {
                      message = data.detail;
                      attr = data.attr;
                    }
                    if (i === arr.length - 1) {
                      toast.update(id, {
                        render: message,
                        type: "error",
                        isLoading: false,
                        autoClose: 5000,
                        closeOnClick: true,
                      });
                      document.getElementsByName(attr)[0].focus();
                    }
                  });
                }
              }
            } else {
              toast.update(id, {
                render: "Ocurrio un error, inténtelo más tarde",
                type: "error",
                isLoading: false,
                autoClose: 5000,
                closeOnClick: true,
              });
            }
          }

          return;
        })
        .finally((_) => {
          setLoader(false);
        });
    }
  };

  const cancelBTN = (event) => {
    event.preventDefault();
    setOpen(true);
  };
  const resetForm = async (event) => {
    event.preventDefault();
    setDepartments([]);
    setCities([]);
    setCountry(null);
    setDepartment(null);
    setCity(null);
    await getData();
    reset(defaultFormData);
  };
  const stay = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const leave = () => {
    setOpen(false);
    setDefaultFormData2(null);
    setForm2();
    props.handlerClick(true);
  };
  const checkFormChanged = () => {
    let data = {};
    for (let key in createAssetForm) {
      data[key] = getValues(key);
      //setValue(key, "");
    }

    let isEqual = JSON.stringify(data) === JSON.stringify(defaultFormData);
    if (isEqual) {
      setTextDialog("salir");
    } else {
      setTextDialog("salir");
    }
  };

  const checkFormChanged2 = () => {
    let data = {};
    for (let key in createAssetForm) {
      data[key] = getValues(key);
      //setValue(key, "");
    }
    let isEqual = JSON.stringify(data) === JSON.stringify(defaultFormData);
    if (isEqual) {
      setTextDialog("salir");
    } else {
      setTextDialog("salir sin guardar");
    }
  };
  const getNameOfUrl = (attr) => {
    if (!formData2) {
      return "Seleccione";
    }
    let url = defaultFormData2[attr];
    if (!url) {
      return "Seleccione";
    }

    let name = url.substring(url.lastIndexOf("/") + 1);
    return name;
  };
  const clearName = (_name) => {
    let name = _name.replace(/[^a-zA-Z0-9._ ]/g, "");
    name = name.replace(/\s{2,}/g, " ");
    name = name.replace(/ /g, "_");
    name = name.trim();
    return name;
  };
  /*********Sugundo formulario********** */
  const onSubmit2 = async (data) => {
    const _formData = new FormData();
    const keys = Object.keys(data);

    await Promise.all(
      keys.map(async (key) => {
        if (data[key] instanceof FileList) {
          const _file = data[key][0];
          if (!_file) {
            return;
          }
          let name = _file.name.replace(/[^a-zA-Z0-9._ ]/g, "");
          name = name.replace(/\s{2,}/g, " ");
          name = name.replace(/ /g, "_");
          name = name.trim();
          _formData.append(key, _file, name);
        } else {
          if (key === "estado_aprobacion" && !data[key]) {
            //_formData.append(key, "Activo sin validar");
          } else {
            switch (key) {
              case "certificado_tradicion_libertad":
                //_formData.append(key, "");
                break;
              case "contrato_arriendo":
                //_formData.append(key, "");
                break;
              case "imagen_1":
                //_formData.append(key, "");
                break;
              case "imagen_2":
                //_formData.append(key, "");
                break;
              case "imagen_3":
                //_formData.append(key, "");
                break;
              case "imagen_4":
                //_formData.append(key, "");
                break;
              case "imagen_5":
                //_formData.append(key, "");
                break;

              default:
                _formData.append(key, data[key]);
            }
            /* _formData.append(key, data[key]); */
          }
        }
      })
    );

    if (scenario === "update") {
      let __data = null;
      if (props.activo) {
        __data = props.activo;
      } else {
        __data = activo;
      }

      const url = `/asset/documental/create/${__data.id}/`;
      const id = toast.loading("Enviando...", {
        closeOnClick: true,
        className: "foo-bar",
      });
      return httpService(`${url}`, _formData)
        .patch()
        .then(async (res) => {
          if (res instanceof Object) {
            if (res) {
              toast.update(id, {
                render: "Los datos se registraron satisfactoriamente",
                type: "success",
                isLoading: false,
                autoClose: 5000,
                closeOnClick: true,
              });
              //props.handlerClick(true);
              let d = await getDetailProjectCreate(__data.id);
              getData(d);
              setOpen2(true);
            } else {
              toast.update(id, {
                render: "Ocurrio un error, inténtelo más tarde",
                type: "error",
                isLoading: false,
                autoClose: 5000,
                closeOnClick: true,
              });
            }
          }
          return res;
        })
        .catch((err) => {
          console.error("XXXX Response status:", err.status);
          if (IsUnauthorized(err, navigate)) {
            /* notify("Sesión expirada",{type:"error"}) */
          } else {
            toast.update(id, {
              render: "Ocurrio un error, inténtelo más tarde",
              type: "error",
              isLoading: false,
              autoClose: 5000,
              closeOnClick: true,
            });
          }

          return;
        });
    } else if (scenario === "create") {
      const url = "/asset/create/";
      const id = toast.loading("Enviando...", {
        closeOnClick: true,
        className: "foo-bar",
      });
      return httpService(`${url}`, formData)
        .post()
        .then(async (res) => {
          if (res instanceof Object) {
            if (res) {
              toast.update(id, {
                render: "Los datos se registraron satisfactoriamente",
                type: "success",
                isLoading: false,
                autoClose: 5000,
                closeOnClick: true,
              });
              const ID = res.id;
              setActivo({ id: ID });
              let _gerDetailProject = await getDetailProjectCreate(ID);
              const _activo = {
                categoria_nombre: _gerDetailProject.categoria.nombre,
                ciudad_name: _gerDetailProject.ciudad.name,
                descripcion_proyecto: _gerDetailProject.descripcion_proyecto,
                descripcion_ubicacion: _gerDetailProject.descripcion_ubicacion,
                dimension_m2: _gerDetailProject.dimension_m2,
                direccion: _gerDetailProject.direccion,
                id: ID,
                latitud: _gerDetailProject.latitud,
                longitud: _gerDetailProject.longitud,
                nombre: _gerDetailProject.nombre,
                pais_name: _gerDetailProject.pais.name,
                region_name: _gerDetailProject.region.name,
                tipo_proyecto_nombre: _gerDetailProject.tipo_proyecto.nombre,
                tir_ea: null,
              };
              setActivo(_activo);
              setTimeout(() => {
                getData(_activo);
              }, 10);

              //props.handlerClick(true);
              setScenario("update");
            } else {
              toast.update(id, {
                render: "Ocurrio un error desconocido, inténtelo más tarde",
                type: "error",
                isLoading: false,
                autoClose: 5000,
                closeOnClick: true,
              });
            }
          }
          return res;
        })
        .catch((err) => {
          console.error("XXXX erro:", err);
          console.error("XXXX Response status:", err.status);
          if (IsUnauthorized(err, navigate)) {
            /* notify("Sesión expirada",{type:"error"}) */
          } else {
            toast.update(id, {
              render: "Ocurrio un error, inténtelo más tarde",
              type: "error",
              isLoading: false,
              autoClose: 5000,
              closeOnClick: true,
            });
          }

          return;
        });
    }
  };
  const onChangeCertificadoTradicionLibertad = (e) => {
    const attr = "certificado_tradicion_libertad";
    const file = e.target.files[0];
    setCertificadoTradicionLibertad(file);

    if (errors2 && errors2[attr]) {
      delete errors2[attr];
    }
  };

  const onChangeContratoArriendo = (e) => {
    const file = e.target.files[0];
    setContratoArriendo(file);
    const attr = "contrato_arriendo";
    if (errors2 && errors2[attr]) {
      delete errors2[attr];
    }
  };

  const onChangeImagen1 = (e) => {
    const attr = "imagen_1";
    const file = e.target.files[0];
    setImagen1(file);
    if (errors2 && errors2[attr]) {
      delete errors2[attr];
    }
  };
  const onChangeImagen2 = (e) => {
    const attr = "imagen_2";
    const file = e.target.files[0];
    setImagen2(file);
    if (errors2 && errors2[attr]) {
      delete errors2[attr];
    }
  };
  const onChangeImagen3 = (e) => {
    const attr = "imagen_3";
    const file = e.target.files[0];
    setImagen3(file);
    if (errors2 && errors2[attr]) {
      delete errors2[attr];
    }
  };
  const onChangeImagen4 = (e) => {
    const attr = "imagen_4";
    const file = e.target.files[0];
    setImagen4(file);
    if (errors2 && errors2[attr]) {
      delete errors2[attr];
    }
  };
  const onChangeImagen5 = (e) => {
    const attr = "imagen_5";
    const file = e.target.files[0];
    setImagen5(file);
    if (errors2 && errors2[attr]) {
      delete errors2[attr];
    }
  };

  const resetForm2 = async (event) => {
    event.preventDefault();
    setCertificadoTradicionLibertad(null);
    setContratoArriendo(null);
    setImagen1(null);
    setImagen2(null);
    setImagen3(null);
    setImagen4(null);
    setImagen5(null);
    reset2(defaultFormData2);
    /* 
    await getData();
    reset2(defaultFormData2); */
  };
  const showImg = (file) => {
    return URL.createObjectURL(file);
  };
  const stay2 = () => {
    setOpen2(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const leave2 = () => {
    setOpen2(false);
    props.handlerClick(true);
  };
  /*********Sugundo formulario********** */
  const viewDocument = (attr) => {
    console.log("viewDocument", attr);
    const url = formData2[attr];
    window.open(url, "_blank", "noreferrer");
  };
  const sendToValidate = () => {
    const id = toast.loading("Enviando...", {
      closeOnClick: true,
      className: "foo-bar",
    });
    const attr = "estado_aprobacion";
    setLoader(false);
    const _formData = new FormData();
    _formData.append(attr, "Activo sin validar");

    if (!activo) {
      return;
    }
    const url = `/asset/send/validation/sponsor/${activo.id}/`;
    return httpService(`${url}`)
      .patch()
      .then((res) => {
        if (res instanceof Object) {
          if (res && res.id && res.codigo) {
            toast.update(id, {
              render: "El activo se ha enviado para validar",
              type: "success",
              isLoading: false,
              autoClose: 5000,
              closeOnClick: true,
            });
            /* props.handlerClick(true); */
            getData();
            setOpen2(true);
          } else {
            toast.update(id, {
              render: "Ocurrio un error, inténtelo más tarde",
              type: "error",
              isLoading: false,
              autoClose: 5000,
              closeOnClick: true,
            });
          }
        }
        return res;
      })
      .catch((err) => {
        console.error("ERROR Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        } else {
          toast.update(id, {
            render: "Ocurrio un error, inténtelo más tarde",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            closeOnClick: true,
          });
        }

        return;
      })
      .finally((_) => {
        setLoader(false);
      });
  };

  const aprobarMinutaEscritura = () => {
    setModalEscrituraMinutaIsOpen(true);
  };
  const sendFormAprobarEscritura = () => {
    const id = toast.loading("Enviando...", {
      closeOnClick: true,
      className: "foo-bar",
    });
    const attr = "aprobado_propietario";
    setLoader(false);
    const _formData = new FormData();
    _formData.append(attr, true);
    setModalEscrituraMinutaIsOpen(false);
    if (!DATA || (DATA && !DATA.minuta_escrituracion)) {
      return;
    }
    setSendMinutaEscritura(true);
    const url = `/asset/minutaescrituracion/${DATA.minuta_escrituracion.id_minuta}/approved/propietario/`;
    return httpService(`${url}`, _formData)
      .patch()
      .then((res) => {
        console.log("/asset/minutaescrituracion/", res);
        if (res instanceof Object) {
          if (res && res.activo && res.aprobado_propietario) {
            toast.update(id, {
              render: "Minuta aprobada",
              type: "success",
              isLoading: false,
              autoClose: 5000,
              closeOnClick: true,
            });
            /* props.handlerClick(true); */
            getData();
            getMessagesMinuta();
            /* setOpen2(true); */
          } else {
            toast.update(id, {
              render: "Ocurrio un error, inténtelo más tarde",
              type: "error",
              isLoading: false,
              autoClose: 5000,
              closeOnClick: true,
            });
          }
        }
        return res;
      })
      .catch((err) => {
        console.error("ERROR Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        } else {
          toast.update(id, {
            render: "Ocurrio un error, inténtelo más tarde",
            type: "error",
            isLoading: false,
            autoClose: 5000,
            closeOnClick: true,
          });
        }

        return;
      })
      .finally((_) => {
        setLoader(false);
        setSendMinutaEscritura(false);
      });
  };

  const sendMessage = (message) => {
    console.log("sendMessage", message);
    let payload = new FormData();
    payload.append("activo", `${rawData.id}`);
    payload.append("mensaje", `${message}`);
    return httpService(`/asset/send/feedback/`, payload)
      .post()
      .then((res) => {
        console.log("sendMessage:", res);
        if (res && res.activo) {
          //setMessages(res.results);
          getMessages();
        } else {
          //setMessages([]);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const mouseOver = () => {
    if (disableForm) {
      let fieldset = document.getElementsByTagName("fieldset")[1];
      fieldset.disabled = false;
    }
  };
  const mouseOut = () => {
    if (disableForm) {
      let fieldset = document.getElementsByTagName("fieldset")[1];
      fieldset.disabled = true;
    }
  };

  const openPdf = (file) => {
    window.open("https://admin.godevise.co" + file, "_blank", "noreferrer");
  };
  return (
    <>
      <div
        className={`col-md-${
          scenario === "update" && viewChat > 0 ? "8" : "12"
        } custom-col-1`}
      >
        <LoaderComponent loader={loader} />
        <Dialog open={open} onClose={handleClose}>
          <div style={{ padding: "25px 30px" }}>
            <div
              className="w-100"
              style={{ minWidth: "350px", textAlign: "center" }}
            >
              <span className="text-subtitle-dark">
                ¿Estás seguro que quieres
              </span>{" "}
              <span className="text-subtitle">{textDialog}</span>
              <span className="text-subtitle-dark">?</span>
            </div>
            <div className="w-100">
              <div className="btn-group mt-4 w-100 btn-group-logout">
                <div className="btn-cancel">
                  <Boton
                    className={"btn _btn-primary font-14"}
                    handlerClick={stay}
                    style={{
                      borderRadius: "8px",
                      width: "150px",
                      background: "white",
                      border: "1px solid #112e6d",
                      color: "#112e6d",
                    }}
                    label={"No, quedarme"}
                  />
                </div>
                <div className="btn-logout">
                  <Boton
                    className={"btn _btn-primary font-14"}
                    style={{ borderRadius: "8px", width: "150px" }}
                    label={"Si, salir"}
                    handlerClick={leave}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <Dialog open={open2} onClose={handleClose2}>
          <div style={{ padding: "25px 30px" }}>
            <div
              className="w-100"
              style={{ minWidth: "350px", textAlign: "center" }}
            >
              <span className="text-subtitle-dark">¿Desea finalizar ó</span>{" "}
              <span className="text-subtitle">seguir editando</span>
              <span className="text-subtitle-dark">?</span>
            </div>
            <div className="w-100">
              <div className="btn-group mt-4 w-100 btn-group-logout">
                <div className="btn-cancel">
                  <Boton
                    className={"btn _btn-primary font-14"}
                    handlerClick={stay2}
                    style={{
                      borderRadius: "8px",
                      width: "150px",
                      background: "white",
                      border: "1px solid #112e6d",
                      color: "#112e6d",
                    }}
                    label={"No, quedarme"}
                  />
                </div>
                <div className="btn-logout">
                  <Boton
                    className={"btn _btn-primary font-14"}
                    style={{ borderRadius: "8px", width: "150px" }}
                    label={"Si, salir"}
                    handlerClick={leave2}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <div className="card-custom">
          <div
            className="page--head"
            style={{ padding: "5px 0px 15px", width: "100%" }}
          >
            <div className="page--subtitle">
              {title + ": "}
              {activo && activo.estado_aprobacion}
            </div>
            {sendToSponsor && (
              <div className="container-animated">
                <Boton
                  className={"btn _btn-primary font-14"}
                  label="Enviar para validar"
                  borderRadius="10px"
                  handlerClick={sendToValidate}
                />
              </div>
            )}
            {DATA &&
              DATA.minuta_escrituracion &&
              !DATA.minuta_escrituracion.aprobado_propietario &&
              paso === 7 && (
                <div className="container-animated">
                  <Boton
                    className={"btn _btn-secondary font-14"}
                    label="Apruebo Escritura de Minuta"
                    borderRadius="10px"
                    style={{ width: "100%" }}
                    handlerClick={aprobarMinutaEscritura}
                    disabled={sendMinutaEscritura}
                  />
                </div>
              )}
          </div>
          <div className="w-100">
            <div className="datos">
              <Tabs
                defaultActiveKey="datos_basicos"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="datos_basicos" title="Datos básicos">
                  <div className="tab-container">
                    <div className="w-100">
                      <form
                        id={"form1"}
                        key={1}
                        onSubmit={handleSubmit((data) => verifySubmit(data))}
                      >
                        <fieldset className="row" disabled={disableForm}>
                          {/* <div className="col-md-6 mb-4">
                <div className="form-custom-box">
                  <FormFloatingLabel
                    label={form["propietario"].label}
                    required={form["propietario"].validators.required.value}
                  />
                  <div className="custom-input-box">
                    <input
                      type="text"
                      
                      {...register(form["propietario"].name, {
                        ...form["propietario"].validators,
                      })}
                      placeholder={form["propietario"].placeholder}
                      autoComplete="off"
                    />
                  </div>
                </div>
                {errors["propietario"] && (
                  <div>
                    <label className="form-error">
                      {errors["propietario"].message}
                    </label>
                  </div>
                )}
              </div> */}

                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["codigo"].label}
                                required={
                                  form["codigo"].validators.required.value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  type="text"
                                  {...register(form["codigo"].name, {
                                    ...form["codigo"].validators,
                                  })}
                                  placeholder={form["codigo"].placeholder}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["codigo"] && (
                              <div>
                                <label className="form-error">
                                  {errors["codigo"].message}
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["nombre"].label}
                                required={
                                  form["nombre"].validators.required.value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  type="text"
                                  {...register(form["nombre"].name, {
                                    ...form["nombre"].validators,
                                  })}
                                  placeholder={form["nombre"].placeholder}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["nombre"] && (
                              <div>
                                <label className="form-error">
                                  {errors["nombre"].message}
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["tipo_proyecto"].label}
                                required={
                                  form["tipo_proyecto"].validators.required
                                    .value
                                }
                              />
                              <div className="custom-input-box">
                                <select
                                  {...register(form["tipo_proyecto"].name, {
                                    ...form["tipo_proyecto"].validators,
                                  })}
                                >
                                  <option value="">
                                    {form["tipo_proyecto"].placeholder}
                                  </option>
                                  {tipoproyecto.map((item) => (
                                    <option
                                      key={`origin-founds-${item.id}`}
                                      value={item.id}
                                    >
                                      {item.nombre}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              {errors["tipo_proyecto"] && (
                                <div>
                                  <label className="form-error">
                                    {errors["tipo_proyecto"].message}
                                  </label>
                                </div>
                              )}
                            </div>
                          </div>

                          {/* <div className="col-md-6 mb-4">
                <div className="form-custom-box">
                  <FormFloatingLabel
                    label={form["sponsor"].label}
                    required={form["sponsor"].validators.required.value}
                  />
                  <div className="custom-input-box">
                    <select
                      {...register(form["sponsor"].name, {
                        ...form["sponsor"].validators,
                      })}
                    >
                      <option value="">{form["sponsor"].placeholder}</option>
                      {sponsorLis.map((item) => (
                        <option
                          key={`origin-founds-${item.id}`}
                          value={item.id}
                        >
                          {item.nombre}
                        </option>
                      ))}
                    </select>
                  </div>

                  {errors["sponsor"] && (
                    <div>
                      <label className="form-error">
                        {errors["sponsor"].message}
                      </label>
                    </div>
                  )}
                </div>
              </div> */}
                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["descripcion_proyecto"].label}
                                required={
                                  form["descripcion_proyecto"].validators
                                    .required.value
                                }
                              />
                              <div className="custom-input-box-tarea">
                                <textarea
                                  {...register(
                                    form["descripcion_proyecto"].name,
                                    {
                                      ...form["descripcion_proyecto"]
                                        .validators,
                                    }
                                  )}
                                  placeholder={
                                    form["descripcion_proyecto"].placeholder
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["descripcion_proyecto"] && (
                              <div>
                                <label className="form-error">
                                  {errors["descripcion_proyecto"].message}
                                </label>
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["dimension_m2"].label}
                                required={
                                  form["dimension_m2"].validators.required.value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  type="text"
                                  {...register(form["dimension_m2"].name, {
                                    ...form["dimension_m2"].validators,
                                  })}
                                  placeholder={form["dimension_m2"].placeholder}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["dimension_m2"] && (
                              <div>
                                <label className="form-error">
                                  {errors["dimension_m2"].message}
                                </label>
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["pais"].label}
                                required={
                                  form["pais"].validators.required.value
                                }
                              />
                              <div className="custom-input-box">
                                <select
                                  {...register(form["pais"].name, {
                                    ...form["pais"].validators,
                                    onChange: (e) => {
                                      onChangeCountry(e);
                                    },
                                  })}
                                >
                                  <option value="">
                                    {form["pais"].placeholder}
                                  </option>
                                  {countries.map((item) => (
                                    <option
                                      key={`origin-founds-${item.id}`}
                                      value={item.id}
                                    >
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              {errors["pais"] && (
                                <div>
                                  <label className="form-error">
                                    {errors["pais"].message}
                                  </label>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["region"].label}
                                required={
                                  form["region"].validators.required.value
                                }
                              />
                              <div className="custom-input-box">
                                <select
                                  {...register(form["region"].name, {
                                    ...form["region"].validators,
                                    onChange: (e) => {
                                      onChangeDepartment(e);
                                    },
                                  })}
                                >
                                  <option value="">
                                    {form["region"].placeholder}
                                  </option>
                                  {departments.map((item) => (
                                    <option
                                      key={`origin-founds-${item.id}`}
                                      value={item.id}
                                    >
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              {errors["region"] && (
                                <div>
                                  <label className="form-error">
                                    {errors["region"].message}
                                  </label>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["ciudad"].label}
                                required={
                                  form["ciudad"].validators.required.value
                                }
                              />
                              <div className="custom-input-box">
                                <select
                                  {...register(form["ciudad"].name, {
                                    ...form["ciudad"].validators,
                                  })}
                                >
                                  <option value="">
                                    {form["ciudad"].placeholder}
                                  </option>
                                  {cities.map((item) => (
                                    <option
                                      key={`origin-founds-${item.id}`}
                                      value={item.id}
                                    >
                                      {item.name}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              {errors["ciudad"] && (
                                <div>
                                  <label className="form-error">
                                    {errors["ciudad"].message}
                                  </label>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["direccion"].label}
                                required={
                                  form["direccion"].validators.required.value
                                }
                              />
                              <div className="custom-input-box-tarea">
                                <textarea
                                  {...register(form["direccion"].name, {
                                    ...form["direccion"].validators,
                                  })}
                                  placeholder={form["direccion"].placeholder}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["direccion"] && (
                              <div>
                                <label className="form-error">
                                  {errors["direccion"].message}
                                </label>
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["latitud"].label}
                                required={
                                  form["latitud"].validators.required.value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  type="text"
                                  {...register(form["latitud"].name, {
                                    ...form["latitud"].validators,
                                  })}
                                  placeholder={form["latitud"].placeholder}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["latitud"] && (
                              <div>
                                <label className="form-error">
                                  {errors["latitud"].message}
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["longitud"].label}
                                required={
                                  form["longitud"].validators.required.value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  type="text"
                                  {...register(form["longitud"].name, {
                                    ...form["longitud"].validators,
                                  })}
                                  placeholder={form["longitud"].placeholder}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["longitud"] && (
                              <div>
                                <label className="form-error">
                                  {errors["longitud"].message}
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["descripcion_ubicacion"].label}
                                required={
                                  form["descripcion_ubicacion"].validators
                                    .required.value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  type="text"
                                  {...register(
                                    form["descripcion_ubicacion"].name,
                                    {
                                      ...form["descripcion_ubicacion"]
                                        .validators,
                                    }
                                  )}
                                  placeholder={
                                    form["descripcion_ubicacion"].placeholder
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["descripcion_ubicacion"] && (
                              <div>
                                <label className="form-error">
                                  {errors["descripcion_ubicacion"].message}
                                </label>
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["area_construida_m2"].label}
                                required={
                                  form["area_construida_m2"].validators.required
                                    .value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  type="text"
                                  {...register(
                                    form["area_construida_m2"].name,
                                    {
                                      ...form["area_construida_m2"].validators,
                                    }
                                  )}
                                  placeholder={
                                    form["area_construida_m2"].placeholder
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["area_construida_m2"] && (
                              <div>
                                <label className="form-error">
                                  {errors["area_construida_m2"].message}
                                </label>
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["area_gla_m2"].label}
                                required={
                                  form["area_gla_m2"].validators.required.value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  type="text"
                                  {...register(form["area_gla_m2"].name, {
                                    ...form["area_gla_m2"].validators,
                                  })}
                                  placeholder={form["area_gla_m2"].placeholder}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["area_gla_m2"] && (
                              <div>
                                <label className="form-error">
                                  {errors["area_gla_m2"].message}
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["area_vendible_m2"].label}
                                required={
                                  form["area_vendible_m2"].validators.required
                                    .value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  type="text"
                                  {...register(form["area_vendible_m2"].name, {
                                    ...form["area_vendible_m2"].validators,
                                  })}
                                  placeholder={
                                    form["area_vendible_m2"].placeholder
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["area_vendible_m2"] && (
                              <div>
                                <label className="form-error">
                                  {errors["area_vendible_m2"].message}
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["area_lote_m2"].label}
                                required={
                                  form["area_lote_m2"].validators.required.value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  type="text"
                                  {...register(form["area_lote_m2"].name, {
                                    ...form["area_lote_m2"].validators,
                                  })}
                                  placeholder={form["area_lote_m2"].placeholder}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["area_lote_m2"] && (
                              <div>
                                <label className="form-error">
                                  {errors["area_lote_m2"].message}
                                </label>
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["ano_construccion"].label}
                                required={
                                  form["ano_construccion"].validators.required
                                    .value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  type="text"
                                  {...register(form["ano_construccion"].name, {
                                    ...form["ano_construccion"].validators,
                                  })}
                                  placeholder={
                                    form["ano_construccion"].placeholder
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["ano_construccion"] && (
                              <div>
                                <label className="form-error">
                                  {errors["ano_construccion"].message}
                                </label>
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["categoria"].label}
                                required={
                                  form["categoria"].validators.required.value
                                }
                              />
                              <div className="custom-input-box">
                                <select
                                  {...register(form["categoria"].name, {
                                    ...form["categoria"].validators,
                                  })}
                                >
                                  <option value="">
                                    {form["categoria"].placeholder}
                                  </option>
                                  {categoriesList.map((item) => (
                                    <option
                                      key={`origin-founds-${item.id}`}
                                      value={item.id}
                                    >
                                      {item.nombre}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              {errors["categoria"] && (
                                <div>
                                  <label className="form-error">
                                    {errors["categoria"].message}
                                  </label>
                                </div>
                              )}
                            </div>
                          </div>
                        </fieldset>
                        <hr />
                        <div className="form-btn-group">
                          <div className="form-btn-group-left col-sm-6">
                            <div
                              style={{ marginRight: "10px" }}
                              className="form-btn-box"
                            >
                              <Boton
                                disabled={disableForm}
                                className={"btn _btn-primary font-14"}
                                label={btnText}
                                borderRadius="10px"
                              />
                            </div>
                            <div
                              style={{ marginLeft: "10px" }}
                              className="form-btn-box"
                            >
                              <Boton
                                disabled={disableForm}
                                className={"btn _btn-secondary font-14"}
                                label="Restablecer"
                                borderRadius="10px"
                                handlerClick={resetForm}
                              />
                            </div>
                          </div>
                          <div className="form-btn-group-right col-sm-6">
                            <div className="form-btn-box">
                              <Boton
                                className={"btn _btn-gray font-14"}
                                label="Cancelar"
                                borderRadius="10px"
                                handlerClick={cancelBTN}
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Tab>
                {scenario === "update" && (
                  <Tab
                    eventKey="datos_complementarios"
                    title="Datos complementarios"
                  >
                    <div className="tab-container">
                      <div className="w-100">
                        <form
                          id={"form2"}
                          key={2}
                          onSubmit={handleSubmit2((data) =>
                            verifySubmit2(data)
                          )}
                        >
                          <fieldset className="row" disabled={disableForm}>
                            <div className="col-md-6 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={form2["matricula_inmobiliaria"].label}
                                  required={
                                    form2["matricula_inmobiliaria"].validators
                                      .required.value
                                  }
                                />
                                <div className="custom-input-box">
                                  <input
                                    type="text"
                                    {...register2(
                                      form2["matricula_inmobiliaria"].name,
                                      {
                                        ...form2["matricula_inmobiliaria"]
                                          .validators,
                                      }
                                    )}
                                    placeholder={
                                      form2["matricula_inmobiliaria"]
                                        .placeholder
                                    }
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                              {errors2["matricula_inmobiliaria"] && (
                                <div>
                                  <label className="form-error">
                                    {errors2["matricula_inmobiliaria"].message}
                                  </label>
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={form2["cedula_catastral"].label}
                                  required={
                                    form2["cedula_catastral"].validators
                                      .required.value
                                  }
                                />
                                <div className="custom-input-box">
                                  <input
                                    type="text"
                                    {...register2(
                                      form2["cedula_catastral"].name,
                                      {
                                        ...form2["cedula_catastral"].validators,
                                      }
                                    )}
                                    placeholder={
                                      form2["cedula_catastral"].placeholder
                                    }
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                              {errors2["cedula_catastral"] && (
                                <div>
                                  <label className="form-error">
                                    {errors2["cedula_catastral"].message}
                                  </label>
                                </div>
                              )}
                            </div>
                            {/* certificado_tradicion_libertad */}
                            <div className="col-md-6 mb-3">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={
                                    form2["certificado_tradicion_libertad"]
                                      .label
                                  }
                                  required={
                                    form2["certificado_tradicion_libertad"]
                                      .validators.required.value
                                  }
                                />
                                <div className="custom-input-box">
                                  <label
                                    style={{
                                      cursor: "pointer",
                                      margin: "auto",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      maxWidth: "100%",
                                    }}
                                    htmlFor={
                                      form2["certificado_tradicion_libertad"]
                                        .name
                                    }
                                    title={
                                      certificadoTradicionLibertad
                                        ? clearName(
                                            certificadoTradicionLibertad.name
                                          )
                                        : getNameOfUrl(
                                            "certificado_tradicion_libertad"
                                          )
                                    }
                                  >
                                    {certificadoTradicionLibertad
                                      ? clearName(
                                          certificadoTradicionLibertad.name
                                        )
                                      : getNameOfUrl(
                                          "certificado_tradicion_libertad"
                                        )}
                                  </label>
                                  <input
                                    id={
                                      form2["certificado_tradicion_libertad"]
                                        .name
                                    }
                                    {...register2(
                                      form2["certificado_tradicion_libertad"]
                                        .name,
                                      {
                                        ...form2[
                                          "certificado_tradicion_libertad"
                                        ].validators,
                                      }
                                    )}
                                    style={{ height: 0, width: 0 }}
                                    type="file"
                                    accept={
                                      form2["certificado_tradicion_libertad"]
                                        .accept
                                    }
                                    onChange={
                                      onChangeCertificadoTradicionLibertad
                                    }
                                  />
                                  {getNameOfUrl(
                                    "certificado_tradicion_libertad"
                                  ) !== "Seleccione" ? (
                                    <div
                                      onMouseOver={() => mouseOver()}
                                      onMouseOut={mouseOut}
                                      disabled={false}
                                      style={{
                                        cursor: "pointer",
                                        zIndex: "999999",
                                      }}
                                      onClick={() =>
                                        viewDocument(
                                          "certificado_tradicion_libertad"
                                        )
                                      }
                                    >
                                      <IconBoxEye
                                        width="29px"
                                        height="20px"
                                        colorIcon="#9891bb"
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                {errors2["certificado_tradicion_libertad"] && (
                                  <div>
                                    <label className="form-error">
                                      {
                                        errors2[
                                          "certificado_tradicion_libertad"
                                        ].message
                                      }
                                    </label>
                                  </div>
                                )}
                              </div>
                            </div>
                            {/* certificado_tradicion_libertad */}
                            <div className="col-md-6 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={form2["canon_arriendo"].label}
                                  required={
                                    form2["canon_arriendo"].validators.required
                                      .value
                                  }
                                />
                                <div className="custom-input-box">
                                  <input
                                    type="text"
                                    {...register2(
                                      form2["canon_arriendo"].name,
                                      {
                                        ...form2["canon_arriendo"].validators,
                                      }
                                    )}
                                    placeholder={
                                      form2["canon_arriendo"].placeholder
                                    }
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                              {errors2["canon_arriendo"] && (
                                <div>
                                  <label className="form-error">
                                    {errors2["canon_arriendo"].message}
                                  </label>
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 mb-3">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={form2["contrato_arriendo"].label}
                                  required={
                                    form2["contrato_arriendo"].validators
                                      .required.value
                                  }
                                />
                                <div className="custom-input-box">
                                  <label
                                    style={{
                                      cursor: "pointer",
                                      margin: "auto",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      maxWidth: "100%",
                                    }}
                                    htmlFor={form2["contrato_arriendo"].name}
                                    title={
                                      contratoArriendo
                                        ? clearName(contratoArriendo.name)
                                        : getNameOfUrl("contrato_arriendo")
                                    }
                                  >
                                    {contratoArriendo
                                      ? clearName(contratoArriendo.name)
                                      : getNameOfUrl("contrato_arriendo")}
                                  </label>
                                  <input
                                    id={form2["contrato_arriendo"].name}
                                    {...register2(
                                      form2["contrato_arriendo"].name,
                                      {
                                        ...form2["contrato_arriendo"]
                                          .validators,
                                      }
                                    )}
                                    style={{ height: 0, width: 0 }}
                                    type="file"
                                    accept={form2["contrato_arriendo"].accept}
                                    onChange={onChangeContratoArriendo}
                                  />
                                  {getNameOfUrl(
                                    "certificado_tradicion_libertad"
                                  ) !== "Seleccione" ? (
                                    <div
                                      onMouseOver={mouseOver}
                                      onMouseOut={mouseOut}
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        viewDocument("contrato_arriendo")
                                      }
                                    >
                                      <IconBoxEye
                                        width="29px"
                                        height="20px"
                                        colorIcon="#9891bb"
                                      />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>

                                {errors2["contrato_arriendo"] && (
                                  <div>
                                    <label className="form-error">
                                      {errors2["contrato_arriendo"].message}
                                    </label>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={form2["nombre_arrendatario"].label}
                                  required={
                                    form2["nombre_arrendatario"].validators
                                      .required.value
                                  }
                                />
                                <div className="custom-input-box">
                                  <input
                                    type="text"
                                    {...register2(
                                      form2["nombre_arrendatario"].name,
                                      {
                                        ...form2["nombre_arrendatario"]
                                          .validators,
                                      }
                                    )}
                                    placeholder={
                                      form2["nombre_arrendatario"].placeholder
                                    }
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                              {errors2["nombre_arrendatario"] && (
                                <div>
                                  <label className="form-error">
                                    {errors2["nombre_arrendatario"].message}
                                  </label>
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={
                                    form2["informacion_arrendatario"].label
                                  }
                                  required={
                                    form2["informacion_arrendatario"].validators
                                      .required.value
                                  }
                                />
                                <div className="custom-input-box-tarea">
                                  <textarea
                                    {...register2(
                                      form2["informacion_arrendatario"].name,
                                      {
                                        ...form2["informacion_arrendatario"]
                                          .validators,
                                      }
                                    )}
                                    placeholder={
                                      form2["informacion_arrendatario"]
                                        .placeholder
                                    }
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                              {errors2["informacion_arrendatario"] && (
                                <div>
                                  <label className="form-error">
                                    {
                                      errors2["informacion_arrendatario"]
                                        .message
                                    }
                                  </label>
                                </div>
                              )}
                            </div>

                            <div className="col-md-6 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={
                                    form2["vencimiento_contrato_arrendamiento"]
                                      .label
                                  }
                                  required={
                                    form2["vencimiento_contrato_arrendamiento"]
                                      .validators.required.value
                                  }
                                />
                                <div className="custom-input-box">
                                  <input
                                    type="date"
                                    {...register2(
                                      form2[
                                        "vencimiento_contrato_arrendamiento"
                                      ].name,
                                      {
                                        ...form2[
                                          "vencimiento_contrato_arrendamiento"
                                        ].validators,
                                      }
                                    )}
                                    placeholder={
                                      form2[
                                        "vencimiento_contrato_arrendamiento"
                                      ].placeholder
                                    }
                                    autoComplete="off"
                                    disabled={disableForm}
                                  />
                                </div>
                                {errors2[
                                  "vencimiento_contrato_arrendamiento"
                                ] && (
                                  <div>
                                    <label className="form-error">
                                      {
                                        errors2[
                                          "vencimiento_contrato_arrendamiento"
                                        ].message
                                      }
                                    </label>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-6 mb-3">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={form2["imagen_1"].label}
                                  required={
                                    form2["imagen_1"].validators.required.value
                                  }
                                />
                                <div className="custom-input-box">
                                  <label
                                    style={{
                                      cursor: "pointer",
                                      margin: "auto",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      maxWidth: "100%",
                                    }}
                                    htmlFor={form2["imagen_1"].name}
                                    title={
                                      imagen1 ? imagen1.name : "Seleccione"
                                    }
                                  >
                                    {imagen1 ? imagen1.name : "Seleccione"}
                                  </label>
                                  <input
                                    id={form2["imagen_1"].name}
                                    {...register2(form2["imagen_1"].name, {
                                      ...form2["imagen_1"].validators,
                                    })}
                                    style={{ height: 0, width: 0 }}
                                    type="file"
                                    accept={form2["imagen_1"].accept}
                                    onChange={onChangeImagen1}
                                  />
                                </div>
                                {defaultFormData2 &&
                                  defaultFormData2["imagen_1"] &&
                                  !imagen1 && (
                                    <div className="img-float">
                                      <img
                                        className="img-box-float"
                                        src={defaultFormData2["imagen_1"]}
                                        alt="Logo"
                                      />
                                    </div>
                                  )}
                                {imagen1 && (
                                  <div className="img-float">
                                    <img
                                      style={{
                                        borderRadius: "5px",
                                      }}
                                      className="img-box-float"
                                      src={showImg(imagen1)}
                                      alt="Logo"
                                    />
                                  </div>
                                )}
                                {errors2["imagen_1"] && (
                                  <div>
                                    <label className="form-error">
                                      {errors2["imagen_1"].message}
                                    </label>
                                  </div>
                                )}
                              </div>
                            </div>

                            {(imagen1 ||
                              (defaultFormData2 &&
                                defaultFormData2["imagen_1"]) ||
                              (defaultFormData2 &&
                                defaultFormData2["imagen_2"])) && (
                              <div className="col-md-6 mb-3 container-animated">
                                <div className="form-custom-box">
                                  <FormFloatingLabel
                                    label={form2["imagen_2"].label}
                                    required={
                                      form2["imagen_2"].validators.required
                                        .value
                                    }
                                  />
                                  <div className="custom-input-box">
                                    <label
                                      style={{
                                        cursor: "pointer",
                                        margin: "auto",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        maxWidth: "100%",
                                      }}
                                      htmlFor={form2["imagen_2"].name}
                                      title={
                                        imagen2 ? imagen2.name : "Seleccione"
                                      }
                                    >
                                      {imagen2 ? imagen2.name : "Seleccione"}
                                    </label>
                                    <input
                                      id={form2["imagen_2"].name}
                                      {...register2(form2["imagen_2"].name, {
                                        ...form2["imagen_2"].validators,
                                      })}
                                      style={{ height: 0, width: 0 }}
                                      type="file"
                                      accept={form2["imagen_2"].accept}
                                      onChange={onChangeImagen2}
                                    />
                                  </div>
                                  {defaultFormData2 &&
                                    defaultFormData2["imagen_2"] &&
                                    !imagen2 && (
                                      <div className="img-float">
                                        <img
                                          className="img-box-float"
                                          src={defaultFormData2["imagen_2"]}
                                          alt="Logo"
                                        />
                                      </div>
                                    )}
                                  {imagen2 && (
                                    <div className="img-float">
                                      <img
                                        className="img-box-float"
                                        src={showImg(imagen2)}
                                        alt="Logo"
                                      />
                                    </div>
                                  )}
                                  {errors2["imagen_2"] && (
                                    <div>
                                      <label className="form-error">
                                        {errors2["imagen_2"].message}
                                      </label>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}

                            {(imagen2 ||
                              (defaultFormData2 &&
                                defaultFormData2["imagen_2"]) ||
                              (defaultFormData2 &&
                                defaultFormData2["imagen_3"])) && (
                              <div className="col-md-6 mb-3 container-animated">
                                <div className="form-custom-box">
                                  <FormFloatingLabel
                                    label={form2["imagen_3"].label}
                                    required={
                                      form2["imagen_3"].validators.required
                                        .value
                                    }
                                  />
                                  <div className="custom-input-box">
                                    <label
                                      style={{
                                        cursor: "pointer",
                                        margin: "auto",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        maxWidth: "100%",
                                      }}
                                      htmlFor={form2["imagen_3"].name}
                                      title={
                                        imagen3 ? imagen3.name : "Seleccione"
                                      }
                                    >
                                      {imagen3 ? imagen3.name : "Seleccione"}
                                    </label>
                                    <input
                                      id={form2["imagen_3"].name}
                                      {...register2(form2["imagen_3"].name, {
                                        ...form2["imagen_3"].validators,
                                      })}
                                      style={{ height: 0, width: 0 }}
                                      type="file"
                                      accept={form2["imagen_3"].accept}
                                      onChange={onChangeImagen3}
                                    />
                                  </div>
                                  {defaultFormData2 &&
                                    defaultFormData2["imagen_3"] &&
                                    !imagen3 && (
                                      <div className="img-float">
                                        <img
                                          className="img-box-float"
                                          src={defaultFormData2["imagen_3"]}
                                          alt="Logo"
                                        />
                                      </div>
                                    )}
                                  {imagen3 && (
                                    <div className="img-float">
                                      <img
                                        className="img-box-float"
                                        src={showImg(imagen3)}
                                        alt="Logo"
                                      />
                                    </div>
                                  )}
                                  {errors2["imagen_3"] && (
                                    <div>
                                      <label className="form-error">
                                        {errors2["imagen_3"].message}
                                      </label>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}

                            {(imagen3 ||
                              (defaultFormData2 &&
                                defaultFormData2["imagen_3"]) ||
                              (defaultFormData2 &&
                                defaultFormData2["imagen_4"])) && (
                              <div className="col-md-6 mb-3 container-animated">
                                <div className="form-custom-box">
                                  <FormFloatingLabel
                                    label={form2["imagen_4"].label}
                                    required={
                                      form2["imagen_4"].validators.required
                                        .value
                                    }
                                  />
                                  <div className="custom-input-box">
                                    <label
                                      style={{
                                        cursor: "pointer",
                                        margin: "auto",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        maxWidth: "100%",
                                      }}
                                      htmlFor={form2["imagen_4"].name}
                                      title={
                                        imagen4 ? imagen4.name : "Seleccione"
                                      }
                                    >
                                      {imagen4 ? imagen4.name : "Seleccione"}
                                    </label>
                                    <input
                                      id={form2["imagen_4"].name}
                                      {...register2(form2["imagen_4"].name, {
                                        ...form2["imagen_4"].validators,
                                      })}
                                      style={{ height: 0, width: 0 }}
                                      type="file"
                                      accept={form2["imagen_4"].accept}
                                      onChange={onChangeImagen4}
                                    />
                                  </div>
                                  {defaultFormData2 &&
                                    defaultFormData2["imagen_4"] &&
                                    !imagen4 && (
                                      <div className="img-float">
                                        <img
                                          className="img-box-float"
                                          src={defaultFormData2["imagen_4"]}
                                          alt="Logo"
                                        />
                                      </div>
                                    )}
                                  {imagen4 && (
                                    <div className="img-float">
                                      <img
                                        className="img-box-float"
                                        src={showImg(imagen4)}
                                        alt="Logo"
                                      />
                                    </div>
                                  )}
                                  {errors2["imagen_4"] && (
                                    <div>
                                      <label className="form-error">
                                        {errors2["imagen_4"].message}
                                      </label>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}

                            {(imagen4 ||
                              (defaultFormData2 &&
                                defaultFormData2["imagen_4"]) ||
                              (defaultFormData2 &&
                                defaultFormData2["imagen_5"])) && (
                              <div className="col-md-6 mb-3 container-animated">
                                <div className="form-custom-box">
                                  <FormFloatingLabel
                                    label={form2["imagen_5"].label}
                                    required={
                                      form2["imagen_5"].validators.required
                                        .value
                                    }
                                  />
                                  <div className="custom-input-box">
                                    <label
                                      style={{
                                        cursor: "pointer",
                                        margin: "auto",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        maxWidth: "100%",
                                      }}
                                      htmlFor={form2["imagen_5"].name}
                                      title={
                                        imagen5 ? imagen5.name : "Seleccione"
                                      }
                                    >
                                      {imagen5 ? imagen5.name : "Seleccione"}
                                    </label>
                                    <input
                                      id={form2["imagen_5"].name}
                                      {...register2(form2["imagen_5"].name, {
                                        ...form2["imagen_5"].validators,
                                      })}
                                      style={{ height: 0, width: 0 }}
                                      type="file"
                                      accept={form2["imagen_5"].accept}
                                      onChange={onChangeImagen5}
                                    />
                                  </div>
                                  {defaultFormData2 &&
                                    defaultFormData2["imagen_5"] &&
                                    !imagen5 && (
                                      <div className="img-float">
                                        <img
                                          className="img-box-float"
                                          src={defaultFormData2["imagen_5"]}
                                          alt="Logo"
                                        />
                                      </div>
                                    )}
                                  {imagen5 && (
                                    <div className="img-float">
                                      <img
                                        className="img-box-float"
                                        src={showImg(imagen5)}
                                        alt="Logo"
                                      />
                                    </div>
                                  )}
                                  {errors2["imagen_5"] && (
                                    <div>
                                      <label className="form-error">
                                        {errors2["imagen_5"].message}
                                      </label>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                            <div className="col-md-6 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={form2["valor_activo"].label}
                                  required={
                                    form2["valor_activo"].validators.required
                                      .value
                                  }
                                />
                                <div className="custom-input-box">
                                  <input
                                    type="text"
                                    {...register2(form2["valor_activo"].name, {
                                      ...form2["valor_activo"].validators,
                                    })}
                                    placeholder={
                                      form2["valor_activo"].placeholder
                                    }
                                    autoComplete="off"
                                  />
                                </div>
                              </div>
                              {errors2["valor_activo"] && (
                                <div>
                                  <label className="form-error">
                                    {errors2["valor_activo"].message}
                                  </label>
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={form2["sponsor"].label}
                                  required={
                                    form2["sponsor"].validators.required.value
                                  }
                                />
                                <div className="custom-input-box">
                                  <select
                                    {...register2(form2["sponsor"].name, {
                                      ...form2["sponsor"].validators,
                                    })}
                                  >
                                    <option value="">
                                      {form2["sponsor"].placeholder}
                                    </option>
                                    {sponsorLis.map((item) => (
                                      <option
                                        key={`origin-founds-${item.id}`}
                                        value={item.id}
                                      >
                                        {item.company_name}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                {errors2["sponsor"] && (
                                  <div>
                                    <label className="form-error">
                                      {errors2["sponsor"].message}
                                    </label>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 mb-4">
                              <div className="form-custom-box">
                                <FormFloatingLabel
                                  label={form2["fiducia"].label}
                                  required={
                                    form2["fiducia"].validators.required.value
                                  }
                                />
                                <div className="custom-input-box">
                                  <select
                                    {...register2(form2["fiducia"].name, {
                                      ...form2["fiducia"].validators,
                                    })}
                                  >
                                    <option value="">
                                      {form2["fiducia"].placeholder}
                                    </option>
                                    {fiduciaLis.map((item) => (
                                      <option
                                        key={`origin-founds-${item.id}`}
                                        value={item.id}
                                      >
                                        {item.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                {errors2["fiducia"] && (
                                  <div>
                                    <label className="form-error">
                                      {errors2["fiducia"].message}
                                    </label>
                                  </div>
                                )}
                              </div>
                            </div>
                          </fieldset>
                          <hr />
                          <div className="form-btn-group">
                            <div className="form-btn-group-left col-sm-6">
                              <div
                                style={{ marginRight: "10px" }}
                                className="form-btn-box"
                              >
                                <Boton
                                  disabled={disableForm}
                                  className={"btn _btn-primary font-14"}
                                  label={btnText}
                                  borderRadius="10px"
                                />
                              </div>
                              <div
                                style={{ marginLeft: "10px" }}
                                className="form-btn-box"
                              >
                                <Boton
                                  disabled={disableForm}
                                  className={"btn _btn-secondary font-14"}
                                  label="Restablecer"
                                  borderRadius="10px"
                                  handlerClick={resetForm2}
                                />
                              </div>
                            </div>
                            <div className="form-btn-group-right col-sm-6">
                              <div className="form-btn-box">
                                <Boton
                                  className={"btn _btn-gray font-14"}
                                  label="Cancelar"
                                  borderRadius="10px"
                                  handlerClick={cancelBTN}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </Tab>
                )}
                {paso >= 5 && DATA && (
                  <Tab eventKey="fideicomiso" title="Fideicomiso">
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="form-custom-box">
                          <FormFloatingLabel
                            label={"Aprobado Devise"}
                            required={false}
                          />
                          <div className="custom-input-box-tarea">
                            <div className="box-info-data">
                              {DATA.fideicomiso.aprobado_devise ? "Si" : "No"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-custom-box">
                          <FormFloatingLabel
                            label={"Aprobado Fiducia"}
                            required={false}
                          />
                          <div className="custom-input-box-tarea">
                            <div className="box-info-data">
                              {DATA.fideicomiso.aprobado_fiducia ? "Si" : "No"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-custom-box">
                          <FormFloatingLabel
                            label={"Aprobado Propietario"}
                            required={false}
                          />
                          <div className="custom-input-box-tarea">
                            <div className="box-info-data">
                              {DATA.fideicomiso.aprobado_propietario
                                ? "Si"
                                : "No"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-custom-box">
                          <FormFloatingLabel
                            label={"Aprobado Sponsor"}
                            required={false}
                          />
                          <div className="custom-input-box-tarea">
                            <div className="box-info-data">
                              {DATA.fideicomiso.aprobado_sponsor ? "Si" : "No"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-4">
                        <div className="form-custom-box">
                          <FormFloatingLabel
                            label={"Archivo"}
                            required={false}
                          />
                          <div
                            style={{ display: "block" }}
                            className="custom-input-box-tarea"
                          >
                            <div className="box-info-data overflow-hidden">
                              {/* {DATA.fideicomiso.archivo.split("/")} */}
                              <iframe
                                src={
                                  "https://admin.godevise.co" +
                                  DATA.fideicomiso.archivo
                                }
                                width="100%"
                                height="300px"
                              />
                            </div>
                            <div>
                              <Boton
                                className={"btn _btn-secondary font-14"}
                                style={{ borderRadius: "8px", width: "150px" }}
                                label={"Descargar"}
                                handlerClick={() =>
                                  openPdf(DATA.fideicomiso.archivo)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-4">
                        <div className="form-custom-box">
                          <FormFloatingLabel
                            label={"Fideicomiso Firmado"}
                            required={false}
                          />
                          <div
                            style={{ display: "block" }}
                            className="custom-input-box-tarea"
                          >
                            <div className="box-info-data overflow-hidden">
                              {/* {DATA.fideicomiso.fideicomiso_firmado.split("/")} */}
                              <iframe
                                src={
                                  "https://admin.godevise.co" +
                                  DATA.fideicomiso.fideicomiso_firmado
                                }
                                width="100%"
                                height="300px"
                              />
                              <div>
                                <Boton
                                  className={"btn _btn-secondary font-14"}
                                  style={{
                                    borderRadius: "8px",
                                    width: "150px",
                                  }}
                                  label={"Descargar"}
                                  handlerClick={() =>
                                    openPdf(
                                      DATA.fideicomiso.fideicomiso_firmado
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                )}
                {paso >= 7 && (
                  <Tab eventKey="minuta" title="Minuta de Escrituración">
                    <div className="row">
                      <div className="col-md-6 mb-4">
                        <div className="form-custom-box">
                          <FormFloatingLabel
                            label={"Aprobado Fiducia"}
                            required={false}
                          />
                          <div className="custom-input-box-tarea">
                            <div className="box-info-data">
                              {DATA.minuta_escrituracion.aprobado_fiducia
                                ? "Si"
                                : "No"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-custom-box">
                          <FormFloatingLabel
                            label={"Aprobado Notario"}
                            required={false}
                          />
                          <div className="custom-input-box-tarea">
                            <div className="box-info-data">
                              {DATA.minuta_escrituracion.aprobado_notario
                                ? "Si"
                                : "No"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-custom-box">
                          <FormFloatingLabel
                            label={"Aprobado Propietario"}
                            required={false}
                          />
                          <div className="custom-input-box-tarea">
                            <div className="box-info-data">
                              {DATA.minuta_escrituracion.aprobado_propietario
                                ? "Si"
                                : "No"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-custom-box">
                          <FormFloatingLabel
                            label={"Aprobado Sponsor"}
                            required={false}
                          />
                          <div className="custom-input-box-tarea">
                            <div className="box-info-data">
                              {DATA.minuta_escrituracion.aprobado_sponsor
                                ? "Si"
                                : "No"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 mb-4">
                        <div className="form-custom-box">
                          <FormFloatingLabel
                            label={"Minuta Aprobada"}
                            required={false}
                          />
                          <div className="custom-input-box-tarea">
                            <div className="box-info-data">
                              {DATA.minuta_escrituracion.minuta_aprobada
                                ? "Si"
                                : "No"}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-4">
                        <div className="form-custom-box">
                          <FormFloatingLabel
                            label={"Archivo"}
                            required={false}
                          />
                          <div
                            style={{ display: "block" }}
                            className="custom-input-box-tarea"
                          >
                            <div className="box-info-data overflow-hidden">
                              {/* {DATA.minuta_escrituracion.archivo.split("/")} */}
                              <iframe
                                src={
                                  "https://admin.godevise.co" +
                                  DATA.minuta_escrituracion.archivo
                                }
                                width="100%"
                                height="300px"
                              />
                            </div>
                            <div>
                              <Boton
                                className={"btn _btn-secondary font-14"}
                                style={{ borderRadius: "8px", width: "150px" }}
                                label={"Descargar"}
                                handlerClick={() =>
                                  openPdf(DATA.fideicomiso.archivo)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                )}
              </Tabs>
            </div>
          </div>
        </div>
        <Modal
          id="response"
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          appElement={document.getElementById("root")}
        >
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Respuesta</h2>

          <div>
            <textarea
              /* onKeyDown={(e) => onSubmitDecline(e)} */
              type="text"
              id="motivo"
              className="form-control"
              placeholder="Escribe la respuesta..."
            />
            {/* <input type="file" id="document" className="form-control" /> */}

            <div className="btn-group mt-4 w-100 btn-group-logout">
              <div className="btn-cancel">
                <Boton
                  className={"btn _btn-primary font-14"}
                  style={{ borderRadius: "8px", width: "150px" }}
                  label={"Continuar"}
                  handlerClick={() => sendForm()}
                />
              </div>
              <div className="btn-logout">
                <Boton
                  className={"btn _btn-primary font-14"}
                  handlerClick={closeModal}
                  style={{
                    borderRadius: "8px",
                    width: "150px",
                    background: "white",
                    border: "1px solid #112e6d",
                    color: "#112e6d",
                  }}
                  label={"Cancelar"}
                />
              </div>
            </div>
          </div>
        </Modal>
      </div>
      {scenario === "update" && viewChat === 1 && (
        <div className="col-md-4 mt-30 custom-col-1">
          {
            <div className="card-custom">
              <Chat messages={messages} id={activo.id} send={false} />
              <Boton
                className={"btn _btn-primary font-14"}
                handlerClick={newMessages}
                style={{
                  borderRadius: "8px",
                  width: "180px",
                  background: "white",
                  border: "1px solid #112e6d",
                  color: "#112e6d",
                }}
                label={"Verificar respuestas"}
              />
            </div>
          }
        </div>
      )}
      {scenario === "update" && viewChat === 2 && (
        <div className="col-md-4 mt-30 custom-col-1">
          {
            <div className="card-custom">
              <Chat
                messages={messagesMinuta}
                id={activo.id}
                title={"Observaciones sobre la  Minuta de escrituración"}
              />
              <Boton
                className={"btn _btn-primary font-14"}
                handlerClick={newMessagesMinuta}
                icon={<IconBoxReloadOutLine colorIcon="#112e6d" />}
                style={{
                  borderRadius: "8px",
                  width: "180px",
                  background: "white",
                  border: "1px solid #112e6d",
                  color: "#112e6d",
                  margin: "auto",
                }}
                label={""}
              />

              {viewChat === 2 && (
                <div className="card-custom col-sm-12">
                  <div className="form-btn-box">
                    <Boton
                      className={"btn _btn-primary font-14"}
                      label="Responder"
                      borderRadius="10px"
                      handlerClick={handleOpenResponseMinuta}
                    />
                  </div>
                </div>
              )}
            </div>
          }
        </div>
      )}

      <Modal
        id="minuta"
        isOpen={openResponseMinuta}
        /* onAfterOpen={afterOpenModal} */
        onRequestClose={() => setOpenResponseMinuta(false)}
        style={customStyles}
        contentLabel="Example Modal"
        appElement={document.getElementById("root")}
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Respuesta</h2>

        <div>
          <textarea
            /* onKeyDown={(e) => onSubmitDecline(e)} */
            type="text"
            id="m-motivo-minuta"
            className="form-control"
            placeholder="Respuesta"
          />
          {/* <input type="file" id="m-minuta-document" className="form-control" /> */}

          <div className="btn-group mt-4 w-100 btn-group-logout">
            <div style={{ marginRight: "10px" }} className="btn-cancel">
              <Boton
                className={"btn _btn-primary font-14"}
                style={{ borderRadius: "8px", width: "150px" }}
                label={"Continuar"}
                handlerClick={() => sendMinuta(true)}
              />
            </div>
            <div style={{ marginLeft: "10px" }} className="btn-logout">
              <Boton
                className={"btn _btn-primary font-14"}
                handlerClick={() => setOpenResponseMinuta(false)}
                style={{
                  borderRadius: "8px",
                  width: "150px",
                  background: "white",
                  border: "1px solid #112e6d",
                  color: "#112e6d",
                }}
                label={"Cancelar"}
              />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        id="aprobarMinutaEscritura"
        isOpen={modalEscrituraMinutaIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        appElement={document.getElementById("root")}
      >
        <h5 ref={(_subtitle) => (subtitle = _subtitle)}>
          <p>Si no tiene ninguna observación puede aprobar</p>
          <p>la minuta de escrituración, para continuar con el proceso</p>
        </h5>

        <div>
          <div className="btn-group mt-4 w-100 btn-group-logout">
            <div className="btn-cancel">
              <Boton
                className={"btn _btn-primary font-14"}
                style={{ borderRadius: "8px", width: "150px" }}
                label={"Continuar"}
                handlerClick={() => sendFormAprobarEscritura()}
              />
            </div>
            <div className="btn-logout">
              <Boton
                className={"btn _btn-primary font-14"}
                handlerClick={() => setModalEscrituraMinutaIsOpen(false)}
                style={{
                  borderRadius: "8px",
                  width: "150px",
                  background: "white",
                  border: "1px solid #112e6d",
                  color: "#112e6d",
                }}
                label={"Cancelar"}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
