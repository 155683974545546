import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Boton from "../../../components/Boton";
import FormFloatingLabel from "../../../components/FormFloatingLabel";
import { AddAuth, GetAuth } from "../../../helpers/Auth";
import { IsUnauthorized, httpService } from "../../../helpers/ApiService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Occupation(props) {
  const navigate = useNavigate();
  const ATTR = "has_workplace_info";
  const [disableForm, setDisableForm] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const postData = {
    occupation: "",
    company_name: "",
    company_position: "",
    company_country: "",
    company_region: "",
    company_city: "",
    company_phone: "",
    company_address: "",
    company_zip: "",
  };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState(null);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState(null);

  const [occupationList, setOccupationList] = useState([
    "INDEPENDIENTE",
    "EMPLEADO",
    "OTRO",
  ]);

  useEffect(() => {
    console.log("Parent data:", props.data);
    const loadData = async () => {
      let userData = GetAuth();
      let profile = userData.profileCompleted.data;
      setDisableForm(profile[ATTR]);
      await getCountries();
      /* setDisableForm(true); */
      await getInitialData();
    };
    loadData();
  }, []);
  const getInitialData = () => {
    const url = "/user/workplace/update_read/";
    return httpService(url)
      .get()
      .then(async (res) => {
        if (res instanceof Object ) {
          if (res && res.occupation && res.company_country && res.company_name) {
            setInitialData(res);
            let _country = res.company_country;
            setCountry(_country)
            await getDeparments(_country)
            let _department = res.company_region;
            /* setDepartment(_department) */
            await getCities(_department,_country)
            setTimeout(() => {
              for (let key in res) {
                setValue(key, res[key]);
              }
            }, 100);
            
          } else {
          }
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  }
  useEffect(() => {
    if(initialData){
      for(let key in initialData){
        setValue(key,initialData[key])
      }
    }
  },[initialData]);
  const getCountries = async () => {
    return httpService("/cities/countries/")
      .get()
      .then((res) => {
        if (res instanceof Array) {
          setCountries(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getDeparments = async (__country = null) => {
    let _country = country;
    if (__country) {
      _country = __country;
    }
    return httpService(`cities/countries/${_country}/regions/`)
      .get()
      .then((res) => {
        if (res instanceof Array) {
          setDepartments(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getCities = async (__department = null, __country = null) => {
    console.log("XXX getCities", __country);
    let _country = country;
    if (__country) {
      _country = __country;
    }
    let _department = department;
    if (__department) {
      _department = __department;
    }
    return httpService(
      `cities/countries/${_country}/regions/${_department}/cities/`
    )
      .get()
      .then(async (res) => {
        if (res instanceof Array) {
          await setCities(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };

  useEffect(() => {
    //console.log("get departments");
    setDepartments([]);
    setDepartment(null);
    setCities([]);
    setCity(null);
    if (country) {
      console.log("get departments country:", country);
      (async () => {
        await getDeparments();
      })();
    }
  }, [country]);
  useEffect(() => {
    //console.log("get departments");
    setCities([]);
    setCity(null);
    if (department) {
      console.log("get departments country:", country);
      (async () => {
        await getCities();
      })();
    }
  }, [department]);

  const onChangeCountry = async (event) => {
    await setCountry(event.target.value);
  };
  const onChangeDepartments = async (event) => {
    await setDepartment(event.target.value);
  };
  const onChangeCity = async (event) => {
    await setCity(event.target.value);
  };
  const onSubmit = (data) => {
    console.log("onSubmit:",data);
    const formData = new FormData();
    for (let key in data) {
      if (data[key] instanceof FileList) {
        const _file = data[key][0]
        console.log("Name:", _file.name);
        let name = _file.name.replace(/[^a-zA-Z0-9._ ]/g, "");
        name = name.replace(/\s{2,}/g, " ");
        name = name.replace(/ /g, "_");
        name = name.trim();
        console.log("Name clear:", name);
        formData.append(key, _file, name);
      } else {
        formData.append(key, data[key]);
      }
      console.log(key, data[key]);
    }
    const id = toast.loading("Enviando...");
    let result = saveData(formData,id);
    if(result){
      toast.update(id, {
        render: "Se registraron los datos",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
      props.actionButton(result);
    }else{
      toast.update(id, {
        render: "Se ha producido un error inténtelo más tarde",
        type: "error",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
    }
    
  };
  const saveData = (formData) => {
    const url = "/user/workplace/create/";
    return httpService(
      url,formData
    )
      .post()
      .then(async (res) => {
        let userData = GetAuth();
        let newData = { ...userData };
        newData.profileCompleted.data[ATTR] = true;
        AddAuth(newData)
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        
        return;
      });
  }
  const onNext = () => {
    props.actionButton(props.data);
  };
  return (
    <div className="component-datos-ocupacion container-animated">
      <div className="page-security-head" style={{ padding: "5px 0px 15px" }}>
        <div className="page-security-subtitle">Datos de ocupación</div>
      </div>
      <div className="w-100">
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <fieldset className="row" disabled={disableForm}>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Ocupación" required={true}/>
                <div className="custom-input-box">
                  <select {...register("occupation", { required: true })}>
                    <option value="">Selecione</option>
                    {occupationList.map((item, i) => (
                      <option key={`${item}-${i}`} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>

                {errors.occupation && (
                  <div>
                    {errors.occupation.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Nombre de la empresa" required={true}/>
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register("company_name", {
                      required: true,
                      maxLength: 255,
                    })}
                    placeholder="Nombre de la empresa"
                    autoComplete="off"
                  />
                </div>

                {errors.company_name && (
                  <div>
                    {errors.company_name.type === "maxLength" && (
                      <label className="form-error">
                        Máximo de caracteres 255
                      </label>
                    )}

                    {errors.company_name.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Cargo" required={true}/>
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register("company_position", {
                      required: true,
                      maxLength: 255,
                    })}
                    placeholder="cargo"
                    autoComplete="off"
                  />
                </div>

                {errors.company_position && (
                  <div>
                    {errors.company_position.type === "maxLength" && (
                      <label className="form-error">
                        Máximo de caracteres 255
                      </label>
                    )}

                    {errors.company_position.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="País" required={true}/>
                <div className="custom-input-box">
                  <select
                    {...register("company_country", {
                      required: true,
                      onChange: (e) => {
                        onChangeCountry(e);
                      },
                    })}
                  >
                    <option value="">Selecione</option>
                    {countries.map((item) => (
                      <option
                        key={`company-country-${item.id}`}
                        value={item.id}
                      >
                        {item.name}
                      </option>
                    ))}
                    <option value="002">España</option>
                  </select>
                </div>

                {errors.company_country && (
                  <div>
                    {errors.company_country.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Departamento" required={true}/>
                <div className="custom-input-box">
                  <select
                    {...register("company_region", {
                      required: true,
                      onChange: (e) => {
                        onChangeDepartments(e);
                      },
                    })}
                  >
                    <option value="">Selecione</option>
                    {departments.map((item) => (
                      <option key={`company-region-${item.id}`} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                {errors.company_region && (
                  <div>
                    {errors.company_region.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Ciudad" required={true}/>
                <div className="custom-input-box">
                  <select
                    {...register("company_city", {
                      required: true,
                      onChange: (e) => {
                        onChangeCity(e);
                      },
                    })}
                  >
                    <option value="">Selecione</option>
                    {cities.map((item) => (
                      <option key={`company-city-${item.id}`} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                {errors.company_city && (
                  <div>
                    {errors.company_city.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Dirección de la compañía" required={true}/>
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register("company_address", {
                      required: true,
                      maxLength: 500,
                    })}
                    placeholder="dirección de la compañía"
                    autoComplete="off"
                  />
                </div>

                {errors.company_address && (
                  <div>
                    {errors.company_address.type === "maxLength" && (
                      <label className="form-error">
                        Máximo de caracteres 500
                      </label>
                    )}

                    {errors.company_address.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Teléfono" required={true}/>
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register("company_phone", {
                      required: true,
                      pattern: /^([0-9]\d*)(\.\d+)?$/,
                      maxLength: 10,
                      minLength: 10,
                    })}
                    placeholder="Teléfono"
                    autoComplete="off"
                  />
                </div>

                {errors.company_phone && (
                  <div>
                    {errors.company_phone && (
                      <div>
                        {errors.company_phone.type ===
                          "maxLength" && (
                          <label className="form-error">
                            Máximo de caracteres 10
                          </label>
                        )}
                        {errors.company_phone.type ===
                          "minLength" && (
                          <label className="form-error">
                            Mínimo de caracteres 10
                          </label>
                        )}
                        {errors.company_phone.type ===
                          "required" && (
                          <label className="form-error">
                            Este campo es requerido
                          </label>
                        )}
                        {errors.company_phone.type === "pattern" && (
                          <label className="form-error">
                            Sólo valores numéricos
                          </label>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Zip de la compañía" required={true}/>
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register("company_zip", {
                      required: true,
                      maxLength: 1000,
                    })}
                    placeholder="zip de la compañía"
                    autoComplete="off"
                  />
                </div>
                {errors["company_zip"] && (
                  <div>
                    {errors["company_zip"].type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                    {errors["company_zip"].type === "maxLength" && (
                      <label className="form-error">
                        Máximo de caracteres 1000
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            {!disableForm && (
              <div className="col-md-12">
                <div
                  className="mb-2 mt-4"
                  style={props.btnstyle ? props.btnstyle : { display: "flex" }}
                >
                  <Boton
                    className={"btn _btn-primary font-14"}
                    label={
                      props && props.btntext
                        ? props.btntext
                        : "Actualizar datos"
                    }
                  />
                </div>
              </div>
            )}
          </fieldset>
        </form>
        {disableForm && (
          <div className="row">
            <div
              className="col-md-12 mb-2 mt-4"
              style={props.btnstyle ? props.btnstyle : { display: "flex" }}
            >
              <Boton
                className={"btn _btn-primary font-14"}
                label={"Siguiente"}
                handlerClick={onNext}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
