import BreadcrumbsComponent from "../../components/Breadcrumbs/BreadcrumbsComponent";

export default function PageAcademyDetails() {
  return (
    <div className="Page-academy-details container-animated">
      PageAcademyDetails - Detalles de articulo
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <BreadcrumbsComponent />
        </div>
      </div>
    </div>
  );
}
