import { havePermission } from "./protectRoutes";

function menuAccess({ AUTH = null, componentName = "" }) {
  const ROUTES = havePermission();
  if (!AUTH) {
    return false;
  }
  const access = ROUTES.find((item) => {
    return item.componentName === componentName;
  });
  //console.log("menuAccess:", access);
  if (access && access.roleAccess.includes(AUTH.user.role)) {
    return true;
  }
  return false;
}
export default menuAccess;
