export const rolesTypes = () => {
  const _TYPES = {
    ADMINISTRADOR: {
      id: 1,
      label: "Administrador",
      code: "administrador",
      codeName: "ADMINISTRADOR",
      public: false,
    },
    SPONSOR: {
      id: 2,
      label: "Sponsor",
      code: "sponsor",
      public: true,
      codeName: "SPONSOR",
    },
    INVERSIONISTA: {
      id: 3,
      label: "Inversionista",
      code: "inversionista",
      public: true,
      codeName: "INVERSIONISTA",
    },
    PROPIETARIO: {
      id: 4,
      label: "Propietario",
      code: "propietario",
      public: true,
      codeName: "PROPIETARIO",
    },
    NOTARIO: {
      id: 5,
      label: "Notario",
      code: "notario",
      public: false,
      codeName: "NOTARIO",
    },
    FIDUCIA: {
      id: 6,
      label: "Fiducia",
      code: "fiducia",
      public: false,
      codeName: "FIDUCIA",
    },
  };
  return _TYPES;
};

export const profileForm = () => {
  const stepsAttr = {
    has_datos_basicos: {
      label: "Básicos",
      step: 1,
    },
    has_resident_info: {
      label: "Correspondencia",
      step: 2,
    },
    has_financial_info: {
      label: "Bancarios",
      step: 3,
    },
    has_workplace_info: {
      label: "Ocupación",
      step: 4,
    },
    has_socioeconomic_info: {
      label: "Socioeconómico",
      step: 5,
    },
  };
  return stepsAttr;
};

export const numFormatter = ({ num, decimals = 0 }) => {
  let numFormat = "";
  numFormat = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: decimals,
  }).format(num);
  numFormat = numFormat.replace(/\s/g, "");
  return numFormat;
};
export const detectarPatronJSON = (cadena) => {
  // Patrón para detectar JSON válido
  if (typeof cadena !== "string") {
    return false;
  }
  var patron = /^\s*(\{.*\}|\[.*\])\s*$/;
  if (patron.test(cadena)) {
    try {
      let json = JSON.parse(cadena);
      return json instanceof JSON;
    } catch (error) {
      return false;
    }
  } else {
    return true;
  }
};

export const unlinkJosnObject = (OBJ,deep = false) => {
  if(deep){
    return JSON.parse(JSON.stringify(OBJ));
  }else{
    return Object.assign({}, OBJ)
  }
  
};

export const classNameProyect = {
  "Proyecto Residencial": "bg-residencial",
  "Proyecto Comercial": "bg-comercial",
  "Proyecto Industrial": "bg-industrial",
  "Proyecto Infraestructura": "bg-infraestructura",
  "Proyecto Oficinas": "bg-oficinas",
};

export const getResourceFromUrl = async (url, callBack) => {
  let base64 = "";
  base64 = await new Promise((resolve) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => console.error("Error fetching PDF:", error));
  });
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  //const base64WithoutPrefix = base64.substr('data:application/pdf;base64,'.length);
  try {
    const base64WithoutPrefix = base64.split(",")[1];
    const mimeType = base64.split(",")[0].split(";")[0].split(":")[1];
    
    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }
    callBack(URL.createObjectURL(new Blob([out], { type: mimeType })));
  } catch (error) {
    callBack(null);
  }
};
