import React from "react";
import "./CategoryTag.css";

export const CategoryTag = (props) => {
  return (
    <div style={{background:props.background}} className={props.bg ? 'categoryTag ' +props.bg : 'categoryTag'}>
      <img
        alt=""
        className='vector'
        src="https://static.overlay-tech.com/assets/92500225-3840-4871-aab3-78d39916b049.svg"
      />
      <p className='categoryName'>{props.text}</p>
    </div>
  );
};

