import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BreadcrumbsComponent from "../../components/Breadcrumbs/BreadcrumbsComponent";
import TableMovements from "../../components/TableMovements/TableMovements";

export default function PageMovements() {
  var data = [
    {
      movimiento: "Recarga de cuenta",
      proyecto_c: "Movimiento en Devise",
      fecha: "15 Dic 2022 - 4:59 p.m.",
      saldo: "+$5.000.004",
      status: "pendiente",
    },
    {
      movimiento: "Inversión en proyecto",
      proyecto_c: "Proyecto Inmobiliario",
      fecha: "15 Dic 2022 - 4:59 p.m.",
      saldo: "$15.045.004",
      status: "completado",
    },
    {
      movimiento: "Recarga de cuenta",
      proyecto_c: "Movimiento en Devise",
      fecha: "15 Dic 2022 - 4:59 p.m.",
      saldo: "$700.004",
      status: "completado",
    },
    {
      movimiento: "Retiro de wallet",
      proyecto_c: "Movimiento en Devise",
      fecha: "15 Dic 2022 - 4:59 p.m.",
      saldo: "$5.045.004",
      status: "completado",
    },
    {
      movimiento: "Retiro de wallet",
      proyecto_c: "Proyecto Inmobiliario",
      fecha: "15 Dic 2022 - 4:59 p.m.",
      saldo: "-$15.00.000",
      status: "completado",
    },
    {
      movimiento: "Inversión en proyecto",
      proyecto_c: "Proyecto Inmobiliario",
      fecha: "15 Dic 2022 - 4:59 p.m.",
      saldo: "-$1.500.000",
      status: "completado",
    },
    {
      movimiento: "Reconpensa",
      proyecto_c: "Proyecto Residencial",
      fecha: "15 Dic 2022 - 4:59 p.m.",
      saldo: "$10.000",
      status: "completado",
    },
  ];
  return (
    <div className="page-movements container-animated">
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <BreadcrumbsComponent />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="table-title mt-4">Movimientos</div>
        </div>

       
          <div className="col-md-12">
            <div className=" m-4">
              <div className="input-group md-form form-sm form-1 pl-0">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text purple lighten-3"
                    id="basic-text1"
                  >
                    <FontAwesomeIcon
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#787878",
                        cursor: "pointer",
                      }}
                      /* onClick={props.handlerClick.hideMenu} */
                      icon={faSearch}
                    />
                  </span>
                </div>
                <input
                  className="form-control my-0 py-1"
                  type="text"
                  placeholder="Buscar proyecto"
                  aria-label="Buscar proyecto"
                />
              </div>
            </div>
          </div>
          
        <div className="col-md-12 col-lg-12">
          <div className="grid-scroll-h-content border-radius">
            <div className="responsive-table">
              <TableMovements data={data} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
