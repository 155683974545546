import axios from "axios";
import { GetAuth, LogOut, UpdateAuth } from "./Auth";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { getEnviroments } from "./enviroments";

/* const BASE_URL = "http://localhost:3100"; */
const BASE_URL =  getEnviroments().DOMAIN;

const apiInstanceAuth = axios.create({ baseURL: BASE_URL,timeout:5000 });
const noAuth = ["/token"];
const noRedirect = ["/test/login"];

apiInstanceAuth.interceptors.request.use(
  async (request) => {
    
    if (!noAuth.includes(request.url)) {
      console.log("interceptors.request:", noAuth.includes(request.url)  );
      let AUTH = GetAuth();
      //let AUTH = null;
      if (AUTH) {
        request.headers["Authorization"] = `Bearer ${AUTH.token}`;
        const user = jwt_decode(AUTH.token);
        const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 15 * 1000;
        if (!isExpired) {
          return request;
        }
        const response = await axios.post(`${BASE_URL}/token/refresh/`, {
          refresh: AUTH.refreshtoken,
        });
        if(response.access){
          AUTH.token = response.access
        }
        UpdateAuth(JSON.stringify(AUTH));
        request.headers["Authorization"] = `Bearer ${AUTH.token}`;
      }
    }
    return request;
  },
  (error) => error
);

apiInstanceAuth.interceptors.response.use(
  async (response) => {
    return response.data;
  },
  (err) => {
    if (!err.response) {
      return Promise.reject({
        status: "999",
        result: {
          data: "Error 999",
          errMessage: err.name,
          message: err.message,
          timestamp: new Date(),
        },
      });
    }
    let statusCode = err.response.status;

    let url = err.response.config.url;
    if (statusCode === 401) {
      /* LogOut();
      if (!noRedirect.includes(url)){
        window.location.href = "/login";
      } */
      return Promise.reject({
        status: "401",
        result: {
          data: "Unauthorized",
          errMessage: (err.response?"Unauthorized": err.name),
          message: ((err.response && err.response.data.result )?err.response.data.result.message: err.message),
          timestamp: new Date(),
        },
        raw: err.response
      });
    }

    return Promise.reject(err.response); //Adaptar segun la respuesta del servidor
  }
);

export default apiInstanceAuth;
