import imagen from "../../assets/img2.jpeg";
import "./Register.css";
import { useEffect, useState } from "react";
import Logo from "../../assets/Logo/Logo";
import ButtonCardOption from "../../components/ButtonCardOption/ButtonCardOption";
import Boton from "../../components/Boton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { rolesTypes } from "../../helpers/functions";

export default function PageReegister() {
  let { code } = useParams();
  const navigate = useNavigate();
  const [type, setType] = useState("");
  const [codeRef, setCodeRef] = useState("");
  const _RolesTypes = rolesTypes();
  useEffect(() => {
    
    if(code){
      console.log("codeRef:",code);
      setCodeRef(code)
    }
    const height = document.getElementsByClassName("column-rigth")[0];
    const element = document.getElementsByClassName("login-img")[0];

    new Promise((resolve) =>
      setTimeout(() => {
        element.style.height = height.clientHeight + "px";
        resolve(true);
      }, 10)
    );

    function handleResize() {
      let height = document.getElementsByClassName("column-rigth")[0];
      if (height) {
        //console.log("resized to: ", height.clientHeight, "x", height.clientWidth);
      }
    }

    window.addEventListener("resize", handleResize);
  }, []);
  const dataOption = (tilte, subtitle) => {
    setStyle(tilte);
    return (
      <div>
        <div className="style-title">{setStyle(tilte)}</div>
        <div className="style-sub-title">{subtitle}</div>
      </div>
    );
  };
  const setStyle = (text) => {
    let partsText = text.split(" ");
    let t2 = partsText.pop();
    let t1 = partsText;
    return (
      <>
        <span className="color-tertiary">{t1.join(" ")} </span>
        <span className="color-main">{t2} </span>
      </>
    );
  };
  const selectType = (type) => {
    setType(type);
  };
  const register = () => {
    if(!type){
      alert("Debe seleccionar una opción");
      return;
    }
    const _TYPE = type.toUpperCase();
    if( _RolesTypes[_TYPE] === undefined ){
      alert("No permitido");
      return;
    }
    if ((type === _RolesTypes[_TYPE].code && _RolesTypes[_TYPE].public  ) || type === _RolesTypes[_TYPE].code || type === _RolesTypes[_TYPE].code) {
      console.log("register:", type);
      if(codeRef){
        navigate("/register/form/"+codeRef, { state: { type: type } });
      }else
      navigate("/register/form", { state: { type: type } });
    } else {
      alert("Opción inválida");
    }
  };
  return (
    <div className="register-container container-animated">
      <div className="container">
        <div className="column-rigth box">
          <div className="for">
            <div className="logo-img">
              <Logo />
            </div>

            <div className="form-title three text-field-box form-box">
              <h1 className="form-title-sup">
                <span className="color-tertiary">Registrarse en </span>
                <span className="color-main">Devise</span>
              </h1>
              <div className="form-title-sub">
                Por favor selecciona el perfil quedesea ser
              </div>
            </div>
            <div className="box-button-types">
              {_RolesTypes["INVERSIONISTA"].public && <div className="form-title three text-field-box form-box">
                <div className="w-100 mt-4">
                  <ButtonCardOption
                    text={dataOption(
                      `Quiero ser ${_RolesTypes["INVERSIONISTA"].label}`,
                      "Estoy interesado en invertir en diferentes proyectos"
                    )}
                    icon="IconBoxWeb"
                    iconW={"30"}
                    iconH={"25"}
                    arrowColor={type === "inversionista" ? "primary" : ""}
                    onlineStyle={
                      type === "inversionista"
                        ? { border: "1px solid #b9de2c" }
                        : null
                    }
                    handleClick={() => selectType(_RolesTypes["INVERSIONISTA"].code)}
                  />
                </div>
              </div>}
              {_RolesTypes["PROPIETARIO"].public && <div className="form-title three text-field-box form-box">
                <div className="w-100 mt-4">
                  <ButtonCardOption
                    text={dataOption(
                      `Quiero ser ${_RolesTypes["PROPIETARIO"].label}`,
                      "Tengo diferentes propiedades y quiero promocionarlas"
                    )}
                    icon="IconBoxWeb"
                    iconW={"30"}
                    iconH={"25"}
                    arrowColor={type === "propietario" ? "primary" : ""}
                    onlineStyle={
                      type === "propietario"
                        ? { border: "1px solid #b9de2c" }
                        : null
                    }
                    handleClick={() => selectType(_RolesTypes["PROPIETARIO"].code)}
                  />
                </div>
              </div>}
              {_RolesTypes["SPONSOR"].public && <div className="form-title three text-field-box form-box">
                <div className="w-100 mt-4">
                  <ButtonCardOption
                    text={dataOption(
                      `Quiero ser ${_RolesTypes["SPONSOR"].label}`,
                      "Tengo diferentes propiedades y quiero promocionarlas"
                    )}
                    icon="IconBoxWeb"
                    iconW={"30"}
                    iconH={"25"}
                    arrowColor={type === "sponsor" ? "primary" : ""}
                    onlineStyle={
                      type === "sponsor"
                        ? { border: "1px solid #b9de2c" }
                        : null
                    }
                    handleClick={() => selectType(_RolesTypes["SPONSOR"].code)}
                  />
                </div>
              </div>}
            </div>
            <Boton
              className="btn button-secondary form-box"
              label="Continuar"
              redirect="/dashboard"
              variant="contained"
              handlerClick={() => register()}
            >
              Continuar
            </Boton>
            <div className="col-md-12 mb-2 mt-4">
              <Link to={"/login"} replace={true} className="link">
                <span style={{ color: "var(--tertiary-main)" }}>
                  Volver a Login
                </span>
              </Link>
            </div>
          </div>
        </div>
        <div className="column-left box">
          <div className="login-img">
            <div className="img-container">
              <img src={imagen} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
