import { Breadcrumbs, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function BreadcrumbsComponent({ params }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const capitalizeWords = (str) => {
    let param = decodeURIComponent(str);
    let texto = param.replaceAll("-", " ");
    let exceptions = ["y", "de", "tu"];
    return texto
      .toLowerCase()
      .split(" ")
      .map((word) => {
        if (exceptions.includes(word)) {
          return word;
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }
      })
      .join(" ");
  };
  return (
    <div className="breadcrumbs-box">
      <div className="breadcrumbs-box-title">
        {!params ? capitalizeWords(splitLocation.slice(-1)[0]) : capitalizeWords(splitLocation[ splitLocation.length - 2 ])}
      </div>
      <div className="material-breadcrumb">
        <Breadcrumbs aria-label="breadcrumb">
          {/* <Link underline="hover" color="inherit" href="/">
          MUI
        </Link> */}
          {splitLocation.map((item, i, arr) => {
            if (!item) {
              return (
                <Link
                  key={"breadcrumb-" + i}
                  underline="hover"
                  color="inherit"
                  to="/dashboard"
                >
                  Dashboard
                </Link>
              );
            } else {
              if (i === arr.length - 1 && !params) {
                return (
                  <Typography key={"breadcrumb-" + i} color="text.primary">
                    {capitalizeWords(item)} 
                  </Typography>
                );
              } else {
                if (params && i === arr.length - 2) {
                  return (
                    <Typography key={"breadcrumb-" + i} color="text.primary">
                    {capitalizeWords(item)} - {params}
                  </Typography>
                  );
                }else if (!params && i < arr.length - 1){
                  return (
                    <Link
                      key={"breadcrumb-" + i}
                      underline="hover"
                      color="inherit"
                      to={"/" + item}
                    >
                      {capitalizeWords(item)}
                    </Link>
                  );
                }
                
              }
            }
          })}
        </Breadcrumbs>
      </div>
    </div>
  );
}
