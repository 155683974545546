import React from "react";

export const IconFlagCop = ({size="25px"}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="1 1 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1212_50667)">
        <path
          d="M30.0312 15.5H0.96875C0.96875 18.1641 1.69531 20.6344 2.90625 22.7656H28.0938C29.3531 20.6344 30.0312 18.1641 30.0312 15.5Z"
          fill="#2A5F9E"
        />
        <path
          d="M15.5 0.96875C7.50781 0.96875 0.96875 7.45937 0.96875 15.5H30.0312C30.0312 7.45937 23.5406 0.96875 15.5 0.96875Z"
          fill="#FFE62E"
        />
        <path
          d="M15.5 30.0312C20.8766 30.0312 25.575 27.125 28.0938 22.7656H2.90625C5.47344 27.125 10.1234 30.0312 15.5 30.0312Z"
          fill="#ED4C5C"
        />
      </g>
      
    </svg>
  );
};
