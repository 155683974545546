

import apiInstanceAuth from "./ApiInstanceAuth";
import { LogOut } from "./Auth";



export const httpService = ( url, postData = {} ) => {
    let DATA = null;
    if(postData instanceof FormData){
        DATA = postData;
    }else{
        DATA =  { ...postData }
    }
    const executeMethod = {
       post: async() => apiInstanceAuth.post(url, DATA),
       get: async() => apiInstanceAuth.get(url),
       patch: async() => apiInstanceAuth.patch(url,DATA)
    }

    return executeMethod
};

export const IsUnauthorized = (response,navigate = null) => {
    if(response && response.status && response.status === "401"){
        LogOut();
        if(navigate){
            try {
                navigate("/login",{replace:true});
            } catch (error) {
                console.error("Error navigate = useNavigate():",error);
                window.location.replace('/login');
            }
        }else{
            window.location.replace('/login');
        }
        return true;
    }
};
