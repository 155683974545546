import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import SignupForm from "../../components/SignupForm/SignupForm";

export function RegisterForm() {
  let { code } = useParams();
  const [codeRef, setCodeRef] = useState("");
  const location = useLocation();
  const _TYPE = location.state.type;
  useEffect(() => {
    if(code){
      console.log("codeRef:",code);
      setCodeRef(code);
    }
    console.log("location:", location);
  },[]);
  return (
    <div className="component-register-form container-animated">
      <SignupForm TYPE={_TYPE} codeRef={codeRef} />
    </div>
  );
}

export const StateParamWrapper = () => {
  const location = useLocation();
  let _TYPE = null;
  let access = false;
  if (location.state && location.state.type) {
    _TYPE = location.state.type;
  }
  if (_TYPE === "sponsor" || _TYPE === "inversionista" || _TYPE === "propietario") {
    access = true;
  }

  return access ? <Outlet /> : <Navigate to="/register?error=1" replace />;
};
