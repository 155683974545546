import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IsUnauthorized, httpService } from "../../../helpers/ApiService";
import { AddAuth, GetAuth } from "../../../helpers/Auth";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import FormFloatingLabel from "../../../components/FormFloatingLabel";
import { unlinkJosnObject } from "../../../helpers/functions";
import { createCompanyForm } from "../../../components/modelForms/createCompanyForm";
import Boton from "../../../components/Boton";

export default function Company(props) {
  const navigate = useNavigate();
  const ATTR = "has_sponsor_company";
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const [disableForm, setDisableForm] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState(null);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState(null);
  const [logoEmpresa, setLogoEmpresa] = useState(null);

  const [form, setForm] = useState(unlinkJosnObject(createCompanyForm));

  const postData = {
    //interface
    company_name: "",
    company_country: "",
    company_region: "",
    company_city: "",
    company_phone: "",
    company_address: "",
    company_zip_code: "",
    nit: "",
    camara_comercio: "",
    logo_empresa: "",
    info_empresa: "",
    area_registro: "",
  };
  useEffect(() => {
    console.log("Parent data:", props.data);
    for (let key in postData) {
      setValue(key, "");
    }
    const loadData = async () => {
      let userData = GetAuth();
      let profile = userData.profileCompleted.data;
      setDisableForm(profile[ATTR]);
      await getCountries();
      /* setDisableForm(true); */
      await getInitialData();
    };
    loadData();
  }, []);

  const getInitialData = () => {
    const url = "/company/read/";
    return httpService(url)
      .get()
      .then(async (res) => {
        if (res instanceof Object) {
          if (
            res /* &&
            res.occupation &&
            res.company_country &&
            res.company_name */
          ) {
            let data = { ...res };
            let newData = {};
            for (let key in data) {
              if (key === "company_country") {
                newData[key] = data[key].id;
              } else if (key === "company_region") {
                newData[key] = data[key].id;
              } else if (key === "company_city") {
                newData[key] = data[key].id;
              } else {
                newData[key] = data[key];
              }
            }
            setInitialData(newData);
            let _country = newData.company_country;
            setCountry(_country);
            await getDeparments(_country);
            let _department = newData.company_region;
            /* setDepartment(_department) */
            await getCities(_department, _country);
            setTimeout(() => {
              for (let key in newData) {
                setValue(key, newData[key]);
              }
            }, 100);
          } else {
          }
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };

  const getCountries = async () => {
    return httpService("/cities/countries/")
      .get()
      .then((res) => {
        if (res instanceof Array) {
          setCountries(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };

  const getDeparments = async (__country = null) => {
    let _country = country;
    if (__country) {
      _country = __country;
    }
    return httpService(`cities/countries/${_country}/regions/`)
      .get()
      .then((res) => {
        if (res instanceof Array) {
          setDepartments(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };

  const getCities = async (__department = null, __country = null) => {
    console.log("XXX getCities", __country);
    let _country = country;
    if (__country) {
      _country = __country;
    }
    let _department = department;
    if (__department) {
      _department = __department;
    }
    return httpService(
      `cities/countries/${_country}/regions/${_department}/cities/`
    )
      .get()
      .then(async (res) => {
        if (res instanceof Array) {
          await setCities(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };

  useEffect(() => {
    setDepartments([]);
    setDepartment(null);
    setCities([]);
    setCity(null);
    if (country) {
      console.log("get departments country:", country);
      (async () => {
        await getDeparments();
      })();
    }
  }, [country]);
  useEffect(() => {
    setCities([]);
    setCity(null);
    if (department) {
      console.log("get departments country:", country);
      (async () => {
        await getCities();
      })();
    }
  }, [department]);
  const onChangeCountry = async (event) => {
    const attr = "pais";
    setDepartments([]);
    setCities([]);
    await setCountry(event.target.value);
  };
  const onChangeDepartments = async (event) => {
    await setDepartment(event.target.value);
  };
  const onChangeCities = async (event) => {
    await setCity(event.target.value);
  };

  const onChangeLogoEmpresa = (e) => {
    const attr = "logo_lmpresa";
    const file = e.target.files[0];
    setLogoEmpresa(file);
    if (errors && errors[attr]) {
      delete errors[attr];
    }
  };

  const onSubmit = (data) => {
    console.log("onSubmit:", data);
    const formData = new FormData();
    for (let key in data) {
      if (data[key] instanceof FileList) {
        const _file = data[key][0];
        console.log("Name:", _file.name);
        let name = _file.name.replace(/[^a-zA-Z0-9._ ]/g, "");
        name = name.replace(/\s{2,}/g, " ");
        name = name.replace(/ /g, "_");
        name = name.trim();
        console.log("Name clear:", name);
        formData.append(key, _file, name);
      } else {
        formData.append(key, data[key]);
      }
      console.log(key, data[key]);
    }
    const id = toast.loading("Enviando...");
    let result = saveData(formData, id);
    if (result) {
      toast.update(id, {
        render: "Se registraron los datos",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
      props.actionButton(result);
    } else {
      toast.update(id, {
        render: "Se ha producido un error inténtelo más tarde",
        type: "error",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
    }
  };
  const saveData = (formData) => {
    const url = "/company/create/";
    return httpService(url, formData)
      .post()
      .then(async (res) => {
        let userData = GetAuth();
        let newData = { ...userData };
        newData.profileCompleted.data[ATTR] = true;
        AddAuth(newData);
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }

        return;
      });
  };
  const onNext = () => {
    props.actionButton(props.data);
  };
  const showImg = (file) => {
    return URL.createObjectURL(file);
  };
  return (
    <div className="component-company container-animated">
      <div className="page-security-head" style={{ padding: "5px 0px 15px" }}>
        <div className="page-security-subtitle">Datos de compañía</div>
      </div>
      <div className="w-100">
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <fieldset className="row" disabled={disableForm}>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel
                  label={form["company_name"].label}
                  required={form["company_name"].validators.required.value}
                />
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register(form["company_name"].name, {
                      ...form["company_name"].validators,
                    })}
                    placeholder={form["company_name"].placeholder}
                    autoComplete="off"
                  />
                </div>

                {errors["company_name"] && (
                  <div>
                    <label className="form-error">
                      {errors["company_name"].message}
                    </label>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel
                  label={form["company_country"].label}
                  required={form["company_country"].validators.required.value}
                />
                <div className="custom-input-box">
                  <select
                    {...register(form["company_country"].name, {
                      ...form["company_country"].validators,
                      onChange: (e) => {
                        onChangeCountry(e);
                      },
                    })}
                  >
                    <option value="">
                      {form["company_country"].placeholder}
                    </option>
                    {countries.map((item) => (
                      <option key={`origin-founds-${item.id}`} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                {errors["company_country"] && (
                  <div>
                    <label className="form-error">
                      {errors["company_country"].message}
                    </label>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel
                  label={form["company_region"].label}
                  required={form["company_region"].validators.required.value}
                />
                <div className="custom-input-box">
                  <select
                    {...register(form["company_region"].name, {
                      ...form["company_region"].validators,
                      onChange: (e) => {
                        onChangeDepartments(e);
                      },
                    })}
                  >
                    <option value="">
                      {form["company_region"].placeholder}
                    </option>
                    {departments.map((item) => (
                      <option key={`origin-founds-${item.id}`} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                {errors["company_region"] && (
                  <div>
                    <label className="form-error">
                      {errors["company_region"].message}
                    </label>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel
                  label={form["company_city"].label}
                  required={form["company_city"].validators.required.value}
                />
                <div className="custom-input-box">
                  <select
                    {...register(form["company_city"].name, {
                      ...form["company_city"].validators,
                      onChange: (e) => {
                        onChangeCities(e);
                      },
                    })}
                  >
                    <option value="">{form["company_city"].placeholder}</option>
                    {cities.map((item) => (
                      <option key={`origin-founds-${item.id}`} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                {errors["company_city"] && (
                  <div>
                    <label className="form-error">
                      {errors["company_city"].message}
                    </label>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel
                  label={form["company_phone"].label}
                  required={form["company_phone"].validators.required.value}
                />
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register(form["company_phone"].name, {
                      ...form["company_phone"].validators,
                    })}
                    placeholder={form["company_phone"].placeholder}
                    autoComplete="off"
                  />
                </div>

                {errors["company_phone"] && (
                  <div>
                    <label className="form-error">
                      {errors["company_phone"].message}
                    </label>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel
                  label={form["company_address"].label}
                  required={form["company_address"].validators.required.value}
                />
                <div className="custom-input-box-tarea">
                  <textarea
                    {...register(form["company_address"].name, {
                      ...form["company_address"].validators,
                    })}
                    placeholder={form["company_address"].placeholder}
                    autoComplete="off"
                  />
                </div>
              </div>
              {errors["company_address"] && (
                <div>
                  <label className="form-error">
                    {errors["company_address"].message}
                  </label>
                </div>
              )}
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel
                  label={form["company_zip_code"].label}
                  required={form["company_zip_code"].validators.required.value}
                />
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register(form["company_zip_code"].name, {
                      ...form["company_zip_code"].validators,
                    })}
                    placeholder={form["company_zip_code"].placeholder}
                    autoComplete="off"
                  />
                </div>

                {errors["company_zip_code"] && (
                  <div>
                    <label className="form-error">
                      {errors["company_zip_code"].message}
                    </label>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel
                  label={form["nit"].label}
                  required={form["nit"].validators.required.value}
                />
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register(form["nit"].name, {
                      ...form["nit"].validators,
                    })}
                    placeholder={form["nit"].placeholder}
                    autoComplete="off"
                  />
                </div>

                {errors["nit"] && (
                  <div>
                    <label className="form-error">
                      {errors["nit"].message}
                    </label>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel
                  label={form["camara_comercio"].label}
                  required={form["camara_comercio"].validators.required.value}
                />
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register(form["camara_comercio"].name, {
                      ...form["camara_comercio"].validators,
                    })}
                    placeholder={form["camara_comercio"].placeholder}
                    autoComplete="off"
                  />
                </div>

                {errors["camara_comercio"] && (
                  <div>
                    <label className="form-error">
                      {errors["camara_comercio"].message}
                    </label>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="form-custom-box">
                <FormFloatingLabel
                  label={form["logo_empresa"].label}
                  required={form["logo_empresa"].validators.required.value}
                />
                <div className="custom-input-box">
                  <label
                    style={{
                      cursor: "pointer",
                      margin: "auto",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      maxWidth: "100%",
                    }}
                    htmlFor={form["logo_empresa"].name}
                    title={logoEmpresa ? logoEmpresa.name : "Seleccione"}
                  >
                    {logoEmpresa ? logoEmpresa.name : "Seleccione"}
                  </label>
                  <input
                    id={form["logo_empresa"].name}
                    {...register(form["logo_empresa"].name, {
                      ...form["logo_empresa"].validators,
                    })}
                    style={{ height: 0, width: 0 }}
                    type="file"
                    accept={form["logo_empresa"].accept}
                    onChange={onChangeLogoEmpresa}
                  />
                </div>
                {initialData && initialData["logo_empresa"] && !logoEmpresa && (
                  <div className="img-float">
                    <img
                      className="img-box-float"
                      src={initialData["logo_empresa"]}
                      alt="Logo"
                    />
                  </div>
                )}
                {logoEmpresa && (
                  <div className="img-float">
                    <img
                      style={{
                        borderRadius: "5px",
                      }}
                      className="img-box-float"
                      src={showImg(logoEmpresa)}
                      alt="Logo"
                    />
                  </div>
                )}
                {errors["logo_empresa"] && (
                  <div>
                    <label className="form-error">
                      {errors["logo_empresa"].message}
                    </label>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel
                  label={form["info_empresa"].label}
                  required={form["info_empresa"].validators.required.value}
                />
                <div className="custom-input-box-tarea">
                  <textarea
                    {...register(form["info_empresa"].name, {
                      ...form["info_empresa"].validators,
                    })}
                    placeholder={form["info_empresa"].placeholder}
                    autoComplete="off"
                  />
                </div>
              </div>
              {errors["info_empresa"] && (
                <div>
                  <label className="form-error">
                    {errors["info_empresa"].message}
                  </label>
                </div>
              )}
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel
                  label={form["area_registro"].label}
                  required={form["area_registro"].validators.required.value}
                />
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register(form["area_registro"].name, {
                      ...form["area_registro"].validators,
                    })}
                    placeholder={form["area_registro"].placeholder}
                    autoComplete="off"
                  />
                </div>

                {errors["area_registro"] && (
                  <div>
                    <label className="form-error">
                      {errors["area_registro"].message}
                    </label>
                  </div>
                )}
              </div>
            </div>
            {!disableForm && (
              <div className="col-md-12">
                <div
                  className="mb-2 mt-4"
                  style={props.btnstyle ? props.btnstyle : { display: "flex" }}
                >
                  <Boton
                    className={"btn _btn-primary font-14"}
                    label={
                      props && props.btntext
                        ? props.btntext
                        : "Actualizar datos"
                    }
                  />
                </div>
              </div>
            )}
          </fieldset>
        </form>
        {disableForm && (
          <div className="row">
            <div
              className="col-md-12 mb-2 mt-4"
              style={props.btnstyle ? props.btnstyle : { display: "flex" }}
            >
              <Boton
                className={"btn _btn-primary font-14"}
                label={"Siguiente"}
                handlerClick={onNext}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
