import { useNavigate, useParams } from "react-router-dom";
import "./AdminAssetSponsorAdminKpi.css";
import Page404 from "../../Page404";
import { useEffect, useState } from "react";
import { useCallback } from "react";
import { IsUnauthorized, httpService } from "../../../helpers/ApiService";
import { StatusData } from "../../../constants/status";
import BreadcrumbsComponent from "../../../components/Breadcrumbs/BreadcrumbsComponent";
import Boton from "../../../components/Boton";
import { Tab, Tabs } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { createKpiForm } from "../../../components/modelForms/createKpiForm";
import { unlinkJosnObject } from "../../../helpers/functions";
import FormFloatingLabel from "../../../components/FormFloatingLabel";
import { IconBoxEye } from "../../../components/Icons/Icons";
import Chat from "../../../components/chat/Chat";
import Modal from "react-modal";
import { InvertColors } from "../../../helpers/ColorsUtils";
import { toast } from "react-toastify";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export default function AdminAssetSponsorAdminKpi() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loadProject, setloadProject] = useState(true);
  const [paso, setPaso] = useState(0);
  const [loader, setLoader] = useState(false);
  const [page404, setPage404] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const [fieldsetIsDisabled, setFieldsetIsDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Guardar");
  const [messages, setMessages] = useState([]);
  const [showMessages, setShowMessages] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  let subtitle;
  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }
  function closeModal() {
    setIsOpen(false);
  }

  const textEstados = StatusData;
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    onChange,
    formState: { errors },
  } = useForm();
  const [form, setForm] = useState(unlinkJosnObject(createKpiForm));
  const [formDefaultData, setFormDefaultData] = useState(null);
  const [formFiles, setFormFiles] = useState(null);
  const [scenario, setScenario] = useState("create");

  useEffect(() => {
    getAllData();
  }, []);
  
  const getAllData = async () => {
    const iniD = await getInitialData();
    const iniKpi = await getKPI();
    const estado_aprobacion = iniD.estado_aprobacion;
    const _paso = textEstados[estado_aprobacion].paso;
    console.log("initialData getAllData: _paso",_paso)
    if (iniKpi && iniKpi.id) {
      if (_paso === 10) {
        setFieldsetIsDisabled(false);
      } else {
        setFieldsetIsDisabled(true);
        
      }
      if(_paso >= 11){
        setShowMessages(false);
      }
    }else{
      setFieldsetIsDisabled(false);
    }
    getMessages();
  };
  useEffect(() => {
    if (formDefaultData) {
      setScenario("update");
      setButtonText("Actualizar");
      let _form = unlinkJosnObject(form);

      for (let item in _form) {
        console.log("item", item);
        _form[item].defaultValue = formDefaultData[item];

        if (_form[item].type === "file") {
          _form[item].validators.required = false;
        }
        setValue(item, formDefaultData[item]);
      }
      //setFieldsetIsDisabled(false);
    } else {
      setFieldsetIsDisabled(false);
      /* let _form = unlinkJosnObject(form);
      _form['valor_token'].defaultValue = 10
      setForm({...form,..._form}) */
      let data = unlinkJosnObject(createKpiForm);
      setForm({ ...data });
      setScenario("create");
    }
  }, [formDefaultData]);
  const getKPI = async() => {
    const url = `asset/read/kpi/${id}/`;
    return await new Promise(resolve=>{
      httpService(url)
      .get()
      .then(
        /* async */ (res) => {
          console.log("getKPI->:", res);
          if (res instanceof Object) {
            if (res.incremento_anual && res.no_id_fideicomiso) {
              setFormDefaultData(res);
            } else {
              setScenario("create");
            }
          }
          resolve(res);
        }
      )
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        if (err && err.status === 404) {
        }
        resolve(null);
      })
      .finally(() => {});
    });
   
  };

  const getMessages = () => {
    const url = `asset/kpi/list/messages/asset/${id}/`;
    return httpService(url)
      .get()
      .then(
        /* async */ (res) => {
          
          if (res && res.results && res.results.length) {
            setMessages(res.results);
          } else {
            setMessages([]);
          }
          //setShowMessages(true);
          return res;
        }
      )
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
        }
        if (err && err.status === 404) {
        }
        return;
      })
      .finally(() => {
        
      });
  };
  const getInitialData = useCallback(async () => {
    const url = `asset/retrieve/${id}/`;
    setLoader(true);
    return await new Promise((resolve) => {
      httpService(url)
        .get()
        .then(
          /* async */ (res) => {
            console.log("setInitialData->:", res);
            if (res instanceof Object) {
              if (res && res.id) {
                setInitialData(res);

                /* showMessages, setShowMessages */
                if (textEstados[res.estado_aprobacion]) {
                  const estado_aprobacion = res.estado_aprobacion;
                  const _paso = textEstados[res.estado_aprobacion].paso;
                  setPaso(_paso);

                  console.log("setInitialData estado:-->_paso", _paso);
                  console.log(
                    "setInitialData estado:-->estado_aprobacion",
                    estado_aprobacion
                  );
                } else {
                  /* setShowMessagesME(false); */
                }
                /* let _images = [];
    
                  if (res.imagen_1) {
                    _images.push(res.imagen_1);
                  }
                  if (res.imagen_2) {
                    _images.push(res.imagen_2);
                  }
    
                  if (res.imagen_3) {
                    _images.push(res.imagen_3);
                  }
                  if (res.imagen_4) {
                    _images.push(res.imagen_4);
                  }
                  if (res.imagen_5) {
                    _images.push(res.imagen_5);
                  }
                  setImages(_images); */
                setloadProject(true);
              } else {
                console.log("Result empty array:", res);
              }
            }
            resolve(res);
          }
        )
        .catch((err) => {
          console.error("XXXX Response status:", err);
          
          if (err && err.status === 404) {
            setloadProject(false);
            setPage404(true);
          }
          if (IsUnauthorized(err, navigate)) {
            resolve(null);
            /* notify("Sesión expirada",{type:"error"}) */
          }else{
            resolve(null);
          }
          
        })
        .finally(() => {
          setLoader(false);
        });
    });
  });
  if ((isNaN(+id) || !initialData || page404) && !loadProject) {
    return <Page404 isdelay={false} />;
  }
  const backBtn = () => {
    navigate(`/admin-activos/admin/${id}`, { replace: true });
  };
  const verifySubmit = async (data) => {
    if (fieldsetIsDisabled) {
      return;
    }
    console.log("verifySubmit: data", data);
    const url = `asset/update/kpi/${id}/`;
    const payload = new FormData();
    const keys = Object.keys(data);
    for await (let key of keys) {
      if (data[key] instanceof FileList) {
        const file = data[key][0];
        let name = file.name.replace(/[^a-z0-9.]/gi, "_");
        name = name.replace(/\s{2,}/g, " ");
        name = name.replace(/ /g, "_");
        name = name.trim();
        payload.append(key, file, `${name}`);
      } else {
        if (form[key].type !== "file") {
          payload.append(key, `${data[key]}`);
        }
      }
    }
    console.log("verifySubmit: payload", payload);
    return httpService(url, payload)
      .patch()
      .then(async (res) => {
        console.log("verifySubmit->:", res);
        setFormFiles(null);
       /*  await getInitialData();
        getKPI(); */
        toast("Queda en espera de aprobación", {
          render: "success",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
          className:"foo-bar"
        });
        getAllData()
        return res;
      })
      .catch((err) => {
        console.error("verifySubmit XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        if (err && err.status === 404) {
        }
        return;
      })
      .finally(() => {});

    /* getInitialData();
    getKPI(); */
  };
  const handleChange = (data) => {
    console.log("Form Change", data.target.type);
    if (data.target.type === "file") {
      let files = { ...formFiles };
      let file = data.target.files[0];
      let name = data.target.name;
      let fileData = {
        name,
        file,
      };
      files[name] = fileData;
      //data.target.files[0];
      console.log("Form files", files);
      setFormFiles(files);
    }
    //setFormData
  };
  const viewDocument = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  const modalSendMessage = () => {
    const texarea = document.getElementById("respuesta");
    if (texarea.value) {
      const documentFile = document.getElementById("document");
      if (!documentFile) {
        sendMessage(texarea.value);
        return;
      }
      const selectedFile = documentFile?.files[0];
      console.log("documentFile:", selectedFile);
      if (selectedFile) {
        const replacedName = selectedFile.name.replace(/[^a-z0-9.]/gi, "_");
        console.log("documentFile name:", replacedName);
        sendMessage(texarea.value, selectedFile);
      } else {
        sendMessage(texarea.value);
      }
    }
  };
  const sendMessage = (message) => {
    console.log("modalSendMessage texarea:", message);
    const url = `asset/sponsor/sendfeedback/kpi/api/`;
    const payload = new FormData();
    payload.append("activo", id);
    payload.append("mensaje", message);

    return httpService(url, payload)
      .post()
      .then(
        /* async */ (res) => {
          
          closeModal();
          return res;
        }
      )
      .catch((err) => {
        console.error("modalSendMessage XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
        }
        if (err && err.status === 404) {
        }
        return;
      })
      .finally(() => {
        closeModal();
        console.log("modalSendMessage getAllData:");
        getAllData();
      });
  };
  const mouseOver = () => {
    if (fieldsetIsDisabled) {
      let fieldset = document.getElementsByTagName("fieldset")[0];
      fieldset.disabled = false;
    }
  };
  const mouseOut = () => {
    if (fieldsetIsDisabled) {
      let fieldset = document.getElementsByTagName("fieldset")[0];
      fieldset.disabled = true;
    }
  };
  return (
    <div className="page-admin-assets-sponsoradmin-kpi container-animated">
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <BreadcrumbsComponent />
        </div>
      </div>
      <div className="row mt-4 index container-animated">
        <div
          style={{ display: "flex", marginBottom: "10px" }}
          className="col-md-12"
        >
          <Boton
            className={"btn _btn-primary font-14"}
            label="Volver"
            borderRadius="10px"
            handlerClick={backBtn}
          />
          <div style={{ margin: "auto auto auto 30px" }}>
            <span
              style={{ color: "#112e6d", fontSize: "18px", fontWeight: "bold" }}
            >
              {" "}
              Estado:{" "}
            </span>
            {initialData && (
              <span
                className="status-style"
                style={{
                  background: textEstados[initialData.estado_aprobacion]
                    ? textEstados[initialData.estado_aprobacion].color
                    : "",
                  color: textEstados[initialData.estado_aprobacion]
                    ? InvertColors(
                        textEstados[initialData.estado_aprobacion].color
                      )
                    : "black",
                }}
              >
                {" "}
                &nbsp;
                {/* {initialData?.estado_aprobacion} */}
                {initialData &&
                  (textEstados[initialData.estado_aprobacion]
                    ? textEstados[initialData.estado_aprobacion].nombre
                    : initialData.estado_aprobacion)}
              </span>
            )}
          </div>
        </div>

        <div className={scenario === "update" ? "col-md-8" : "col-md-12"}>
          <div className="w-100">
            <div className="datos">
              <Tabs
                defaultActiveKey="kpi-form"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="kpi-form" title="Formulario">
                  <div className="tab-container">
                    <div className="w-100">
                      <form
                        id={"form-kpi"}
                        key={1}
                        onSubmit={handleSubmit((data) => verifySubmit(data))}
                        onChange={(data) => handleChange(data)}
                      >
                        <fieldset className="row" disabled={fieldsetIsDisabled}>
                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["valor_token"].label}
                                required={
                                  form["valor_token"].validators.required.value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  defaultValue={
                                    form["valor_token"].defaultValue
                                  }
                                  type={form["valor_token"].type}
                                  id={form["valor_token"].name}
                                  {...register(form["valor_token"].name, {
                                    ...form["valor_token"].validators,
                                  })}
                                  step={
                                    form["valor_token"].type === "number"
                                      ? form["valor_token"].step
                                        ? form["valor_token"].step
                                        : ".1"
                                      : undefined
                                  }
                                  placeholder={form["valor_token"].placeholder}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["valor_token"] && (
                              <div>
                                <label className="form-error">
                                  {errors["valor_token"].message}
                                </label>
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["tokens_totales"].label}
                                required={
                                  form["tokens_totales"].validators.required
                                    .value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  defaultValue={
                                    form["tokens_totales"].defaultValue
                                  }
                                  type={form["tokens_totales"].type}
                                  id={form["tokens_totales"].name}
                                  {...register(form["tokens_totales"].name, {
                                    ...form["tokens_totales"].validators,
                                  })}
                                  step={
                                    form["tokens_totales"].type === "number"
                                      ? form["tokens_totales"].step
                                        ? form["tokens_totales"].step
                                        : ".1"
                                      : undefined
                                  }
                                  placeholder={
                                    form["tokens_totales"].placeholder
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["tokens_totales"] && (
                              <div>
                                <label className="form-error">
                                  {errors["tokens_totales"].message}
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["tokens_disponibles"].label}
                                required={
                                  form["tokens_disponibles"].validators.required
                                    .value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  defaultValue={
                                    form["tokens_disponibles"].defaultValue
                                  }
                                  type={form["tokens_disponibles"].type}
                                  id={form["tokens_disponibles"].name}
                                  {...register(
                                    form["tokens_disponibles"].name,
                                    {
                                      ...form["tokens_disponibles"].validators,
                                    }
                                  )}
                                  step={
                                    form["tokens_disponibles"].type === "number"
                                      ? form["tokens_disponibles"].step
                                        ? form["tokens_disponibles"].step
                                        : ".1"
                                      : undefined
                                  }
                                  placeholder={
                                    form["tokens_disponibles"].placeholder
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["tokens_disponibles"] && (
                              <div>
                                <label className="form-error">
                                  {errors["tokens_disponibles"].message}
                                </label>
                              </div>
                            )}
                          </div>
                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["tir_ea"].label}
                                required={
                                  form["tir_ea"].validators.required.value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  defaultValue={form["tir_ea"].defaultValue}
                                  type={form["tir_ea"].type}
                                  id={form["tir_ea"].name}
                                  {...register(form["tir_ea"].name, {
                                    ...form["tir_ea"].validators,
                                  })}
                                  step={
                                    form["tir_ea"].type === "number"
                                      ? form["tir_ea"].step
                                        ? form["tir_ea"].step
                                        : ".1"
                                      : undefined
                                  }
                                  placeholder={form["tir_ea"].placeholder}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["tir_ea"] && (
                              <div>
                                <label className="form-error">
                                  {errors["tir_ea"].message}
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["pago_rendimiento"].label}
                                required={
                                  form["pago_rendimiento"].validators.required
                                    .value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  defaultValue={
                                    form["pago_rendimiento"].defaultValue
                                  }
                                  type={form["pago_rendimiento"].type}
                                  id={form["pago_rendimiento"].name}
                                  {...register(form["pago_rendimiento"].name, {
                                    ...form["pago_rendimiento"].validators,
                                  })}
                                  step={
                                    form["pago_rendimiento"].type === "number"
                                      ? form["pago_rendimiento"].step
                                        ? form["pago_rendimiento"].step
                                        : ".1"
                                      : undefined
                                  }
                                  placeholder={
                                    form["pago_rendimiento"].placeholder
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["pago_rendimiento"] && (
                              <div>
                                <label className="form-error">
                                  {errors["pago_rendimiento"].message}
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["tenencia_sugerida"].label}
                                required={
                                  form["tenencia_sugerida"].validators.required
                                    .value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  defaultValue={
                                    form["tenencia_sugerida"].defaultValue
                                  }
                                  type={form["tenencia_sugerida"].type}
                                  id={form["tenencia_sugerida"].name}
                                  {...register(form["tenencia_sugerida"].name, {
                                    ...form["tenencia_sugerida"].validators,
                                  })}
                                  step={
                                    form["tenencia_sugerida"].type === "number"
                                      ? form["tenencia_sugerida"].step
                                        ? form["tenencia_sugerida"].step
                                        : ".1"
                                      : undefined
                                  }
                                  placeholder={
                                    form["tenencia_sugerida"].placeholder
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["tenencia_sugerida"] && (
                              <div>
                                <label className="form-error">
                                  {errors["tenencia_sugerida"].message}
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["renta_mensual"].label}
                                required={
                                  form["renta_mensual"].validators.required
                                    .value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  defaultValue={
                                    form["renta_mensual"].defaultValue
                                  }
                                  type={form["renta_mensual"].type}
                                  id={form["renta_mensual"].name}
                                  {...register(form["renta_mensual"].name, {
                                    ...form["renta_mensual"].validators,
                                  })}
                                  step={
                                    form["renta_mensual"].type === "number"
                                      ? form["renta_mensual"].step
                                        ? form["renta_mensual"].step
                                        : ".1"
                                      : undefined
                                  }
                                  placeholder={
                                    form["renta_mensual"].placeholder
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["renta_mensual"] && (
                              <div>
                                <label className="form-error">
                                  {errors["renta_mensual"].message}
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["renta_por_m2"].label}
                                required={
                                  form["renta_por_m2"].validators.required.value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  defaultValue={
                                    form["renta_por_m2"].defaultValue
                                  }
                                  type={form["renta_por_m2"].type}
                                  id={form["renta_por_m2"].name}
                                  {...register(form["renta_por_m2"].name, {
                                    ...form["renta_por_m2"].validators,
                                  })}
                                  step={
                                    form["renta_por_m2"].type === "number"
                                      ? form["renta_por_m2"].step
                                        ? form["renta_por_m2"].step
                                        : ".1"
                                      : undefined
                                  }
                                  placeholder={form["renta_por_m2"].placeholder}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["renta_por_m2"] && (
                              <div>
                                <label className="form-error">
                                  {errors["renta_por_m2"].message}
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["incremento_anual"].label}
                                required={
                                  form["incremento_anual"].validators.required
                                    .value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  defaultValue={
                                    form["incremento_anual"].defaultValue
                                  }
                                  type={form["incremento_anual"].type}
                                  id={form["incremento_anual"].name}
                                  {...register(form["incremento_anual"].name, {
                                    ...form["incremento_anual"].validators,
                                  })}
                                  step={
                                    form["incremento_anual"].type === "number"
                                      ? form["incremento_anual"].step
                                        ? form["incremento_anual"].step
                                        : ".1"
                                      : undefined
                                  }
                                  placeholder={
                                    form["incremento_anual"].placeholder
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["incremento_anual"] && (
                              <div>
                                <label className="form-error">
                                  {errors["incremento_anual"].message}
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["modelo_financiero"].label}
                                required={
                                  form["modelo_financiero"].validators.required
                                    .value
                                }
                              />
                              <div className="custom-input-box">
                                {form["modelo_financiero"].type === "file" && (
                                  <label
                                    style={{
                                      cursor: "pointer",
                                      margin: "auto",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      maxWidth: "100%",
                                    }}
                                    htmlFor={form["modelo_financiero"].name}
                                    title={
                                      formFiles &&
                                      formFiles["modelo_financiero"]
                                        ? formFiles["modelo_financiero"].file
                                            .name
                                        : form["modelo_financiero"].defaultValue
                                        ? form[
                                            "modelo_financiero"
                                          ].defaultValue.substring(
                                            form[
                                              "modelo_financiero"
                                            ].defaultValue.lastIndexOf("/") + 1
                                          )
                                        : "Seleccione"
                                    }
                                  >
                                    {formFiles && formFiles["modelo_financiero"]
                                      ? formFiles["modelo_financiero"].file.name
                                      : form["modelo_financiero"].defaultValue
                                      ? form[
                                          "modelo_financiero"
                                        ].defaultValue.substring(
                                          form[
                                            "modelo_financiero"
                                          ].defaultValue.lastIndexOf("/") + 1
                                        )
                                      : "Seleccione"}
                                  </label>
                                )}
                                {scenario === "update" && (
                                  <div
                                    onMouseOver={mouseOver}
                                    onMouseOut={mouseOut}
                                    style={{
                                      width: "30px",
                                      margin: "auto 0 auto auto",
                                      display: "flex",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      viewDocument(
                                        form["modelo_financiero"].defaultValue
                                      )
                                    }
                                  >
                                    <IconBoxEye
                                      width="49px"
                                      height="23px"
                                      colorIcon="#9891bb"
                                    />
                                  </div>
                                )}
                                <input
                                  type={form["modelo_financiero"].type}
                                  id={form["modelo_financiero"].name}
                                  accept={form["modelo_financiero"].accept}
                                  {...register(form["modelo_financiero"].name, {
                                    ...form["modelo_financiero"].validators,
                                  })}
                                  style={
                                    form["modelo_financiero"].type === "file"
                                      ? { height: 0, width: 0 }
                                      : null
                                  }
                                  step={
                                    form["modelo_financiero"].type === "number"
                                      ? form["modelo_financiero"].step
                                        ? form["modelo_financiero"].step
                                        : ".1"
                                      : undefined
                                  }
                                  placeholder={
                                    form["modelo_financiero"].placeholder
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["modelo_financiero"] && (
                              <div>
                                <label className="form-error">
                                  {errors["modelo_financiero"].message}
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["clase_inversion"].label}
                                required={
                                  form["clase_inversion"].validators.required
                                    .value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  defaultValue={
                                    form["clase_inversion"].defaultValue
                                  }
                                  type={form["clase_inversion"].type}
                                  id={form["clase_inversion"].name}
                                  {...register(form["clase_inversion"].name, {
                                    ...form["clase_inversion"].validators,
                                  })}
                                  step={
                                    form["clase_inversion"].type === "number"
                                      ? form["clase_inversion"].step
                                        ? form["clase_inversion"].step
                                        : ".1"
                                      : undefined
                                  }
                                  placeholder={
                                    form["clase_inversion"].placeholder
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["clase_inversion"] && (
                              <div>
                                <label className="form-error">
                                  {errors["clase_inversion"].message}
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["plazo_contrato"].label}
                                required={
                                  form["plazo_contrato"].validators.required
                                    .value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  defaultValue={
                                    form["plazo_contrato"].defaultValue
                                  }
                                  type={form["plazo_contrato"].type}
                                  id={form["plazo_contrato"].name}
                                  {...register(form["plazo_contrato"].name, {
                                    ...form["plazo_contrato"].validators,
                                  })}
                                  step={
                                    form["plazo_contrato"].type === "number"
                                      ? form["plazo_contrato"].step
                                        ? form["plazo_contrato"].step
                                        : ".1"
                                      : undefined
                                  }
                                  placeholder={
                                    form["plazo_contrato"].placeholder
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["plazo_contrato"] && (
                              <div>
                                <label className="form-error">
                                  {errors["plazo_contrato"].message}
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["teaser"].label}
                                required={
                                  form["teaser"].validators.required.value
                                }
                              />
                              <div className="custom-input-box">
                                {form["teaser"].type === "file" && (
                                  <label
                                    style={{
                                      cursor: "pointer",
                                      margin: "auto",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      maxWidth: "100%",
                                    }}
                                    htmlFor={form["teaser"].name}
                                    title={
                                      formFiles && formFiles["teaser"]
                                        ? formFiles["teaser"].file.name
                                        : form["teaser"].defaultValue
                                        ? form["teaser"].defaultValue.substring(
                                            form[
                                              "teaser"
                                            ].defaultValue.lastIndexOf("/") + 1
                                          )
                                        : "Seleccione"
                                    }
                                  >
                                    {formFiles && formFiles["teaser"]
                                      ? formFiles["teaser"].file.name
                                      : form["teaser"].defaultValue
                                      ? form["teaser"].defaultValue.substring(
                                          form[
                                            "teaser"
                                          ].defaultValue.lastIndexOf("/") + 1
                                        )
                                      : "Seleccione"}
                                  </label>
                                )}
                                {scenario === "update" && (
                                  <div
                                    onMouseOver={mouseOver}
                                    onMouseOut={mouseOut}
                                    style={{
                                      width: "30px",
                                      margin: "auto 0 auto auto",
                                      display: "flex",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      viewDocument(form["teaser"].defaultValue)
                                    }
                                  >
                                    <IconBoxEye
                                      width="49px"
                                      height="23px"
                                      colorIcon="#9891bb"
                                    />
                                  </div>
                                )}
                                <input
                                  type={form["teaser"].type}
                                  accept={form["teaser"].accept}
                                  style={
                                    form["modelo_financiero"].type === "file"
                                      ? { height: 0, width: 0 }
                                      : null
                                  }
                                  id={form["teaser"].name}
                                  {...register(form["teaser"].name, {
                                    ...form["teaser"].validators,
                                  })}
                                  step={
                                    form["teaser"].type === "number"
                                      ? form["teaser"].step
                                        ? form["teaser"].step
                                        : ".1"
                                      : undefined
                                  }
                                  placeholder={form["teaser"].placeholder}
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["teaser"] && (
                              <div>
                                <label className="form-error">
                                  {errors["teaser"].message}
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["no_id_fideicomiso"].label}
                                required={
                                  form["no_id_fideicomiso"].validators.required
                                    .value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  defaultValue={
                                    form["no_id_fideicomiso"].defaultValue
                                  }
                                  type={form["no_id_fideicomiso"].type}
                                  id={form["no_id_fideicomiso"].name}
                                  {...register(form["no_id_fideicomiso"].name, {
                                    ...form["no_id_fideicomiso"].validators,
                                  })}
                                  step={
                                    form["no_id_fideicomiso"].type === "number"
                                      ? form["no_id_fideicomiso"].step
                                        ? form["no_id_fideicomiso"].step
                                        : ".1"
                                      : undefined
                                  }
                                  placeholder={
                                    form["no_id_fideicomiso"].placeholder
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["no_id_fideicomiso"] && (
                              <div>
                                <label className="form-error">
                                  {errors["no_id_fideicomiso"].message}
                                </label>
                              </div>
                            )}
                          </div>

                          <div className="col-md-6 mb-4">
                            <div className="form-custom-box">
                              <FormFloatingLabel
                                label={form["nombre_fideicomiso"].label}
                                required={
                                  form["nombre_fideicomiso"].validators.required
                                    .value
                                }
                              />
                              <div className="custom-input-box">
                                <input
                                  defaultValue={
                                    form["nombre_fideicomiso"].defaultValue
                                  }
                                  type={form["nombre_fideicomiso"].type}
                                  id={form["nombre_fideicomiso"].name}
                                  {...register(
                                    form["nombre_fideicomiso"].name,
                                    {
                                      ...form["nombre_fideicomiso"].validators,
                                    }
                                  )}
                                  step={
                                    form["nombre_fideicomiso"].type === "number"
                                      ? form["nombre_fideicomiso"].step
                                        ? form["nombre_fideicomiso"].step
                                        : ".1"
                                      : undefined
                                  }
                                  placeholder={
                                    form["nombre_fideicomiso"].placeholder
                                  }
                                  autoComplete="off"
                                />
                              </div>
                            </div>
                            {errors["nombre_fideicomiso"] && (
                              <div>
                                <label className="form-error">
                                  {errors["nombre_fideicomiso"].message}
                                </label>
                              </div>
                            )}
                          </div>
                        </fieldset>
                        <hr />
                        <div className="form-btn-group">
                          <div className="form-btn-group-left col-sm-6">
                            <div
                              style={{ marginRight: "10px" }}
                              className="form-btn-box"
                            >
                              <Boton
                                disabled={fieldsetIsDisabled}
                                className={"btn _btn-primary font-14"}
                                label={buttonText}
                                borderRadius="10px"
                              />
                            </div>
                            {/* <div
                              style={{ marginLeft: "10px" }}
                              className="form-btn-box"
                            >
                              <Boton
                                disabled={false}
                                className={"btn _btn-secondary font-14"}
                                label="Restablecer"
                                borderRadius="10px"
                                handlerClick={resetForm}
                              />
                            </div> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        {scenario === "update" && (
          <div className="col-sm-6 col-md-4 mt-30 ">
            <div className="card-custom">
              <Chat messages={messages} id={id} />
              <Boton
                className={"btn _btn-primary font-14"}
                handlerClick={getAllData}
                style={{
                  borderRadius: "8px",
                  width: "180px",
                  background: "white",
                  border: "1px solid #112e6d",
                  color: "#112e6d",
                }}
                label={"Verificar respuestas"}
              />
            </div>
            {showMessages && (
              <div className="card-custom col-sm-12">
                <div className="form-btn-box">
                  <Boton
                    className={"btn _btn-primary font-14"}
                    label="Responder"
                    borderRadius="10px"
                    handlerClick={() => setIsOpen(true)}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        id="decline"
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        appElement={document.getElementById("root")}
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Responder</h2>

        <div>
          <textarea
            /* onKeyDown={(e) => onSubmitDecline(e)} */
            type="text"
            id="respuesta"
            className="form-control"
            placeholder="..."
          />
          {/*  <input type="file" id="document" className="form-control" /> */}

          <div className="btn-group mt-4 w-100 btn-group-logout">
            <div style={{ marginRight: "10px" }} className="btn-cancel">
              <Boton
                className={"btn _btn-primary font-14"}
                style={{ borderRadius: "8px", width: "150px" }}
                label={"Enviar"}
                handlerClick={() => modalSendMessage()}
              />
            </div>
            <div style={{ marginLeft: "10px" }} className="btn-logout">
              <Boton
                className={"btn _btn-primary font-14"}
                handlerClick={closeModal}
                style={{
                  borderRadius: "8px",
                  width: "150px",
                  background: "white",
                  border: "1px solid #112e6d",
                  color: "#112e6d",
                }}
                label={"Cancelar"}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
