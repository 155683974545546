import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Boton from "../../../components/Boton";
import FormFloatingLabel from "../../../components/FormFloatingLabel";
import { AddAuth, GetAuth } from "../../../helpers/Auth";
import { IsUnauthorized, httpService } from "../../../helpers/ApiService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function BankData(props) {
  const ATTR = "has_financial_info";
  const navigate = useNavigate();
  const [disableForm, setDisableForm] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const [pdfName, setPdfName] = useState("Seleccione");

  

  const postData = {
    bank: "",
    account_number: "",
    account_type: "",
    account_subtype: "",
    certification_file: "",
    aba_code: "",
    swift_code: "",
  };

  const [documentsTypes, setDocumentsTypes] = useState([]);
  const [banks, setBanks] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [accountSubTypes, setAccountSubTypes] = useState([]);
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfUrl, setPdfUrl] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    console.log("Parent data:", props.data);

    const loadData = async () => {
      let userData = GetAuth();

      const [_banks, _accountTypes, _accountSubTypes] = await Promise.all([
        getBanks(),
        getAccountTypes(),
        getAccountSubTypes(),
      ]);
      await getInitialData();
      let profile = userData.profileCompleted.data;
      setDisableForm(profile[ATTR]);
      /* setDisableForm(false); */
      /* if (props && props.data && Object.keys(props.data).length) {
        let data = props.data;
        for (let key in data) {
          setValue(key, data[key]);
        }
      } */
    };
    loadData();
  }, []);
  useEffect(() => {
    if(initialData){
      for(let key in initialData){
        setValue(key,initialData[key])
      }
    }
  },[initialData]);

  const getBanks = async () => {
    const url = "/druo/banks/";
    return httpService(url)
      .get()
      .then(async (res) => {
        if (res instanceof Array && res.length) {
          if (res && res[0].id && res[0].institution_name && res[0].country) {
            setBanks(res);
          } else {
          }
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getAccountTypes = async () => {
    const url = "/druo/account/types/";
    return httpService(url)
      .get()
      .then(async (res) => {
        if (res instanceof Array && res.length) {
          if (res && res[0].id && res[0].value && res[0].name) {
            setAccountTypes(res);
          } else {
          }
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getAccountSubTypes = async () => {
    const url = "druo/account/subtypes/";
    return httpService(url)
      .get()
      .then(async (res) => {
        if (res instanceof Array && res.length) {
          if (res && res[0].id && res[0].value && res[0].name) {
            setAccountSubTypes(res);
          } else {
          }
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getInitialData = () => {
    const url = "/user/financialinfo/update_read/";
    return httpService(url)
      .get()
      .then(async (res) => {
        if (res instanceof Object ) {
          if (res && res.bank && res.account_number && res.account_type) {
            setInitialData(res);
            let name = res.certification_file.substring(res.certification_file.lastIndexOf('/')+1);
            setPdfName(name);
          } else {
          }
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  }

  const onChangePdf = (e) => {
    const file = e.target.files[0];
    errors["certification_file"] = null; 
    setPdfFile(file)
  }

  const onSubmit = (data) => {
    console.log("onSubmit:", data);
    const formData = new FormData();
    for (let key in data) {
      if (data[key] instanceof FileList) {
        const _file = data[key][0]
        console.log("Name:", _file.name);
        let name = _file.name.replace(/[^a-zA-Z0-9._ ]/g, "");
        name = name.replace(/\s{2,}/g, " ");
        name = name.replace(/ /g, "_");
        name = name.trim();
        console.log("Name clear:", name);
        formData.append(key, _file, name);
      } else {
        formData.append(key, data[key]);
      }
      console.log(key, data[key]);
    }
    console.log("Submiting...:", formData);
    const id = toast.loading("Enviando...");
    let result = saveData(formData,id);
    if(result){
      toast.update(id, {
        render: "Se registraron los datos",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
      props.actionButton(result);
    }else{
      toast.update(id, {
        render: "Se ha producido un error inténtelo más tarde",
        type: "error",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
    }
    


  };
  const saveData = (formData) => {
    const url = "/user/financialinfo/create/";
    return httpService(
      url,formData
    )
      .post()
      .then(async (res) => {
        let userData = GetAuth();
        let newData = { ...userData };
        newData.profileCompleted.data[ATTR] = true;
        AddAuth(newData)
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        
        return;
      });
  }
  const onNext = () => {
    props.actionButton(props.data);
  };

  
  const showImg = (file) => {
    return URL.createObjectURL(file);
  };
  return (
    <div className="component-bank-data container-animated">
      {/* <div className="page-security-head" style={{ padding: "5px 0px 15px" }}>
        <div className="page-security-subtitle">Datos del Titular</div>
      </div> */}

      <div className="w-100">
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <fieldset className="row" disabled={disableForm}>
            {/* Seccion datos bamcarios */}
            <div className="col-md-12">
              <div
                className="page-security-head"
                style={{ padding: "5px 0px 15px" }}
              >
                <div className="page-security-subtitle">Datos bancarios</div>
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Banco" required={true} />
                <div className="custom-input-box">
                  <select {...register("bank", { required: true })}>
                    <option value="">Selecione</option>
                    {banks.map((bank) => (
                      <option key={`bank-${bank.id}`} value={bank.id}>
                        {bank.institution_name} {bank.country}
                      </option>
                    ))}
                  </select>
                </div>

                {errors.bank && (
                  <div>
                    {errors.bank.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel required={true} label="Número de cuenta" />
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register("account_number", {
                      required: true,
                      maxLength: 50,
                    })}
                    placeholder="Número de cuenta"
                    autoComplete="off"
                  />
                </div>
                {errors["account_number"] && (
                  <div>
                    {errors["account_number"].type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                    {errors["account_number"].type === "maxLength" && (
                      <label className="form-error">
                        Máximo de caracteres 30
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Tipo de cuenta" required={true}/>
                <div className="custom-input-box">
                  <select {...register("account_type", { required: true })}>
                    <option value="">Selecione</option>
                    {accountTypes.map((item) => (
                      <option key={`account-type-${item.id}`} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                {errors.account_type && (
                  <div>
                    {errors.account_type.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Tipo de sub cuenta" required={true}/>
                <div className="custom-input-box">
                  <select {...register("account_subtype", { required: true })}>
                    <option value="">Selecione</option>
                    {accountSubTypes.map((item) => (
                      <option
                        key={`account-subtype-${item.id}`}
                        value={item.id}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                {errors.account_subtype && (
                  <div>
                    {errors.account_subtype.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>


            <div className="col-md-6 mb-3">
              <div className="form-custom-box">
                <FormFloatingLabel label="PDF de la certificacion bancaria" required={true}/>
                <div className="custom-input-box">
                  <label
                    style={{ cursor: "pointer", margin: "auto" }}
                    htmlFor="certification_file"
                  >
                    {pdfFile
                      ? pdfFile.name
                      : pdfName}
                  </label>
                  <input
                    id="certification_file"
                    {...register("certification_file", { required: true })}
                    style={{ height: 0, width: 0 }}
                    type="file"
                    accept=".pdf"
                    onChange={onChangePdf}
                  />
                </div>
                
                {errors.certification_file && (
                  <label className="form-error">Este campo es requerido</label>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Char code aba" required={true}/>
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register("aba_code", {
                      required: true,
                      maxLength: 50,
                    })}
                    placeholder="char code aba"
                    autoComplete="off"
                  />
                </div>
                {errors["aba_code"] && (
                  <div>
                    {errors["aba_code"].type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                    {errors["aba_code"].type === "maxLength" && (
                      <label className="form-error">
                        Máximo de caracteres 30
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Char code Swift" required={true}/>
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register("swift_code", {
                      required: true,
                      maxLength: 50,
                    })}
                    placeholder="char code Swift"
                    autoComplete="off"
                  />
                </div>
                {errors["swift_code"] && (
                  <div>
                    {errors["swift_code"].type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                    {errors["swift_code"].type === "maxLength" && (
                      <label className="form-error">
                        Máximo de caracteres 30
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>

            {!disableForm && (
              <div className="col-md-12">
                <div
                  className="mb-2 mt-4"
                  style={props.btnstyle ? props.btnstyle : { display: "flex" }}
                >
                  <Boton
                    className={"btn _btn-primary font-14"}
                    label={
                      props && props.btntext
                        ? props.btntext
                        : "Actualizar datos"
                    }
                  />
                </div>
              </div>
            )}
          </fieldset>
        </form>
        {disableForm && (
          <div className="row">
            <div
              className="col-md-12 mb-2 mt-4"
              style={props.btnstyle ? props.btnstyle : { display: "flex" }}
            >
              <Boton
                className={"btn _btn-primary font-14"}
                label={"Siguiente"}
                handlerClick={onNext}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
