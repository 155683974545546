import { useCallback, useEffect, useState } from "react";
import BreadcrumbsComponent from "../../components/Breadcrumbs/BreadcrumbsComponent";
import { IsUnauthorized, httpService } from "../../helpers/ApiService";
import { useNavigate } from "react-router-dom";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import { NavDropdownMenu } from "react-bootstrap-submenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { NavDropdown } from "react-bootstrap";
import BadgesProjects from "../../components/BadgesProjects/BadgesProjects/BadgesProjects";
import { classNameProyect } from "../../helpers/functions";
import { Dialog } from "@mui/material";
import Boton from "../../components/Boton";

export default function PageAdminAssetSponsor() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [activo, setActivo] = useState(false);


  const [responseList, setResponseList] = useState({
    count: 0,
    next: null,
    previous: null,
  });
  useEffect(() => {
    getInitialData();
  }, []);
  const getInitialData = useCallback(async () => {
    const url = "/asset/admin/list/toaprovedsponsor/";
    setLoader(true);
    return httpService(url)
      .get()
      .then(
        /* async */ (res) => {
          if (res instanceof Object) {
            if (res && res.results) {
              setResponseList({
                count: res.count,
                next: res.next,
                previous: res.previous,
              });
              setAssetsList(res.results);
            } else {
              console.log("Result empty array:", res);
            }
          }
          return res;
        }
      )
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      })
      .finally(() => {
        setLoader(false);
      });
  });
  const [assetsList, setAssetsList] = useState([]);

  const viewActivo = (_activo) => {
    /* console.log("_activo:", _activo); */
    setActivo(_activo);
    navigate("/admin-activos/" + _activo.id);
  };
 

  return (
    <div className="page-admin-asset-sponsor container-animated">
      
      <LoaderComponent loader={loader} />
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <BreadcrumbsComponent />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-12">
          <div className={"table-container-responsive"}>
            <div className="table-responsive">
              <table className="table table-borderless">
                <thead>
                  <tr className="head border-top-0">
                    <th
                      scope="col"
                      style={{ minWidth: "100px" }}
                      className="first-col"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      style={{ minWidth: "100px" }}
                      className="middle-col"
                    >
                      Proyecto
                    </th>
                    <th
                      scope="col"
                      style={{ minWidth: "150px" }}
                      className="middle-col"
                    >
                      Tipo
                    </th>
                    <th
                      scope="col"
                      style={{ minWidth: "100px" }}
                      className="middle-col"
                    >
                      Categoría
                    </th>
                    <th
                      scope="col"
                      style={{ minWidth: "100px" }}
                      className="middle-col"
                    >
                      Estado
                    </th>
                    <th
                      scope="col"
                      style={{ minWidth: "100px" }}
                      className="last-col"
                    >
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {assetsList.map((data, i) => (
                    <tr
                      key={"row-component-investments-" + i}
                      className="row-component-investments"
                    >
                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                        className="col-component-investments"
                      >
                        <div style={{ textAlign: "center" }} className="center">
                          {data.id}
                        </div>
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                        className="col-component-investments"
                      >
                        <div style={{ textAlign: "center" }} className="center">
                          {data.nombre}
                        </div>
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                        className="col-component-investments"
                      >
                        <div className="badge">
                          <BadgesProjects
                            labelStyle={{
                              background: data.tipo_proyecto_nombre.color,
                            }}
                            className={`${
                              classNameProyect[data.tipo_proyecto_nombre.nombre]
                                ? classNameProyect[
                                    data.tipo_proyecto_nombre.nombre
                                  ]
                                : "bg-blue"
                            }`}
                            text={data.tipo_proyecto_nombre.nombre}
                          />
                        </div>
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                        className="col-component-investments"
                      >
                        <div className="center col-fecha-inversion">
                          {data.categoria_nombre}
                        </div>
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                        className="col-component-investments"
                      >
                        <div className="center col-fecha-inversion">
                          {data.estado_aprobacion}
                        </div>
                      </td>
                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "middle",
                        }}
                        className="col-component-investments"
                      >
                        <div className="center col-fecha-inversion">
                          <div className="center col-propiedad">
                            <NavDropdownMenu
                              className="hide-arrow"
                              title={
                                <FontAwesomeIcon
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    color: "#787878",
                                    cursor: "pointer",
                                  }}
                                  /* onClick={props.handlerClick.hideMenu} */
                                  icon={faEllipsisV}
                                />
                              }
                              id={"collasible-nav-dropdown" + data.id}
                            >
                              <NavDropdown.Item
                                onClick={() => viewActivo(data)}
                              >
                                Ver
                              </NavDropdown.Item>
                            </NavDropdownMenu>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* <div className="pagination-right">
                <div className="box-right">
                  <Pagination
                    count={pageCount}
                    page={page}
                    onChange={handleChangePagination}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
