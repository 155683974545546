// Overlay use className props to pass style properties to child component.
// To make this component work add className props to your child component manually.
// Here an example: https://gist.github.com/Miniplop/8f87608f8100e758fa5a4eb46f9d151f

import React from "react";
import {
  IconAcademy,
  IconAccount,
  IconBoxEmail,
  IconBoxInfo,
  IconBoxMap,
  IconBoxSecurity,
  IconBoxWeb,
  IconBoxWhatsapp,
  IconDashboard,
  IconHelpSupport,
  IconInvest,
  IconInvestments,
  IconLogOut,
  IconMovements,
  IconReferals,
  IconSecurity,
} from "../Icons/Icons";
import styles from "./ButtonCardOption.module.css";

const ButtonCardOption = ({
  text,
  handleClick,
  icon = null,
  iconW = null,
  iconH = null,
  option = null,
  arrowColor = null,
  onlineStyle = null
}) => {
  return (
    <div style={ onlineStyle?onlineStyle:{} } className={styles.itemMenu}>
      <div className={styles.imputContent} onClick={handleClick}>
        <div className={styles.labelContent}>
          {icon && <div className="icon-box">{iconList(icon,iconW,iconH)}</div>}
          <div className={styles.label}>{text}</div>
        </div>
        <div className={styles.iconContent}>
          <li className={"arrow-right" + (arrowColor?" " + arrowColor:"")}></li>
          {/* <IconRight className={styles.iconRight} /> */}
        </div>
      </div>
    </div>
  );
};
const iconList = (iconName, iconW = null, iconH = null, color = "#B9DE2C") => {
  let ICON = "icon";
  switch (iconName) {
    case "IconDashboard":
      ICON = <IconDashboard colorIcon={color} height={iconH} width={iconW} />;
      break;
    case "IconInvest":
      ICON = <IconInvest colorIcon={color} height={iconH} width={iconW}/>;
      break;
    case "IconInvestments":
      ICON = <IconInvestments colorIcon={color} height={iconH} width={iconW}/>;
      break;
    case "IconMovements":
      ICON = <IconMovements colorIcon={color} height={iconH} width={iconW} />;
      break;
    case "IconAccount":
      ICON = <IconAccount colorIcon={color} height={iconH} width={iconW}/>;
      break;
    case "IconReferals":
      ICON = <IconReferals colorIcon={color} height={iconH} width={iconW}/>;
      break;
    case "IconSecurity":
      ICON = <IconSecurity colorIcon={color} width="19" height="22" />;
      break;
    case "IconAcademy":
      ICON = <IconAcademy colorIcon={color} height={iconH} width={iconW}/>;
      break;
    case "IconHelpSupport":
      ICON = <IconHelpSupport colorIcon={color} height={iconH} width={iconW}/>;
      break;
    case "IconBoxMap":
      ICON = <IconBoxMap colorIcon={color} height={iconH} width={iconW}/>;
      break;
    case "IconLogOut":
      ICON = <IconLogOut colorIcon={color} height={iconH} width={iconW}/>;
      break;
      
      case "IconBoxInfo":
      ICON = <IconBoxInfo colorIcon={color} height={iconH} width={iconW}/>;
      break;
      case "IconBoxSecurity":
      ICON = <IconBoxSecurity colorIcon={color} height={iconH} width={iconW}/>;
      break;
      
      case "IconBoxEmail":
      ICON = <IconBoxEmail colorIcon={color} height={iconH} width={iconW}/>;
      break;
      
      case "IconBoxWhatsapp":
      ICON = <IconBoxWhatsapp colorIcon={color} height={iconH} width={iconW}/>;
      break;
      
      case "IconBoxWeb":
      ICON = <IconBoxWeb colorIcon={color} height={iconH} width={iconW}/>;
      break;

    default:
      ICON = <IconDashboard colorIcon={color} height={iconH} width={iconW}/>;
      break;
  }
  return ICON;
};

export default ButtonCardOption;
