import { useState } from "react";
import "./PdfViewer.css";
import { Document, Page, pdfjs } from "react-pdf";
import Boton from "../Boton";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfViewer({ url, height = "300px" }) {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const paginatorArchivoNext = () => {
    if (numPages === 1) {
      return;
    }
    if (pageNumber === numPages) {
      return;
    }
    setPageNumber(pageNumber + 1);
  };
  const paginatorArchivoPrevious = () => {
    if (numPages === 1) {
      return;
    }
    if (pageNumber === 1) {
      return;
    }
    setPageNumber(pageNumber - 1);
  };
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const openPdf = (file) => {
    window.open(file, "_blank", "noreferrer");
  };
  return (
    <div style={{ marginTop: "10px" }}>
      <div
        style={{
          border: "1px solid #dedede",
          borderRadius: "5px",
          height: "400px",
          overflow: "auto",
          background: "#dedede",
          padding: "2px",
          display: "block ruby",
        }}
      >
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
      <div className="page-container">
        <div onClick={paginatorArchivoPrevious} className="button-round">
          {" "}
          {"<"}{" "}
        </div>{" "}
        <div className="page-info">
          Página {pageNumber} de {numPages}
        </div>{" "}
        <div onClick={paginatorArchivoNext} className="button-round">
          {" "}
          {">"}{" "}
        </div>
      </div>
      <div>
        <Boton
          className={"btn _btn-secondary font-14"}
          style={{
            borderRadius: "8px",
            width: "150px",
          }}
          label={"Descargar"}
          handlerClick={() => openPdf(url)}
        />
      </div>
    </div>
  );
}
