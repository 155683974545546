export const pais = (obj) => {
  if(!obj){
    return {id:'',name:""}
  }
  let data = {};
  const keys = Object.keys(obj);
  keys.forEach((key, index) => {
    if (key === "id") {
      data["id"] = obj[key];
    } else if (key === "name") {
      data["name"] = obj[key];
    } else {
      data[key] = obj;
    }
  });
  return data;
};
export const region = (obj) => {
  if(!obj){
    return {id:'',name:""}
  }
  let data = {};
  const keys = Object.keys(obj);
  keys.forEach((key, index) => {
    if (key === "id") {
      data["id"] = obj[key];
    } else if (key === "name") {
      data["name"] = obj[key];
    } else {
      data[key] = obj;
    }
  });

  return data;
};
export const ciudad = (obj) => {
  if(!obj){
    return {id:'',name:""}
  }
  let data = {};
  const keys = Object.keys(obj);
  keys.forEach((key, index) => {
    if (key === "id") {
      data["id"] = obj[key];
    } else if (key === "name") {
      data["name"] = obj[key];
    } else {
      data[key] = obj;
    }
  });
  return data;
};
export const categoria = (obj) => {
  if(!obj){
    return {id:'',name:""}
  }
  let data = {};
  const keys = Object.keys(obj);
  keys.forEach((key, index) => {
    if (key === "id") {
      data["id"] = obj[key];
    } else if (key === "name") {
      data["name"] = obj[key];
    } else {
      data[key] = obj;
    }
  });
  return data;
};
export const tipoProyecto = (obj) => {
  if (!obj) {
    return { id: "", name: "" };
  }
  let data = {};
  const keys = Object.keys(obj);
  keys.forEach((key, index) => {
    if (key === "id") {
      data["id"] = obj[key];
    } else if (key === "nombre") {
      data["name"] = obj[key];
    } else {
      data[key] = obj;
    }
  });
  return data;
};
export const sponsor = (obj) => {
  if (!obj) {
    return { id: 0, name: "" };
  }
  let data = {};
  const keys = Object.keys(obj);
  keys.forEach((key, index) => {
    if (key === "id") {
      data["id"] = obj[key];
    } else if (key === "company_name") {
      data["name"] = obj[key];
    } else {
      data[key] = obj;
    }
  });

  return data;
};
