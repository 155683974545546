import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Boton from "../../../components/Boton";
import FormFloatingLabel from "../../../components/FormFloatingLabel";
import { GetAuth } from "../../../helpers/Auth";
import { IsUnauthorized, httpService } from "../../../helpers/ApiService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AddAuth } from "../../../helpers/Auth";

export default function BasicData(props) {
  const DATAFORM = {
    birth_city: null,
    birth_country: null,
    birth_date: null,
    birth_region: null,
    doc_city_expedition: null,
    doc_country_expedition: null,
    doc_region_expedition: null,
    document_back_image: null,
    document_front_image: null,
    document_number: null,
    expedition_date: null,
    first_name: null,
    is_natural_person: null,
    last_name: null,
    local_id_type: null,
    mail_delivery: null,
    selfie: null,
    username: null,
  };
  const navigate = useNavigate();
  const ATTR = "has_datos_basicos";
  const [disableForm, setDisableForm] = useState(false);

  const [onlyTime, setOnlyTime] = useState(true);

  const [mailDelivery, setMailDelivery] = useState([]);

  const [userBasicData, setUserBasicData] = useState(DATAFORM);
  const [loadData, setLoadData] = useState(DATAFORM);

  const [documentTypeList, setDocumentTypeList] = useState(false);

  const [documentFrontImage, setDocumentFrontImage] = useState(null);
  const [documentBacktImage, setDocumentBacktImage] = useState(null);
  const [documentSelfie, setDocumentSelfie] = useState(null);

  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [departments, setDepartments] = useState([]);
  const [department, setDepartment] = useState([]);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState([]);

  const [countriesExp, setCountriesExp] = useState([]);
  const [countryExp, setCountryExp] = useState("");
  const [departmentsExp, setDepartmentsExp] = useState([]);
  const [departmentExp, setDepartmentExp] = useState([]);
  const [citiesExp, setCitiesExp] = useState([]);
  const [cityExp, setCityExp] = useState([]);

  const { register, handleSubmit, setValue, formState } = useForm();
  const { errors } = formState;

  const onChangeDocumentFrontImage = (e) => {
    setOnlyTime(false);
    const file = e.target.files[0];
    let _userBasicData = { ...userBasicData };
    _userBasicData["document_front_image"] = file;
    setUserBasicData(_userBasicData);
    /* errors["document_front_image"] = null; */
    setDocumentFrontImage(file);
  };

  const showImg = (file) => {
    return URL.createObjectURL(file);
  };

  const onChangeDocumentBackImage = (e) => {
    setOnlyTime(false);
    const file = e.target.files[0];
    let _userBasicData = { ...userBasicData };
    _userBasicData["document_back_image"] = file;
    setUserBasicData(_userBasicData);
    console.log("onChangeDocumentBackImage", file);
    errors["document_back_image"] = null;
    setDocumentBacktImage(file);
  };
  const onChangeSelfie = (e) => {
    setOnlyTime(false);
    const file = e.target.files[0];
    let _userBasicData = { ...userBasicData };
    _userBasicData["selfie"] = file;
    setUserBasicData(_userBasicData);
    console.log("onChangeDocumentBackImage", file);
    errors["selfie"] = null;
    console.log("errors['selfie']:", errors["selfie"]);
    setDocumentSelfie(file);
  };
  useEffect(() => {
    console.log("useEffect userBasicData:", loadData);
    for (let key in loadData) {
      setValue(key, loadData[key]);
    }
  }, [loadData]);

  useEffect(() => {
    setMailDelivery(["E-MAIL", "DOMICILIO", "TRABAJO"]);
    console.log("Parent data:", props.data);
    let userData = GetAuth();
    let profile = userData.profileCompleted.data;

    const _getDocumentsTypes = async () => {
      await getDocumentsTypes();
      await getCountries();
      await getCountriesExp();
      await getBasicdata();
    };
    _getDocumentsTypes();

    setDisableForm(profile[ATTR]);
    /* setDisableForm(true); */
    if (props && props.data && Object.keys(props.data).length) {
      let data = props.data;
      for (let key in data) {
        setValue(key, data[key]);
      }
    }
  }, []);
  /* /user/basicdata/update_read/ */
  const getBasicdata = async () => {
    return httpService("/user/basicdata/update_read/")
      .get()
      .then(async (res) => {
        console.log("getBasicdata:", res);
        if (res instanceof Object) {
          console.log("getBasicdata Object:", res);
          //res.first_name = "Juan Carlos"
          let _country = 0;
          let _countryExp = 0;
          if (res)
            for (let key in res) {
              if (key === "birth_country" && res[key]) {
                console.log("useEffect setCountry", res[key]);
                _country = res[key];
                setCountry(_country);
                await getDeparments(res[key]);
              }
              if (key === "birth_region" && res[key]) {
                console.log("useEffect setDepartment", res[key]);
                setDepartment(res[key]);
                await getCities(res[key], _country);
              }
              if (key === "birth_city" && res[key]) {
                setCity(res[key]);
              }

              if (key === "doc_country_expedition" && res[key]) {
                console.log("useEffect setCountry", res[key]);
                _countryExp = res[key];
                setCountryExp(_countryExp);
                await getDeparmentsExp(res[key]);
              }
              if (key === "doc_region_expedition" && res[key]) {
                console.log("useEffect setDepartment", res[key]);
                setDepartmentExp(res[key]);
                await getCitiesExp(res[key], _countryExp);
              }
              if (key === "doc_city_expedition" && res[key]) {
                setCityExp(res[key]);
              }
              /* if(key === "doc_country_expedition" && res[key]){
              await getDeparmentsExp(res[key]);
              
            } */

              /* await setValue(key,res[key]) */
            }
          setLoadData(res);
          setUserBasicDataResult(res);
          /* setUserBasicData(res); */
          /* setValue("last_name","Carrizo")
          setValue("is_natural_person",true) */
        }

        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const setUserBasicDataResult = async (response) => {
    console.log("response:", response);
    let data = {};
    for (let key in response) {
      let _data = response[key];
      if (
        key === "document_front_image" ||
        key === "document_back_image" ||
        key === "selfie"
      ) {
        if (typeof response[key] === "string") {
          data[key] = null;
        } else {
          data[key] = _data;
          /* (data[key] = null); */
        }
      } else {
        await (data[key] = _data);
      }
    }
    setUserBasicData(data);
  };

  const getDocumentsTypes = async () => {
    return httpService("/user/idtypes/list/")
      .get()
      .then((res) => {
        if (res instanceof Array) {
          setDocumentTypeList(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getCountries = async () => {
    return httpService("/cities/countries/")
      .get()
      .then((res) => {
        if (res instanceof Array) {
          setCountries(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getCountriesExp = async () => {
    return httpService("/cities/countries/")
      .get()
      .then((res) => {
        if (res instanceof Array) {
          setCountriesExp(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const onChangeCountry = async (event) => {
    setOnlyTime(false);
    console.log("onChangeCountry:", event.target.value);
    setDepartments([]);
    setCities([]);
    let _userBasicData = { ...userBasicData };
    _userBasicData["birth_country"] = event.target.value;
    setUserBasicData(_userBasicData);
    await setCountry(event.target.value);
    getDeparments(event.target.value);
  };
  const getDeparments = async (_country) => {
    return httpService(`cities/countries/${_country}/regions/`)
      .get()
      .then((res) => {
        if (res instanceof Array) {
          setDepartments(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getDeparmentsExp = async (_country) => {
    return httpService(`cities/countries/${_country}/regions/`)
      .get()
      .then((res) => {
        if (res instanceof Array) {
          setDepartmentsExp(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const onChangeDepartments = async (event) => {
    setOnlyTime(false);
    console.log("onChangeCountry:", event.target.value);
    setCities([]);
    let _userBasicData = { ...userBasicData };
    _userBasicData["birth_region"] = event.target.value;
    setUserBasicData(_userBasicData);
    await setDepartment(event.target.value);
    getCities(event.target.value);
  };
  const onChangeDepartmentsExp = async (event) => {
    setOnlyTime(false);
    console.log("onChangeCountry:", event.target.value);
    setCitiesExp([]);
    let _userBasicData = { ...userBasicData };
    _userBasicData["doc_region_expedition"] = event.target.value;
    setUserBasicData(_userBasicData);
    await setDepartmentExp(event.target.value);
    getCitiesExp(event.target.value);
  };
  const getCities = async (_department, _country = null) => {
    let __country = country;
    if (_country) {
      __country = _country;
    }
    return httpService(
      `cities/countries/${__country}/regions/${_department}/cities/`
    )
      .get()
      .then((res) => {
        if (res instanceof Array) {
          setCities(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getCitiesExp = async (_department, _country = null) => {
    let __country = countryExp;
    if (_country) {
      __country = _country;
    }
    return httpService(
      `cities/countries/${__country}/regions/${_department}/cities/`
    )
      .get()
      .then((res) => {
        if (res instanceof Array) {
          setCitiesExp(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const onChangeCity = async (event) => {
    setOnlyTime(false);
    console.log("onChangeCity:", event.target.value);
    let _userBasicData = { ...userBasicData };
    _userBasicData["birth_city"] = event.target.value;
    setUserBasicData(_userBasicData);
    await setCity(event.target.value);
  };
  const onChangeCityExp = async (event) => {
    //doc_city_expedition
    let _userBasicData = { ...userBasicData };
    _userBasicData["doc_city_expedition"] = event.target.value;
    setUserBasicData(_userBasicData);
    console.log("onChangeCityExp:", event.target.value);
    await setCityExp(event.target.value);
  };
  const onMailDelivery = async (event) => {
    //doc_city_expedition
    let _userBasicData = { ...userBasicData };
    _userBasicData["mail_delivery"] = event.target.value;
    setUserBasicData(_userBasicData);
    console.log("onMailDelivery:", event.target.value);
  };

  const onChangeCountryExp = async (event) => {
    setOnlyTime(false);
    console.log("onChangeCountry:", event.target.value);
    setDepartmentsExp([]);
    setCitiesExp([]);
    let _userBasicData = { ...userBasicData };
    _userBasicData["doc_country_expedition"] = event.target.value;
    setUserBasicData(_userBasicData);
    await setCountryExp(event.target.value);
    getDeparmentsExp(event.target.value);
  };

  const getUserMe = async () => {
    return httpService("/user/me/")
      .get()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const onSubmit = async (data) => {
    /* console.log("onSubmit data:", data);
    console.log("onSubmit:", userBasicData); */
    const formData = new FormData();
    for (let key in userBasicData) {
      if (userBasicData[key] instanceof File) {
        console.log("Name:", userBasicData[key].name);
        let name = userBasicData[key].name.replace(/[^a-zA-Z0-9._ ]/g, "");
        name = name.replace(/\s{2,}/g, " ");
        name = name.replace(/ /g, "_");
        name = name.trim();
        console.log("Name clear:", name);
        formData.append(key, userBasicData[key], name);
      } else {
        formData.append(key, userBasicData[key]);
      }
      console.log(key, userBasicData[key]);
    }
    console.log("Submiting...:", formData);
    const id = toast.loading("Enviando...");
    let result = await saveData(formData, id);
    if (result) {
      let userData = GetAuth();
      let newData = { ...userData };
      
      let user = await getUserMe();
      newData.user.name = user.full_name;
      AddAuth(newData);
      toast.update(id, {
        render: "Se registraron los datos",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
      console.log("Submiting data...:", data);
    props.actionButton(data);
    }
    
  };
  const saveData = async (formData, id = null) => {
    return httpService("/user/basicdata/update_read/", formData)
      .patch()
      .then((res) => {
        console.log("getBasicdata:", res);
        if (res instanceof Object) {
          console.log("getBasicdata Object:", res);
          let userData = GetAuth();
          let newData = { ...userData };
          newData.profileCompleted.data[ATTR] = true;
          AddAuth(newData)
        }

        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        } else {
          let message = "";
          if (err && err.data && err.data.errors instanceof Array) {
            let errors = [...err.data.errors];
            errors.forEach((item) => {
              message += item.detail + "\n";
            });
            console.error("Errors list:", message);
            toast.update(id, {
              render: message,
              type: "error",
              isLoading: false,
              autoClose: 5000,
              closeOnClick: true,
            });
          } else {
            toast.update(id, {
              render: "Error desconocido, inténtelo más tarde",
              type: "error",
              isLoading: false,
              autoClose: 5000,
              closeOnClick: true,
            });
          }
          console.error("XXXX Response status:", err.data);
        }
        return;
      });
  };

  const onNext = () => {
    props.actionButton(props.data);
  };
  const onChangeForm = (event, attr) => {
    setOnlyTime(false);
    let _userBasicData = { ...userBasicData };
    _userBasicData[attr] = event.target.value;
    setUserBasicData(_userBasicData);
  };
  return (
    <div className="component-basic-data container-animated">
      <div className="page-security-head" style={{ padding: "5px 0px 15px" }}>
        <div className="page-security-subtitle">Datos básicos</div>
      </div>

      <div className="w-100">
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <fieldset className="row" disabled={disableForm}>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Tipo persona" required={true} />
                <div className="custom-input-box">
                  <select
                    {...register("is_natural_person", {
                      required: true,
                      onChange: (e) => {
                        onChangeForm(e, "is_natural_person");
                      },
                    })}
                  >
                    <option value="">Selecione</option>
                    <option value={true}>Natural</option>
                    <option value={false}>Jurídica</option>
                  </select>
                </div>

                {errors.is_natural_person && (
                  <div>
                    {errors.is_natural_person.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-custom-box">
                <FormFloatingLabel label="Nombres" required={true}/>
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register("first_name", {
                      required: true,
                      onChange: (e) => {
                        onChangeForm(e, "first_name");
                      },
                    })}
                    placeholder="nombres"
                    autoComplete="off"
                  />
                </div>
                {errors.first_name && (
                  <label className="form-error">Este campo es requerido</label>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-custom-box">
                <FormFloatingLabel label="Apellidos" required={true} />
                <div className="custom-input-box">
                  <input
                    /* value={
                      userBasicData["last_name"]
                        ? userBasicData["last_name"]
                        : ""
                    } */
                    type="text"
                    {...register("last_name", {
                      required: true,
                      onChange: (e) => {
                        onChangeForm(e, "last_name");
                      },
                    })}
                    placeholder="apellidos"
                    autoComplete="off"
                  />
                </div>
                {errors.last_name && (
                  <label className="form-error">Este campo es requerido</label>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-custom-box">
                <FormFloatingLabel label="Username" required={true} />
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register("username", {
                      required: true,
                      onChange: (e) => {
                        onChangeForm(e, "username");
                      },
                    })}
                    placeholder="username"
                    autoComplete="off"
                  />
                </div>
                {errors.username && (
                  <label className="form-error">Este campo es requerido</label>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Fecha de nacimiento" required={true} />
                <div className="custom-input-box">
                  <input
                    type="date"
                    {...register("birth_date", {
                      required: true,
                      onChange: (e) => {
                        onChangeForm(e, "birth_date");
                      },
                    })}
                    placeholder="Fecha de nacimiento"
                    autoComplete="off"
                    disabled={disableForm}
                  />
                </div>
                {errors["birth_date"] && (
                  <div>
                    {errors["birth_date"].type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                    {errors["birth_date"].type === "maxLength" && (
                      <label className="form-error">
                        Máximo de caracteres 10
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Pais de nacimiento" required={true}/>
                <div className="custom-input-box">
                  <select
                    {...register("birth_country", {
                      required: true,
                      onChange: (e) => {
                        onChangeCountry(e);
                      },
                    })}
                  >
                    <option value="">Selecione</option>
                    {countries.map((item) => {
                      return (
                        <option key={`country-${item.id}`} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {errors.birth_country && (
                  <div>
                    {errors.birth_country.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Departamento de nacimiento" required={true}/>
                <div className="custom-input-box">
                  <select
                    {...register("birth_region", {
                      required: true,
                      onChange: (e) => {
                        onChangeDepartments(e);
                      },
                    })}
                  >
                    <option value="">Selecione</option>
                    {departments.map((item) => {
                      return (
                        <option key={`depatment-${item.id}`} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {errors.birth_region && (
                  <div>
                    {errors.birth_region.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Ciudad de nacimiento" required={true}/>
                <div className="custom-input-box">
                  <select
                    {...register("birth_city", {
                      required: true,
                      onChange: (e) => {
                        onChangeCity(e);
                      },
                    })}
                  >
                    <option value="">Selecione</option>
                    {cities.map((item) => (
                      <option key={`city-${item.id}`} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                {errors.birth_city && (
                  <div>
                    {errors.birth_city.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Tipo de documento" required={true}/>
                <div className="custom-input-box">
                  <select
                    {...register("local_id_type", {
                      required: true,
                      onChange: (e) => {
                        onChangeForm(e, "local_id_type");
                      },
                    })}
                  >
                    <option value="">Selecione</option>
                    {documentTypeList &&
                      documentTypeList.map((data, i) => {
                        return (
                          <option key={"dt-" + i} value={data.id}>
                            {data.name}
                          </option>
                        );
                      })}
                  </select>
                </div>

                {errors.local_id_type && (
                  <div>
                    {errors.local_id_type.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Fecha de expedición" required={true}/>
                <div className="custom-input-box">
                  <input
                    type="date"
                    {...register("expedition_date", {
                      required: true,
                      onChange: (e) => {
                        onChangeForm(e, "expedition_date");
                      },
                    })}
                    autoComplete="off"
                    disabled={disableForm}
                  />
                </div>
                {errors["expedition_date"] && (
                  <div>
                    {errors["expedition_date"].type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                    {errors["expedition_date"].type === "maxLength" && (
                      <label className="form-error">
                        Máximo de caracteres 10
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="form-custom-box">
                <FormFloatingLabel label="Número de documento" required={true}/>
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register("document_number", {
                      required: true,
                      onChange: (e) => {
                        onChangeForm(e, "document_number");
                      },
                    })}
                    placeholder="número de documento"
                    autoComplete="off"
                  />
                </div>
                {errors.document_number && (
                  <label className="form-error">Este campo es requerido</label>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-custom-box">
                <FormFloatingLabel label="Imagen frontal documento" required={true}/>
                <div className="custom-input-box">
                  <label
                    style={{ cursor: "pointer", margin: "auto" }}
                    htmlFor="document_front_image"
                  >
                    {documentFrontImage
                      ? documentFrontImage.name
                      : "Seleccione"}
                  </label>
                  <input
                    id="document_front_image"
                    {...register("document_front_image", { required: (loadData["document_front_image"]?false:true) })}
                    style={{ height: 0, width: 0 }}
                    type="file"
                    accept=".jpg,.png,.jpeg"
                    onChange={onChangeDocumentFrontImage}
                  />
                </div>
                {loadData &&
                  loadData["document_front_image"] &&
                  userBasicData &&
                  !userBasicData["document_front_image"] && (
                    <div style={{ marginTop: "10px" }}>
                      <img
                        style={{
                          width: "200px",
                          height: "130px",
                          objectFit: "cover",
                          borderRadius: 5,
                        }}
                        src={loadData["document_front_image"]}
                        alt="Logo"
                      />
                    </div>
                  )}
                {userBasicData && userBasicData["document_front_image"] && (
                  <div style={{ marginTop: "10px" }}>
                    <img
                      style={{
                        width: "200px",
                        height: "130px",
                        objectFit: "cover",
                        borderRadius: 5,
                      }}
                      src={showImg(userBasicData["document_front_image"])}
                      alt="Logo"
                    />
                  </div>
                )}
                {errors.document_front_image && (
                  <label className="form-error">Este campo es requerido</label>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-custom-box">
                <FormFloatingLabel required={(loadData["document_back_image"]?false:true)} label="Imagen reverso documento" />
                <div className="custom-input-box">
                  <label
                    style={{ cursor: "pointer", margin: "auto" }}
                    htmlFor="document_back_image"
                  >
                    {documentBacktImage
                      ? documentBacktImage.name
                      : "Seleccione"}
                  </label>
                  <input
                    id="document_back_image"
                    type="file"
                    style={{ height: 0, width: 0 }}
                    accept=".jpg,.png,.jpeg"
                    {...register("document_back_image", { required: (loadData["document_back_image"]?false:true) })}
                    onChange={onChangeDocumentBackImage}
                  />
                </div>
                {loadData &&
                  loadData["document_back_image"] &&
                  userBasicData &&
                  !userBasicData["document_back_image"] && (
                    <div style={{ marginTop: "10px" }}>
                      <img
                        style={{
                          width: "200px",
                          height: "130px",
                          objectFit: "cover",
                          borderRadius: 5,
                        }}
                        src={loadData["document_back_image"]}
                        alt="Logo"
                      />
                    </div>
                  )}
                {userBasicData && userBasicData["document_back_image"] && (
                  <div style={{ marginTop: "10px" }}>
                    <img
                      style={{
                        width: "200px",
                        height: "130px",
                        objectFit: "cover",
                        borderRadius: 5,
                      }}
                      src={showImg(userBasicData["document_back_image"])}
                      alt="Logo"
                    />
                  </div>
                )}
                {errors.document_back_image && (
                  <label className="form-error">Este campo es requerido</label>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-custom-box">
                <FormFloatingLabel label="Selfie" required={(loadData["selfie"]?false:true)}/>
                <div className="custom-input-box">
                  <label
                    style={{ cursor: "pointer", margin: "auto" }}
                    htmlFor="selfie"
                  >
                    {documentSelfie ? documentSelfie.name : "Seleccione"}
                  </label>
                  <input
                    id="selfie"
                    style={{ height: 0, width: 0 }}
                    type="file"
                    accept=".jpg,.png,.jpeg"
                    {...register("selfie", { required: (loadData["selfie"]?false:true)  })}
                    onChange={onChangeSelfie}
                  />
                </div>
                {loadData && loadData["selfie"] && userBasicData &&
                  !userBasicData["selfie"] &&  (
                  <div style={{ marginTop: "10px" }}>
                    <img
                      style={{
                        width: "120px",
                        height: "120px",
                        objectFit: "cover",
                        borderRadius: 5,
                      }}
                      src={loadData["selfie"]}
                      alt="Logo"
                    />
                  </div>
                )}
                {userBasicData && userBasicData["selfie"] && (
                  <div style={{ marginTop: "10px" }}>
                    <img
                      style={{
                        width: "120px",
                        height: "120px",
                        objectFit: "cover",
                        borderRadius: 5,
                      }}
                      src={showImg(userBasicData["selfie"])}
                      alt="Logo"
                    />
                  </div>
                )}
                {errors.selfie && (
                  <label className="form-error">Este campo es requerido</label>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="País de expedición" required={true}/>
                <div className="custom-input-box">
                  <select
                    {...register("doc_country_expedition", {
                      required: true,
                      onChange: (e) => {
                        onChangeCountryExp(e);
                      },
                    })}
                  >
                    <option value="">Selecione</option>
                    {countriesExp.map((item) => (
                      <option key={`countryExp-${item.id}`} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                {errors.doc_country_expedition && (
                  <div>
                    {errors.doc_country_expedition.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Departamento de expedición" required={true}/>
                <div className="custom-input-box">
                  <select
                    {...register("doc_region_expedition", {
                      required: true,
                      onChange: (e) => {
                        onChangeDepartmentsExp(e);
                      },
                    })}
                  >
                    <option value="">Selecione</option>
                    {departmentsExp.map((item) => (
                      <option key={`departmentExp-${item.id}`} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                {errors.birth_region && (
                  <div>
                    {errors.birth_region.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Ciudad de expedición" required={true}/>
                <div className="custom-input-box">
                  <select
                    {...register("doc_city_expedition", {
                      required: true,
                      onChange: (e) => {
                        onChangeCityExp(e);
                      },
                    })}
                  >
                    <option value="">Selecione</option>
                    {citiesExp.map((item) => (
                      <option key={`cityExp-${item.id}`} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                {errors.doc_city_expedition && (
                  <div>
                    {errors.doc_city_expedition.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Envio Notificaciones" required={true}/>
                <div className="custom-input-box">
                  <select
                    {...register("mail_delivery", {
                      required: true,
                      onChange: (e) => {
                        onMailDelivery(e);
                      },
                    })}
                  >
                    <option value="">Selecione</option>
                    {mailDelivery.map((item) => (
                      <option key={`mail-delivery-${item}`} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>

                {errors.mail_delivery && (
                  <div>
                    {errors.mail_delivery.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>

            {!disableForm && (
              <div
                className="col-md-12 mb-2 mt-4"
                style={props.btnstyle ? props.btnstyle : { display: "flex" }}
              >
                <Boton
                  className={"btn _btn-primary font-14"}
                  label={
                    props && props.btntext ? props.btntext : "Actualizar datos"
                  }
                />
              </div>
            )}
          </fieldset>
        </form>
        {disableForm && (
          <div className="row">
            <div
              className="col-md-12 mb-2 mt-4"
              style={props.btnstyle ? props.btnstyle : { display: "flex" }}
            >
              <Boton
                className={"btn _btn-primary font-14"}
                label={"Siguiente"}
                handlerClick={onNext}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
