import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LogOut, GetAuth, getWelcome, removeWelcome, getLastLogin, removeLastLogin, getPasswordExpires, removePasswordExpires } from "../../helpers/Auth";
import HeaderDashboard from "../../components/HeaderDashboard/HeaderDashboard";
import { Menu } from "../../components/menu/Menu";
import "./Dashboard.css";
import { IsUnauthorized, httpService } from "../../helpers/ApiService";
import user from "../../assets/user-d.png";
import { getEnviroments } from "../../helpers/enviroments";
import { toast } from "react-toastify";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";

export const Dashboard = () => {
  const [visible, setVisible] = useState(true);
  const [windowsMinimize, setWindowsMinimize] = useState(false);
  const [userData, setuserData] = useState(null);
  const [userMe, setUserMe] = useState(null);
  const [userImage, setUserImage] = useState(user);
  const [domain, setDomain] = useState(null);
  useEffect(() => {
    setDomain(getEnviroments().DOMAIN);
    let welcome = getWelcome();
    if(welcome){
      toast(welcome, {
        render: "Bienvenido",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        className:"foo-bar"
      });
      removeWelcome()
    }
    let last_login = getLastLogin();
    if(last_login){
      toast("Último acceso: " + last_login, {
        render: "LastLogin",
        type: "info",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        className:"foo-bar"
      });
      removeLastLogin()
    }
    let password_expires = getPasswordExpires();
    if(password_expires){
      toast("Su contraseña expira en: " + password_expires, {
        render: "PasswordExpires",
        type: "warning",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        className:"foo-bar"
      });
      removePasswordExpires()
    }
    
  }, []);
  useEffect(() => {}, [visible]);
  useEffect(() => {
    let userdata = GetAuth();
    if (!userData) {
      setuserData(userdata);
    } else if (userdata !== userData) {
      let result = compareObjects(userdata, userData);
      if (!result) {
        setuserData(userdata);
      }
    }
    const loadData = async () => {
      getUserMe();
    };
    loadData();
  }, [userData]);
  const getUserMe = async () => {
    return httpService("/user/me/")
      .get()
      .then((res) => {
        if (res && res.id && res.perfil_image) {
          let perfil_image = res.perfil_image;
          setUserMe(res)
          setUserImage(getEnviroments().DOMAIN+perfil_image);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };

  const compareObjects = (object1, object2) => {
    let isEqual = true;
    let object1String = JSON.stringify(object1);
    let object2String = JSON.stringify(object2);
    if (object1String !== object2String) {
      isEqual = false;
    }
    return isEqual;
  };

  useEffect(() => {
    document.getElementById("menu-container");
    if (window.innerWidth > 968) {
      setWindowsMinimize(false);
    } else {
      //sideBar.style.display = 'none';
      setWindowsMinimize(true);
    }
    function handleResize() {
      let sideBar = document.getElementById("menu-container");
      if (!sideBar) {
        return;
      }
      if (window.innerWidth > 968) {
        setVisible(true);
        sideBar.classList.remove("hide");
        sideBar.classList.add("show");
        setWindowsMinimize(false);
      } else {
        sideBar.classList.add("hide");
        sideBar.classList.remove("show");
        setVisible(false);
        setWindowsMinimize(true);
      }
    }
    window.addEventListener("resize", handleResize);
  }, []); //  });

  const navigate = useNavigate();
  const handlerClick = () => {
    LogOut();
    setTimeout(() => {
      navigate("/login", { replace: true });
      window.location.replace('/login');
    }, 0);
  };

  const hideMenu = () => {
    let sideBar = document.getElementById("menu-container");
    let _visible = !visible;
    setVisible(_visible);
    if (!_visible) {
      sideBar.classList.add("hide");
      sideBar.classList.remove("show");
    } else {
      sideBar.classList.remove("hide");
      sideBar.classList.add("show");
    }
  };
  const hideFloatMenu = () => {
    let sideBar = document.getElementById("menu-container");
    sideBar.classList.add("hide");
    sideBar.classList.remove("show");
    setVisible(false);
  };
  return (
    <div className="dashboard-container">
      <div className="segments">
        <div className="sidebar">
          <div id="menu-container" className="menu-container show">
            <Menu
              actions={{
                hideFloatMenu: hideFloatMenu,
                windowsMinimize: windowsMinimize,
              }}
              userData={userData}
            />
          </div>
        </div>
        <div className="segment-container">
          <div className="Header-dashboard-container">
            <HeaderDashboard handlerClick={{ hideMenu: hideMenu }} />
          </div>
          <div className="container-outlet">
            <div className="padding-box">
            
              <Outlet context={[userMe, setUserMe]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
