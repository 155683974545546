import React, { useEffect, useState } from "react";
import Boton from "../Boton";
import ItemMenu from "../ItemMenu/item-menu";
import imagen from "../../assets/img1.jpeg";
/* import { Button } from "components";
import styles from "./menu.css"; */
import "./Menu.css";
import Logo from "../../assets/Logo/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { useLocation, NavLink, Link } from "react-router-dom";
import { GetAuth, LogOut } from "../../helpers/Auth";
import { useNavigate } from "react-router-dom";
import user from "../../assets/user-d.png";
import { Dialog } from "@mui/material";
import { toast } from "react-toastify";
import { IsUnauthorized, httpService } from "../../helpers/ApiService";
import { getEnviroments } from "../../helpers/enviroments";
import { rolesTypes } from "../../helpers/functions";
import menuAccess from "../../helpers/menuAccess";

import AnimateHeight from "react-animate-height";

export const Menu = (props) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useState(null);
  const [open, setOpen] = useState(false);
  const [userImage, setUserImage] = useState(user);
  const [userMe, setUserMe] = useState(user);
  const [_AUTH, setAUTH] = useState(null);

  const [subMenuH, setSubMenuH] = useState({
    item: "dashboard",
    height: "auto",
    chck:true
  });
  const _location = useLocation();
  useEffect(() => {
    const { pathname } = _location;
    const splitLocation = pathname.split("/");
    setSubMenuH({
      item: sanitizeURL(splitLocation[1]),
      height: "auto",
      chck:true
    });
    
  },[_location]);
  const [activePath, setActivePath] = useState("");
  useEffect(() => {
    let AUTH = GetAuth();

    setAUTH(AUTH);
    if (AUTH) {
      getUserMe();
      setAuth(AUTH);
    }

    const { pathname } = _location;
    const splitLocation = pathname.split("/");
    /* console.log("Location:---------->", splitLocation); */
    setSubMenuH({
      item: sanitizeURL(splitLocation[1]),
      height: "auto",
      chck:true
    });



    
  }, []);
  function sanitizeURL(url) {
    // Expresión regular para caracteres válidos en una URL
    const validCharacters = /^[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*$/;

    // Verificar si la URL contiene solo caracteres válidos
    if (validCharacters.test(url)) {
      return url;
    } else {
      // Si la URL contiene caracteres inválidos, eliminarlos
      const sanitizedURL = url.replace(
        /[^a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]/g,
        ""
      );
      return sanitizedURL;
    }
  }
  const getUserMe = async () => {
    return httpService("/user/me/")
      .get()
      .then((res) => {
        if (res && res.id && res.perfil_image) {
          let perfil_image = res.perfil_image;
          setUserMe(res);
          setUserImage(getEnviroments().DOMAIN + perfil_image);
        }
        return res;
      })
      .catch((err) => {
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const notify = (message, type = "default") =>
    toast(message, { ...type, className: "foo-bar" });
  const handleClose = () => {
    setOpen(false);
  };

  const logoutOption = () => {
    setOpen(true);
  };
  const stay = () => {
    setOpen(false);
  };
  const leave = () => {
    setOpen(false);
    handlerClick();
  };
  const handlerClick = () => {
    LogOut();
    setTimeout(() => {
      navigate("/welcome", { replace: true });
      notify("Ha cerrado sesión satisfactoriamente", { type: "info" });

      //window.location.replace('/login');
    }, 0);
  };
  const setActive = (path) => {
    console.log("_pathname-------->setActive:", path);
    setActivePath(path);
  };

  const setActiveMenu = (path, id) => {
    if (path.target.checked) {
      document.getElementById(`list-${id}`).classList.remove("none");
      document.getElementById(`list-${id}`).classList.add("list");
      setSubMenuH({
        item: id,
        height: "auto",
        chck:true
      });
    } else {
      document.getElementById(`list-${id}`).classList.remove("list");
      document.getElementById(`list-${id}`).classList.add("none");
      setSubMenuH({
        item: id,
        height: 0,
        chck:false
      });
    }
  };

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <div className="menu">
      <Dialog open={open} onClose={handleClose}>
        <div style={{ padding: "25px 30px" }}>
          <div className="w-100">
            <span className="text-subtitle-dark">
              ¿Estás seguro que quieres
            </span>{" "}
            <span className="text-subtitle">salir de Devise</span>
            <span className="text-subtitle-dark">?</span>
          </div>
          <div className="w-100">
            <div className="btn-group mt-4 w-100 btn-group-logout">
              <div className="btn-cancel">
                <Boton
                  className={"btn _btn-primary font-14"}
                  handlerClick={stay}
                  style={{
                    borderRadius: "8px",
                    width: "150px",
                    background: "white",
                    border: "1px solid #112e6d",
                    color: "#112e6d",
                  }}
                  label={"No, quedarme"}
                />
              </div>
              <div className="btn-logout">
                <Boton
                  className={"btn _btn-primary font-14"}
                  style={{ borderRadius: "8px", width: "150px" }}
                  label={"Si, salir"}
                  handlerClick={leave}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <div className="menu-header">
        <div className="btn-float">
          {props.actions.windowsMinimize && (
            <FontAwesomeIcon
              style={{
                width: "25px",
                height: "25px",
                color: "#1c4fc1",
                cursor: "pointer",
              }}
              onClick={props.actions.hideFloatMenu}
              icon={faWindowClose}
            />
          )}
        </div>
        <div className="menu-text">
          <Logo width="80%" height="80%" />
        </div>
      </div>
      {auth && auth.profileCompleted && auth.profileCompleted.completed && (
        <div className="content-items">
          <div className="_card-user">
            <div className="user-image">
              <div className="user-image-box">
                <img
                  src={userImage}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = user;
                  }}
                />
              </div>
            </div>
            <div className="_card-column">
              <div
                title={props.userData ? props.userData.user.name : ""}
                className="_card-header"
              >
                {props.userData ? props.userData.user.name : ""}
              </div>
              <div className="_card-body">
                {props.userData ? "Eres " + props.userData.user.profile : ""}
              </div>
            </div>
          </div>
          <div className="items-group">
            <div className="items-group-title">General</div>
            {menuAccess({ AUTH: _AUTH, componentName: "PageDashboard" }) && (
              <div className="item-container">
                {/* <div className="item-box drop-down"> */}
                <div
                  className="item-box"
                  onClick={() => setActive("dashboard")}
                >
                  <Link to="/dashboard">
                    <ItemMenu
                      text="Mi Tablero"
                      icon="IconDashboard"
                      active={
                        splitLocation.length > 1 &&
                        splitLocation[1] == "dashboard"
                      }
                    />
                  </Link>
                </div>
                <div className="item-childs none"></div>
              </div>
            )}
            {menuAccess({ AUTH: _AUTH, componentName: "PageInvest" }) && (
              <div className="item-container">
                <div className="item-box" onClick={() => setActive("invertir")}>
                  <Link to="/invertir">
                    <ItemMenu
                      text="Quiero invertir"
                      icon="IconInvest"
                      active={
                        splitLocation.length > 1 &&
                        splitLocation[1] == "invertir"
                      }
                    />
                  </Link>
                </div>
                <div className="item-childs none"></div>
              </div>
            )}
            {menuAccess({ AUTH: _AUTH, componentName: "PageInvestments" }) && (
              <div className="item-container">
                <div
                  className="item-box"
                  onClick={() => setActive("mis-inversiones")}
                >
                  <Link to="/mis-inversiones">
                    <ItemMenu
                      text="Mis Inversiones"
                      icon="IconInvestments"
                      active={
                        splitLocation.length > 1 &&
                        splitLocation[1] == "mis-inversiones"
                      }
                    />
                  </Link>
                </div>
                <div className="item-childs none"></div>
              </div>
            )}
            {menuAccess({ AUTH: _AUTH, componentName: "PageMovements" }) && (
              <div className="item-container">
                <div
                  className="item-box"
                  onClick={() => setActive("mis-movimientos")}
                >
                  <Link to="/mis-movimientos">
                    <ItemMenu
                      text="Mis Movimientos"
                      icon="IconMovements"
                      active={
                        splitLocation.length > 1 &&
                        splitLocation[1] == "mis-movimientos"
                      }
                    />
                  </Link>
                </div>
                <div className="item-childs none"></div>
              </div>
            )}
            {menuAccess({ AUTH: _AUTH, componentName: "PageMyAssets" }) && (
              <label id="admin-activos" className="item-container">
                <div
                  className="item-box"
                  onClick={() => setActive("mis-activos")}
                >
                  <Link to="/mis-activos">
                    <ItemMenu
                      text="Mis Activos"
                      icon="IconInvest"
                      activePath={activePath}
                      active={
                        splitLocation.length > 1 &&
                        splitLocation[1] === "mis-activos"
                      }
                    />
                  </Link>
                </div>
                <div className="item-childs none"></div>
              </label>
            )}
            {menuAccess({
              AUTH: _AUTH,
              componentName: "PageAdminAssetSponsor",
            }) && (
              <label id="admin-activos" className="item-container">
                <div
                  className="item-box"
                  /* onClick={() => setActive("admin-activos")} */
                >
                  <ItemMenu
                    text="Admin Activos"
                    icon="IconInvest"
                    parentMenu={true}
                    activePath={activePath}
                    splitLocation={"admin-activos"}
                    active={
                      splitLocation.length > 1 &&
                      splitLocation[1] === "admin-activos"
                    }
                  />
                  <i className="arrow right"></i>
                  <input
                    type="checkbox"
                    onChange={(e) => setActiveMenu(e, "admin-activos")}
                    hidden={true}
                    checked={subMenuH.item === "admin-activos" && subMenuH.chck}
                  />
                </div>
                <AnimateHeight
                  duration={300}
                  height={
                    subMenuH.item && subMenuH.item === "admin-activos" && subMenuH.chck
                      ? subMenuH.height
                      : 0
                  }
                >
                  <div
                    id="list-admin-activos"
                    className={
                      splitLocation.length > 1 &&
                      splitLocation[1] === "admin-activos"
                        ? "item-childs list"
                        : "item-childs none"
                    }
                  >
                    <Link to="/admin-activos">
                      <ItemMenu
                        text="Pendientes aprobación Sponsor"
                        icon="IconInvest"
                        subMenu={true}
                        splitLocation={"admin-activos"}
                        active={
                          splitLocation.length > 1 &&
                          splitLocation[1] === "admin-activos" &&
                          (splitLocation[2] !== "admin" || ( splitLocation[2] && /^[0-9]+$/.test(splitLocation[2])) )
                        }
                      />
                    </Link>
                    <Link to="/admin-activos/admin">
                     
                      <ItemMenu
                        text="Pendientes Aprobación Devise"
                        icon="IconInvest"
                        splitLocation={"admin-activos"}
                        subMenu={true}
                        active={
                          splitLocation.length > 1 &&
                          splitLocation[1] === "admin-activos" &&
                          splitLocation[2] === "admin"
                        }
                      />
                    </Link>
                  </div>
                </AnimateHeight>

                {/* <input
                  type="checkbox"
                  onChange={(e) => setActiveMenu(e, "admin-activos")}
                  hidden={true}
                  id="chck-admin-activos"
                /> */}
              </label>
            )}
          </div>

          <div className="items-group">
            <div className="items-group-title">Tu cuenta</div>
            {menuAccess({ AUTH: _AUTH, componentName: "PageAccount" }) && (
              <div className="item-container">
                <div className="item-box" onClick={() => setActive("cuenta")}>
                  <Link to="/cuenta">
                    <ItemMenu
                      text="Cuenta"
                      icon="IconAccount"
                      active={
                        splitLocation.length > 1 && splitLocation[1] == "cuenta"
                      }
                    />
                  </Link>
                </div>
                <div className="item-childs none"></div>
              </div>
            )}
            {menuAccess({ AUTH: _AUTH, componentName: "PageMyCompany" }) && (
              <div className="item-container">
                <div
                  className="item-box"
                  onClick={() => setActive("mi-empresa")}
                >
                  <Link to="/mi-empresa">
                    <ItemMenu
                      text="Mi Empresa"
                      icon="IconInvest"
                      active={
                        splitLocation.length > 1 &&
                        splitLocation[1] == "mi-empresa"
                      }
                    />
                  </Link>
                </div>
                <div className="item-childs none"></div>
              </div>
            )}
            {menuAccess({ AUTH: _AUTH, componentName: "PageReferals" }) && (
              <div className="item-container">
                <div
                  className="item-box"
                  onClick={() => setActive("referidos")}
                >
                  <Link to="/referidos">
                    <ItemMenu
                      text="Referidos"
                      icon="IconReferals"
                      active={
                        splitLocation.length > 1 &&
                        splitLocation[1] == "referidos"
                      }
                    />
                  </Link>
                </div>
                <div className="item-childs none"></div>
              </div>
            )}
            {menuAccess({ AUTH: _AUTH, componentName: "PageSecurity" }) && (
              <div className="item-container">
                <div
                  className="item-box"
                  onClick={() => setActive("seguridad")}
                >
                  <Link to="/seguridad">
                    <ItemMenu
                      text="Seguridad"
                      icon="IconSecurity"
                      active={
                        splitLocation.length > 1 &&
                        splitLocation[1] == "seguridad"
                      }
                    />
                  </Link>
                </div>
                <div className="item-childs none"></div>
              </div>
            )}
            {menuAccess({ AUTH: _AUTH, componentName: "PageAcademy" }) && (
              <div className="item-container">
                <div className="item-box" onClick={() => setActive("academia")}>
                  <Link to="/academia">
                    <ItemMenu
                      text="Academia"
                      icon="IconAcademy"
                      active={
                        splitLocation.length > 1 &&
                        splitLocation[1] == "academia"
                      }
                    />
                  </Link>
                </div>
                <div className="item-childs none"></div>
              </div>
            )}
            {menuAccess({ AUTH: _AUTH, componentName: "PageHelpSupport" }) && (
              <div className="item-container">
                <div
                  className="item-box"
                  onClick={() => setActive("ayuda-y-soporte")}
                >
                  <Link to="/ayuda-y-soporte">
                    <ItemMenu
                      text="Ayuda y soporte"
                      icon="IconHelpSupport"
                      active={
                        splitLocation.length > 1 &&
                        splitLocation[1] == "ayuda-y-soporte"
                      }
                    />
                  </Link>
                </div>
                <div className="item-childs none"></div>
              </div>
            )}
            <div className="item-container">
              <div className="item-box" onClick={logoutOption}>
                <ItemMenu
                  text="Cerrar Sesión"
                  icon="IconLogOut"
                  active={false}
                />
              </div>
              <div className="item-childs none"></div>
            </div>
          </div>
        </div>
      )}
      {auth && auth.profileCompleted && !auth.profileCompleted.completed && (
        <div className="incomplete-profile">
          <div className="items-group">
            <div className="item-container">
              <div className="items-group-title">Tu cuenta</div>
              <div className="item-box">
                <Link to="/completar-perfil">
                  <ItemMenu text="Cuenta" icon="IconAccount" active={true} />
                </Link>
              </div>
              <div className="item-childs none"></div>
            </div>
            <div className="item-container">
              <div className="item-box" onClick={logoutOption}>
                <ItemMenu
                  text="Cerrar Sesión"
                  icon="IconLogOut"
                  active={false}
                />
              </div>
              <div className="item-childs none"></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
