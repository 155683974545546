import { useForm } from "react-hook-form";
import Boton from "../../../components/Boton";
import FormFloatingLabel from "../../../components/FormFloatingLabel";

export default function DatosSocioeconomicos() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("onSubmit:", data);
  };
  return (
    <div className="component-datos-socioeconomicos container-animated">
      <div className="page-security-head" style={{ padding: "5px 0px 15px" }}>
        <div className="page-security-subtitle">Datos socioeconómicos</div>
      </div>

      <div className="w-100">
        <form className="row" onSubmit={handleSubmit((data) => onSubmit(data))}>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Ingresos mensuales" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("datos_socioeconomicos_ingresos_mensuales", {
                    required: true,
                    pattern: /^([0-9]\d*)(\.\d+)?$/,
                    maxLength: 20,
                  })}
                  placeholder="Ingresos mensuales"
                  autoComplete="off"
                />
              </div>

              {errors.datos_socioeconomicos_ingresos_mensuales && (
                <div>
                  {errors.datos_socioeconomicos_ingresos_mensuales.type ===
                    "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 20
                    </label>
                  )}

                  {errors.datos_socioeconomicos_ingresos_mensuales.type ===
                    "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                  {errors.datos_socioeconomicos_ingresos_mensuales.type ===
                    "pattern" && (
                    <label className="form-error">Sólo valores numéricos</label>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Otros ingresos" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("datos_socioeconomicos_otros_ingresos", {
                    required: true,
                    pattern: /^([0-9]\d*)(\.\d+)?$/,
                    maxLength: 20,
                  })}
                  placeholder="Otros ingresos"
                  autoComplete="off"
                />
              </div>

              {errors.datos_socioeconomicos_otros_ingresos && (
                <div>
                  {errors.datos_socioeconomicos_otros_ingresos.type ===
                    "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 20
                    </label>
                  )}

                  {errors.datos_socioeconomicos_otros_ingresos.type ===
                    "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                  {errors.datos_socioeconomicos_otros_ingresos.type ===
                    "pattern" && (
                    <label className="form-error">Sólo valores numéricos</label>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Egresos mensuales" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("datos_socioeconomicos_egresos_mensuales", {
                    required: true,
                    pattern: /^([0-9]\d*)(\.\d+)?$/,
                    maxLength: 20,
                  })}
                  placeholder="Egresos mensuales"
                  autoComplete="off"
                />
              </div>

              {errors.datos_socioeconomicos_egresos_mensuales && (
                <div>
                  {errors.datos_socioeconomicos_egresos_mensuales.type ===
                    "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 20
                    </label>
                  )}

                  {errors.datos_socioeconomicos_egresos_mensuales.type ===
                    "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                  {errors.datos_socioeconomicos_egresos_mensuales.type ===
                    "pattern" && (
                    <label className="form-error">Sólo valores numéricos</label>
                  )}
                </div>
              )}
            </div>
          </div>


          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Valor" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("datos_socioeconomicos_valor", {
                    required: true,
                    pattern: /^([0-9]\d*)(\.\d+)?$/,
                    maxLength: 20,
                  })}
                  placeholder="Valor"
                  autoComplete="off"
                />
              </div>

              {errors.datos_socioeconomicos_valor && (
                <div>
                  {errors.datos_socioeconomicos_valor.type ===
                    "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 20
                    </label>
                  )}

                  {errors.datos_socioeconomicos_valor.type ===
                    "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                  {errors.datos_socioeconomicos_valor.type ===
                    "pattern" && (
                    <label className="form-error">Sólo valores numéricos</label>
                  )}
                </div>
              )}
            </div>
          </div>


          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Total activos" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("datos_socioeconomicos_total_activos", {
                    required: true,
                    pattern: /^([0-9]\d*)(\.\d+)?$/,
                    maxLength: 20,
                  })}
                  placeholder="Total activos"
                  autoComplete="off"
                />
              </div>

              {errors.datos_socioeconomicos_total_activos && (
                <div>
                  {errors.datos_socioeconomicos_total_activos.type ===
                    "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 20
                    </label>
                  )}

                  {errors.datos_socioeconomicos_total_activos.type ===
                    "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                  {errors.datos_socioeconomicos_total_activos.type ===
                    "pattern" && (
                    <label className="form-error">Sólo valores numéricos</label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Total pasivos" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("datos_socioeconomicos_total_pasivos", {
                    required: true,
                    pattern: /^([0-9]\d*)(\.\d+)?$/,
                    maxLength: 20,
                  })}
                  placeholder="Total pasivos"
                  autoComplete="off"
                />
              </div>

              {errors.datos_socioeconomicos_total_pasivos && (
                <div>
                  {errors.datos_socioeconomicos_total_pasivos.type ===
                    "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 20
                    </label>
                  )}

                  {errors.datos_socioeconomicos_total_pasivos.type ===
                    "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                  {errors.datos_socioeconomicos_total_pasivos.type ===
                    "pattern" && (
                    <label className="form-error">Sólo valores numéricos</label>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Origen de fondos" />
              <div className="custom-input-box">
                <select
                  {...register("datos_socioeconomicos_origen_fondos", { required: true })}
                >
                  <option value="">Selecione</option>
                  <option value="001">Inversiones</option>
                  <option value="002">Retiros</option>
                </select>
              </div>

              {errors.datos_ocupacion_ocupacion && (
                <div>
                  {errors.datos_ocupacion_ocupacion.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Teléfono" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("datos_socioeconomicos_telefono", {
                    required: true,
                    pattern: /^([0-9]\d*)(\.\d+)?$/,
                    maxLength: 10,
                    minLength: 10,
                  })}
                  placeholder="Teléfono"
                  autoComplete="off"
                />
              </div>

              {errors.datos_socioeconomicos_telefono && (
                <div>
                  {errors.datos_socioeconomicos_telefono.type === "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 10
                    </label>
                  )}
                  {errors.datos_socioeconomicos_telefono.type === "minLength" && (
                    <label className="form-error">
                      Mínimo de caracteres 10
                    </label>
                  )}
                  {errors.datos_socioeconomicos_telefono.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                  {errors.datos_socioeconomicos_telefono.type === "pattern" && (
                    <label className="form-error">Sólo valores numéricos</label>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="col-md-12 mb-2 mt-4" style={{ display: "flex" }}>
            <Boton
              className={"btn _btn-primary font-14"}
              label={"Actualizar datos"}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
