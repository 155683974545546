import React, { useEffect, useState } from "react";
import "./CardPortafolio.css";
import { VictoryPie } from "victory";
import { numFormatter } from "../../helpers/functions";

export const ContentCard = ({ valorActivo }) => {
  const [graphicDatas, setGraphicDatas] = useState();
  const colorScale = ["#dcef95", "#a490ee", "#557bd1", "#ffba43", "#ebe3d7"];

  const getPercentages = (data) => {
    let DATA = { ...data };
    delete DATA.totalInvertido;
    let valores = [];
    for (let key in DATA) {
      valores.push(Number.parseInt(DATA[key].cop));
    }
    const suma = valores.reduce((total, valor) => total + valor, 0);
    const porcentajes = valores.map((valor) => (valor / suma) * 100);
  };
  useEffect(() => {
    if (valorActivo) {
      const data = valorActivo;
      let totalInvertido = Number.parseInt(data.totalInvertido);
      getPercentages(data);
      let totalPercent = totalInvertido / 100;
      let sumTotal = Number.parseInt(0);
      let datas = [];
      for (let d in data) {
        if (d !== "totalInvertido") {
          let item = data[d];
          /* console.log("inversiones:", item); */
          let cop = Number.parseInt(item.cop);
          sumTotal += cop;
          let subTotal = Number.parseFloat(totalInvertido / cop);
          let percent = Number.parseFloat(100 / subTotal);
          /* console.log("Porcentage:", percent); */
          /* let _data = { y: percent, x: Number((Math.round(percent)).toFixed(2)) + " %" } */
          let _data = { y: percent, x: Number.parseFloat(percent).toFixed(2) };
          datas.push(_data);
        }
        setGraphicDatas(datas);
      }
    }
  }, [valorActivo]);

  return (
    <div className="contentCard" style={{ display: "block", padding: "0px" }}>
      <div className="row">
        <div className="col-md-6">
          <div
            style={{ maxHeight: "450px", display: "inline-grid" }}
            className="boxGrafico"
          >
            <div className="title">
              <p className="h5">Mi portafolio</p>
            </div>
            <div className="graficoTwo">
              <div className="p-label">
                <p className="body1">Total Invertido</p>
                <p className="h3">
                  {valorActivo && numFormatter({num:valorActivo.totalInvertido}) }
                </p>
              </div>
              <VictoryPie
                colorScale={colorScale}
                data={graphicDatas}
                innerRadius={130}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="boxInfoProyectos">
            <div className="proyectoResidencial">
              <div className="indicador" />
              <div className="info">
                <div className="titleTwo">
                  <p className="caption">Proyecto Residencial</p>
                </div>
                <div className="titleTwo">
                  <p className="body2">
                    {valorActivo && valorActivo.proyectoResidencial.porcentaje}
                  </p>
                  <p className="body2Bold">
                    {valorActivo && numFormatter({num:valorActivo.proyectoResidencial.cop})}
                  </p>
                </div>
                <div className="titleTwo">
                  <p className="captionTwo">Devise Coin</p>
                  <p className="captionBold">
                    {valorActivo && valorActivo.proyectoResidencial.devicecoin}
                  </p>
                </div>
              </div>
            </div>
            <div className="proyectoResidencial">
              <div className="indicadorTwo" />
              <div className="info">
                <p className="caption">Proyecto Comercial</p>
                <div className="titleTwo">
                  <p className="body2Two">
                    {valorActivo && valorActivo.proyectoComercial.porcentaje}
                  </p>
                  <p className="body2Bold">
                    {valorActivo && numFormatter({num:valorActivo.proyectoComercial.cop})}
                  </p>
                </div>
                <div className="titleTwo">
                  <p className="captionTwo">Devise Coin</p>
                  <p className="captionBold">
                    {" "}
                    {valorActivo && valorActivo.proyectoComercial.devicecoin}
                  </p>
                </div>
              </div>
            </div>
            <div className="proyectoResidencial">
              <div className="indicadorThree" />
              <div className="info">
                <p className="caption">Proyecto de Oficinas</p>
                <div className="titleTwo">
                  <p className="body2Two">
                    {valorActivo && valorActivo.proyectoOficina.porcentaje}
                  </p>
                  <p className="body2Bold">
                    {valorActivo && numFormatter({num:valorActivo.proyectoOficina.cop})}
                  </p>
                </div>
                <div className="titleTwo">
                  <p className="captionTwo">Devise Coin</p>
                  <p className="captionBold">
                    {valorActivo && valorActivo.proyectoOficina.devicecoin}
                  </p>
                </div>
              </div>
            </div>
            <div className="proyectoResidencial">
              <div className="indicadorFour" />
              <div className="info">
                <p className="caption">Proyecto Industrial</p>
                <div className="titleTwo">
                  <p className="body2Three">
                    {valorActivo && valorActivo.proyectoIndustrial.porcentaje}
                  </p>
                  <p className="body2Bold">
                    {valorActivo && numFormatter({num:valorActivo.proyectoIndustrial.cop})}
                  </p>
                </div>
                <div className="titleTwo">
                  <p className="captionTwo">Devise Coin</p>
                  <p className="captionBold">
                    {" "}
                    {valorActivo && valorActivo.proyectoIndustrial.devicecoin}
                  </p>
                </div>
              </div>
            </div>
            <div className="proyectoResidencial">
              <div className="indicadorFive" />
              <div className="info">
                <p className="caption">Proyecto de Infraestructura</p>
                <div className="balance">
                  <p className="body2Four">
                    {valorActivo &&
                      valorActivo.proyectoInfraestructura.porcentaje}{" "}
                  </p>
                  <p className="body2Bold">
                    {valorActivo && numFormatter({num:valorActivo.proyectoInfraestructura.cop})}
                  </p>
                </div>
                <div className="titleTwo">
                  <p className="captionTwo">Devise Coin</p>
                  <p className="captionBold">
                    {valorActivo &&
                      valorActivo.proyectoInfraestructura.devicecoin}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="frame589">
        <div className="separador" />
      </div> */}
    </div>
  );
};
