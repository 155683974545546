import React, { useState } from "react";
/* import { TextField, Checkbox, Button } from "components"; */
/* import styles from "./Fragment.module.scss"; */
import {
  Alert,
  Box,
  Checkbox,
  Collapse,
  IconButton,
  TextField,
} from "@mui/material";
/* import styles from "./FormLogin.module.css"; */
import "./FormLogin.css";
import Logo from "../../assets/Logo/Logo";
import Boton from "../../components/Boton";
import {
  AddAuth,
  AddLastLogin,
  AddPasswordExpires,
  AddWelcome,
  GetAuth,
} from "../../helpers/Auth";
import { Link, useNavigate } from "react-router-dom";
import apiInstanceAuth from "../../helpers/ApiInstanceAuth";
import CloseIcon from "@mui/icons-material/Close";
import {
  testData,
  httpService,
  IsUnauthorized,
} from "../../helpers/ApiService";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";

export const FormLogin = () => {
  const [authFormData, setAuthFormData] = useState({
    email: "",
    password: "",
  });
  const [open, setOpen] = React.useState(false);
  const [err, setErr] = React.useState("");
  const [userMe, setUserMe] = React.useState(null);
  const [errFormData, setErrFormData] = useState({
    email: "",
    password: "",
  });
  const AuthService = {
    login: ({ email, password }) =>
      apiInstanceAuth.post("/token/", { email: email, password: password }),
  };

  const navigate = useNavigate();
  const onChange = (event) => {
    /* let data = JSON.parse(JSON.stringify(authFormData)); */
    let data = { ...authFormData };
    /* if(!/^[A-Za-z0-9@.\-_]*$/.test(event.value)){
      return;
    } */
    data[event.name] = event.value;
    let attr = event.name;
    let errData = { ...errFormData };
    if (!data[event.name]) {
      errData[attr] = "Campo requerido";
      setErrFormData(errData);
    } else {
      errData[attr] = "";
      setErrFormData(errData);
    }
    setAuthFormData(data);
  };
  const resetErrors = () => {
    setErrFormData({
      email: "",
      password: "",
    });
  };

  const getUserData = async (authData, id_toast) => {
    let authDataResponse = authData;

    AddAuth(authDataResponse);
    let user = await getUserMe();

    let userData = GetAuth();
    let newData = { ...userData };

    let profile = "";
    let _role = "";
    if (user) {
      setUserMe(user);
      console.log(
        "getUserData: user---------------------------------->>>",
        user
      );
      if (user.role) {
        _role = user.role.name;
        profile = user.role.name.toLowerCase();
        profile = profile.charAt(0).toUpperCase() + profile.slice(1);
        newData.user.role = user.role.name;
      }
      /* sesion:{
        last_login:'',
        password_expires:''
      }, */
      console.log(
        "getUserData: newData1---------------------------------->>>",
        newData
      );
      newData["sesion"]["last_login"] = user.last_login;
      newData["sesion"]["password_expires"] = user.password_expires;
      newData.user.name = user.full_name;
      newData.user.profile = profile;
      console.log(
        "getUserData: newData2---------------------------------->>>",
        newData
      );
    } else {
      toast.update(id_toast, {
        render: "Perfil no creado",
        type: "warning",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        className: "foo-bar",
      });
    }

    let completed = false;
    let data = {};
    console.log("XX profile:", profile);
    if (_role === "SPONSOR") {
      if (
        user.has_datos_basicos &&
        user.has_resident_info &&
        user.has_financial_info &&
        user.has_sponsor_company
      ) {
        completed = true;
      }
      data = {
        has_datos_basicos: user.has_datos_basicos,
        has_resident_info: user.has_resident_info,
        has_financial_info: user.has_financial_info,
        has_sponsor_company: user.has_sponsor_company,
      };
    } else if (_role === "INVERSIONISTA") {
      if (
        user.has_datos_basicos &&
        user.has_resident_info &&
        user.has_workplace_info &&
        user.has_financial_info &&
        user.has_socioeconomic_info
      ) {
        completed = true;
      }
      data = {
        has_datos_basicos: user.has_datos_basicos,
        has_resident_info: user.has_resident_info,
        has_workplace_info: user.has_workplace_info,
        has_financial_info: user.has_financial_info,
        has_socioeconomic_info: user.has_socioeconomic_info,
      };
    } else {
      if (
        user.has_datos_basicos &&
        user.has_resident_info &&
        user.has_workplace_info &&
        user.has_financial_info &&
        user.has_socioeconomic_info
      ) {
        completed = true;
      }
      data = {
        has_datos_basicos: user.has_datos_basicos,
        has_resident_info: user.has_resident_info,
        has_workplace_info: user.has_workplace_info,
        has_financial_info: user.has_financial_info,
        has_socioeconomic_info: user.has_socioeconomic_info,
      };
    }

    newData.profileCompleted = {
      completed: completed,
      data: data,
    };

    newData.user.name = user.full_name ? user.full_name : user.email;
    newData.user.code = user.code;
    newData["subrole"] = user.subrole;
    newData["role"] = user.role;
    console.log(
      "getUserData: AddAuth---------------------------------->>>",
      newData
    );
    AddAuth(newData);
    AddWelcome("Bienvenido");
    AddLastLogin(newData["sesion"]["last_login"]);
    if (newData["sesion"]["password_expires"]) {
      AddPasswordExpires(newData["sesion"]["password_expires"]);
    }
    setTimeout(() => {
      toast.update(id_toast, {
        render: "Ha iniciado sesión satisfactoriamente",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
        className: "foo-bar",
      });
      //navigate("/dashboard", { replace: true });
    }, 20);
    setTimeout(() => {
      //navigate("/dashboard", { replace: true });
      window.location.replace("/dashboard");
    }, 1000);
  };

  const getUserMe = async () => {
    return httpService("/user/me/")
      .get()
      .then((res) => {
        console.log("/user/me/ Response status:", res);
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);

        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const handlerClick = async () => {
    let authDataResponse = null;
    let err = false;
    if (!authFormData.email || !authFormData.password) {
      let _errFormData = { ...errFormData };
      if (!authFormData.password) {
        _errFormData.password = "Campo requerido";
        setErrFormData(_errFormData);
      }
      if (!authFormData.email) {
        _errFormData.email = "Campo requerido";
        setErrFormData(_errFormData);
      }

      err = true;
    }
    if (err) {
      return;
    }
    const id = toast.loading("Iniciando sesión...", { className: "foo-bar" });
    const REQUEST = true;
    if (REQUEST) {
      AuthService.login(authFormData)
        .then(async (response) => {
          if (response.refresh && response.access) {
            const access = jwt_decode(response.access);

            let authData = {
              isLogin: true,
              token: response.access,
              refreshtoken: response.refresh,
              profileCompleted: {
                completed: false,
                data: {
                  has_datos_basicos: false,
                  has_resident_info: false,
                  has_workplace_info: false,
                  has_financial_info: false,
                  has_socioeconomic_info: false,
                },
              },
              sesion: {
                last_login: "",
                password_expires: "",
              },
              user: {
                id: access.user_id,
                name: "",
                profile: "...",
                image: "",
                email: authFormData.email,
                code: "",
                subrole: null,
                role: null,
              },
            };

            getUserData(authData, id);
          } else {
            toast.update(id, {
              render: "Login fallido",
              type: "info",
              isLoading: false,
              autoClose: 5000,
              closeOnClick: true,
            });
            console.log("Response no Success:", response);
            return;
          }
        })
        .catch((err) => {
          toast.dismiss();
          //console.error("XX Response error:", err);
          if (err && err.data && err.data.errors) {
            //alert(err.data.errors[0].detail);
            setErr(err.data.errors[0].detail);
            setOpen(true);
            return;
          }
          if (err && err.raw && err.raw.data.errors) {
            setErr(err.raw.data.errors[0].detail);
            setOpen(true);
            return;
          }
          setErr("Ocurrió un error inesperado, inténtelo más tarde");
          setOpen(true);
          return;
          console.error("XX Response error:", err);
          console.error("XX Response error:", err.result.message);
          if (err.status === 401) {
            setErr(err.result.message);
          } else
            setErr("La combinación de credenciales no tiene una cuenta activa");
          setOpen(true);
          return;
        });
      return;
    }
    const authData = {
      isLogin: true,
      token: "SnVhbiBDYXJsb3MgQ2Fycml6byBDb3JvbmVs",
      refreshtoken: "Y29uc3RyaWN0b3I3OUBnbWFpbC5jb20=",
      profileCompleted: {
        completed: false,
        data: {
          has_datos_basicos: false,
          has_resident_info: false,
          has_workplace_info: false,
          has_financial_info: false,
          has_socioeconomic_info: false,
        },
      },
      sesion: {
        last_login: "",
        password_expires: "",
      },
      user: {
        id: 0,
        name: "",
        profile: "",
        image: "",
        email: "",
        code: "",
        subrole: null,
        role: null,
      },
    };
    AddAuth(authData);
    setTimeout(() => {
      navigate("/dashboard", { replace: true });
      //window.location.replace('/login');
    }, 20);
  };

  return (
    <div className="for">
      <div className="logo-img">
        <Logo />
      </div>

      <div className="form-title three text-field-box form-box">
        <h1 className="form-title-sup">
          <span className="color-main">Ingresa a </span>
          <span className="color-tertiary">Devise</span>
        </h1>
        <div className="form-title-sub">Por favor ingresa tus datos</div>
      </div>

      <div className="two">
        <div className="text-field-box">
          <TextField
            onChange={(e) => onChange(e.target)}
            value={authFormData.email}
            placeholder="Correo electrónico"
            className="textField form-box"
            name="email"
          />
          {errFormData.email && (
            <div>
              <label className="form-error">{errFormData.email}</label>
            </div>
          )}
        </div>
        <div className="text-field-box">
          <TextField
            type={"password"}
            onChange={(e) => onChange(e.target)}
            value={authFormData.password}
            name="password"
            placeholder="Contraseña"
            className="textField form-box"
          />
          {errFormData.password && (
            <div>
              <label className="form-error">{errFormData.password}</label>
            </div>
          )}
        </div>
      </div>
      <Box sx={{ width: "100%" }}>
        <Collapse in={open}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {err}
          </Alert>
        </Collapse>
      </Box>
      <div className="three text-field-box form-box">
        <div>
          <Checkbox id="chk" />
          <label htmlFor="chk">Recordarme</label>
        </div>

        <p className="forgotPassword">
          {/* <a href="/reset-password" className="link">
            ¿Olvidaste tu contraseña?
          </a> */}
          <Link className="link" to={"/reset-password"}>¿Olvidaste tu contraseña?</Link>
        </p>
      </div>
      <div className="three text-field-box form-box">
        <Boton
          className="btn button-secondary form-box"
          label="Continuar"
          redirect="/dashboard"
          variant="contained"
          handlerClick={handlerClick}
        >
          Continuar
        </Boton>
      </div>
      <div className="three text-field-box form-box form-footer">
        <span className="form-title-sub">¿No tienes una cuenta?</span>&nbsp;
        <span className="color-tertiary">
          <Link className="link" to="/register">
            Crea una cuenta aquí
          </Link>
        </span>
      </div>
      {/* <div className="three text-field-box form-box">
        <div className="sponsor">
          <div className="sponsor__box">Sponsor</div>
        </div>
      </div> */}

      <div className="copy-rigth">
        <div className="footer-left">
          <div className="float-content">Copyright © 2022 Lincep V 1.0</div>
        </div>
      </div>
    </div>
  );
};
