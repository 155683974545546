export const signupForm = {
  email: {
    name: "email",
    label: "Correo electrónico",

    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "Correo inválido",
      },
    },

    placeholder: "Correo electrónico",
  },
  code_phone: {
    name: "code_phone",
    label: "Código",

    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
    },
    placeholder: "Seleccione",
  },
  phone: {
    name: "phone",
    label: "Teléfono",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      pattern: {
        value: /^([0-9]\d*)?$/,
        message: "Sólo valores numérico",
      },
    },
    placeholder: "teléfono",
  },
  password: {
    name: "password",
    label: "Contraseña",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      pattern: {
        value:
          /^(?=.*[A-ZÀ-ÿ\u00f1\u00d1])(?=.*\d)(?=.*[@$!%*?&\+\-])[A-Za-zÀ-ÿ\u00f1\u00d1\d@$!%*?&\+\-]+$/,
        message:
          'La contraseña debe contener al menos una mayúscula, un número y un carácter especial "@$!%*?&+-" y un mínimo de 5 carecteres',
      },
      minLength:{
        value:5,
        message: 'La contraseña debe contener al menos una mayúscula, un número y un carácter especial "@$!%*?&+-" y un mínimo de 5 carecteres',
      }
    },
    placeholder: "contraseña",
  },
  re_password: {
    name: "re_password",
    label: "Verificar contraseña",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
    },
    placeholder: "verificar contraseña",
  },
  referred_by_code: {
    name: "referred_by_code",
    label: "Coódigo de referido",
    validators: {
      required: {
        value: false,
        message: "Este campo es requerido",
      },
      pattern: {
        value:/^[A-Z0-9]+$/,
        message: "Formato inválido",
      },
      maxLength: {
        value: 30,
        message:
          "El valor no debe exceder los 30 caracteres",
      },
    },
    placeholder: "código de referido",
  },
};
