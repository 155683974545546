import { useState } from "react";
import BreadcrumbsComponent from "../../components/Breadcrumbs/BreadcrumbsComponent";
import { ContentCard } from "../../components/CardPortafolio/CardPortafolio";
import SwitchModuleMisInversiones from "../../components/Switch/switch";
import TableInvestments from "../../components/TableInvestments/TableInvestments";

export default function PageInvestments() {
  const [activeBTN, setActiveBTN] = useState(1);
  const [valorActivo, setValorActivo] = useState(null);
  const handleEvent = (event) => {
    setActiveBTN(event);
    if(event === 1){
      setValorActivo(inversiones);
    }else{
      setValorActivo(rendimientos);
    }
  };
  const inversiones = {
    totalInvertido: "92000000",
    proyectoResidencial: {
      porcentaje: "59.78%",
      cop: "55000000",
      devisecoin: "55.000.000",
    },
    proyectoComercial: {
      porcentaje: "21.74%",
      cop: "20000000",
      devisecoin: "20.000.000",
    },
    proyectoOficina: {
      porcentaje: "13.04%",
      cop: "12000000",
      devisecoin: "12.000.000",
    },
    proyectoIndustrial: {
      porcentaje: "5.43%",
      cop: "5000000",
      devisecoin: "5.000.000",
    },
    proyectoInfraestructura: {
      porcentaje: "0.00%",
      cop: "0",
      devisecoin: "0",
    },
  };
  const rendimientos = {
    totalInvertido: "84600000",
    proyectoResidencial: {
      porcentaje: "53.19%",
      cop: "45000000",
      devisecoin: "45.000.000",
    },
    proyectoComercial: {
      porcentaje: "26.00%",
      cop: "22000000",
      devisecoin: "22.000.000",
    },
    proyectoOficina: {
      porcentaje: "14.78%",
      cop: "12500000",
      devisecoin: "12.500.000",
    },
    proyectoIndustrial: {
      porcentaje: "6.03%",
      cop: "5100000",
      devisecoin: "5.100.000",
    },
    proyectoInfraestructura: {
      porcentaje: "0.00%",
      cop: "0",
      devisecoin: "0",
    },
  };


  return (
    <div className="page-investments container-animated">
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <BreadcrumbsComponent />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <SwitchModuleMisInversiones handleEvent={handleEvent} />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 col-lg-12 mb-4">
          <div className="card-container">
            <div style={ {width:"100%"} }>
              <ContentCard valorActivo={valorActivo} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <div className="grid-scroll-h-content border-radius">
            <div className="responsive-table">
              <TableInvestments />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
