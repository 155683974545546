import { useState } from "react";
import BreadcrumbsComponent from "../../components/Breadcrumbs/BreadcrumbsComponent";
import ButtonCardOption from "../../components/ButtonCardOption/ButtonCardOption";
import FormPassword from "../../components/FormPassword/FormPassword";
import './PageSecurity.css';

export default function PageSecurity() {
  const [option,setOption] = useState("");

  const clickOption = (op) => {
    console.log("clickOption:",op);
    setOption(op);
  }

  return (
    <div className="page-security container-animated">
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <BreadcrumbsComponent />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-4 col-lg-4">
          <div className="page-security-head">
            <div className="page-security-subtitle">Opciones de seguridad</div>
            <div className="page-security-body">Manten tu cuenta con las siguientes opciones:</div>
          </div>
          <div className="page-security-options">
            <ButtonCardOption text="Opciones de seguridad"  handleClick={() => clickOption("prueba")} />
          </div>
          <div className="page-security-options">
              <div className="page-security-last-update">
                <div className="page-security-l-text">
                  Último acceso
                </div>
                <div className="page-security-l-body">
                  18/11/2022 a las 8:44 a.m.
                </div>
              </div>
          </div>
        </div>
        <div className="col-xl-8 col-lg-8">
          <FormPassword />
        </div>
      </div>
    </div>
  );
}
