import * as React from "react";
import {
  Button,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Boton from "../../components/Boton";
import {
  IconBoxIllustrationForm,
  IconBoxTakePicture,
} from "../../components/Icons/Icons";
import "./PageImcompleteProfile.css";
import { Box } from "@mui/system";
import BasicData from "./BasicData/BasicData";
import Correspondence from "./Correspondence/Correspondence";
import BankData from "./BankData/BankData";
import Occupation from "./Occupation/Occupation";
import SocioeconomicData from "./SocioeconomicData/SocioeconomicData";
import { GetAuth, UpdateAuth, AddAuth } from "../../helpers/Auth";
import Company from "./Company/Company";

export default function PageImcompleteProfile({ Auth }) {
  const [step, setStep] = useState(0);
  const [auth, setAuth] = useState(0);

  const [steps, setSteps] = useState([]);
  const [countToRedirect, setCountToRedirect] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [ROLE, setROLE] = useState("");

  const [completed, setCompleted] = useState({});

  const totalSteps = () => {
    return steps.length;
  };
  const completedSteps = () => {
    return Object.keys(completed).length;
  };
  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  /* const stepsAttr = {
    has_datos_basicos: {
      label: "Básicos",
      step: 1,
    },
    has_resident_info: {
      label: "Correspondencia",
      step: 2,
    },
    has_financial_info: {
      label: "Bancarios",
      step: 3,
    },
    has_workplace_info: {
      label: "Ocupación",
      step: 4,
    },
    has_socioeconomic_info: {
      label: "Socioeconómico",
      step: 5,
    },
  }; */
  const stepsAttrSponsor = {
    has_datos_basicos: {
      label: "Básicos",
      step: 1,
    },
    has_resident_info: {
      label: "Correspondencia",
      step: 2,
    },
    has_financial_info: {
      label: "Bancarios",
      step: 3,
    },
    has_workplace_info: {
      label: "Compañía",
      step: 4,
    }
  };
  const stepsAttrInversor = {
    has_datos_basicos: {
      label: "Básicos",
      step: 1,
    },
    has_resident_info: {
      label: "Correspondencia",
      step: 2,
    },
    has_financial_info: {
      label: "Bancarios",
      step: 3,
    },
    has_workplace_info: {
      label: "Ocupación",
      step: 4,
    },
    has_socioeconomic_info: {
      label: "Socioeconómico",
      step: 5,
    },
  };
  const [stepsAttr, setStepsAttr] = useState(stepsAttrInversor);
  

  React.useEffect(() => {
    let AUTH = null;
    if(!Auth){
      AUTH = GetAuth();
      setAuth(AUTH)
    }else{
      AUTH = Auth;
      setAuth(AUTH);
    }
    /* let _steps = [];
    for (let key in stepsAttr) {
      _steps.push(stepsAttr[key].label);
    } */
    setROLE(AUTH.user.role)
    if(AUTH.user.role === "SPONSOR"){
      setStepsAttr(stepsAttrSponsor);
    }
    
  }, []);

  React.useEffect(() => {
    let _steps = [];
    for (let key in stepsAttr) {
      _steps.push(stepsAttr[key].label);
    }
    let AUTH = GetAuth();
    setSteps(_steps);
    const checkInfoData = async () => {
      let userData = GetAuth();
      let profile = userData.profileCompleted.data;
      console.log("profile:",AUTH);
      /* profile.has_socioeconomic_info = false */
      /* profile.has_datos_basicos = false; */
      /* profile.has_datos_basicos = true; */
      /* profile.has_financial_info = true;  */
      /* profile.has_resident_info = true;  */
      /* profile.has_workplace_info = true;  */
      /* profile.has_socioeconomic_info = true; */

      let incompletedStep = -1;
      for (let key in stepsAttr) {

        if (profile[key]) {
          const newCompleted = completed;
          newCompleted[stepsAttr[key].step - 1] = true;
          setCompleted(newCompleted);
          if (incompletedStep === -1) {
            setActiveStep(stepsAttr[key].step + 0); //// + 1 ó + 0
          }
        } else {
          incompletedStep = stepsAttr[key].step;
        }

      }
    };
    checkInfoData();
  },[stepsAttr])

  const UpdatesetCountToRedirect = (sec) => {
    setCountToRedirect(sec);
  };
  const allStepsCompleted = () => {
    let completed = completedSteps() === totalSteps();
    if (completed) {
      let AUTH = GetAuth();
      if (AUTH) {
        AUTH.profileCompleted.completed = true;
        AUTH.profileCompleted.data = null;
       /*  AddAuth(AUTH); */
        UpdateAuth(AUTH);
        setTimeout(() => {
          window.location.replace("/dashboard");
        }, 5000);
      }
    }
    console.log("All steps completed - you're finished");
    return completed;
  };
  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const [activeStepForm, setActiveStepForm] = useState(0);
  const nextStep = () => {
    setStep(step + 1);
  };

  const previousStep = () => {
    if (step > 0) setStep(step - 1);
  };

  const nextStepForm = () => {
    console.log("nextStepForm:", activeStep + 1);
    setActiveStep(activeStep + 1);
  };
  const prevStepForm = () => {
    setActiveStep(activeStep - 1);
  };

  /*   data Save Steps */
  const [basicData, setBasicData] = useState({});
  const [correspondence, setCorrespondence] = useState({});
  const [bankData, setBankData] = useState({});
  const [occupationData, setOccupationData] = useState({});
  const [sponsorCompanyData, setSponsorCompanyData] = useState({});
  const [socioeconomicData, setSocioeconomicData] = useState({});
  const saveBasicData = (data) => {
    console.log("Datos saveBasicData:", data);
    setBasicData(data);
    handleComplete();
  };
  const saveCorrespondence = (data) => {
    console.log("Datos saveCorrespondence:", data);
    setCorrespondence(data);
    handleComplete();
  };
  const saveBankData = (data) => {
    console.log("Datos saveBankData:", data);
    setBankData(data);
    handleComplete();
  };
  const saveOccupationData = (data) => {
    console.log("Datos setOccupationData:", data);
    setOccupationData(data);
    handleComplete();
  };
  const saveSponsorCompanyData = (data) => {
    console.log("Datos saveSponsorCompanyData:", data);
    setSponsorCompanyData(data);
    handleComplete();
  };
  const saveSocioeconomicData = (data) => {
    console.log("Datos setOccupationData:", data);
    setSocioeconomicData(data);
    handleComplete();
  };

  /*   data Save Steps */

  return (
    <div className="page-incomplete-profile container-animated">
      <div className="row">
        {step === 0 && (
          <div className="col-xl-12 col-lg-12">
            <div className="card-custom">
              <div className="row w-100">
                <div className="wrapper">
                  <div className="completar-perfil-container">
                    <div className="container-img container-animated">
                      <IconBoxIllustrationForm />
                    </div>
                  </div>
                  <div className="completar-perfil-form">
                    <div
                      className="form-title three text-field-box"
                      style={{ marginTop: "160px", marginBottom: "160px" }}
                    >
                      <h1
                        className="form-title-sup"
                        style={{ textAlign: "start" }}
                      >
                        <span className="color-tertiary">Bienvenido </span>{" "}
                        <strong>👋</strong>
                        <br />
                        <span className="color-main">
                          {auth && auth.user && <div>{auth.user.name != "None None"?auth.user.name:auth.user.email}</div> }
                        </span>
                      </h1>
                      <div
                        className="form-title-sub mt-4 mb-4"
                        style={{ textAlign: "start" }}
                      >
                        <p className="p-normal-line">
                          Estamos felices de tenerte en Devise.
                        </p>
                        <p className="p-normal-line">
                          Antes de continuar, primero debes completar tu perfil
                          y los siguientes datos.
                        </p>
                      </div>
                      <div
                        className="col-md-12 mb-2 mt-4 p-0"
                        style={{ display: "flex" }}
                      >
                        <Boton
                          className="btn button-secondary"
                          label="Continuar"
                          redirect="/dashboard"
                          variant="contained"
                          handlerClick={nextStep}
                        >
                          Continuar
                        </Boton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {step === 1 && (
          <div className="col-xl-12 col-lg-12">
            <div className="card-custom">
              <div className="container-box container-main">
                <div className="container-main-body">
                  <div className="title-form">Aviso legal</div>
                  <div className="sub-title-form">
                    Requerimientos de datos personales son necesarios por la
                    ley.
                  </div>
                  <div
                    className="col-md-12 mb-2 mt-4 p-0"
                    style={{ display: "flex" }}
                  >
                    <Boton
                      className="btn button-secondary"
                      label="Continuar"
                      redirect="/dashboard"
                      variant="contained"
                      handlerClick={nextStep}
                    >
                      Continuar
                    </Boton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="col-xl-12 col-lg-12">
            <Box sx={{ width: "100%" }}>
              <Stepper nonLinear activeStep={activeStep}>
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]}>
                    <StepButton
                      color="inherit" /*  onClick={handleStep(index)} */
                    >
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <div>
                {allStepsCompleted() ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      Perfil completado, será redireccionado en 5 segundos...
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Box sx={{ flex: "1 1 auto" }} />
                      {/* <Button onClick={handleReset}>Reset</Button> */}
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                      Paso {activeStep + 1}
                    </Typography>
                    {/* Contenido */}
                    <div
                      className="col-xl-8 col-lg-8"
                      style={{ margin: "auto" }}
                    >
                      {activeStep === 0 && (
                        <div>
                          <BasicData
                            data={basicData}
                            actionButton={saveBasicData}
                            btntext="Guardar"
                            btnstyle={{ maxWidth: "215px" }}
                          />
                        </div>
                      )}
                      {activeStep === 1 && (
                        <div>
                          <Correspondence
                            btntext="Guardar"
                            data={correspondence}
                            actionButton={saveCorrespondence}
                            btnstyle={{ maxWidth: "215px" }}
                          />
                        </div>
                      )}
                      {activeStep === 2 && (
                        <div>
                          <BankData
                            data={bankData}
                            actionButton={saveBankData}
                            btntext="Guardar"
                            btnstyle={{ maxWidth: "215px" }}
                          />
                        </div>
                      )}
                      {activeStep === 3 && (
                        <div>
                          {ROLE === "INVERSIONISTA" && <Occupation
                            data={occupationData}
                            actionButton={saveOccupationData}
                            btntext="Guardar"
                            btnstyle={{ maxWidth: "215px" }}
                          />}
                          {(ROLE !== "INVERSIONISTA" && ROLE !== "SPONSOR") && <Occupation
                            data={occupationData}
                            actionButton={saveOccupationData}
                            btntext="Guardar"
                            btnstyle={{ maxWidth: "215px" }}
                          />}
                          <div>
                          {ROLE === "SPONSOR" && <Company
                            data={sponsorCompanyData}
                            actionButton={saveSponsorCompanyData}
                            btntext="Guardar"
                            btnstyle={{ maxWidth: "215px" }}
                          />}
                        </div>
                        </div>
                        
                      )}
                      {activeStep === 4 && (
                        <div>
                          <SocioeconomicData
                            data={socioeconomicData}
                            actionButton={saveSocioeconomicData}
                            btntext="Guardar"
                            btnstyle={{ maxWidth: "215px" }}
                          />
                        </div>
                      )}
                    </div>
                    {/* Contenido */}

                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      {/* <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Regresar
                      </Button> */}
                      <Box sx={{ flex: "1 1 auto" }} />
                      {/* <Button onClick={handleNext} sx={{ mr: 1 }}>
                        Siguiente
                      </Button> */}
                      {activeStep !== steps.length &&
                        (completed[activeStep] ? (
                          <Typography
                            variant="caption"
                            sx={{ display: "inline-block" }}
                          >
                            {/* Step {activeStep + 1} already completed */}
                          </Typography>
                        ) : (
                          <div>
                            {/* <Button onClick={handleComplete}>
                            {completedSteps() === totalSteps() - 1
                              ? "Finish"
                              : "Complete Step"}
                          </Button> */}
                          </div>
                        ))}
                    </Box>
                  </React.Fragment>
                )}
              </div>
            </Box>
          </div>
        )}
      </div>
    </div>
  );
}
