import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CardBalance } from "../../components/CardBalance/CardBalance";
import { CardBienvenida } from "../../components/CardBienvenida/CardBienvenida";
import { CardBlog } from "../../components/CardBlog/CardBlog";
import CardWallet from "../../components/CardWallet/CardWallet";
import TableInvestments from "../../components/TableInvestments/TableInvestments";
import apiInstanceAuth from "../../helpers/ApiInstanceAuth";
import { httpService, IsUnauthorized } from "../../helpers/ApiService";
import { GetAuth } from "../../helpers/Auth";
import "./PageDashboard.css";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function PageDashboard() {
  const navigate = useNavigate();
  const dashboardService = {
    getData: () => apiInstanceAuth.get("/test/dashboard"),
  };
  
  const notify = (message,type = "default") => toast(message,{...type});
  useEffect(() => {

    /* httpService("/test/dashboard" ).get().then(res=>{
      console.log("Response",res)
    }).catch((err) => {
      console.error("XXXX Response status:", err.status);
      if(IsUnauthorized(err,navigate)){
        notify("Sesión expirada",{type:"error"})
      }
      return;
    }); */



  }, []);

  const [userData, setuserData] = useState(null);
  useEffect(() => {
    let userdata = GetAuth();
    if (!userData) {
      setuserData(userdata);
    } else if (userdata !== userData) {
      let result = compareObjects(userdata, userData);
      if (!result) {
        setuserData(userdata);
      }
    }
  }, [userData]);

  const compareObjects = (object1, object2) => {
    let isEqual = true;
    let object1String = JSON.stringify(object1);
    let object2String = JSON.stringify(object2);
    if (object1String !== object2String) {
      isEqual = false;
    }
    return isEqual;
  };
  const content2 = () => {
    return (
      <div style={{ fontSize: "13px", textAlign: "start", color: "#4d4d4d" }}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry.
      </div>
    );
  };
  const viewMore = () => {
    navigate("/academia/detalles-de-artículo");
  };
  const viewAll = () => {
    navigate("/academia");
  };
  const content3 = () => {
    return (
      <div
        style={{ marginTop: "10px", marginBottom: "20px", cursor: "pointer" }}
        onClick={() => viewMore()}
      >
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Bold",
            padding: "3px 10px",
            background: "#b9de2c",
            borderRadius: "5px",
          }}
        >
          Ver más
        </div>
      </div>
    );
  };
  return (
    <div className="page-dashboard container-animated">
      
      <div className="row">
        <div className="col-md-6 mb-2">
          <CardBienvenida userData={userData} />
        </div>
        <div className="col-md-6 mb-2">
          <CardWallet />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-lg-4 mb-2">
          <CardBalance
            icon="IconBoxBriefcase"
            title="Mis Inversiones"
            saldocop="$68.000.000"
            saldodevisecoin="$68.000.000"
          />
        </div>
        <div className="col-md-12 col-lg-4 mb-2">
          <CardBalance
            icon="IconBoxSaving"
            title="Mi Disponible"
            title2="para retirar"
            saldocop="$22.000.000"
            saldodevisecoin="$22.000.000"
          />
        </div>
        <div className="col-md-12 col-lg-4 mb-2">
          <CardBalance
            icon="IconBoxGraph"
            title="Mis Rendimientos"
            rendimientoTitle="15% E.A."
            rendimientos="$10.000.000"
            rendimientosSubTitle="Mis Rendimientos"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-lg-12">
          {/* <CardTableInvestor /> */}
          <div className="grid-scroll-h-content border-radius">
            <div className="responsive-table">
              <TableInvestments />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="card-container content-box">
            <div className="row">
              <div className="col">
                <div className="table-title " style={{ marginTop: "0px" }}>
                  Aprende con Devise
                </div>
              </div>
              <div className="col-md-12">
                <CardBlog
                  bg_color="#bbacf2"
                  content1="Liberta Financiera, claves para 2023"
                  content2={content2()}
                  content3={content3()}
                />
              </div>
              <div className="col-md-12">
                <div className="_card-footer">
                  <div
                    className="_card-footer-right"
                    onClick={() => viewAll()}
                    style={{ cursor: "pointer" }}
                  >
                    Ver todas
                    <li className="arrow-right"></li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
