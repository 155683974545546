import React from "react";

function Boton({ label, className, handlerClick,borderRadius="20px", style=null, disabled=false,icon=null }) {
  return (
    <button disabled={disabled} style={ style?style:{borderRadius:borderRadius}} className={`${className}`} onClick={handlerClick}>
      {icon && <div style={{clear:'both',display:'unset',marginRight:'2px'}}>{icon}</div>}{label}
    </button>
  );
}
export default Boton;
