export const createCompanyForm = {
  company_name: {
    name: "company_name",
    label: "Nombre de la compañía",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 100,
        message: "El valor no debe exceder los 100 caracteres",
      },
    },
    placeholder: "nombre de la compañía",
  },
  company_country: {
    name: "company_country",
    label: "País de la compañía",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
    },
    placeholder: "Seleccione",
  },
  company_region: {
    name: "company_region",
    label: "Región de la compañía",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
    },
    placeholder: "Seleccione",
  },
  company_city: {
    name: "company_city",
    label: "Ciudad de la compañía",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
    },
    placeholder: "Seleccione",
  },
  company_phone: {
    name: "company_phone",
    label: "Teléfono de la compañía",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 10,
        message: "El valor no debe exceder los 10 caracteres",
      },
    },
    placeholder: "teléfono de la compañía",
  },
  company_address: {
    name: "company_address",
    label: "Dirección de la compañía",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 100,
        message: "El valor no debe exceder los 100 caracteres",
      },
    },
    placeholder: "dirección de la compañía",
  },
  company_zip_code: {
    name: "company_zip_code",
    label: "Zip de la compañía",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 10,
        message: "El valor no debe exceder los 10 caracteres",
      },
      pattern: {
        value: /^([0-9]\d*)?$/,
        message: "Sólo valores numéricos",
      },
    },
    placeholder: "zip de la compañía",
  },
  nit: {
    name: "nit",
    label: "Nit de la compañía",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 100,
        message: "El valor no debe exceder los 100 caracteres",
      },
     
    },
    placeholder: "nit de la compañía",
  },
  camara_comercio: {
    name: "camara_comercio",
    label: "Cámara de comercio",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 100,
        message: "El valor no debe exceder los 100 caracteres",
      },
     
    },
    placeholder: "cámara de comercio",
  },
  logo_empresa: {
    name: "logo_empresa",
    label: "Logo de la empresa",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
    },
    accept: ".jpg,.png,.jpeg",
    placeholder: "Logo de la empresa",
  },
  info_empresa: {
    name: "info_empresa",
    label: "Información de la empresa",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 512,
        message: "El valor no debe exceder los 512 caracteres",
      },
     
    },
    placeholder: "información de la empresa",
  },
  area_registro: {
    name: "area_registro",
    label: "Área de registro",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 256,
        message: "El valor no debe exceder los 256 caracteres",
      },
     
    },
    placeholder: "área de registro",
  },
};
