import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Boton from "../../../components/Boton";
import FormFloatingLabel from "../../../components/FormFloatingLabel";
import { AddAuth, GetAuth } from "../../../helpers/Auth";
import { IsUnauthorized, httpService } from "../../../helpers/ApiService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


export default function SocioeconomicData(props) {
  const navigate = useNavigate();
  const [userMe, setUserMe] = useState(null);
  const formLabels = {

  }





  const ATTR = "has_socioeconomic_info";
  const [disableForm, setDisableForm] = useState(false);
  const [originFounds, setOriginFounds] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const [otherIncome, setOtherIncome] = useState(false);
  const [managePublicResources, setManagePublicResources] = useState(false);
  const [linksWithPep, setLinksWithPep] = useState(false);
  const [foreignCurrencyOperations, setForeignCurrencyOperations] =
    useState(false);
  const [outsideTaxObligation, setOutsideTaxObligation] = useState(false);
  const [isDeclarant, setIsDeclarant] = useState(false);
  const [fOCountries, setFOCountries] = useState([]);
  const [countryOfTaxResidence, setCountryOfTaxResidence] = useState([]);
  const [lastYearIncomeStatementFile, setLastYearIncomeStatementFile] =
    useState(null);
  const [lastYearIncomeStatementName, setLastYearIncomeStatementName] =
    useState("Seleccione");

  const [economicDependencyLetterFile, setEconomicDependencyLetterFile] =
    useState(null);
  const [economicDependencyLetterName, setEconomicDependencyLetterName] =
    useState("Seleccione");

  const [certifiedPublicAccountantFile, setCertifiedPublicAccountantFile] =
    useState(null);
  const [certifiedPublicAccountantName, setCertifiedPublicAccountantName] =
    useState("Seleccione");

  const [profesionalAccountantCardFile, setProfesionalAccountantCardFile] =
    useState(null);
  const [profesionalAccountantCardName, setProfesionalAccountantCardName] =
    useState("Seleccione");

  const [pensionPaymentReceiptFile, setPensionPaymentReceiptFile] =
    useState(null);
  const [pensionPaymentReceiptName, setPensionPaymentReceiptName] =
    useState("Seleccione");

  const [sourceFundsSupportFile, setSourceFundsSupportFile] = useState(null);
  const [sourceFundsSupportName, setSourceFundsSupportName] =
    useState("Seleccione");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    console.log("Parent data:", props.data);
    const loadData = async () => {
      let userData = GetAuth();
      let profile = userData.profileCompleted.data;
      setDisableForm(profile[ATTR]);
      await getOriginFounds();
      let _countries = await getCountries();
      if (_countries instanceof Array) {
        setFOCountries(_countries);
        setCountryOfTaxResidence(_countries);
      }
      getInitialData();
      /* setDisableForm(true); */
      /* if (props && props.data && Object.keys(props.data).length) {
        let data = props.data;
        for (let key in data) {
          setValue(key, data[key]);
        }
      } */
    };
    loadData();
  }, []);
  useEffect(() => {
    if (initialData) {
      for (let key in initialData) {
        setValue(key, initialData[key]);
      }
    }
  }, [initialData]);
  const getUserMe = async () => {
    return httpService("/user/me/")
      .get()
      .then((res) => {
        if(res){
          setUserMe(res)
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getInitialData = () => {
    const url = "/user/socioeconomic/update_read/";
    return httpService(url)
      .get()
      .then(async (res) => {
        if (res instanceof Object) {
          if (
            res &&
            res.monthly_income &&
            res.value_other_income &&
            res.foreign_operations_country
          ) {
            setInitialData(res);
            if (res.last_year_income_statement) {
              let name = res.last_year_income_statement.substring(
                res.last_year_income_statement.lastIndexOf("/") + 1
              );
              setLastYearIncomeStatementName(name);
            }
            if (res.economic_dependency_letter) {
              let name1 = res.economic_dependency_letter.substring(
                res.economic_dependency_letter.lastIndexOf("/") + 1
              );
              setEconomicDependencyLetterName(name1);
            }
            if (res.certified_public_accountant) {
              let name2 = res.certified_public_accountant.substring(
                res.certified_public_accountant.lastIndexOf("/") + 1
              );
              setCertifiedPublicAccountantName(name2);
            }

            if (res.profesional_accountant_card) {
              let name3 = res.profesional_accountant_card.substring(
                res.profesional_accountant_card.lastIndexOf("/") + 1
              );
              setProfesionalAccountantCardName(name3);
            }
            if (res.pension_payment_receipt) {
              let name4 = res.pension_payment_receipt.substring(
                res.pension_payment_receipt.lastIndexOf("/") + 1
              );
              setPensionPaymentReceiptName(name4);
            }
            if (res.source_funds_support) {
              let name5 = res.source_funds_support.substring(
                res.source_funds_support.lastIndexOf("/") + 1
              );
              setSourceFundsSupportName(name5);
            }
          } else {
          }
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getOriginFounds = () => {
    const url = "/user/socioeconomic/listoriginsfunds/";
    return httpService(url)
      .get()
      .then(async (res) => {
        if (res instanceof Array) {
          setOriginFounds(res);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getCountries = async () => {
    return httpService("/cities/countries/")
      .get()
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const onChangeOtherIncome = (event) => {
    setOtherIncome(event.target.checked);
    console.log("onChangeOtherIncome:", event.target.checked);
  };
  const onChangeManagePublicResources = (event) => {
    setManagePublicResources(event.target.checked);
    console.log("onChangeOtherIncome:", event.target.checked);
  };
  const onChangeLinksWithPep = (event) => {
    setLinksWithPep(event.target.checked);
    console.log("onChangeLinksWithPep:", event.target.checked);
  };
  const onChangeForeignCurrencyOperations = (event) => {
    setForeignCurrencyOperations(event.target.checked);
    console.log("onChangeForeignCurrencyOperations:", event.target.checked);
  };
  const onChangeOutsideTaxObligation = (event) => {
    setOutsideTaxObligation(event.target.checked);
    console.log("onChangeOutsideTaxObligation:", event.target.checked);
  };
  const onChangeIsDeclarant = (event) => {
    setIsDeclarant(event.target.checked);
    console.log("onChangeIsDeclarant:", event.target.checked);
  };

  const onChangeLastYearIncomeStatement = (e) => {
    const file = e.target.files[0];
    errors["last_year_income_statement"] = null;
    setLastYearIncomeStatementFile(file);
  };
  const onChangeEconomicDependencyLetter = (e) => {
    const file = e.target.files[0];
    errors["economic_dependency_letter"] = null;
    setEconomicDependencyLetterFile(file);
  };
  const onChangeCertifiedPublicAccountant = (e) => {
    const file = e.target.files[0];
    errors["certified_public_accountant"] = null;
    setCertifiedPublicAccountantFile(file);
  };

  const onChangeProfesionalAccountantCard = (e) => {
    const file = e.target.files[0];
    errors["profesional_accountant_card"] = null;
    setProfesionalAccountantCardFile(file);
  };

  const onChangePensionPaymentReceipt = (e) => {
    const file = e.target.files[0];
    errors["pension_payment_receipt"] = null;
    setPensionPaymentReceiptFile(file);
  };
  const onChangeSourceFundsSupport = (e) => {
    const file = e.target.files[0];
    errors["source_funds_support"] = null;
    setSourceFundsSupportFile(file);
  };

  const onSubmit = async(data) => {
    console.log("onSubmit:", data);
    const formData = new FormData();
    for (let key in data) {
      if (data[key] instanceof FileList) {
        const _file = data[key][0];
        if (_file) {
          console.log("Name:", _file.name);
          let name = _file.name.replace(/[^a-zA-Z0-9._ ]/g, "");
          name = name.replace(/\s{2,}/g, " ");
          name = name.replace(/ /g, "_");
          name = name.trim();
          console.log("Name clear:", name);
          formData.append(key, _file, name);
        }
      } else {
        formData.append(key, data[key]);
      }
      console.log(key, data[key]);
    }
    console.log("Submiting...:", formData);
    const id = toast.loading("Enviando...");
    let result = await saveData(formData, id);
    console.log("Result:", result);
    if (result && result.data && result.data.errors ) {
      toast.update(id, {
        render: "Se registraron los datos",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
      let errors = [];
      errors = result.data.errors;
      let message = ``;
      errors.forEach((data,i,array)=>{
        message += data.detail + ": " + data.attr + ".\n\r";
        if(i === array.length - 1 ){
          toast.update(id, {
            render: message,
            type: "error",
            isLoading: false,
            autoClose: 5000,
            closeOnClick: true,
          });
        }
      });
      
    } else if (result) {
      toast.update(id, {
        render: "Se registraron los datos",
        type: "success",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
      props.actionButton(result);
      return
    } else {
      toast.update(id, {
        render: "Se ha producido un error inténtelo más tarde",
        type: "error",
        isLoading: false,
        autoClose: 5000,
        closeOnClick: true,
      });
    }
    //props.actionButton(data);
  };
  const saveData = async(formData) => {
    const url = "/user/socioeconomic/create/";
    let _userMe = await getUserMe();
    return httpService(url, formData)
      .post()
      .then(async (res) => {

        let userData = GetAuth();
        let newData = { ...userData };
        newData.profileCompleted.data[ATTR] = true;
        newData.user.name = _userMe.full_name;
        AddAuth(newData);
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err);
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }

        return err;
      });
  };
  const onNext = () => {
    props.actionButton(props.data);
  };
  return (
    <div className="component-datos-socioeconomicos container-animated">
      <div className="page-security-head" style={{ padding: "5px 0px 15px" }}>
        <div className="page-security-subtitle">Datos socioeconómicos</div>
      </div>

      <div className="w-100">
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <fieldset className="row" disabled={disableForm}>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Ingresos mensuales" required={true}/>
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register("monthly_income", {
                      required: true,
                      pattern: /^\d+(\.\d+)?$/,
                      maxLength: 20,
                    })}
                    placeholder="Ingresos mensuales"
                    autoComplete="off"
                  />
                </div>

                {errors.monthly_income && (
                  <div>
                    {errors.monthly_income.type === "maxLength" && (
                      <label className="form-error">
                        Máximo de caracteres 20
                      </label>
                    )}

                    {errors.monthly_income.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                    {errors.monthly_income.type === "pattern" && (
                      <label className="form-error">
                        Sólo valores numéricos
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <FormFloatingLabel label="Otros ingresos" required={false}/>
              <div className="custom-input-box">
                <div className="custom-control custom-switch">
                  <input
                    defaultChecked={otherIncome}
                    type="checkbox"
                    {...register("other_income", {
                      required: false,
                      onChange: (e) => {
                        onChangeOtherIncome(e);
                      },
                    })}
                    className="custom-control-input"
                    id="other_income_Switche"
                    /* defaultChecked={verifyEmail} */
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="other_income_Switche"
                  >
                    {otherIncome ? "Si" : "No"}
                  </label>
                </div>
              </div>
            </div>

            {otherIncome && (
              <div className="col-md-6 mb-4">
                <div className="form-custom-box">
                  <FormFloatingLabel label="Valor otros ingresos" required={true}/>
                  <div className="custom-input-box">
                    <input
                      type="text"
                      {...register("value_other_income", {
                        required: true,
                        pattern: /^\d+(\.\d+)?$/,
                        maxLength: 20,
                      })}
                      placeholder="Otros ingresos"
                      autoComplete="off"
                    />
                  </div>

                  {errors.value_other_income && (
                    <div>
                      {errors.value_other_income.type === "maxLength" && (
                        <label className="form-error">
                          Máximo de caracteres 20
                        </label>
                      )}

                      {errors.value_other_income.type === "required" && (
                        <label className="form-error">
                          Este campo es requerido
                        </label>
                      )}
                      {errors.value_other_income.type === "pattern" && (
                        <label className="form-error">
                          Sólo valores numéricos
                        </label>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Gastos mensuales" required={true}/>
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register("monthly_expenses", {
                      required: true,
                      pattern: /^([0-9]\d*)(\.\d+)?$/,
                      maxLength: 20,
                    })}
                    placeholder="gastos mensuales"
                    autoComplete="off"
                  />
                </div>

                {errors.monthly_expenses && (
                  <div>
                    {errors.monthly_expenses.type === "maxLength" && (
                      <label className="form-error">
                        Máximo de caracteres 20
                      </label>
                    )}

                    {errors.monthly_expenses.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                    {errors.monthly_expenses.type === "pattern" && (
                      <label className="form-error">
                        Sólo valores numéricos
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Activos totales" required={true}/>
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register("total_assets", {
                      required: true,
                      pattern: /^([0-9]\d*)(\.\d+)?$/,
                      maxLength: 20,
                    })}
                    placeholder="activos totales"
                    autoComplete="off"
                  />
                </div>

                {errors.total_assets && (
                  <div>
                    {errors.total_assets.type === "maxLength" && (
                      <label className="form-error">
                        Máximo de caracteres 20
                      </label>
                    )}

                    {errors.total_assets.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                    {errors.total_assets.type === "pattern" && (
                      <label className="form-error">
                        Sólo valores numéricos
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Pasivos totales" required={true}/>
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register("total_liabilities", {
                      required: true,
                      pattern: /^([0-9]\d*)(\.\d+)?$/,
                      maxLength: 20,
                    })}
                    placeholder="pasivos totales"
                    autoComplete="off"
                  />
                </div>

                {errors.total_liabilities && (
                  <div>
                    {errors.total_liabilities.type === "maxLength" && (
                      <label className="form-error">
                        Máximo de caracteres 20
                      </label>
                    )}

                    {errors.total_liabilities.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                    {errors.total_liabilities.type === "pattern" && (
                      <label className="form-error">
                        Sólo valores numéricos
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Origen de fondos" required={true}/>
                <div className="custom-input-box">
                  <select
                    {...register("origin_of_funds", {
                      required: true,
                    })}
                  >
                    <option value="">Selecione</option>
                    {originFounds.map((item) => (
                      <option key={`origin-founds-${item.id}`} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                {errors.origin_of_funds && (
                  <div>
                    {errors.origin_of_funds.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Explicación de ingresos" required={false}/>
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register("income_explanation", {
                      required: false,
                      maxLength: 500,
                    })}
                    placeholder="explicación de ingresos"
                    autoComplete="off"
                  />
                </div>

                {errors.income_explanation && (
                  <div>
                    {errors.income_explanation.type === "maxLength" && (
                      <label className="form-error">
                        Máximo de caracteres 20
                      </label>
                    )}

                    {errors.income_explanation.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <FormFloatingLabel label="¿Gestiona recursos públicos?" />
              <div className="custom-input-box">
                <div className="custom-control custom-switch">
                  <input
                    defaultChecked={managePublicResources}
                    type="checkbox"
                    {...register("manage_public_resources", {
                      required: false,
                      onChange: (e) => {
                        onChangeManagePublicResources(e);
                      },
                    })}
                    className="custom-control-input"
                    id="manage_public_resources_Switche"
                    /* defaultChecked={verifyEmail} */
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="manage_public_resources_Switche"
                  >
                    {managePublicResources ? "Si" : "No"}
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <FormFloatingLabel label="Vínculos con PEP" />
              <div className="custom-input-box">
                <div className="custom-control custom-switch">
                  <input
                    defaultChecked={linksWithPep}
                    type="checkbox"
                    {...register("links_with_pep", {
                      required: false,
                      onChange: (e) => {
                        onChangeLinksWithPep(e);
                      },
                    })}
                    className="custom-control-input"
                    id="links_with_pep_Switche"
                    /* defaultChecked={verifyEmail} */
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="links_with_pep_Switche"
                  >
                    {linksWithPep ? "Si" : "No"}
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <FormFloatingLabel label="Operaciones con moneda extranjera" />
              <div className="custom-input-box">
                <div className="custom-control custom-switch">
                  <input
                    defaultChecked={foreignCurrencyOperations}
                    type="checkbox"
                    {...register("foreign_currency_operations", {
                      required: false,
                      onChange: (e) => {
                        onChangeForeignCurrencyOperations(e);
                      },
                    })}
                    className="custom-control-input"
                    id="foreign_currency_operations_Switche"
                    /* defaultChecked={verifyEmail} */
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="foreign_currency_operations_Switche"
                  >
                    {foreignCurrencyOperations ? "Si" : "No"}
                  </label>
                </div>
              </div>
            </div>
            {foreignCurrencyOperations && (
              <div className="col-md-6 mb-4">
                <div className="form-custom-box">
                  <FormFloatingLabel label="País de operaciones" required={foreignCurrencyOperations}/>
                  <div className="custom-input-box">
                    <select
                      {...register("foreign_operations_country", {
                        required: foreignCurrencyOperations,
                      })}
                    >
                      <option value="">Selecione</option>
                      {fOCountries.map((item) => (
                        <option
                          key={`origin-founds-${item.id}`}
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {errors.foreign_operations_country && (
                    <div>
                      {errors.foreign_operations_country.type ===
                        "required" && (
                        <label className="form-error">
                          Este campo es requerido
                        </label>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            {foreignCurrencyOperations && (
              <div className="col-md-6 mb-4">
                <div className="form-custom-box">
                  <FormFloatingLabel label="Valor de operaciones en moneda extranjera" required={true}/>
                  <div className="custom-input-box">
                    <input
                      type="text"
                      {...register("foreign_operations_value", {
                        required: true,
                        pattern: /^([0-9]\d*)(\.\d+)?$/,
                        maxLength: 20,
                      })}
                      placeholder="foreign_operations_value"
                      autoComplete="off"
                    />
                  </div>

                  {errors.foreign_operations_value && (
                    <div>
                      {errors.foreign_operations_value.type === "maxLength" && (
                        <label className="form-error">
                          Máximo de caracteres 20
                        </label>
                      )}

                      {errors.foreign_operations_value.type === "required" && (
                        <label className="form-error">
                          Este campo es requerido
                        </label>
                      )}
                      {errors.foreign_operations_value.type === "pattern" && (
                        <label className="form-error">
                          Sólo valores numéricos
                        </label>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className="col-md-6 mb-4">
              <FormFloatingLabel label="Obligación de tributar fuera del país" required={false}/>
              <div className="custom-input-box">
                <div className="custom-control custom-switch">
                  <input
                    defaultChecked={outsideTaxObligation}
                    type="checkbox"
                    {...register("outside_tax_obligation", {
                      required: false,
                      onChange: (e) => {
                        onChangeOutsideTaxObligation(e);
                      },
                    })}
                    className="custom-control-input"
                    id="outside_tax_obligation_Switche"
                    /* defaultChecked={verifyEmail} */
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="outside_tax_obligation_Switche"
                  >
                    {outsideTaxObligation ? "Si" : "No"}
                  </label>
                </div>
              </div>
            </div>
            {outsideTaxObligation && <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="País de residencia fiscal" required={true} />
                <div className="custom-input-box">
                  <select
                    {...register("country_of_tax_residence", {
                      required: true,
                    })}
                  >
                    <option value="">Selecione</option>
                    {countryOfTaxResidence.map((item) => (
                      <option key={`origin-founds-${item.id}`} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>

                {errors.country_of_tax_residence && (
                  <div>
                    {errors.country_of_tax_residence.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>}
            <div className="col-md-6 mb-4">
              <div className="form-custom-box">
                <FormFloatingLabel label="Número TIN ó equivalente" />
                <div className="custom-input-box">
                  <input
                    type="text"
                    {...register("tin_number_or_equivalent", {
                      required: true,
                      maxLength: 30,
                    })}
                    placeholder="número TIN ó equivalent"
                    autoComplete="off"
                  />
                </div>

                {errors.tin_number_or_equivalent && (
                  <div>
                    {errors.tin_number_or_equivalent.type === "maxLength" && (
                      <label className="form-error">
                        Máximo de caracteres 30
                      </label>
                    )}

                    {errors.tin_number_or_equivalent.type === "required" && (
                      <label className="form-error">
                        Este campo es requerido
                      </label>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <FormFloatingLabel label="¿Es declarante?" />
              <div className="custom-input-box">
                <div className="custom-control custom-switch">
                  <input
                    defaultChecked={isDeclarant}
                    type="checkbox"
                    {...register("is_declarant", {
                      required: false,
                      onChange: (e) => {
                        onChangeIsDeclarant(e);
                      },
                    })}
                    className="custom-control-input"
                    id="is_declarant_Switche"
                    /* defaultChecked={verifyEmail} */
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="is_declarant_Switche"
                  >
                    {isDeclarant ? "Si" : "No"}
                  </label>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-custom-box">
                <FormFloatingLabel label="Declaración de renta del último año" required={false}/>
                <div className="custom-input-box">
                  <label
                    title={
                      lastYearIncomeStatementFile
                        ? lastYearIncomeStatementFile.name
                        : lastYearIncomeStatementName
                    }
                    style={{
                      cursor: "pointer",
                      margin: "auto",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                    htmlFor="last_year_income_statement"
                  >
                    {lastYearIncomeStatementFile
                      ? lastYearIncomeStatementFile.name
                      : lastYearIncomeStatementName}
                  </label>
                  <input
                    id="last_year_income_statement"
                    {...register("last_year_income_statement", {
                      required: false,
                    })}
                    style={{ height: 0, width: 0 }}
                    type="file"
                    accept=".pdf"
                    onChange={onChangeLastYearIncomeStatement}
                  />
                </div>

                {errors.last_year_income_statement && (
                  <label className="form-error">Este campo es requerido</label>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-custom-box">
                <FormFloatingLabel label="Carta de dependencia económica" required={false}/>
                <div className="custom-input-box">
                  <label
                    title={
                      economicDependencyLetterFile
                        ? economicDependencyLetterFile.name
                        : economicDependencyLetterName
                    }
                    style={{
                      cursor: "pointer",
                      margin: "auto",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                    htmlFor="economic_dependency_letter"
                  >
                    {economicDependencyLetterFile
                      ? economicDependencyLetterFile.name
                      : economicDependencyLetterName}
                  </label>
                  <input
                    id="economic_dependency_letter"
                    {...register("economic_dependency_letter", {
                      required: false,
                    })}
                    style={{ height: 0, width: 0 }}
                    type="file"
                    accept=".pdf"
                    onChange={onChangeEconomicDependencyLetter}
                  />
                </div>

                {errors.economic_dependency_letter && (
                  <label className="form-error">Este campo es requerido</label>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="form-custom-box">
                <FormFloatingLabel label="Certificado de contador pública" required={false}/>
                <div className="custom-input-box">
                  <label
                    title={
                      certifiedPublicAccountantFile
                        ? certifiedPublicAccountantFile.name
                        : certifiedPublicAccountantName
                    }
                    style={{
                      cursor: "pointer",
                      margin: "auto",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                    htmlFor="certified_public_accountant"
                  >
                    {certifiedPublicAccountantFile
                      ? certifiedPublicAccountantFile.name
                      : certifiedPublicAccountantName}
                  </label>
                  <input
                    id="certified_public_accountant"
                    {...register("certified_public_accountant", {
                      required: false,
                    })}
                    style={{ height: 0, width: 0 }}
                    type="file"
                    accept=".pdf"
                    onChange={onChangeCertifiedPublicAccountant}
                  />
                </div>

                {errors.certified_public_accountant && (
                  <label className="form-error">Este campo es requerido</label>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-custom-box">
                <FormFloatingLabel label="Tarjeta profesional de contador" required={false}/>
                <div className="custom-input-box">
                  <label
                    title={
                      profesionalAccountantCardFile
                        ? profesionalAccountantCardFile.name
                        : profesionalAccountantCardName
                    }
                    style={{
                      cursor: "pointer",
                      margin: "auto",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                    htmlFor="profesional_accountant_card"
                  >
                    {profesionalAccountantCardFile
                      ? profesionalAccountantCardFile.name
                      : profesionalAccountantCardName}
                  </label>
                  <input
                    id="profesional_accountant_card"
                    {...register("profesional_accountant_card", {
                      required: false,
                    })}
                    style={{ height: 0, width: 0 }}
                    type="file"
                    accept=".pdf"
                    onChange={onChangeProfesionalAccountantCard}
                  />
                </div>

                {errors.profesional_accountant_card && (
                  <label className="form-error">Este campo es requerido</label>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="form-custom-box">
                <FormFloatingLabel label="Recivo de pago de pensión" required={false}/>
                <div className="custom-input-box">
                  <label
                    title={
                      pensionPaymentReceiptFile
                        ? pensionPaymentReceiptFile.name
                        : pensionPaymentReceiptName
                    }
                    style={{
                      cursor: "pointer",
                      margin: "auto",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                    htmlFor="pension_payment_receipt"
                  >
                    {pensionPaymentReceiptFile
                      ? pensionPaymentReceiptFile.name
                      : pensionPaymentReceiptName}
                  </label>
                  <input
                    id="pension_payment_receipt"
                    {...register("pension_payment_receipt", {
                      required: false,
                    })}
                    style={{ height: 0, width: 0 }}
                    type="file"
                    accept=".pdf"
                    onChange={onChangePensionPaymentReceipt}
                  />
                </div>

                {errors.pension_payment_receipt && (
                  <label className="form-error">Este campo es requerido</label>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <div className="form-custom-box">
                <FormFloatingLabel label="Soporte de origen de los fondos" required={false}/>
                <div className="custom-input-box">
                  <label
                    title={
                      sourceFundsSupportFile
                        ? sourceFundsSupportFile.name
                        : sourceFundsSupportName
                    }
                    style={{
                      cursor: "pointer",
                      margin: "auto",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                    htmlFor="source_funds_support"
                  >
                    {sourceFundsSupportFile
                      ? sourceFundsSupportFile.name
                      : sourceFundsSupportName}
                  </label>
                  <input
                    id="source_funds_support"
                    {...register("source_funds_support", {
                      required: false,
                    })}
                    style={{ height: 0, width: 0 }}
                    type="file"
                    accept=".pdf"
                    onChange={onChangeSourceFundsSupport}
                  />
                </div>

                {errors.source_funds_support && (
                  <label className="form-error">Este campo es requerido</label>
                )}
              </div>
            </div>

            {/* New Form end */}

            {!disableForm && (
              <div className="col-md-12">
                <div
                  className="mb-2 mt-4"
                  style={props.btnstyle ? props.btnstyle : { display: "flex" }}
                >
                  <Boton
                    className={"btn _btn-primary font-14"}
                    label={
                      props && props.btntext
                        ? props.btntext
                        : "Actualizar datos"
                    }
                  />
                </div>
              </div>
            )}
          </fieldset>
        </form>
        {disableForm && (
          <div className="row">
            <div
              className="col-md-12 mb-2 mt-4"
              style={props.btnstyle ? props.btnstyle : { display: "flex" }}
            >
              <Boton
                className={"btn _btn-primary font-14"}
                label={"Siguiente"}
                handlerClick={onNext}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
