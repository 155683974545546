import { useState } from "react";
import Boton from "../../components/Boton";
import BreadcrumbsComponent from "../../components/Breadcrumbs/BreadcrumbsComponent";
import { IconBoxArrowDiagonal, IconBoxArrowDown, IconBoxDevice } from "../../components/Icons/Icons";
import {IconFlagCop} from '../../assets/icon/IconFlagCop'
import { IconWallet } from "../../assets/icon/IconWallet";

export default function PageWithdrawal() {
  const [withdrawal, setWithdrawal] = useState("");
  const [transactionCost, setTransactionCost] = useState("0");
  const [available, setAvailable] = useState("22.500.000");
  const [availableDC, setAvailableDC] = useState("22.500.000");

  return (
    <div className="page-withdrawal container-animated">
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <BreadcrumbsComponent />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-8 col-lg-8 mx-auto">
          <div className="card-custom">
            <div className="w-100">
              <div className="wrapper-content custom-1">
                <div className="w-left">
                  <div className="wm-auto text-subtitle"> <span><IconWallet /></span> Disponible</div>
                </div>
                <div className="w-right">
                  <div className="wm-auto">
                    <div className="text-subtitle-dark">
                      <span>$ {available}</span>
                      <span style={ { margin:"0 4px" } }>
                        <IconFlagCop colorIcon="#252772" size="14px" />
                      </span>
                      <span style={{ fontSize: "11px" }}>COP</span>
                    </div>
                    <div
                      className="text-small-dark"
                      style={{ borderTop: "1px dotted" }}
                    >
                      <span>{availableDC} </span>
                      <span>
                        <IconBoxDevice
                          style={{ transform: "translate(0px, -2px)" }}
                          colorIcon="#252772"
                          height="14px"
                          width="20px"
                        />
                      </span>
                      <span>Devise Coin</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mx-auto">
                <div className="col-md-12 mt-4 mb-4">
                  <div className="text-extra-light" style={ {textAlign:"justify"} }>
                    Recuerda que puedes retirar hasta el total de tu disponible y tu saldo será abonado a tu cuenta bancaria registrada.
                  </div>
                </div>
                <div className="col-md-12 mt-4 mb-4">
                  <div className="text-subtitle"> <span><IconBoxArrowDiagonal /></span> Retirar</div>
                </div>
                <div className="col-md-12 mt-4 mb-4">
                  <div className="card-custom">
                    <div className="wrapper-bid-input">
                      <div className="form-custom-box">
                        <div className="custom-input-box size-big">
                          <div style={{ lineHeight: "1.06" }}>$</div>
                          <input
                            type={"text"}
                            value={withdrawal}
                            onChange={(value) =>
                              setWithdrawal(value.target.value)
                            }
                          />
                        </div>
                        <div
                          className="text-extra-light"
                          style={{
                            textAlign: "start",
                            color: "rgb(147, 147, 147)",
                          }}
                        >
                          Monto mínimo: $1.000
                        </div>
                      </div>
                      <div className="icon-box-gray">
                        <div
                          style={{
                            width: "40%",
                            height: "60%",
                            margin: "auto",
                          }}
                        >
                          <IconBoxDevice
                            colorIcon="#252772"
                            width="20"
                            height="30px"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="btn-values">
                      <div className="row">
                        <div className="col">
                          <div
                            onClick={() => setWithdrawal(50000)}
                            className="btn btn-values-box"
                          >
                            50.000
                          </div>
                        </div>
                        <div className="col">
                          <div
                            onClick={() => setWithdrawal(100000)}
                            className="btn btn-values-box"
                          >
                            {" "}
                            100.000
                          </div>
                        </div>
                        <div className="col">
                          <div
                            onClick={() => setWithdrawal(500000)}
                            className="btn btn-values-box"
                          >
                            500.000
                          </div>
                        </div>
                        <div className="col">
                          <div
                            onClick={() => setWithdrawal(1000000)}
                            className="btn btn-values-box"
                          >
                            1.000.000
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-4 mb-4">
                  <div className="text-subtitle" style={{ textAlign: "right" }}>
                    <span><IconBoxArrowDown /></span><span>Recibir</span>
                  </div>
                </div>
                <div className="col-md-12 mt-4 mb-4">
                  <div className="card-custom">
                    <div className="wrapper-bid-input">
                      <div className="form-custom-box">
                        <div className="custom-input-box size-big">
                          <div style={{ lineHeight: "1.06" }}>$</div>
                          <input
                            type={"text"}
                            value={transactionCost ? transactionCost : ""}
                            readOnly={true}
                          />
                        </div>
                        <div
                          className="text-extra-light"
                          style={{
                            textAlign: "start",
                            color: "rgb(147, 147, 147)",
                          }}
                        >
                          Costo de transacción | ${transactionCost}
                        </div>
                      </div>
                      <div className="icon-box-gray">
                        <div
                          style={{
                            width: "auto",
                            height: "auto",
                            margin: "auto",
                          }}
                        >
                          <IconFlagCop size="30px" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 mt-4 mb-4 mx-auto">
                  <Boton
                    className="btn button-secondary w-100"
                    label="Continuar"
                    variant="contained"
                    style={{ borderRadius: "10px", backgroundColor: "#557bd1" }}
                    /* handlerClick={handlerClick} */
                  >
                    Continuar
                  </Boton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
