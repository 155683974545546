import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { GetAuth, SetAuth } from '../../helpers/Auth';
function Protected({ checkAuth, children }) {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  let AUTH = GetAuth();
  let isSignedIn = false;
  if(AUTH){
    isSignedIn = AUTH.isLogin;
  }
  
  //checkAuth(isSignedIn)
  /* for(let i = 0; i < 10000; i++){
    console.log("Intents:" + i);
  } */
  if (!isSignedIn) {
    return <Navigate to="/welcome" replace />
  }

  if(AUTH && AUTH.profileCompleted &&  !AUTH.profileCompleted.completed && splitLocation.length == 2 && splitLocation[1] !== "completar-perfil"){
    return <Navigate to="/completar-perfil" replace />
  }
  if(AUTH && AUTH.profileCompleted && AUTH.profileCompleted.completed && splitLocation.length == 2 && splitLocation[1] === "completar-perfil"){
    return <Navigate to="/dashboard" replace />
  }
  setTimeout(() => {
    //window.location.replace('/login');
  }, 0);
  return children;
  
}
export default Protected