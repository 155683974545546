import { useEffect, useState } from "react";
import BreadcrumbsComponent from "../../components/Breadcrumbs/BreadcrumbsComponent";
import SwitchModuleMisInversiones from "../../components/Switch/switch";
import { useOutletContext } from "react-router-dom";
import "./PageReferals.css";
import { getEnviroments } from "../../helpers/enviroments";
import styles from "./PageReferals.module.css";
import { IconInvest } from "../../components/Icons/Icons";
import BadgesProjects from "../../components/BadgesProjects/BadgesProjects/BadgesProjects";
import user from "../../assets/user-d.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import SwitchButtons from "../../components/SwitchButtons/switch";

export default function PageReferals() {
  const [userMe, setUserMe] = useOutletContext();
  const [activeBTN, setActiveBTN] = useState(1);
  var dataInvest = [
    {
      nombre: "Camila Giraldo",
      fecha: "02 Dic 2022 - 4:59 p.m.",
      reward: "$5.040.000",
      status: "Activo",
    },
    {
      nombre: "Robert Giraldo",
      fecha: "02 Dic 2022 - 4:59 p.m.",
      reward: "$5.040.000",
      status: "Activo",
    },
  ];
  var dataYields = [
    {
      descripcion: "Recompensa",
      fecha: "02 Dic 2022 - 4:59 p.m.",
      cantidad: "$5.000",
      status: "Activo",
    },
    {
      descripcion: "Recompensa",
      fecha: "02 Dic 2022 - 4:59 p.m.",
      cantidad: "$5.040",
      status: "Activo",
    },
  ];

  useEffect(() => {
    if (userMe) {
     
    }
  }, [userMe]);
  const handleEvent = (event) => {
    setActiveBTN(event);
  };
  const copyToClipBoard = async () => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(userMe.code);
    } else {
      return document.execCommand("copy", true, userMe.code);
    }
  };
  const copyToClipBoardUrl = async () => {
    console.log("process.env:", getEnviroments().HOST);
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(
        getEnviroments().HOST + "/register/" + userMe.code
      );
    } else {
      return document.execCommand("copy", true, userMe.code);
    }
  };

  return (
    <div className="page-referals container-animated">
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <BreadcrumbsComponent />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <SwitchButtons style={{float:"right"}} handleEvent={handleEvent} btnName1={"Mis referidos"} btnName2={"Mis recompensas"} />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-4  media-size">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div
                style={{ height: "auto" }}
                className="grid-scroll-h-content border-radius"
              >
                <div className="m-4 ml-2">
                  <div className="page--head">
                    <div className="page--subtitle">Código referido</div>
                  </div>
                  <div className="code-ref-container">
                    <div className="code-ref-box">{userMe && userMe.code}</div>
                  </div>
                  <div className="code-ref-container mt-4">
                    <button
                      onClick={copyToClipBoard}
                      className="w-100 btn btn-system-primary"
                    >
                      Copiar código
                    </button>
                  </div>
                  <div className="code-ref-container mt-4">
                    <button
                      onClick={copyToClipBoardUrl}
                      className="w-100 btn btn-system-white"
                    >
                      Compartir URL
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <div className="m-2">
                  <div className="page--head mt-4">
                    <div className="page--subtitle">¿Cómo funciona?</div>
                    <div style={{ textAlign: "justify", color: "gray" }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Quisque consectetur mi est, sit amet tristique tortor
                      hendrerit laoreet. Proin consequat lobortis ipsum eget
                      elementum. Cras semper lorem sit amet ultricies feugiat.
                      Etiam semper odio sed mollis tincidunt. Praesent vel risus
                      id neque sollicitudin rutrum. Sed ornare ultricies ornare.
                      Vestibulum nisl.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {activeBTN === 1 && (
          <div className="col-md-8 container-animated media-size">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="grid-scroll-h-content border-radius">
                  <div className="m-4 ml-2">
                    <div className="page--head">
                      <div className="page--subtitle">Mis Referidos</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className=" mb-4">
                          <div className="input-group md-form form-sm form-1 pl-0">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text purple lighten-3"
                                id="basic-text1"
                              >
                                <FontAwesomeIcon
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    color: "#787878",
                                    cursor: "pointer",
                                  }}
                                  /* onClick={props.handlerClick.hideMenu} */
                                  icon={faSearch}
                                />
                              </span>
                            </div>
                            <input
                              className="form-control my-0 py-1"
                              type="text"
                              placeholder="Buscar referido"
                              aria-label="Buscar referido"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* tabla */}
                    <div
                      className={styles.tableViewNewInvoice}
                      style={{
                        padding: 0,
                        display: "table",
                        tableLayout: "fixed",
                        width: "100%",
                      }}
                    >
                      <div className="table-responsive">
                        <table className="table table-borderless">
                          <thead>
                            <tr className="head border-top-0">
                              <th scope="col" style={{minWidth:"100px"}} className="first-col">
                                Nombre
                              </th>
                              <th scope="col" style={{minWidth:"150px"}} className="middle-col">
                                Fecha
                              </th>
                              <th scope="col" style={{minWidth:"100px"}} className="middle-col">
                                Recompensa
                              </th>
                              <th scope="col" style={{minWidth:"100px"}} className="last-col">
                                Estado
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {dataInvest.map((data, i) => (
                              <tr
                                key={"row-component-investments-" + i}
                                className="row-component-investments"
                              >
                                <td className="col-component-investments">
                                  <div style={{textAlign:"left"}} className="start col-proyecto">
                                    <div
                                      className="td-img"
                                      style={{ background: "#f0f0f0" }}
                                    >
                                      <img width={40} src={user} />
                                    </div>
                                    <div className="td-txt">
                                      <div>{data.nombre}</div>
                                    </div>
                                  </div>
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                  className="col-component-investments"
                                >
                                  <div
                                    style={{ textAlign: "center" }}
                                    className="center col-fecha-inversion"
                                  >
                                    {data.fecha}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                  className="col-component-investments"
                                >
                                  <div className="center col-fecha-inversion">
                                    {data.reward}
                                  </div>
                                </td>

                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                  className="col-component-investments"
                                >
                                  <div className="center col-activo">
                                    {data.status}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {/* <div className="col-md-12">
                          <div className="_card-footer">
                            <div className="_card-footer-right">
                              Ver todas
                              <li className="arrow-right"></li>
                            </div>
                          </div>
                        </div> */}
                        {/* <div className="pagination-right">
            <div className="box-right">
              <Pagination count={10} />
            </div>
          </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeBTN === 2 && (
          <div className="col-md-8 container-animated media-size">
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="grid-scroll-h-content border-radius">
                  <div className="m-4 ml-2">
                    <div className="page--head">
                      <div className="page--subtitle">Mis Recompensas</div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className=" mb-4">
                          <div className="input-group md-form form-sm form-1 pl-0">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text purple lighten-3"
                                id="basic-text1"
                              >
                                <FontAwesomeIcon
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    color: "#787878",
                                    cursor: "pointer",
                                  }}
                                  /* onClick={props.handlerClick.hideMenu} */
                                  icon={faSearch}
                                />
                              </span>
                            </div>
                            <input
                              className="form-control my-0 py-1"
                              type="text"
                              placeholder="Buscar referido"
                              aria-label="Buscar referido"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={styles.tableViewNewInvoice}
                      style={{
                        padding: 0,
                        display: "table",
                        tableLayout: "fixed",
                        width: "100%",
                      }}
                    >
                      <div className="table-responsive">
                        <table className="table table-borderless">
                          <thead>
                            <tr className="head border-top-0">
                              <th scope="col" style={{minWidth:"100px"}} className="first-col">
                                Descripción
                              </th>
                              <th scope="col" style={{minWidth:"150px"}} className="middle-col">
                                Fecha
                              </th>
                              <th scope="col" style={{minWidth:"100px"}} className="middle-col">
                                Cantidad
                              </th>
                              <th scope="col" style={{minWidth:"100px"}} className="last-col">
                                Estado
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {dataYields.map((data, i) => (
                              <tr
                                key={"row-component-investments-" + i}
                                className="row-component-investments"
                              >
                                <td className="col-component-investments">
                                  <div className="start col-proyecto">
                                    <div className="td-txt">
                                      <div>{data.descripcion}</div>
                                    </div>
                                  </div>
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                  className="col-component-investments"
                                >
                                  <div
                                    style={{ textAlign: "center" }}
                                    className="center col-fecha-inversion"
                                  >
                                    {data.fecha}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                  className="col-component-investments"
                                >
                                  <div className="center col-fecha-inversion">
                                    {data.cantidad}
                                  </div>
                                </td>

                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                  className="col-component-investments"
                                >
                                  <div className="center col-activo">
                                    {data.status}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {/* <div className="col-md-12">
                          <div className="_card-footer">
                            <div className="_card-footer-right">
                              Ver todas
                              <li className="arrow-right"></li>
                            </div>
                          </div>
                        </div> */}
                        {/* <div className="pagination-right">
            <div className="box-right">
              <Pagination count={10} />
            </div>
          </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
