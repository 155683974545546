import { useEffect, useState } from "react";
import BreadcrumbsComponent from "../../components/Breadcrumbs/BreadcrumbsComponent";
import { GetAuth } from "../../helpers/Auth";
import { IsUnauthorized, httpService } from "../../helpers/ApiService";
import { useNavigate } from "react-router-dom";
import Boton from "../../components/Boton";

export default function PageMyCompany() {
  const navigate = useNavigate();
  const [initialData, setInitialData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [phoneCode, setPhoneCode] = useState("");
  useEffect(() => {
    const loadData = async () => {
      let _userData = GetAuth();
      setUserData(_userData);
      await getInitialData();
    };
    loadData();
  }, []);
  const getInitialData = () => {
    const url = "company/read/";
    return httpService(url)
      .get()
      .then(async (res) => {
        if (res instanceof Object) {
          if (res && res.company_name && res.company_zip_code && res.nit) {
            let data = { ...res };
            let newData = {};
            for (let key in data) {
              if (key === "company_country") {
                newData[key] = data[key].name;
                setPhoneCode(data[key].phone);
              } else if (key === "company_region") {
                newData[key] = data[key].name;
              } else if (key === "company_city") {
                newData[key] = data[key].name;
              } else {
                newData[key] = data[key];
              }
            }
            setInitialData(newData);
            /* let _country = res.company_country; */
            /* setCountry(_country);
            await getDeparments(_country);
            let _department = res.company_region; */
            /* setDepartment(_department) */
            /* await getCities(_department, _country);
            setTimeout(() => {
              for (let key in res) {
                setValue(key, res[key]);
              }
            }, 100); */
          } else {
          }
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const toEdit = () => {
    navigate("/mi-empresa/editar");
  };

  return (
    <div className="page-referals container-animated">
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <BreadcrumbsComponent />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-xl-4 col-lg-4 mt-4">
          <div className="card-custom">
            <div className="w-100">
              <div className="col-md-12">
                <label htmlFor="profile-picture" className="picture-profile">
                  <div className="w-100">
                    <div
                      style={{ border: "#dedede solid 0.5px" }}
                      className="profile-img"
                    >
                      <img
                        src={initialData ? initialData.logo_empresa : ""}
                        className="rounded mx-auto d-block"
                        alt="logo-company"
                      />
                    </div>
                  </div>
                </label>
                <div
                  title={
                    initialData && initialData.company_name
                      ? initialData.company_name
                      : ""
                  }
                  style={{
                    fontSize: "20px",
                    fontFamily: "Poppins-Bold",
                    color: "var(--secondarycolorpalette-lightblue-darker)",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maxWidth: "calc(100% - 0px)",
                  }}
                >
                  {initialData && initialData.company_name}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-2">
            <div>
              <Boton
                className={"btn _btn-primary font-14"}
                label={"Actualizar empresa"}
                handlerClick={toEdit}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-8 col-lg-8 mt-4">
          <div className="card-custom">
            <div style={{ marginLeft: "15px", marginRight: "15px" }}>
              <section style={{ marginBottom: "10px" }}>
                <div
                  className="page-security-head"
                  style={{ padding: "5px 0px 0px" }}
                >
                  <div className="page-security-subtitle">Información</div>
                </div>
                <div style={{ textAlign: "justify" }} className="mb-2">
                  <span
                    style={{
                      fontSize: "14px",
                      color: "gray",
                      fontFamily: "Poppins-Bold",
                    }}
                    className="page-security-subtitle"
                  >
                    Nombre:
                  </span>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins-Bold",
                    }}
                    className="page-security-subtitle"
                  >
                    {initialData && initialData.company_name}
                  </span>
                </div>
                <div style={{ textAlign: "justify" }} className="mb-2">
                  <span
                    style={{
                      fontSize: "14px",
                      color: "gray",
                      fontFamily: "Poppins-Bold",
                    }}
                    className="page-security-subtitle"
                  >
                    NIT:
                  </span>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins",
                    }}
                    className="page-security-subtitle"
                  >
                    {initialData && initialData.nit}
                  </span>
                </div>

                <div style={{ textAlign: "justify" }} className="mb-2">
                  <span
                    style={{
                      fontSize: "14px",
                      color: "gray",
                      fontFamily: "Poppins-Bold",
                    }}
                    className="page-security-subtitle"
                  >
                    Información de la empresa:
                  </span>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins",
                    }}
                    className="page-security-subtitle"
                  >
                    {initialData && initialData.info_empresa}
                  </span>
                </div>
                <div style={{ textAlign: "justify" }} className="mb-2">
                  <span
                    style={{
                      fontSize: "14px",
                      color: "gray",
                      fontFamily: "Poppins-Bold",
                    }}
                    className="page-security-subtitle"
                  >
                    Cámara de comercio:
                  </span>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins",
                    }}
                    className="page-security-subtitle"
                  >
                    {initialData && initialData.camara_comercio}
                  </span>
                </div>

                <div style={{ textAlign: "justify" }} className="mb-2">
                  <span
                    style={{
                      fontSize: "14px",
                      color: "gray",
                      fontFamily: "Poppins-Bold",
                    }}
                    className="page-security-subtitle"
                  >
                    Área de registro:
                  </span>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins",
                    }}
                    className="page-security-subtitle"
                  >
                    {initialData && initialData.area_registro}
                  </span>
                </div>
              </section>

              <section style={{ marginBottom: "10px" }}>
                <div
                  className="page-security-head"
                  style={{ padding: "5px 0px 0px", margin: "0px" }}
                >
                  <div className="page-security-subtitle">Ubicación</div>
                </div>
                <div style={{ textAlign: "justify" }} className="mb-2">
                  <span
                    style={{
                      fontSize: "14px",
                      color: "gray",
                      fontFamily: "Poppins-Bold",
                    }}
                    className="page-security-subtitle"
                  >
                    País:
                  </span>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins",
                    }}
                    className="page-security-subtitle"
                  >
                    {initialData && initialData.company_country}
                  </span>
                </div>
                <div style={{ textAlign: "justify" }} className="mb-2">
                  <span
                    style={{
                      fontSize: "14px",
                      color: "gray",
                      fontFamily: "Poppins-Bold",
                    }}
                    className="page-security-subtitle"
                  >
                    Estado/departamento:
                  </span>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins",
                    }}
                    className="page-security-subtitle"
                  >
                    {initialData && initialData.company_region}
                  </span>
                </div>

                <div style={{ textAlign: "justify" }} className="mb-2">
                  <span
                    style={{
                      fontSize: "14px",
                      color: "gray",
                      fontFamily: "Poppins-Bold",
                    }}
                    className="page-security-subtitle"
                  >
                    Ciudad:
                  </span>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins",
                    }}
                    className="page-security-subtitle"
                  >
                    {initialData && initialData.company_city}
                  </span>
                </div>
                <div style={{ textAlign: "justify" }} className="mb-2">
                  <span
                    style={{
                      fontSize: "14px",
                      color: "gray",
                      fontFamily: "Poppins-Bold",
                    }}
                    className="page-security-subtitle"
                  >
                    Dirección:
                  </span>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins",
                    }}
                    className="page-security-subtitle"
                  >
                    {initialData && initialData.company_address}
                  </span>
                </div>
              </section>

              <section style={{ marginBottom: "10px" }}>
                <div
                  className="page-security-head"
                  style={{ padding: "5px 0px 0px", margin: "0px" }}
                >
                  <div className="page-security-subtitle">Contacto</div>
                </div>
                <div style={{ textAlign: "justify" }} className="mb-2">
                  <span
                    style={{
                      fontSize: "14px",
                      color: "gray",
                      fontFamily: "Poppins-Bold",
                    }}
                    className="page-security-subtitle"
                  >
                    Teléfono:
                  </span>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins",
                    }}
                    className="page-security-subtitle"
                  >
                    {initialData && initialData.company_phone && (
                      <a href={"tel:+" + phoneCode + initialData.company_phone}>
                        {"+" + phoneCode + "" + initialData.company_phone}
                      </a>
                    )}
                  </span>
                </div>
                <div style={{ textAlign: "justify" }} className="mb-2">
                  <span
                    style={{
                      fontSize: "14px",
                      color: "gray",
                      fontFamily: "Poppins-Bold",
                    }}
                    className="page-security-subtitle"
                  >
                    ZIP:
                  </span>{" "}
                  <span
                    style={{
                      fontSize: "14px",
                      fontFamily: "Poppins",
                    }}
                    className="page-security-subtitle"
                  >
                    {initialData && initialData.company_zip_code}
                  </span>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
