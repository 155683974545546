// Overlay use className props to pass style properties to child component.
// To make this component work add className props to your child component manually.
// Here an example: https://gist.github.com/Miniplop/8f87608f8100e758fa5a4eb46f9d151f

import React, { useEffect, useState } from "react";
import styles from "./ItemMenu.module.css";
import {
  IconAcademy,
  IconAccount,
  IconDashboard,
  IconHelpSupport,
  IconInvest,
  IconInvestments,
  IconLogOut,
  IconMovements,
  IconReferals,
  IconSecurity,
} from "../Icons/Icons";
import "./item-menu.css";

const ItemMenu = ({
  text = "",
  icon = "",
  active = false,
  parentMenu = false,
  subMenu = false,
  splitLocation = "",
  activePath = ""
}) => {
  const [submenu, setSubmenu] = useState("");
  const [_active, setActive] = useState(active);
  const [activeInitial, setActiveInitial] = useState(active);
  const [pathname, setPathname] = useState("");
  const [_activePath, setActivePath] = useState(activePath);

  const [actualPath, setActualPath] = useState("");

  useEffect(() => {
    
  }, [activePath]);


  useEffect(() => {
    setActive(active);
    setActiveInitial(active);
    if (active && parentMenu) {
      //setSubmenu("showing");
    } else {
      setSubmenu("");
    }
  }, [active]);

  useEffect(() => {
    const url = window.location.href;
    const _pathname = window.location.pathname.split("/");

    if (parentMenu) {
      
      if (splitLocation !== _pathname[1]) {
        let itemBox = document.getElementsByClassName("item-box");
        
        for (let i = 0; i < itemBox.length; i++) {
         
          let doc = itemBox[i];
         
        }
        return;
      }

     
    }
  });

  useEffect(() => {
    //setActiveInitial(active)
    let itemBox = document.getElementsByClassName("item-box");
    
    for (let i = 0; i < itemBox.length; i++) {
      //itemBox[i]
      
      
      let doc = itemBox[i];
      if (!parentMenu)
        for (let j = 0; j < doc.childNodes.length; j++) {
          if (doc.childNodes[j].className.includes("showing")) {
            let notes = doc.childNodes[j];
            /* setSubmenu("");
            if (notes.classList.remove("showing")) {
            } */
            
            break;
          }
        }
    }
  }, [submenu]);


  const handleShowing = () => {

  }


  const showing = (event) => {
    console.log("showing event", event);
    console.log("showing event", event);
    const _pathname_ = window.location.pathname.split("/");
    
    if ((parentMenu || subMenu) && !_active) {
      let el = document.getElementById(splitLocation);
      
      if(splitLocation == _pathname_){
        el.classList.add("showing");
      }
     
      /* el.classList.add("showing"); */
      let itemBox = el.closest(".item-container");
      
      
      for (let j = 0; j < itemBox.childNodes.length; j++) {
        //console.log("itemBox[" + j + "] has showing", itemBox[j]);
        let doc = itemBox.childNodes[j];
        //console.log("itemBox[" + j + "] has showing", doc  );
        if (doc.className.includes("item-childs")) {
          
          doc.classList.add("view");
          doc.classList.remove("none");
          for (let i = 0; i < doc.childNodes.length; i++) {
            doc.childNodes[i].classList.add("list");
            doc.childNodes[i].classList.remove("none");
            let notes = doc.childNodes[i];
          
            
          }
         
          
        }  
      }

      /* console.log("_pathname itemBox------->",itemBox);
      console.log("_pathname XXXXX------->"); */
      
     
    } else {
      setSubmenu("no-submenu");
      //document.getElementById(splitLocation).classList.remove("showing");
    }
  };
  const hide = (event) =>{
    const _pathname = window.location.pathname.split("/");
    if ((parentMenu || subMenu) && !_active) {
      let el = document.getElementById(splitLocation);
      el.classList.remove("showing");
      let itemBox = el.closest(".item-container");      
      
      for (let j = 0; j < itemBox.childNodes.length; j++) {
        //console.log("itemBox[" + j + "] has showing", itemBox[j]);
        let doc = itemBox.childNodes[j];
        //console.log("itemBox[" + j + "] has showing", doc  );
        if (doc.className.includes("item-childs")) {
          if(splitLocation !== _pathname){
            doc.classList.remove("view");
            doc.classList.add("none");
          }
          
          
          console.log("splitLocation----->", splitLocation);
          console.log("splitLocation----->_pathname[1]", _pathname[1]);
          if(splitLocation !== _pathname[1])//verificar ruta
          for (let i = 0; i < doc.childNodes.length; i++) {
            doc.childNodes[i].classList.remove("list");
            doc.childNodes[i].classList.add("none");
            let notes = doc.childNodes[i];
           /*  console.log("itemBox[" + i + "] has notes", notes); */
          }
         
          
        }  
      }
    }
  }
  return (
    <div
      onClick={(e) => showing(e)}
      //onMouseOut={(e) => hide(e)}
      id={splitLocation}
      className={
        _active
          ? `${styles.itemMenu_active}${submenu ? " " + submenu : ""}`
          : `${styles.itemMenu}${submenu ? " " + submenu : ""}`
      }
    >
      <div className={styles.boxIcon} >
        <div className={styles.iconsMenu}>
          {!subMenu &&
            !parentMenu &&
            iconList(icon, _active ? "#B9DE2C" : "#1c4fc1")}
          {!subMenu &&
            parentMenu &&
            iconList(icon, _active ? "#B9DE2C" : "#1c4fc1")}
          {subMenu && iconList(icon, _active ? "#1c4fc1" : "#B9DE2C")}{_active && !parentMenu && subMenu && <div className="active-sub-menu"></div>}
        </div>
      </div>
      <div className={styles.boxName}>
        {!subMenu && (
          <p
            className={
              _active
                ? `${styles.dashboardTwo_active} p-title`
                : `${styles.dashboardTwo} p-title`
            }
          >
            {text}
          </p>
        )}
        {subMenu && (
          <p
            className={
              _active
                ? `${styles.dashboardTwo} p-title`
                : `${styles.dashboardTwo_active} p-title`
            }
          >
            {text}
          </p>
        )}
      </div>
    </div>
  );
};

const iconList = (iconName, color = "#B9DE2C") => {
  let ICON = "icon";
  switch (iconName) {
    case "IconDashboard":
      ICON = <IconDashboard colorIcon={color} />;
      break;
    case "IconInvest":
      ICON = <IconInvest colorIcon={color} />;
      break;
    case "IconInvestments":
      ICON = <IconInvestments colorIcon={color} />;
      break;
    case "IconMovements":
      ICON = <IconMovements colorIcon={color} />;
      break;
    case "IconAccount":
      ICON = <IconAccount colorIcon={color} />;
      break;
    case "IconReferals":
      ICON = <IconReferals colorIcon={color} />;
      break;
    case "IconSecurity":
      ICON = <IconSecurity colorIcon={color} width="19" height="22" />;
      break;
    case "IconAcademy":
      ICON = <IconAcademy colorIcon={color} />;
      break;
    case "IconHelpSupport":
      ICON = <IconHelpSupport colorIcon={color} />;
      break;
    case "IconLogOut":
      ICON = <IconLogOut colorIcon={color} />;
      break;

    default:
      ICON = <IconDashboard colorIcon={color} />;
      break;
  }
  return ICON;
};

export default ItemMenu;
