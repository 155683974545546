// Overlay use className props to pass style properties to child component.
// To make this component work add className props to your child component manually.
// Here an example: https://gist.github.com/Miniplop/8f87608f8100e758fa5a4eb46f9d151f

import React, { useState } from "react";
import styles from "./FormPassword.module.css";
import Boton from "../Boton";
import { IconBoxEye, IconBoxEyeOff, IconSecurity } from "../Icons/Icons";

const FormPassword = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [newPasswordInput, setNewPasswordInput] = useState("");
  const handleNewPasswordChange = (evnt) => {
    setNewPasswordInput(evnt.target.value);
  };
  const toggleNewPassword = () => {
    if (newPasswordType === "password") {
      setNewPasswordType("text");
      return;
    }
    setNewPasswordType("password");
  };

  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [confirmPasswordInput, setConfirmPasswordInput] = useState("");
  const handleConfirmPasswordChange = (evnt) => {
    setConfirmPasswordInput(evnt.target.value);
  };
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };

  return (
    <div className={styles.cardContrasena}>
      <div className={styles.headingTwo}>
        <p className={styles.heading}>Cambiar contraseña</p>
      </div>
      <div className="mb-4 w-100">
        <div className={styles.boxContent}>
          <p className={styles.label}>Contraseña actual</p>
          <div className="form-custom-box">
            <div className="custom-input-box">
              <IconSecurity width="21px" height="24px" colorIcon="#9891bb" />
              <input
                type={passwordType}
                onChange={handlePasswordChange}
                value={passwordInput}
                id="inlineFormInputGroup"
                placeholder="Contraseña actual"
                autoComplete="off"
              />
              <div className="icon-action" onClick={togglePassword}>
                {passwordType === "password" ? (
                  <IconBoxEyeOff
                    width="29px"
                    height="20px"
                    colorIcon="#9891bb"
                  />
                ) : (
                  <IconBoxEye width="29px" height="20px" colorIcon="#9891bb" />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.boxContent}>
          <p className={styles.label}>Nueva Contraseña</p>
          <div className="form-custom-box">
            <div className="custom-input-box">
              <IconSecurity width="21px" height="24px" colorIcon="#9891bb" />
              <input
                type={newPasswordType}
                onChange={handleNewPasswordChange}
                value={newPasswordInput}
                id="inlineNewFormInputGroup"
                placeholder="Nueva Contraseña"
                autoComplete="off"
              />
              <div className="icon-action" onClick={toggleNewPassword}>
                {newPasswordType === "password" ? (
                  <IconBoxEyeOff
                    width="29px"
                    height="20px"
                    colorIcon="#9891bb"
                  />
                ) : (
                  <IconBoxEye width="29px" height="20px" colorIcon="#9891bb" />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.boxContent}>
          <p className={styles.label}>Confirmar Contraseña</p>
          <div className="form-custom-box">
            <div className="custom-input-box">
              <IconSecurity width="21px" height="24px" colorIcon="#9891bb" />
              <input
                type={confirmPasswordType}
                onChange={handleConfirmPasswordChange}
                value={confirmPasswordInput}
                id="inlineConfirmFormInputGroup"
                placeholder="Confirmar Contraseña"
                autoComplete="off"
              />
              <div className="icon-action" onClick={toggleConfirmPassword}>
                {confirmPasswordType === "password" ? (
                  <IconBoxEyeOff
                    width="29px"
                    height="20px"
                    colorIcon="#9891bb"
                  />
                ) : (
                  <IconBoxEye width="29px" height="20px" colorIcon="#9891bb" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Boton
        className={"btn _btn-primary font-14"}
        label="Actualizar datos"
        borderRadius="10px"
      />
    </div>
  );
};

export default FormPassword;
