import { useState } from "react";
import PageAcademy from "../pages/PageAcademy/PageAcademy";
import PageAccount from "../pages/PageAccount/PageAccount";
import PageDashboard from "../pages/PageDashboard/PageDashboard";
import PageDeposit from "../pages/PageDeposit/PageDeposit";
import PageInvest from "../pages/PageInvest/PageInvest";
import PageInvestments from "../pages/PageInvestments/PageInvestments";
import PageMovements from "../pages/PageMovements/PageMovements";
import PageMyAssets from "../pages/PageMyAssets/PageMyAssets";
import PageProjectDetail from "../pages/PageProjectDetail/PageProjectDetail";
import PageReferals from "../pages/PageReferals/PageReferals";
import PageSecurity from "../pages/PageSecurity/PageSecurity";
import PageWithdrawal from "../pages/PageWithdrawal/PageWithdrawal";
import { rolesTypes } from "./functions";
import PageImcompleteProfile from "../pages/PageImcompleteProfile/PageImcompleteProfile";
import PageAcademyDetails from "../pages/PageAcademyDetails/PageAcademyDetails";
import PageHelpSupport from "../pages/PageHelpSupport/PageHelpSupport";
import PageMyCompany from "../pages/PageMyCompany/PageMyCompany";
import MyCompanyCreate from "../pages/PageMyCompany/MyCompanyCreate/MyCompanyCreate";
import PageAdminAssetSponsor from "../pages/PageAdminAssetSponsor/PageAdminAssetSponsor";
import AdminAssetSponsorView from "../pages/PageAdminAssetSponsor/AdminAssetSponsorView/AdminAssetSponsorView";
import AdminAssetSponsorAdmin from "../pages/PageAdminAssetSponsor/AdminAssetSponsorAdmin/AdminAssetSponsorAdmin";
import AdminAssetSponsorAdminView from "../pages/PageAdminAssetSponsor/AdminAssetSponsorAdminView/AdminAssetSponsorAdminView";
import AdminAssetSponsorAdminKpi from "../pages/PageAdminAssetSponsor/AdminAssetSponsorAdminKpi/AdminAssetSponsorAdminKpi";

const ROUTES = [
  {
    path: "/dashboard",
    element: PageDashboard,
    componentName: "PageDashboard",
    roleAccess: [
      rolesTypes().PROPIETARIO.codeName,
      rolesTypes().INVERSIONISTA.codeName,
      rolesTypes().SPONSOR.codeName,
      rolesTypes().ADMINISTRADOR.codeName,
    ],
  },
  {
    path: "/invertir",
    element: PageInvest,
    componentName: "PageInvest",
    roleAccess: [
      rolesTypes().PROPIETARIO.codeName,
      rolesTypes().INVERSIONISTA.codeName,
      rolesTypes().SPONSOR.codeName,
      rolesTypes().ADMINISTRADOR.codeName,
    ],
  },
  {
    path: "/invertir/proyecto/:id",
    element: PageProjectDetail,
    componentName: "PageProjectDetail",
    roleAccess: [
      rolesTypes().PROPIETARIO.codeName,
      rolesTypes().INVERSIONISTA.codeName,
      rolesTypes().SPONSOR.codeName,
      rolesTypes().ADMINISTRADOR.codeName,
    ],
  },
  {
    path: "/mis-movimientos",
    element: PageMovements,
    componentName: "PageMovements",
    roleAccess: [
      rolesTypes().PROPIETARIO.codeName,
      rolesTypes().INVERSIONISTA.codeName,
      rolesTypes().SPONSOR.codeName,
      rolesTypes().ADMINISTRADOR.codeName,
    ],
  },
  {
    path: "/cuenta",
    element: PageAccount,
    componentName: "PageAccount",
    roleAccess: [
      rolesTypes().PROPIETARIO.codeName,
      rolesTypes().INVERSIONISTA.codeName,
      rolesTypes().SPONSOR.codeName,
      rolesTypes().ADMINISTRADOR.codeName,
    ],
  },
  {
    path: "/mi-empresa",
    element: PageMyCompany,
    componentName: "PageMyCompany",
    roleAccess: [
      rolesTypes().SPONSOR.codeName,
    ],
  },
  {
    path: "/mi-empresa/editar",
    element: MyCompanyCreate,
    componentName: "MyCompanyCreate",
    roleAccess: [
      rolesTypes().SPONSOR.codeName,
    ],
  },
  {
    path: "/mis-inversiones",
    element: PageInvestments,
    componentName: "PageInvestments",
    roleAccess: [
      rolesTypes().PROPIETARIO.codeName,
      rolesTypes().INVERSIONISTA.codeName,
      rolesTypes().SPONSOR.codeName,
      rolesTypes().ADMINISTRADOR.codeName,
    ],
  },
  {
    path: "/solicitud-de-retiro",
    element: PageWithdrawal,
    componentName: "PageWithdrawal",
    roleAccess: [
      rolesTypes().PROPIETARIO.codeName,
      rolesTypes().INVERSIONISTA.codeName,
      rolesTypes().SPONSOR.codeName,
      rolesTypes().ADMINISTRADOR.codeName,
    ],
  },
  {
    path: "/recarga-tu-cuenta",
    element: PageDeposit,
    componentName: "PageDeposit",
    roleAccess: [
      rolesTypes().PROPIETARIO.codeName,
      rolesTypes().INVERSIONISTA.codeName,
      rolesTypes().SPONSOR.codeName,
      rolesTypes().ADMINISTRADOR.codeName,
    ],
  },
  {
    path: "/referidos",
    element: PageReferals,
    componentName: "PageReferals",
    roleAccess: [
      rolesTypes().PROPIETARIO.codeName,
      rolesTypes().INVERSIONISTA.codeName,
      rolesTypes().SPONSOR.codeName,
      rolesTypes().ADMINISTRADOR.codeName,
    ],
  },
  {
    path: "/seguridad",
    element: PageSecurity,
    componentName: "PageSecurity",
    roleAccess: [
      rolesTypes().PROPIETARIO.codeName,
      rolesTypes().INVERSIONISTA.codeName,
      rolesTypes().SPONSOR.codeName,
      rolesTypes().ADMINISTRADOR.codeName,
    ],
  },
  {
    path: "/academia",
    element: PageAcademy,
    componentName: "PageAcademy",
    roleAccess: [
      rolesTypes().PROPIETARIO.codeName,
      rolesTypes().INVERSIONISTA.codeName,
      rolesTypes().SPONSOR.codeName,
      rolesTypes().ADMINISTRADOR.codeName,
    ],
  },

  {
    path: "/academia/detalles-de-artículo",
    element: PageAcademyDetails,
    componentName: "PageAcademyDetails",
    roleAccess: [
      rolesTypes().PROPIETARIO.codeName,
      rolesTypes().INVERSIONISTA.codeName,
      rolesTypes().SPONSOR.codeName,
      rolesTypes().ADMINISTRADOR.codeName,
    ],
  },
  {
    path:"/ayuda-y-soporte",
    element:PageHelpSupport,
    componentName: "PageHelpSupport",
    roleAccess: [
      rolesTypes().PROPIETARIO.codeName,
      rolesTypes().INVERSIONISTA.codeName,
      rolesTypes().SPONSOR.codeName,
      rolesTypes().ADMINISTRADOR.codeName,
    ],
  },
  {
    path: "/mis-activos",
    element: PageMyAssets,
    componentName: "PageMyAssets",
    roleAccess: [
      rolesTypes().PROPIETARIO.codeName,
      rolesTypes().SPONSOR.codeName,
      rolesTypes().ADMINISTRADOR.codeName,
    ],
  },
  
  {
    path: "/admin-activos",
    element: PageAdminAssetSponsor,
    componentName: "PageAdminAssetSponsor",
    roleAccess: [
      rolesTypes().SPONSOR.codeName
    ],
  },
  {
    path: "/admin-activos/admin",
    element: AdminAssetSponsorAdmin,
    componentName: "AdminAssetSponsorAdmin",
    roleAccess: [
      rolesTypes().SPONSOR.codeName
    ],
  },
  {
    path: "/admin-activos/admin/:id",
    element: AdminAssetSponsorAdminView,
    componentName: "AdminAssetSponsorAdminView",
    roleAccess: [
      rolesTypes().SPONSOR.codeName
    ],
  },
  {
    path: "/admin-activos/admin/:id/kpi",
    element: AdminAssetSponsorAdminKpi,
    componentName: "AdminAssetSponsorAdminKpi",
    roleAccess: [
      rolesTypes().SPONSOR.codeName
    ],
  },
  
  {
    path: "/admin-activos/:id",
    element: AdminAssetSponsorView,
    componentName: "AdminAssetSponsorView",
    roleAccess: [
      rolesTypes().SPONSOR.codeName
    ],
  },
  
  
  
  
];
export const havePermission = () => {
  return ROUTES;
};
