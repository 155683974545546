import { useForm } from "react-hook-form";
import Boton from "../../../components/Boton";
import FormFloatingLabel from "../../../components/FormFloatingLabel";

export default function DatosOcupacion() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("onSubmit:", data);
  };
  return (
    <div className="component-datos-ocupacion container-animated">
      <div className="page-security-head" style={{ padding: "5px 0px 15px" }}>
        <div className="page-security-subtitle">Datos de ocupación</div>
      </div>
      <div className="w-100">
        <form className="row" onSubmit={handleSubmit((data) => onSubmit(data))}>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Ocupación" />
              <div className="custom-input-box">
                <select
                  {...register("datos_ocupacion_ocupacion", { required: true })}
                >
                  <option value="">Selecione</option>
                  <option value="001">Empleado</option>
                  <option value="002">Gerente</option>
                </select>
              </div>

              {errors.datos_ocupacion_ocupacion && (
                <div>
                  {errors.datos_ocupacion_ocupacion.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Nombre de la empresa" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("datos_ocupacion_nombre_empresa", {
                    required: true,
                    maxLength: 255,
                  })}
                  placeholder="Nombre de la empresa"
                  autoComplete="off"
                />
              </div>

              {errors.datos_ocupacion_nombre_empresa && (
                <div>
                  {errors.datos_ocupacion_nombre_empresa.type === "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 255
                    </label>
                  )}
                  
                  {errors.datos_ocupacion_nombre_empresa.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="País" />
              <div className="custom-input-box">
                <select
                  {...register("datos_ocupacion_pais", { required: true })}
                >
                  <option value="">Selecione</option>
                  <option value="001">Colombia</option>
                  <option value="002">España</option>
                </select>
              </div>

              {errors.datos_ocupacion_pais && (
                <div>
                  {errors.datos_ocupacion_pais.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Departamento" />
              <div className="custom-input-box">
                <select
                  {...register("datos_ocupacion_departamento", { required: true })}
                >
                  <option value="">Selecione</option>
                  <option value="001">Bogotá</option>
                  <option value="002">Cundinamarca</option>
                </select>
              </div>

              {errors.datos_ocupacion_departamento && (
                <div>
                  {errors.datos_ocupacion_departamento.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Ciudad" />
              <div className="custom-input-box">
                <select
                  {...register("datos_ocupacion_ciudad", { required: true })}
                >
                  <option value="">Selecione</option>
                  <option value="001">Bogotá</option>
                  <option value="002">Madrid</option>
                </select>
              </div>

              {errors.datos_ocupacion_ciudad && (
                <div>
                  {errors.datos_ocupacion_ciudad.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Dirección" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("datos_ocupacion_direccion", {
                    required: true,
                    maxLength: 500,
                  })}
                  placeholder="Dirección"
                  autoComplete="off"
                />
              </div>

              {errors.datos_ocupacion_direccion && (
                <div>
                  {errors.datos_ocupacion_direccion.type === "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 500
                    </label>
                  )}
                  
                  {errors.datos_ocupacion_direccion.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Cargo" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("datos_ocupacion_cargo", {
                    required: true,
                    maxLength: 255,
                  })}
                  placeholder="Cargo"
                  autoComplete="off"
                />
              </div>

              {errors.datos_ocupacion_cargo && (
                <div>
                  {errors.datos_ocupacion_cargo.type === "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 255
                    </label>
                  )}
                  
                  {errors.datos_ocupacion_cargo.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Teléfono" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("datos_ocupacion_telefono", {
                    required: true,
                    pattern: /^([0-9]\d*)(\.\d+)?$/,
                    maxLength: 10,
                    minLength: 10,
                  })}
                  placeholder="Teléfono"
                  autoComplete="off"
                />
              </div>

              {errors.datos_ocupacion_telefono && (
                <div>
                  {errors.datos_ocupacion_telefono && (
                <div>
                  {errors.datos_ocupacion_telefono.type === "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 10
                    </label>
                  )}
                  {errors.datos_ocupacion_telefono.type === "minLength" && (
                    <label className="form-error">
                      Mínimo de caracteres 10
                    </label>
                  )}
                  {errors.datos_ocupacion_telefono.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                  {errors.datos_ocupacion_telefono.type === "pattern" && (
                    <label className="form-error">Sólo valores numéricos</label>
                  )}
                </div>
              )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-12 mb-2 mt-4" style={{ display: "flex" }}>
            <Boton
              className={"btn _btn-primary font-14"}
              label={"Actualizar datos"}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
