import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Boton from "../Boton";
import styles from "./HeaderDashboard.module.css";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import user from "../../assets/user-d.png";
import { useNavigate } from "react-router-dom";
import { GetAuth } from "../../helpers/Auth";
import { IsUnauthorized, httpService } from "../../helpers/ApiService";
import { getEnviroments } from "../../helpers/enviroments";
const HeaderDashboard = (props) => {
  const navigate = useNavigate();
  const [auth, setAuth] = useState(null);
  const [userImage, setUserImage] = useState(user);
  useEffect(() => {
    let AUTH = GetAuth();
    if (AUTH) {
      setAuth(AUTH);
      getUserMe();
    }
  }, []);
  const getUserMe = async () => {
    return httpService("/user/me/")
      .get()
      .then((res) => {
        if (res && res.id && res.perfil_image) {
          let perfil_image = res.perfil_image;
          setUserImage(getEnviroments().DOMAIN + perfil_image);
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };

  return (
    <div className={styles.headerDashboard}>
      <div>
        <FontAwesomeIcon
          style={{
            width: "25px",
            height: "25px",
            color: "#787878",
            cursor: "pointer",
          }}
          onClick={props.handlerClick.hideMenu}
          icon={faBars}
        />
      </div>
      {auth && auth.profileCompleted && auth.profileCompleted.completed && (
        <div>
          <div className="user-image">
            <div className="user-image-box">
              <img
                src={userImage}
                alt=""
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = user;
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderDashboard;
