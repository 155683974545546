import React, { useEffect, useState } from "react";
import styles from "./Swicht.module.css";
import  "./swicht.css";

const SwitchButtons = ( {handleEvent,btnName1,btnName2,style} ) => {
  const [activeBTN, setActiveBTN] = useState(1);
  useEffect( ()=>{
    handleEvent(activeBTN)
  },[activeBTN] )
  

  const handlerBTNClic = (value) => {
    setActiveBTN(value);
  }
  
  return (
    <div style={ {display: 'flex', ...style} }>
      <div className={styles.swhictPortafdolio}  />
      <div className={styles.btnSwitchInversiones} onClick={() => handlerBTNClic(1)} style={ (activeBTN== 1 ?{ zIndex:"1000",background:'#112e6d',color:'#b9de2c'}:{background:'#dedede'}) }>
        <p className={styles.inversiones} style={ (activeBTN== 1 ?{ color:'#b9de2c'}:{color:'rgb(17, 46, 109)'}) }>{btnName1}</p>
      </div>
      <div className={styles.btnSwitchRendimientos} onClick={() => handlerBTNClic(2)} style={ (activeBTN== 2 ?{ zIndex:"1000",background:'#112e6d',color:'#b9de2c'}:{background:'#dedede'}) }>
        <p className={styles.rendimientos} style={ (activeBTN== 2 ?{ color:'#b9de2c'}:{color:'rgb(17, 46, 109)'}) }>{btnName2}</p>
      </div>
    </div>
  );
};

export default SwitchButtons;