import React from "react";

import styles from "./TableMovements.module.css";
import "./TableMovements.css";
import BadgesProjects from "../BadgesProjects/BadgesProjects/BadgesProjects";
import { Pagination } from "@mui/material";
import { IconInvest } from "../Icons/Icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const TableMovements = ( {data} ) => {
  const capitalizeWords = (str) => {
    let texto = str.replaceAll("-", " ");
    let exceptions = ["y", "de"];
    return texto
      .toLowerCase()
      .split(" ")
      .map((word) => {
        if (exceptions.includes(word)) {
          return word;
        } else {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }
      })
      .join(" ");
  };
  
  return (
    <div className="table-investments">
      
      <div className={styles.tableViewNewInvoice}>
        <div className="table-responsive">
          <table className="table table-borderless">
            <thead>
              <tr className="head border-top-0">
                <th scope="col" className="first-col">
                  Tipo de movimiento
                </th>
                <th scope="col" className="middle-col">
                  Proyecto/caracteristica
                </th>
                <th scope="col" className="middle-col">
                  Fecha
                </th>
                <th scope="col" className="middle-col">
                  saldo
                </th>
                <th scope="col" className="middle-col">
                  Estado
                </th>
                <th scope="col" className="middle-col">
                  Acciones
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((data, i) => (
                <tr
                  key={"row-component-investments-" + i}
                  className="row-component-investments"
                >
                  <td className="col-component-investments">
                    <div className="start col-proyecto">
                      <div className="td-txt">
                        <div>{data.movimiento}</div>
                      </div>
                    </div>
                  </td>
                  <td className="col-component-investments">
                    {/* <div className="badge"> */}
                    <div className="center col-fecha-inversion">
                      {/* <BadgesProjects text={data.categoria} /> */}
                      {data.proyecto_c}
                    </div>
                  </td>
                  <td className="col-component-investments">
                    <div className="center col-fecha-inversion">
                      {data.fecha}
                    </div>
                  </td>
                  <td className="col-component-investments">
                    <div className="col-rendimientos">{data.saldo}</div>
                  </td>
                  <td className="col-component-investments">
                    <div className={"center col-" + data.status}>
                      {capitalizeWords(data.status)}
                    </div>
                  </td>

                  <td className="col-component-investments">
                    <div className="center col-propiedad">
                      <FontAwesomeIcon
                        style={{
                          width: "25px",
                          height: "25px",
                          color: "#787878",
                          cursor: "pointer",
                        }}
                        /* onClick={props.handlerClick.hideMenu} */
                        icon={faEllipsisV}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="col-md-12">
            <div className="_card-footer">
              <div className="_card-footer-right">
                Ver todo
                <li className="arrow-right"></li>
              </div>
            </div>
          </div>
          {/* <div className="pagination-right">
            <div className="box-right">
              <Pagination count={10} />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TableMovements;
