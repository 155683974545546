import React, { useEffect } from "react";
/* import Logo from "../../assets/Logo/Logo"; */
import { FormLogin } from "../../containers/FormLogin/FormLogin";
import "./Login.css";
import imagen from "../../assets/img1.jpeg";
function Login() {
  useEffect(() => {
    const height = document.getElementsByClassName("column-rigth")[0];
    const element = document.getElementsByClassName("login-img")[0];

    new Promise((resolve) =>
      setTimeout(() => {
        element.style.height = height.clientHeight + "px";
        //let imgW = element.getElementsByTagName('img')[0].style.width = height.clientWidth + "px"
        //let imgW = element.getElementsByTagName('img')[0].style.width = (height.clientWidth - 45) + "px"
        //let imgH = element.getElementsByTagName('img')[0].style.height = height.clientHeight + "px"
        resolve(true);
      }, 10)
    );

    function handleResize() {
      let height = document.getElementsByClassName("column-rigth")[0];
      if(height){
        //console.log("resized to: ", height.clientHeight, "x", height.clientWidth);
      }
      
      /* let element = document.getElementsByClassName('login-img')[0];
      element.style.minHeight = height.clientHeight + "px"; */

      //let imgW = element.getElementsByTagName('img')[0].style.width = (height.clientWidth - 45) + "px"
      //let imgH = element.getElementsByTagName('img')[0].style.height = height.clientHeight + "px"
    }

    window.addEventListener("resize", handleResize);
  });

  return (
    <div className="Login container-animated">
      <div className="login-container">
        <div className="container">
          <div className="column-left box">
            <div className="login-img">
              <div className="img-container">
                <img src={imagen} />
              </div>
            </div>
          </div>
          <div className="column-rigth box">
            <FormLogin />
            {/* <div className="copy-rigth">
              <div className="footer-left">
                <div className="float-content">
                  Copyright © 2022 Lincep V 1.0
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
