import { useEffect, useState } from "react";
import BreadcrumbsComponent from "../../components/Breadcrumbs/BreadcrumbsComponent";
import ButtonCardOption from "../../components/ButtonCardOption/ButtonCardOption";
import Logo from "../../assets/Logo/Logo";

export default function PageHelpSupport() {
  const [option, setOption] = useState("sobre-device");
  const [team, setTeam] = useState("");
  const clickOption = (op) => {
    setOption(op);
  };

  const clickTeam = (op) => {
    console.log("clickTeam:", op);
  };
  const openEmail = (email) => {
    window.location.href = `mailto:${email}`;
  };
  const openWhatsApp = (whatsapp) => {
    console.log("openWhatsApp:", whatsapp);
    window.location.href = `whatsapp://send?text=&phone=${whatsapp}'`;
  };

  const openWeb = (web) => {
    window.open("https://" + web, "_blank", "noreferrer");
  };
  useEffect(() => {
    console.log("clickOption:", option);
  }, [option]);

  useEffect(() => {
    console.log("clickTeam:", team);
  }, [team]);

  const terminosCondiciones = (url) => {
    console.log("terminosCondiciones");
    //window.open("https://" + url, "_blank", "noreferrer");
  };
  const politicasPrivacidad = (url) => {
    console.log("politicasPrivacidad");
    //window.open("https://" + url, "_blank", "noreferrer");
  };

  const optionButtonText = (title, body) => {
    return (
      <div style={{ lineHeight: "17px" }}>
        <div
          style={{
            textAlign: "start",
            fontFamily: "Poppins",
            fontWeight: "normal",
            fontSize: "13px",
          }}
        >
          {title}
        </div>

        <div
          style={{
            textAlign: "start",
            fontFamily: "Poppins",
            fontWeight: "bold",
            fontSize: "13px",
          }}
        >
          {body}
        </div>
      </div>
    );
  };

  return (
    <div className="page-help-support container-animated">
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <BreadcrumbsComponent />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-4 col-lg-4">
          <div className="page-security-head">
            <div className="page-security-subtitle">
              Opciones de ayuda y soporte
            </div>
          </div>
          <div className="page-security-options mb-2">
            <ButtonCardOption
              text="Sobre Devise"
              handleClick={() => clickOption("sobre-device")}
            />
          </div>
          <div className="page-security-options mb-2">
            <ButtonCardOption
              text="Soporte"
              handleClick={() => clickOption("soporte")}
            />
          </div>
          <div className="page-security-options mb-2">
            <ButtonCardOption
              text="Preguntas frecuentes"
              handleClick={() => clickOption("faqs")}
            />
          </div>
        </div>
        <div className="col-xl-8 col-lg-8">
          {option === "sobre-device" && (
            <div className="card-custom mt-4 container-animated">
              <div className="page-card-subtitle" style={{ margin: "23px" }}>
                <Logo width="80%" height="80%" />
              </div>
              <div className="page-text-body justify padding mb-1" style={ {color:"#9891bb",fontFamily: "Poppins-ExtraLight"} }>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </div>
              <div className="page-text-body justify padding mb-4" style={ {color:"#9891bb",fontFamily: "Poppins-ExtraLight"} }>
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old. Richard McClintock, a
                Latin professor at Hampden-Sydney College in Virginia, looked up
                one of the more obscure Latin words, consectetur, from a Lorem
                Ipsum passage, and going through the cites of the word in
                classical literature, discovered the undoubtable source. Lorem
                Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
                Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
                written in 45 BC. This book is a treatise on the theory of
                ethics, very popular during the Renaissance. The first line of
                Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line
                in section 1.10.32.
              </div>
              <div className="col-md-12 col-lg-8 mb-2">
                <ButtonCardOption
                  text="Términos y condiciones"
                  handleClick={() => terminosCondiciones("")}
                  icon="IconBoxInfo"
                  iconW={"33"}
                  iconH={"30"}
                />
              </div>
              <div className="col-md-12 col-lg-8 mb-2">
                <ButtonCardOption
                  text="Políticas de privacidad"
                  handleClick={() => politicasPrivacidad("")}
                  icon="IconBoxSecurity"
                  iconW={"33"}
                  iconH={"30"}
                />
              </div>
            </div>
          )}
          {option === "soporte" && (
            <div className="card-custom mt-4 container-animated">
              <div className="page-card-subtitle">
                Contacta con nuestro equipo
              </div>
              <div className="w-50 mb-2">
                <ButtonCardOption
                  text={optionButtonText(
                    "Dirección",
                    "Carrera 100 #26-80 Bogotá D.C. Colombia"
                  )}
                  handleClick={() => clickTeam("address")}
                  icon="IconBoxMap"
                  iconW={"37"}
                  iconH={"30"}
                />
              </div>
              <div className="w-50 mb-2">
                <ButtonCardOption
                  text={optionButtonText("WhatsApp", "+57 305 954 2634")}
                  handleClick={() => openWhatsApp("3059542634")}
                  icon="IconBoxWhatsapp"
                  iconW={"30"}
                  iconH={"23"}
                />
              </div>
              <div className="w-50 mb-2">
                <ButtonCardOption
                  text={optionButtonText(
                    "Correo electrónico",
                    "contacto@devise.com"
                  )}
                  handleClick={() => openEmail("contacto@devise.com")}
                  icon="IconBoxEmail"
                  iconW={"25"}
                  iconH={"23"}
                />
              </div>
              <div className="w-50 mb-2">
                <ButtonCardOption
                  text={optionButtonText("Sitio Web", "www.devise.com")}
                  handleClick={() => openWeb("www.devise.com")}
                  icon="IconBoxWeb"
                  iconW={"30"}
                  iconH={"25"}
                />
              </div>
            </div>
          )}
          
            {option === "faqs" && (
              <div className="card-custom mt-4 container-animated">
                <div className="page-security-head"  style={ {padding: "20px 20px 0px"} }>
                  <div className="page-security-subtitle">Sobre la app</div>
                </div>
                <div className="page-text-body justify padding mb-4" style={ {color:"#9891bb",fontFamily: "Poppins-ExtraLight"} }>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </div>
                <div className="page-security-head" style={ {padding: "20px 20px 0px"} }>
                  <div className="page-security-subtitle">Sobre las Fiducias</div>
                </div>
                <div className="page-text-body justify padding mb-4" style={ {color:"#9891bb",fontFamily: "Poppins-ExtraLight"} }>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old. Richard McClintock,
                  a Latin professor at Hampden-Sydney College in Virginia,
                  looked up one of the more obscure Latin words, consectetur,
                  from a Lorem Ipsum passage, and going through the cites of the
                  word in classical literature, discovered the undoubtable
                  source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of
                  "de Finibus Bonorum et Malorum" (The Extremes of Good and
                  Evil) by Cicero, written in 45 BC. This book is a treatise on
                  the theory of ethics, very popular during the Renaissance. The
                  first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..",
                  comes from a line in section 1.10.32.
                </div>
                <div className="page-security-head" style={ {padding: "20px 20px 0px"} }>
                  <div className="page-security-subtitle" >Sobre los Proyectos</div>
                </div>
                <div className="page-text-body justify padding mb-1" style={ {color:"#9891bb",fontFamily: "Poppins-ExtraLight"} }>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text. It has roots in a piece of classical Latin literature
                  from 45 BC, making it over 2000 years old. Richard McClintock,
                  a Latin professor at Hampden-Sydney College in Virginia,
                  looked up one of the more obscure Latin words, consectetur,
                  from a Lorem Ipsum passage, and going through the cites of the
                  word in classical literature, discovered the undoubtable
                  source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of
                  "de Finibus Bonorum et Malorum" (The Extremes of Good and
                  Evil) by Cicero, written in 45 BC. This book is a treatise on
                  the theory of ethics, very popular during the Renaissance. The
                  first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..",
                  comes from a line in section 1.10.32.
                </div>
                
              </div>
            )}
        </div>
      </div>
    </div>
  );
}
