import BreadcrumbsComponent from "../../components/Breadcrumbs/BreadcrumbsComponent";
/* import logo from './logo.png'; */
import user from "../../assets/user-d.png";
import { IconBoxTakePicture } from "../../components/Icons/Icons";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./PageAccount.css";
import DatosBasicos from "./DatosBasicos/DatosBasicos";
import DatosLegales from "./DatosLegales/DatosLegales";
import DatosOcupacion from "./DatosOcupacion/DatosOcupacion";
import DatosSocioeconomicos from "./DatosSocioeconomicos/DatosSocioeconomicos";
import { IsUnauthorized, httpService } from "../../helpers/ApiService";
import { useNavigate } from "react-router-dom";
import { getEnviroments } from "../../helpers/enviroments";

export default function PageAccount() {
  const navigate = useNavigate();
  const [picture, setPicture] = useState(null);
  const [_user, setUser] = useState(user);
  const [verifyEmail, setVerifyEmail] = useState(true);
  const [userMe, setUserMe] = useState(null);
  useEffect(()=>{
    getUserMe();
  },[]);
  const getUserMe = async () => {
    return httpService("/user/me/")
      .get()
      .then((res) => {
        if(res){
          
          if(res.has_datos_basicos && res.perfil_image){
            setUser(getEnviroments().DOMAIN + res.perfil_image )
          }
          setUserMe(res)
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  const getFileExtension = (filename) => {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
  };
  const makeid = (length = 10) => {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };
  const generateImage = (imageB64, mimeType, fileName) => {
    let blob = new Blob([window.atob(imageB64.split(",")[1])], {
      type: mimeType,
    });
    let _image = {
      name: fileName,
      blob: blob,
      data: `${imageB64}`,
    };
     (document
      .getElementsByClassName("user-image-box")[0]
      .getElementsByTagName("img")[0].src = imageB64);
    (document
      .getElementsByClassName("user-image-box")[1]
      .getElementsByTagName("img")[0].src = imageB64);
    setPicture(_image);
  };
  const getPhoto = (event) => {
    console.log("getPhoto:", event.target.files);
    if (!event.target.files) {
      return;
    }
    let file = event.target.files[0];
    let ext = getFileExtension(file.name);
    let _ext = ext;
    let mimeType = "";
    if (ext === "jpg") {
      _ext = "jpeg";
      mimeType = "image/" + _ext;
    } else {
      mimeType = "image/" + _ext;
    }
    const fileName = "img_" + makeid() + "." + ext;
    let IMAGE = ``;
    var reader = new FileReader();
    reader.onloadend = function () {
      IMAGE = `${reader.result}`;
      generateImage(IMAGE, mimeType, fileName);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="page-account container-animated">
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <BreadcrumbsComponent />
        </div>
      </div>
      <div className="row ">
        <div className="col-xl-4 col-lg-4 mt-4">
          <div className="card-custom">
            <div className="w-100">
              <div className="col-md-12">
                <label htmlFor="profile-picture" className="picture-profile">
                  <div className="floating-text cursor-hand">
                    <div className="svg-transparent-container">
                      <IconBoxTakePicture colorIcon="#fff" />
                    </div>
                    <div className="text-transparent-container">
                      Upload photo
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="profile-img">
                      <img
                        src={picture ? picture.data : _user}
                        className="rounded mx-auto d-block"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = user;
                        }}
                        alt="User"
                      />
                    </div>
                  </div>
                </label>
              </div>
            </div>

            <input
              id="profile-picture"
              style={{ display: "none" }}
              onChange={getPhoto}
              type={"file"}
              accept="image/png, .jpeg, .jpg, image/gif"
            />
            <div
              className="col-md-12"
              style={{ fontFamily: "Poppins-ExtraLight" }}
            >
              <div
                className="page-text-body padding mb-1 mt-4"
                style={{ textAlign: "center", padding: "0" }}
              >
                Allowed *.jpeg, *jpg, *.png, *.gif
              </div>
              <div
                className="page-text-body padding mb-4"
                style={{ textAlign: "center", padding: "0" }}
              >
                Max size of 3.1 MB
              </div>
            </div>
            <div className="col-md-12 mb-4">
              Verificación por correo electrónico
              <div
                className="page-text-body padding mb-1 mt-1"
                style={{
                  textAlign: "left",
                  padding: "0",
                  maxWidth: "320px",
                  margin: "auto",
                  display: "flex",
                }}
              >
                <div style={{ fontFamily: "Poppins-ExtraLight" }}>
                  Desabilita esta opción para no utilizar tú correo
                  electrónico como sistema de verificación
                </div>
                <div>
                  <div className="custom-control custom-switch check-color-primary">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customSwitches"
                      defaultChecked={verifyEmail}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customSwitches"
                    ></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-8 col-lg-8 mt-4">
          <div className="card-custom">
            <Tabs
              defaultActiveKey="datos_basicos"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="datos_basicos" title="Datos básicos">
                <div className="tab-container">
                  <DatosBasicos />
                </div>
              </Tab>
              <Tab eventKey="datos_legales" title="Datos legales">
                <div className="tab-container">
                  <DatosLegales />
                </div>
              </Tab>
              <Tab eventKey="datos_ocupacion" title="Datos ocupación">
                <div className="tab-container">
                  <DatosOcupacion />
                </div>
              </Tab>
              <Tab eventKey="datos_socio_economicos" title="Datos socioeconómicos">
                <div className="tab-container">
                  <DatosSocioeconomicos />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}
