import React from 'react'
import { GetAuth, SetAuth } from '../../helpers/Auth';
import { Navigate } from 'react-router-dom'
function NoProtected({ checkAuth, children }) {
  let AUTH = GetAuth();
  let isSignedIn = false;
  if(AUTH){
    isSignedIn = AUTH.isLogin;  
  }
  if (isSignedIn) {
    return <Navigate to="/dashboard" replace />
  }
  return children
}
export default NoProtected;