import Boton from "../../components/Boton";
import imagen from "../../assets/img2.jpeg";
import Logo from "../../assets/Logo/Logo";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function AccountCreated() {
    const navigate = useNavigate();
    useEffect(() => {
        const height = document.getElementsByClassName("column-rigth")[0];
        const element = document.getElementsByClassName("login-img")[0];
    
        new Promise((resolve) =>
          setTimeout(() => {
            element.style.height = height.clientHeight + "px";
            resolve(true);
          }, 10)
        );
    
        function handleResize() {
          let height = document.getElementsByClassName("column-rigth")[0];
          if (height) {
            //console.log("resized to: ", height.clientHeight, "x", height.clientWidth);
          }
        }
    
        window.addEventListener("resize", handleResize);
      }, []);
      const login = () => {
        //navigate("/login", { replace: true });
        window.location.replace('/login');
      }
  return (
    <div className="page-account-created register-container container-animated">
    <div className="container">
      <div className="column-rigth box">
        <div className="for">
          <div className="logo-img">
            <Logo />
          </div>

          <div className="form-title three text-field-box form-box mb-4">
            <h1 className="form-title-sup">
              <span className="color-tertiary">¡Tucuenta se ha creado </span>
              <span className="color-main">satisfactoriamente!</span>
            </h1>
            <div className="form-title-sub">
              Por favor consfirma tu cuenta iniciando desde el link de tu correo
            </div>
          </div>

          <Boton
            className="btn button-secondary form-box mt-4"
            label="Iniciar sesión"
            redirect=""
            variant="contained"
            handlerClick={() => login()}
          >
          </Boton>
          
        </div>
      </div>
      <div className="column-left box">
        <div className="login-img">
          <div className="img-container">
            <img src={imagen} />
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}
