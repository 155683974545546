export const createKpiForm = {
  valor_token: {
    name: "valor_token",
    label: "Valor de Token",
    type: "number",
    defaultValue:'',
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido"
      },
      pattern: {
        value: /^([0-9]\d*)(\.\d{1,2})?$/,
        message: "Formato incorrecto"
      },
      min: {
        value: 1,
        message: "Monto mínimo 1"
      },
      max: {
        value: 1000000000000,
        message: "Monto máximo 1000000000000"
      }
    },
    placeholder: "Valor ej: 1000.99",
    step:".1"//si es decimal y  type: "number"
  },
  tokens_totales: {
    name: "tokens_totales",
    label: "Tokens totales",
    type: "text",
    defaultValue:'',
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      pattern: {
        value:/^[0-9]+$/,
        message: "Formato incorrecto",
      },
      min: {
        value: 1000,
        message: "Monto mínimo 1000"
      }
    },
    placeholder: "Ingrese el valor",
    step:"1"//si es decimal y  type: "number"
  },
  tokens_disponibles: {
    name: "tokens_disponibles",
    label: "Tokens disponibles",
    type: "text",
    defaultValue:'',
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      pattern: {
        value:/^[0-9]+$/,
        message: "Formato incorrecto",
      },
      min: {
        value: 1000,
        message: "Monto mínimo 1000",
      },
    },
    placeholder: "Ingrese el valor",
    //step:".1"//si es decimal y  type: "number"
  },
  tir_ea: {
    name: "tir_ea",
    label: "Tir ea",
    type: "number",
    defaultValue:'',
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      pattern: {
        value: /^([0-9]\d*)(\.\d{1,2})?$/,
        message: "Formato incorrecto",
      },
      min: {
        value: 0,
        message: "Monto mínimo 0",
      },
      max: {
        value: 100,
        message: "Monto máximo 100",
      },
    },
    placeholder: "Ingrese el valor",
    step:".1"//si es decimal y  type: "number"
  },
  pago_rendimiento: {
    name: "pago_rendimiento",
    label: "Pago rendimiento",
    type: "text",
    defaultValue:'',
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 255,
        message: "El valor no debe exceder los 255 caracteres",
      },
    },
    placeholder: "",
  },
  tenencia_sugerida: {
    name: "tenencia_sugerida",
    label: "Tenencia sugerida",
    type: "text",
    defaultValue:'',
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 255,
        message: "El valor no debe exceder los 255 caracteres",
      },
    },
    placeholder: "",
  },
  renta_mensual: {
    name: "renta_mensual",
    label: "Renta mensual",
    type: "number",
    defaultValue:'',
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      pattern: {
        value: /^([0-9]\d*)(\.\d{1,2})?$/,
        message: "Formato incorrecto",
      },
      min: {
        value: 1,
        message: "Monto mínimo 1",
      },
      max: {
        value: 1000000000000,
        message: "Monto máximo 1000000000000"
      }
    },
    placeholder: "Ingrese el valor",
    step:".1"//si es decimal y  type: "number"
  },
  renta_por_m2: {
    name: "renta_por_m2",
    label: "Renta por m2",
    type: "text",
    defaultValue:'',
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      pattern: {
        value: /^([0-9]\d*)(\.\d{1,2})?$/,
        message: "Formato incorrecto",
      },
      min: {
        value: 1,
        message: "Monto mínimo 1",
      },
      max: {
        value: 1000000000000,
        message: "Monto máximo 1000000000000"
      }
    },
    placeholder: "Ingrese el valor",
    step:".1"//si es decimal y  type: "number"
  },
  incremento_anual: {
    name: "incremento_anual",
    label: "Incremento anual",
    type: "text",
    defaultValue:'',
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 255,
        message: "El valor no debe exceder los 255 caracteres",
      },
    },
    placeholder: "Ingrese el valor",
  },
  modelo_financiero: {
    name: "modelo_financiero",
    label: "Modelo financiero",
    type: "file",
    defaultValue:'',
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 500,
        message: "El nombre no debe exceder los 500 caracteres",
      },
    },
    accept:'application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    placeholder: "Seleccione",
  },
  clase_inversion: {
    name: "clase_inversion",
    label: "Clase Inversión",
    type: "text",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 255,
        message: "El valor no debe exceder los 255 caracteres",
      },
    },
    placeholder: "",
  },
  plazo_contrato: {
    name: "plazo_contrato",
    label: "Plazo de contrato",
    type: "text",
    defaultValue:'',
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      
      maxLength: {
        value: 255,
        message: "Monto mínimo 255",
      },
    },
    placeholder: "",
    //step:".1"//si es decimal y  type: "number"
  },
  teaser: {
    name: "teaser",
    label: "Teaser",
    type: "file",
    defaultValue:'',
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 500,
        message: "El nombre no debe exceder los 500 caracteres",
      },
    },
    accept:'application/pdf',
    placeholder: "Seleccione",
  },
  no_id_fideicomiso: {
    name: "no_id_fideicomiso",
    label: "N. id fideicomiso",
    type: "text",
    defaultValue:'',
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 255,
        message: "El valor no debe exceder los 255 caracteres",
      },
    },
    placeholder: ""
  },
  nombre_fideicomiso: {
    name: "nombre_fideicomiso",
    label: "Nombre fideicomiso",
    type: "text",
    defaultValue:'',
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 255,
        message: "El valor no debe exceder los 255 caracteres",
      },
    },
    placeholder: ""
  },
};
