import React from "react";

export const IconWallet = ({size="25px"}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66406 5.07723H20.2891C20.4716 5.07714 20.6539 5.08872 20.835 5.1119C20.7736 4.68112 20.6256 4.26724 20.4 3.89519C20.1743 3.52315 19.8757 3.20066 19.522 2.94715C19.1684 2.69365 18.767 2.51439 18.3422 2.42019C17.9174 2.32598 17.478 2.31878 17.0503 2.39901L4.2002 4.59286H4.18555C3.37894 4.7471 2.66165 5.20346 2.18018 5.86873C2.90556 5.35278 3.7739 5.07608 4.66406 5.07723ZM20.2891 6.24911H4.66406C3.83554 6.25001 3.04121 6.57954 2.45535 7.1654C1.8695 7.75125 1.53997 8.54558 1.53906 9.37411V18.7491C1.53997 19.5776 1.8695 20.372 2.45535 20.9578C3.04121 21.5437 3.83554 21.8732 4.66406 21.8741H20.2891C21.1176 21.8732 21.9119 21.5437 22.4978 20.9578C23.0836 20.372 23.4132 19.5776 23.4141 18.7491V9.37411C23.4132 8.54558 23.0836 7.75125 22.4978 7.1654C21.9119 6.57954 21.1176 6.25001 20.2891 6.24911ZM17.9697 15.6241C17.6607 15.6241 17.3586 15.5325 17.1016 15.3608C16.8447 15.1891 16.6444 14.9451 16.5262 14.6595C16.4079 14.374 16.377 14.0599 16.4372 13.7568C16.4975 13.4537 16.6464 13.1753 16.8649 12.9568C17.0834 12.7382 17.3618 12.5894 17.6649 12.5291C17.968 12.4688 18.2822 12.4998 18.5677 12.618C18.8532 12.7363 19.0972 12.9366 19.2689 13.1935C19.4406 13.4505 19.5322 13.7526 19.5322 14.0616C19.5322 14.476 19.3676 14.8734 19.0746 15.1665C18.7816 15.4595 18.3841 15.6241 17.9697 15.6241Z"
        fill="#1C4FC1"
      />
      <path
        d="M1.5625 12.6719V7.81348C1.5625 6.75537 2.14844 4.98145 4.18213 4.59717C5.9082 4.27344 7.61719 4.27344 7.61719 4.27344C7.61719 4.27344 8.74023 5.05469 7.8125 5.05469C6.88477 5.05469 6.90918 6.25098 7.8125 6.25098C8.71582 6.25098 7.8125 7.39844 7.8125 7.39844L4.1748 11.5244L1.5625 12.6719Z"
        fill="#1C4FC1"
      />
    </svg>
  );
};
