import { useState } from "react";
import { useForm } from "react-hook-form";
import Boton from "../../../components/Boton";
import FormFloatingLabel from "../../../components/FormFloatingLabel";
import "./DatosBasicos.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
registerLocale('es', es)

export default function DatosBasicos(props) {
  const [startDate, setStartDate] = useState(new Date());
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [verifyEmail, setVerifyEmail] = useState(true);

  const onSubmit = (data) => {
    console.log("onSubmit:", data);
  };
  return (
    <div className="component-datos-basicos container-animated">
      <div className="page-security-head" style={{ padding: "5px 0px 15px" }}>
        <div className="page-security-subtitle">Datos básicos</div>
      </div>

      <div className="w-100">
        <form className="row" onSubmit={handleSubmit((data) => onSubmit(data))}>
          <div className="col-md-6 mb-3">
            <div className="form-custom-box">
              <FormFloatingLabel label="Nombres" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("nombres", { required: true })}
                  placeholder="Nombres"
                  autoComplete="off"
                />
              </div>
              {errors.nombres && (
                <label className="form-error">Este campo es requerido</label>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Apellidos" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("apellidos", { required: true })}
                  placeholder="Apellidos"
                  autoComplete="off"
                />
              </div>
              {errors.apellidos && (
                <label className="form-error">Este campo es requerido</label>
              )}
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Correo" />
              <div className="custom-input-box">
                <input
                  type="email"
                  {...register("correo", { required: true })}
                  placeholder="Correo"
                  autoComplete="off"
                />
              </div>
              {errors.correo && (
                <label className="form-error">Este campo es requerido</label>
              )}
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Teléfono" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("telefono", {
                    required: true,
                    pattern: /^([0-9]\d*)(\.\d+)?$/,
                    maxLength: 10,
                    minLength: 10,
                  })}
                  placeholder="Teléfono"
                  autoComplete="off"
                />
              </div>

              {errors.telefono && (
                <div>
                  {errors.telefono.type === "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 10
                    </label>
                  )}
                  {errors.telefono.type === "minLength" && (
                    <label className="form-error">
                      Mínimo de caracteres 10
                    </label>
                  )}
                  {errors.telefono.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                  {errors.telefono.type === "pattern" && (
                    <label className="form-error">Sólo valores numéricos</label>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="País" />
              <div className="custom-input-box">
                <select {...register("pais", { required: true })}>
                  <option value="">Selecione</option>
                  <option value="001">Colombia</option>
                  <option value="002">España</option>
                </select>
              </div>

              {errors.pais && (
                <div>
                  {errors.pais.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Departamento" />
              <div className="custom-input-box">
                <select {...register("departamento", { required: true })}>
                  <option value="">Selecione</option>
                  <option value="001">Bogotá</option>
                  <option value="002">Madrid</option>
                </select>
              </div>

              {errors.departamento && (
                <div>
                  {errors.departamento.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Dirección de residencia" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("direccion", {
                    required: true,
                    maxLength: 1000,
                  })}
                  placeholder="Dirección de residencia"
                  autoComplete="off"
                />
              </div>
              {errors.direccion && (
                <div>
                  {errors["direccion"].type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                  {errors["direccion"].type === "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 1000
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Código postal" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("codigo_postal", {
                    required: true,
                    maxLength: 10,
                  })}
                  placeholder="Código postal"
                  autoComplete="off"
                />
              </div>
              {errors["codigo_postal"] && (
                <div>
                  {errors["codigo_postal"].type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                  {errors["codigo_postal"].type === "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 10
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
          
            <div className="form-custom-box">
              <FormFloatingLabel label="Fecha de nacimiento" />
              <div className="custom-input-box">
                <input
                  type="date"
                  {...register("fecha_nacimiento", {
                    required: true
                  })}
                  placeholder="Fecha de nacimiento"
                  autoComplete="off"
                  value={startDate}
                />
               {/* <DatePicker
                selected={startDate}
                value={startDate}
                locale="es"
                onChange={(date) => setStartDate(date)}
                placeholder="Fecha de nacimiento"
                
                /> */}
              </div>
              
              {errors["fecha_nacimiento"] && (
                <div>
                  {errors["fecha_nacimiento"].type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                  {errors["fecha_nacimiento"].type === "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 10
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="País de nacimiento" />
              <div className="custom-input-box">
                <select {...register("pais_nacimiento", { required: true })}>
                  <option value="">Selecione</option>
                  <option value="001">Colombia</option>
                  <option value="002">España</option>
                </select>
              </div>

              {errors.pais_nacimiento && (
                <div>
                  {errors.pais_nacimiento.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Departamento de nacimiento" />
              <div className="custom-input-box">
                <select
                  {...register("departamento_nacimiento", { required: true })}
                >
                  <option value="">Selecione</option>
                  <option value="0010001">Bogotá</option>
                  <option value="0020002">Madrid</option>
                </select>
              </div>

              {errors.departamento_nacimiento && (
                <div>
                  {errors.departamento_nacimiento.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Ciudad de nacimiento" />
              <div className="custom-input-box">
                <select {...register("ciudad_nacimiento", { required: true })}>
                  <option value="">Selecione</option>
                  <option value="0010001">Ciudad 1</option>
                  <option value="0020002">Ciudad 2</option>
                </select>
              </div>

              {errors.departamento_nacimiento && (
                <div>
                  {errors.departamento_nacimiento.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* Seccion datos de correspondencia */}
          <div className="col-md-12">
            <div
              className="page-security-head"
              style={{ padding: "5px 0px 15px" }}
            >
              <div className="page-security-subtitle">
                Datos de correspondencia
              </div>
              <div className="page-security-body">
                Utilizar mis datos como datos de correspondencia
                <div className="checkbox-text">
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      {...register("correspondence_data", { required: false })}
                      className="custom-control-input"
                      id="correspondence_data_2"
                      defaultChecked={verifyEmail}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="correspondence_data_2"
                    ></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="País" />
              <div className="custom-input-box">
                <select
                  {...register("pais_datos_correspondencia", {
                    required: true,
                  })}
                >
                  <option value="">Selecione</option>
                  <option value="001">Colombia</option>
                  <option value="002">España</option>
                </select>
              </div>

              {errors.pais_datos_correspondencia && (
                <div>
                  {errors.pais_datos_correspondencia.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Departamento" />
              <div className="custom-input-box">
                <select
                  {...register("departamento_datos_correspondencia", {
                    required: true,
                  })}
                >
                  <option value="">Selecione</option>
                  <option value="001001">Bogotá</option>
                  <option value="002002">Madrid</option>
                </select>
              </div>

              {errors.departamento_datos_correspondencia && (
                <div>
                  {errors.departamento_datos_correspondencia.type ===
                    "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Código postal" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("codigo_postal_datos_correspondencia", {
                    required: true,
                    maxLength: 10,
                  })}
                  placeholder="Código postal"
                  autoComplete="off"
                />
              </div>
              {errors["codigo_postal_datos_correspondencia"] && (
                <div>
                  {errors["codigo_postal_datos_correspondencia"].type ===
                    "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                  {errors["codigo_postal_datos_correspondencia"].type ===
                    "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 10
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Dirección de residencia" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("direccion_datos_correspondencia", {
                    required: true,
                    maxLength: 1000,
                  })}
                  placeholder="Dirección de residencia"
                  autoComplete="off"
                />
              </div>
              {errors.direccion_datos_correspondencia && (
                <div>
                  {errors["direccion_datos_correspondencia"].type ===
                    "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                  {errors["direccion_datos_correspondencia"].type ===
                    "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 1000
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            className="col-md-12 mb-2 mt-4"
            style={props.btnstyle ? props.btnstyle : { display: "flex" }}
          >
            <Boton
              className={"btn _btn-primary font-14"}
              label={
                props && props.btntext ? props.btntext : "Actualizar datos"
              }
            />
          </div>
        </form>
      </div>
    </div>
  );
}
