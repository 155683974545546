import CryptoJS from "crypto-js";
const _KEY = "storageSnVhbiBKey";
const _KEYWELCOME = "welcomwSnVhbiBKey";
const _KEYSESION_PASSWORD_EXP = "password_expiresSXVPbizKey";
const _KEYSESION_LAST_LOGIN = "last_loginFqVPwizKey";
const passphrase =
  "akFFIyFeZVhKeU5IbkZQNSxqY19jYXJyaXpvNzlAaG90bWFpbC5jb20sMjYwMzE5NzksKno0bXJkUXliQlg1Zjh2WSwxNzA1MjAyMw==";
const encrypt = (text) => {
  const cypherText = CryptoJS.AES.encrypt(text, passphrase).toString();
  return cypherText;
};
const decrypt = (cypherText) => {
  const bytes = CryptoJS.AES.decrypt(cypherText, passphrase);
  const decryptText = bytes.toString(CryptoJS.enc.Utf8);
  return decryptText;
};
//token:'SnVhbiBDYXJsb3MgQ2Fycml6byBDb3JvbmVs'
//refreshtoken:'Y29uc3RyaWN0b3I3OUBnbWFpbC5jb20='
export const GetAuth = () => {
  /* const authData = {
    isLogin: false,
    token:"SnVhbiBDYXJsb3MgQ2Fycml6byBDb3JvbmVs",
    refreshtoken:"Y29uc3RyaWN0b3I3OUBnbWFpbC5jb20=",
    user: {
      id:1,
      name: "Felipe Contreras",
      profile: "",
      image: "",
      email:"juanfelipe@devise.com"
    },
  }; */
  const authData = {
    isLogin: false,
    token: "",
    refreshtoken: "",
    user: {
      id: 0,
      name: "",
      profile: "",
      image: "",
      email: "",
    },
  };
  /* let _data = JSON.stringify(authData); */
  //authData = JSON.parse(data);
  var data = localStorage.getItem(_KEY);
  if (!data) {
    return null;
  }
  let decryptData = "";
  let _authData = "";
  try {
    decryptData = decrypt(data);
    _authData = JSON.parse(decryptData);
  } catch (error) {
    localStorage.removeItem(_KEY);
    
  }

  /* let decryptData = decrypt(encryptData);
  console.log("decryptData:",decryptData); */
  //console.log("Auth GetAuth:",_authData);
  return _authData;
};

export const AddWelcome = (message="") => {
  localStorage.setItem(_KEYWELCOME, message);
}
export const getWelcome = () => {
  return localStorage.getItem(_KEYWELCOME);
}
export const removeWelcome = () => {
  localStorage.removeItem(_KEYWELCOME);
}


export const AddPasswordExpires = (message="") => {
  localStorage.setItem(_KEYSESION_PASSWORD_EXP, message);
}
export const getPasswordExpires = () => {
  return localStorage.getItem(_KEYSESION_PASSWORD_EXP);
}
export const removePasswordExpires = () => {
  localStorage.removeItem(_KEYSESION_PASSWORD_EXP);
}

export const AddLastLogin = (message="") => {
  localStorage.setItem(_KEYSESION_LAST_LOGIN, message);
}
export const getLastLogin = () => {
  return localStorage.getItem(_KEYSESION_LAST_LOGIN);
}
export const removeLastLogin = () => {
  localStorage.removeItem(_KEYSESION_LAST_LOGIN);
}

export const AddAuth = (data) => {
  const authData = {
    isLogin: true,
    token: data.token,
    refreshtoken: data.refreshtoken,
    profileCompleted: data.profileCompleted,
    /*  profileCompleted:data.profileCompleted, */
    sesion:{
      ...data.sesion
    },
    user: {
      id: data.user.id,
      name: data.user.name,
      profile: data.user.profile,
      image: data.user.image,
      email: data.user.email,
      code: data.user.code,
      subrole: data.user.subrole,
      role: data.user.role,
    },
  };
  let _data = JSON.stringify(authData);
  let encryptData = encrypt(_data);
  /* let decryptData = decrypt(encryptData);
  console.log("decryptData:",decryptData); */
  localStorage.setItem(_KEY, encryptData);
  return authData;
};
export const SetAuth = (isLogin) => {
  var data = localStorage.getItem(_KEY);
  let decryptData = decrypt(data);
  const authData = JSON.parse(decryptData);
  authData.isLogin = isLogin;
  let _data = JSON.stringify(authData);
  let encryptData = encrypt(_data);
  localStorage.setItem(_KEY, encryptData);
  return authData;
};
export const UpdateAuth = (data) => {
  let _data = JSON.stringify(data);
  let encryptedData = encrypt(_data);
  localStorage.setItem(_KEY, encryptedData);
  return data;
};

export const LogOut = () => {
  let authData = {
    isLogin: false,
    token: "",
    refrfeshtoken: "",
    profileCompleted: {
      completed: false,
      data: {
        has_datos_basicos: false,
        has_resident_info: false,
        has_workplace_info: false,
        has_financial_info: false,
        has_socioeconomic_info: false,
      },
    },
    sesion:{
      last_login:'',
      password_expires:''
    },
    user: {
      id: "",
      name: "",
      profile: "",
      image: "",
      email: "",
      code: "",
      subrole: null,
      role: null,
    },
  };
  let _data = JSON.stringify(authData);
  let encryptData = encrypt(_data);
  localStorage.setItem(_KEY, encryptData);
  localStorage.removeItem(_KEY);
  authData = JSON.parse(_data);
  return authData;
};
