import { useForm } from "react-hook-form";
import Boton from "../../../components/Boton";
import FormFloatingLabel from "../../../components/FormFloatingLabel";

export default function DatosLegales() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("onSubmit:", data);
  };
  return (
    <div className="component-datos-legales container-animated">
      <div className="page-security-head" style={{ padding: "5px 0px 15px" }}>
        <div className="page-security-subtitle">Datos legales</div>
      </div>

      <div className="w-100">
        <form className="row" onSubmit={handleSubmit((data) => onSubmit(data))}>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Tipo de identificación" />
              <div className="custom-input-box">
                <select {...register("tipo_identificacion", { required: true })}>
                  <option value="">Selecione</option>
                  <option value="001">Cedula de ciudadania</option>
                  <option value="002">Cedula de extranjería</option>
                </select>
              </div>

              {errors.tipo_identificacion && (
                <div>
                  {errors.tipo_identificacion.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Número de documento" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("num_documento", {
                    required: true,
                    pattern: /^([0-9]\d*)(\.\d+)?$/,
                    maxLength: 20,
                  })}
                  placeholder="Número de documento"
                  autoComplete="off"
                />
              </div>

              {errors.num_documento && (
                <div>
                  {errors.num_documento.type === "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 20
                    </label>
                  )}
                  
                  {errors.num_documento.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                  {errors.num_documento.type === "pattern" && (
                    <label className="form-error">Sólo valores numéricos</label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Pais de expedición" />
              <div className="custom-input-box">
                <select {...register("pais_expedicion", { required: true })}>
                  <option value="">Selecione</option>
                  <option value="001">Colombia</option>
                  <option value="002">España</option>
                </select>
              </div>

              {errors.pais_expedicion && (
                <div>
                  {errors.pais_expedicion.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Departamento de expedición" />
              <div className="custom-input-box">
                <select {...register("departamento_expedicion", { required: true })}>
                  <option value="">Selecione</option>
                  <option value="001001">Bogotá</option>
                  <option value="002002">Madrid</option>
                </select>
              </div>

              {errors.departamento_expedicion && (
                <div>
                  {errors.departamento_expedicion.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Ciudad de expedición" />
              <div className="custom-input-box">
                <select {...register("ciudad_expedicion", { required: true })}>
                  <option value="">Selecione</option>
                  <option value="001001001">Bogotá</option>
                  <option value="002002002">Cundinamarca</option>
                </select>
              </div>

              {errors.ciudad_expedicion && (
                <div>
                  {errors.ciudad_expedicion.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Fecha de expedición" />
              <div className="custom-input-box">
                <input
                  type="date"
                  {...register("fecha_expedicion", {
                    required: true
                  })}
                  placeholder="Fecha de expedición"
                  autoComplete="off"
                />
              </div>
              {errors["fecha_expedicion"] && (
                <div>
                  {errors["fecha_expedicion"].type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                  {errors["fecha_expedicion"].type === "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 10
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Tipo de persona" />
              <div className="custom-input-box">
                <select {...register("tipo_persona", { required: true })}>
                  <option value="">Selecione</option>
                  <option value="001003">Natural</option>
                  <option value="002004">Juridica</option>
                </select>
              </div>

              {errors.tipo_persona && (
                <div>
                  {errors.tipo_persona.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* Seccion datos bamcarios */}
          <div className="col-md-12">
            <div
              className="page-security-head"
              style={{ padding: "5px 0px 15px" }}
            >
              <div className="page-security-subtitle">Datod bancarios</div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Banco" />
              <div className="custom-input-box">
                <select {...register("banco", { required: true })}>
                  <option value="">Selecione</option>
                  <option value="001003">Banco AV Villas</option>
                  <option value="002004">Bancolombia</option>
                </select>
              </div>

              {errors.banco && (
                <div>
                  {errors.banco.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Número de cuenta" />
              <div className="custom-input-box">
                <input
                  type="text"
                  {...register("num_cuenta", {
                    required: true,
                    maxLength: 50,
                  })}
                  placeholder="Número de cuenta"
                  autoComplete="off"
                />
              </div>
              {errors["num_cuenta"] && (
                <div>
                  {errors["num_cuenta"].type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                  {errors["num_cuenta"].type === "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 10
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Tipo de cuenta" />
              <div className="custom-input-box">
                <select {...register("tipo_cuenta", { required: true })}>
                  <option value="">Selecione</option>
                  <option value="001003">Ahorro</option>
                  <option value="002004">Corriente</option>
                </select>
              </div>

              {errors.tipo_cuenta && (
                <div>
                  {errors.tipo_cuenta.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Departamnto de expedición" />
              <div className="custom-input-box">
                <select {...register("banco_departamento_expedicion", { required: true })}>
                  <option value="">Selecione</option>
                  <option value="001003">Bogotá</option>
                  <option value="002004">Cundinamarca</option>
                </select>
              </div>

              {errors.banco_departamento_expedicion && (
                <div>
                  {errors.banco_departamento_expedicion.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Ciudad de expedición" />
              <div className="custom-input-box">
                <select {...register("banco_ciudad_expedicion", { required: true })}>
                  <option value="">Selecione</option>
                  <option value="001003">Bogotá</option>
                  <option value="002004">Cundinamarca</option>
                </select>
              </div>

              {errors.banco_ciudad_expedicion && (
                <div>
                  {errors.banco_ciudad_expedicion.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Fecha de expedición" />
              <div className="custom-input-box">
                <input
                  type="date"
                  {...register("banco_fecha_expedicion", {
                    required: true
                  })}
                  placeholder="Fecha de expedición"
                  autoComplete="off"
                />
              </div>
              {errors["banco_fecha_expedicion"] && (
                <div>
                  {errors["banco_fecha_expedicion"].type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                  {errors["banco_fecha_expedicion"].type === "maxLength" && (
                    <label className="form-error">
                      Máximo de caracteres 10
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="form-custom-box">
              <FormFloatingLabel label="Tipo de persona" />
              <div className="custom-input-box">
                <select {...register("banco_tipo_persona", { required: true })}>
                  <option value="">Selecione</option>
                  <option value="001003">Natural</option>
                  <option value="002004">Juridica</option>
                </select>
              </div>

              {errors.banco_tipo_persona && (
                <div>
                  {errors.banco_tipo_persona.type === "required" && (
                    <label className="form-error">
                      Este campo es requerido
                    </label>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="col-md-12 mb-2 mt-4" style={{ display: "flex" }}>
            <Boton
              className={"btn _btn-primary font-14"}
              label={"Actualizar datos"}
            />
          </div>
        </form>
      </div>
    </div>
  );
}
