import {
  categoria,
  ciudad,
  pais,
  region,
  sponsor,
  tipoProyecto,
} from "./dropdown";

export const createAssetForm = {
  propietario: {
    name: "propietario",
    label: "Propietario",

    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 100,
        message: "El valor no debe exceder los 100 caracteres",
      },
    },

    placeholder: "propietario",
  },
  codigo: {
    name: "codigo",
    label: "Código",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 100,
        message: "El valor no debe exceder los 100 caracteres",
      },
    },
    placeholder: "código",
  },
  nombre: {
    name: "nombre",
    label: "Nombre",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 150,
        message: "El valor no debe exceder los 150 caracteres",
      },
    },
    placeholder: "nombre",
  },
  tipo_proyecto: {
    name: "tipo_proyecto",
    label: "Tipo de proyecto",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
    },
    placeholder: "Seleccione",
  },
  /* sponsor:{
      name: "sponsor",
      label: "Sponsor",
      validators: {
        required: {
          value: true,
          message: "Este campo es requerido",
        },
      },
      placeholder: "Seleccione",
    }, */
  descripcion_proyecto: {
    name: "descripcion_proyecto",
    label: "Descripción del proyecto",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 1500,
        message: "El valor no debe exceder los 1500 caracteres",
      },
    },
    placeholder: "descripción",
  },

  dimension_m2: {
    name: "dimension_m2",
    label: "Dimenciones m²",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      pattern: {
        value: /^([0-9]\d*)(\.\d+)?$/,
        message: "Sólo valores numérico",
      },
    },
    placeholder: "m²",
  },
  pais: {
    name: "pais",
    label: "País",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
    },
    placeholder: "Seleccione",
  },
  region: {
    name: "region",
    label: "Región/Estado",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
    },
    placeholder: "Seleccione",
  },
  ciudad: {
    name: "ciudad",
    label: "Ciudad",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
    },
    placeholder: "Seleccione",
  },
  direccion: {
    name: "direccion",
    label: "Dirección",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 1500,
        message: "El valor no debe exceder los 1500 caracteres",
      },
    },
    placeholder: "dirección",
  },

  latitud: {
    name: "latitud",
    label: "Latitud",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 100,
        message: "El valor no debe exceder los 100 caracteres",
      },
      pattern: {
        value: /^-?([1-8]?\d(?:\.\d{1,})?|90(?:\.0{1,})?)$/,
        message: "La latitud no es válida",
      },
    },
    placeholder: "latitud",
  },

  longitud: {
    name: "longitud",
    label: "Longitud",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 100,
        message: "El valor no debe exceder los 100 caracteres",
      },
      pattern: {
        value: /^-?((?:1[0-7]|[1-9])?\d(?:\.\d{1,})?|180(?:\.0{1,})?)$/,
        message: "La latitud no es válida",
      },
    },
    placeholder: "longitud",
  },
  descripcion_ubicacion: {
    name: "descripcion_ubicacion",
    label: "Descripcion de ubicacion",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 1500,
        message: "El valor no debe exceder los 1500 caracteres",
      },
    },
    placeholder: "ej. Es cerca de algun sitio",
  },

  area_construida_m2: {
    name: "area_construida_m2",
    label: "Área construida m²",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      pattern: {
        value: /^([0-9]\d*)(\.\d+)?$/,
        message: "Sólo valores numérico",
      },
    },
    placeholder: "m²",
  },

  area_gla_m2: {
    name: "area_gla_m2",
    label: "Área gla m²",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      pattern: {
        value: /^([0-9]\d*)(\.\d+)?$/,
        message: "Sólo valores numérico",
      },
    },
    placeholder: "m²",
  },

  area_vendible_m2: {
    name: "area_vendible_m2",
    label: "Área vendible m²",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      pattern: {
        value: /^([0-9]\d*)(\.\d+)?$/,
        message: "Sólo valores numérico",
      },
    },
    placeholder: "m²",
  },

  area_lote_m2: {
    name: "area_lote_m2",
    label: "Área lote m²",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      pattern: {
        value: /^([0-9]\d*)(\.\d+)?$/,
        message: "Sólo valores numérico",
      },
    },
    placeholder: "m²",
  },

  ano_construccion: {
    name: "ano_construccion",
    label: "Año de construcción",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      pattern: {
        value: /^([0-9]\d*)?$/,
        message: "Sólo valores numérico",
      },
      min: {
        value: 1000,
        message: "El año debe ser mayor a 999",
      },
    },
    placeholder: "año de construcción",
  },

  categoria: {
    name: "categoria",
    label: "Categoria",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
    },
    placeholder: "Seleccione",
  },
  /* code_phone: {
      name: "code_phone",
      label: "Código",
  
      validators: {
        required: {
          value: true,
          message: "Este campo es requerido",
        },
      },
      placeholder: "Seleccione",
    },
    phone: {
      name: "phone",
      label: "Teléfono",
      validators: {
        required: {
          value: true,
          message: "Este campo es requerido",
        },
        pattern: {
          value: /^([0-9]\d*)?$/,
          message: "Sólo valores numérico",
        },
      },
      placeholder: "teléfono",
    },
    password: {
      name: "password",
      label: "Contraseña",
      validators: {
        required: {
          value: true,
          message: "Este campo es requerido",
        },
        pattern: {
          value:
            /^(?=.*[A-ZÀ-ÿ\u00f1\u00d1])(?=.*\d)(?=.*[@$!%*?&\+\-])[A-Za-zÀ-ÿ\u00f1\u00d1\d@$!%*?&\+\-]+$/,
          message:
            'La contraseña debe contener al menos una mayúscula, un número y un carácter especial "@$!%*?&+-" y un mínimo de 5 carecteres',
        },
        minLength:{
          value:5,
          message: 'La contraseña debe contener al menos una mayúscula, un número y un carácter especial "@$!%*?&+-" y un mínimo de 5 carecteres',
        }
      },
      placeholder: "contraseña",
    },
    re_password: {
      name: "re_password",
      label: "Verificar contraseña",
      validators: {
        required: {
          value: true,
          message: "Este campo es requerido",
        },
      },
      placeholder: "verificar contraseña",
    },
    referred_by_code: {
      name: "referred_by_code",
      label: "Coódigo de referido",
      validators: {
        required: {
          value: false,
          message: "Este campo es requerido",
        },
        pattern: {
          value:/^[A-Z0-9]+$/,
          message: "Formato inválido",
        },
        maxLength: {
          value: 30,
          message:
            "El valor no debe exceder los 30 caracteres",
        },
      },
      placeholder: "código de referido",
    }, */
};
export const createAssetFormP2 = {
  matricula_inmobiliaria: {
    name: "matricula_inmobiliaria",
    label: "Matricula inmobiliaria",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 100,
        message: "El valor no debe exceder los 100 caracteres",
      },
    },
    placeholder: "matricula inmobiliaria",
  },
  cedula_catastral: {
    name: "cedula_catastral",
    label: "Cédula catastral",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 100,
        message: "El valor no debe exceder los 100 caracteres",
      },
    },
    placeholder: "cédula catastral",
  },
  certificado_tradicion_libertad: {
    name: "certificado_tradicion_libertad",
    label: "Certificado de tradicion libertad",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
    },
    accept: ".pdf",
    placeholder: "certificado de tradicion libertad",
  },
  canon_arriendo: {
    name: "canon_arriendo",
    label: "Canon de arriendo",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      pattern: {
        value: /^([0-9]\d*)(\.\d+)?$/,
        message: "Sólo valores numérico",
      },
    },
    placeholder: "canon de arriendo",
  },
  contrato_arriendo: {
    name: "contrato_arriendo",
    label: "Contrato de arriendo",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
    },
    accept: ".pdf",
    placeholder: "contrato de arriendo",
  },
  nombre_arrendatario: {
    name: "nombre_arrendatario",
    label: "Nombre de arrendatario",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 100,
        message: "El valor no debe exceder los 100 caracteres",
      },
    },
    placeholder: "nombre de arrendatario",
  },
  informacion_arrendatario: {
    name: "informacion_arrendatario",
    label: "Informacion del arrendatario",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 100,
        message: "El valor no debe exceder los 100 caracteres",
      },
    },
    placeholder: "informacion del arrendatario",
  },
  vencimiento_contrato_arrendamiento: {
    name: "vencimiento_contrato_arrendamiento",
    label: "Vencimiento de contrato arrendamiento",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 100,
        message: "El valor no debe exceder los 100 caracteres",
      },
    },
    placeholder: "vencimiento de contrato arrendamiento",
  },
  imagen_1: {
    name: "imagen_1",
    label: "Imagen 1",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
    },
    accept: ".jpg,.png,.jpeg",
    placeholder: "imagen 1",
  },
  imagen_2: {
    name: "imagen_2",
    label: "Imagen 2 - opcional",
    validators: {
      required: {
        value: false,
        message: "Este campo es requerido",
      },
    },
    accept: ".jpg,.png,.jpeg",
    placeholder: "imagen 2",
  },
  imagen_3: {
    name: "imagen_3",
    label: "Imagen 3 - opcional",
    validators: {
      required: {
        value: false,
        message: "Este campo es requerido",
      },
    },
    accept: ".jpg,.png,.jpeg",
    placeholder: "imagen 3",
  },
  imagen_4: {
    name: "imagen_4",
    label: "Imagen 4 - opcional",
    validators: {
      required: {
        value: false,
        message: "Este campo es requerido",
      },
    },
    accept: ".jpg,.png,.jpeg",
    placeholder: "imagen 4",
  },
  imagen_5: {
    name: "imagen_5",
    label: "Imagen 5 - opcional",
    validators: {
      required: {
        value: false,
        message: "Este campo es requerido",
      },
    },
    accept: ".jpg,.png,.jpeg",
    placeholder: "imagen 5",
  },
  valor_activo: {
    name: "valor_activo",
    label: "Valor de activo",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      pattern: {
        value: /^([0-9]\d*)(\.\d+)?$/,
        message: "Sólo valores numérico",
      },
    },
    placeholder: "valor de activo",
  },
  sponsor: {
    name: "sponsor",
    label: "Sponsor",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
    },
    placeholder: "Seleccione",
  },
  fiducia: {
    name: "fiducia",
    label: "fiducia",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
    },
    placeholder: "Seleccione",
  },
  estado_aprobacion: {
    name: "estado_aprobacion",
    label: "Estado de aprobación",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      maxLength: {
        value: 100,
        message: "El valor no debe exceder los 100 caracteres",
      },
    },
    placeholder: "estado de aprobación",
  },
};

export const extractDataForm = (obj) => {
  let data = {};
  const keys = Object.keys(obj);
  keys.forEach((key, index) => {
    if (key === "pais") {
      //obj[key].id
      data[key] = pais(obj[key]).id;
    } else if (key === "region") {
      data[key] = region(obj[key]).id;
    } else if (key === "ciudad") {
      data[key] = ciudad(obj[key]).id;
    } else if (key === "tipo_proyecto") {
      data[key] = tipoProyecto(obj[key]).id;
    } else if (key === "categoria") {
      data[key] = categoria(obj[key]).id;
    } else if (key === "sponsor") {
      data[key] = sponsor(obj[key]).id;
    } else if (key === "fiducia") {
      data[key] = sponsor(obj[key]).id;
    } else {
      data[key] = obj[key];
    }
  });
  return data;
};
