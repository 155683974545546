import React from "react";
import { Button } from "../Button/Button";
import styles from "./CardWallet.module.css";
import "./CardWallet.css";
import { useNavigate } from "react-router-dom";
import { IconBoxWallet } from "../Icons/Icons";

const CardWallet = ( ) => {
  const navigate = useNavigate();
  const retirar = () => {
    navigate("/solicitud-de-retiro");
  }
  const depositar = () => {
    navigate("/recarga-tu-cuenta");
  }

  return (
    <div className="CardWallet-container">
      <div className={styles.CardWallet}>
        <div className={styles.cardWallet}>
          <div className={styles.information}>
            <div className={styles.titleTwo}>
              <p className={styles.title}><IconBoxWallet height="30px" /> Mi Cartera </p>
            </div>
            <div className={styles.saldocopTwo}>
              <p className={styles.saldocop}>
                $22.500.000
                <strong className={styles.saldocopEmphasis1}> </strong>
                <strong className={styles.saldocopEmphasis2}>COP</strong>
              </p>
            </div>
            <div className={styles.saldocopTwo}>
              <p className={styles.saldoDeviseCoin}>
                <strong className={styles.saldoDeviseCoinEmphasis0}>
                  22.500.000
                </strong>
                <strong className={styles.saldoDeviseCoinEmphasis1}> </strong>
                Devise Coin
              </p>
            </div>
            <div className={styles.description}>
              <p className={styles.text}>
                Mi Wallet &#61; Saldo en cuenta &#43; Ingresos por rentas
              </p>
            </div>
          </div>
          <div className={styles.buttonBox}>
            <div>
              {/* <button className={styles.buttonDepositar}>
            <p className={styles.textTwo}>Depositar</p>
          </button> */}
              <Button handleClick={depositar} redirect="/solicitud-de-retiro" className="button btn primary">Depositar</Button>
            </div>
            <div>
              <Button handleClick={retirar} className="button btn secondary">Retirar</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardWallet;
