export const StatusData = {
    ACTIVO_SIN_APROBAR_SPONSOR: {
      nombre: "Activo pendiente aprobación Sponsor",
      paso: 1,
      color: "#FF5733",
    },
    PENDIENTE_RESPUESTA_PROPIETARIO: {
      nombre: "Pendiente respuesta del Propietario",
      paso: 1.1,
      color: "#FFC300",
    },
    ACTIVO_APROBADO_SPONSOR: {
      nombre: "Activo aprobado por el Sponsor",
      paso: 2,
      color: "#33FF57",
    },
    PENDIENTE_RESPUESTA_SPONSOR: {
      nombre: "Pendiente respuesta del Sponsor",
      paso: 2.1,
      color: "#337DFF",
    },
    ACTIVO_APROBADO_DEVISE: {
      nombre: "Activo aprobado por Devise",
      paso: 3,
      color: "#FF33E9",
    },
    ACTIVO_APROBADO_FIDUCIA: {
      nombre: "Activo aprobado por Fiducia",
      paso: 4,
      color: "#33A7FF",
    },
    ACTIVO_FIDEICOMISO_CARGADO: {
      nombre: "Activo con fideicomiso cargado",
      paso: 5,
      color: "#FF33A6",
    },
    ACTIVO_FIDEICOMISO_TRAMITE_FIRMA: {
      nombre: "Activo con firmas de fideicomiso pendientes",
      paso: 5.1,
      color: "#33FFBE",
    },
    ACTIVO_FIDEICOMISO_FIRMADO: {
      nombre: "Activo con fideicomiso firmado",
      paso: 6,
      color: "#A633FF", //#A633FF
    },
    /* ACTIVO_PENDIENTE_MINUTA_ESCRITURACION: {
      nombre: "Activo pendinete carque minuta escrituración sponsor",
      paso: 6.5,
      color: "#A6F3FF",
    }, */
    MINUTA_ESCRITURACION_CARGADA: {
      nombre:
        "Activo con minuta de escrituracion cargada y pendiente por aprobar",
      paso: 7,
      color: "#008B8B",
    },
    MINUTA_ESCRITURACION_APROBADA: {
      nombre: "Activo con minuta de escrituracion aprobada",
      paso: 8,
      color: "#FF8C00",
    },
    EN_REVISION_POR_NOTARIA: {
      nombre: "Activo pendiente por nueva escritura",
      paso: 9,
      color: "#00BFFF",
    },
    NUEVA_ESCRITURA_TRAMITADA: {
      nombre: "Activo con la nueva escritura por la notaria",
      paso: 9.2,
      color: "#FFD700",
    },
    NUEVO_CERTIFICADO_TRADICION_Y_LIBERTAD: {
      nombre: "Activo con certificado tradición y libertad actualizado",
      paso: 9.4,
      color: "#ADD8E6",
    },
    /* ENCARGO_FIDUCIARIO_ACTIVO: {
      nombre: "Activo con encargo fiduciario activo",
      paso: 9.6,
      color: "#ADFF2F",
    }, */
    ACTIVO_EN_CALCULO_KPI_SPONSOR: {
      nombre: "Activo pendiente por configuracion KPI",
      paso: 10,
      color: "#8A2BE2",
    },
    PENDIENTE_RESPUESTA_KPI_DEVISE: {
      nombre: "Pendiente respuesta KPI por Devise",
      paso: 10.5,
      color: "#337DFF",
    },
    ACTIVO_KPI_APROBADO_DEVISE: {
      nombre: "Activo con KPI aprobado por Devise",
      paso: 11,
      color: "#FF33E9",
    },
    CONTRATO_CONFIGURADO_KALEIDO: {
      nombre: "Activo con ID. contrato Kaleido",
      paso: 11.5,
      color: "#A133E9",
    },
    PUBLICACION_ACTIVO_APROBADO_DEVISE: {
      nombre: "Activo con KPI aprobado por Devise",
      paso: 12,
      color: "#0000FF",
    },
    DOCUMENTO_DE_CESION_CARGADO: {
      nombre: "Activo con documento de sesion cargado por el Sponsor",
      paso: 13,
      color: "#7CFC00",
    },
  };